import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { DatePicker } from '@material-ui/pickers';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as Yup from 'yup';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import MaskedInput from 'react-text-mask';
import axios from 'axios';

import { API_LOCAL_URL, APP_ERROR } from '~/constants';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import clienteService from '~/store/modules/cliente/cliente-service';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import ErrorFormik from '~/components/ErrorFormik';
import { CurrencyInputCustom } from '~/components/CustomInput';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

let cancel;
const CancelToken = axios.CancelToken;

const ModalCadastroCheque = () => {
  const dispatch = useDispatch();

  const { selects, showModalCadastroCheque } = useSelector(state => state.pdv);

  const isOnline = useSelector(getApplicationIsOnline());

  const [loadingCliente, setLoadingCliente] = useState(false);
  const [cheque, setCheque] = useState({ ...showModalCadastroCheque?.data });
  const [validationSchema] = useState(
    Yup.object().shape({
      clienteNome: Yup.string().required('Nome do cliente é obrigatório'),
      clienteTelefone: Yup.string().required('Telefone do cliente é obrigatório'),
      clienteCpfCnpj: Yup.string().required('CPF/CNPJ é obrigatório'),
      finInstituicaoFinanceira: Yup.object().required('Banco é obrigatório'),
      chequeAgencia: Yup.string().required('Agência é obrigatório'),
      chequeConta: Yup.string().required('Conta é obrigatório'),
      chequeSerie: Yup.string().required('Série é obrigatório'),
      chequeNumero: Yup.string().required('Número é obrigatório'),
      // chequeVencimento: Yup.date().required('Data é obrigatório'),
      chequeCompensacao: Yup.string().required('Compensação é obrigatório')
    })
  );

  const handleChangeCpfCnpj = async cpfCnpj => {
    try {
      if (cancel != undefined) cancel();

      if (cpfCnpj?.length < 14) {
        return;
      }
      setLoadingCliente(true);
      const {
        data: { items }
      } = isOnline
        ? await clienteService.query(cpfCnpj, {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            })
          })
        : await api.get(`${API_LOCAL_URL}/integracao/cliente?queryString=${cpfCnpj}`);

      if (items?.length > 0) {
        setCheque({
          clienteNome: items[0]?.razaoSocial ?? '',
          clienteCpfCnpj: items[0]?.pfCpf ?? items[0]?.pjCnpj,
          clienteTelefone: items[0]?.telefone ?? ''
        });
      } else
        setCheque({
          clienteNome: '',
          clienteCpfCnpj: cpfCnpj,
          clienteTelefone: ''
        });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoadingCliente(false);
    }
  };

  const handleClearCliente = () => {
    setCheque(state => ({
      ...state,
      clienteNome: '',
      clienteCpfCnpj: '',
      clienteTelefone: '',
      clienteCelular: '',
    }));
  };

  const handleSubmit = values => {
    handleClose();

    dispatch(pdvActions.adicionarChequeParcela(showModalCadastroCheque?.uuid, values));
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalCadastroCheque({ show: false }));
  };

  // useEffect(()=> {
  //   const _cheque = {
  //     chequeValor: data?.chequeValor
  //   }

  //   if(data?.clienteCpfCnpj){
  //     _cheque
  //   }
  // }, [])

  return (
    <Mui.Dialog
      open={showModalCadastroCheque?.show}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          handleClose();
        }
      }}
      PaperComponent={Mui.Paper}
      maxWidth={'lg'}
    >
      <Mui.DialogTitle>
        <Mui.AppBar color="primary" position="static">
          <Mui.Toolbar>
            <Mui.Box>
              <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                Cadastro de cheque
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box flexGrow={1} />

            <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
              <MuiIcons.Close />
            </Mui.IconButton>
          </Mui.Toolbar>
        </Mui.AppBar>
      </Mui.DialogTitle>
      <Formik
        initialValues={showModalCadastroCheque?.data}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
          <>
            <form noValidate onSubmit={handleSubmit}>
              <Mui.DialogContent dividers style={{ padding: 32, width: 800 }}>
                <Mui.Grid container spacing={2}>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item md={6}>
                      <Mui.TextField
                        name="clienteCpfCnpj"
                        label={'CPF/CNPJ'}
                        value={values?.clienteCpfCnpj}
                        error={Boolean(touched.clienteCpfCnpj && errors.clienteCpfCnpj)}
                        autoFocus
                        fullWidth
                        helperText={touched.clienteCpfCnpj && errors.clienteCpfCnpj}
                        onBlur={handleBlur}
                        onChange={e => {
                          const cpfCnpj = e.target.value;
                          setFieldValue('clienteCpfCnpj', cpfCnpj);
                          handleChangeCpfCnpj(cpfCnpj);
                        }}
                        variant="outlined"
                        InputProps={{
                          inputComponent: CpfCnpj,
                          endAdornment: (
                            <>
                              {loadingCliente ? (
                                <Mui.CircularProgress color="inherit" size={20} />
                              ) : (
                                <>
                                  {values?.clienteCpfCnpj?.length >= 14 && (
                                    <Mui.IconButton
                                      tabIndex={-1}
                                      color="inherit"
                                      onClick={handleClearCliente}
                                      aria-label="close"
                                    >
                                      <MuiIcons.Close />
                                    </Mui.IconButton>
                                  )}
                                </>
                              )}
                            </>
                          )
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>

                    <Mui.Grid item md={6}>
                      <Mui.TextField
                        name="clienteTelefone"
                        label={'Telefone'}
                        value={values?.clienteTelefone}
                        error={Boolean(touched.clienteTelefone && errors.clienteTelefone)}
                        fullWidth
                        helperText={touched.clienteTelefone && errors.clienteTelefone}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        InputProps={{
                          inputComponent: TextMaskCustom
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={12}>
                      <Mui.TextField
                        name="clienteNome"
                        label={'Nome'}
                        value={values?.clienteNome}
                        error={Boolean(touched.clienteNome && errors.clienteNome)}
                        fullWidth
                        helperText={touched.clienteNome && errors.clienteNome}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item md={6}>
                      <Mui.Select
                        variant="outlined"
                        value={values?.finInstituicaoFinanceira ?? null}
                        onChange={e => setFieldValue('finInstituicaoFinanceira', e.target.value)}
                        fullWidth
                      >
                        {selects?.bancos?.map((x, index) => (
                          <Mui.MenuItem key={index} value={x}>
                            {x.instituicao}
                          </Mui.MenuItem>
                        ))}
                      </Mui.Select>
                    </Mui.Grid>
                    <Mui.Grid item md={6}>
                      <Mui.TextField
                        name="chequeCmc7"
                        label="CMC7"
                        variant="outlined"
                        value={values?.chequeCmc7}
                        onChange={handleChange}
                        fullWidth
                        placeholder="CMC7, barra magnética de leitura dos dados do cheque, disponível em caso de leitura por leitor de cheque"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item md={4}>
                      <Mui.TextField
                        name="chequeAgencia"
                        label="Agência"
                        variant="outlined"
                        value={values?.chequeAgencia}
                        error={Boolean(touched.chequeAgencia && errors.chequeAgencia)}
                        fullWidth
                        helperText={touched.chequeAgencia && errors.chequeAgencia}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Número da agência da conta"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                      <Mui.TextField
                        name="chequeConta"
                        label="Conta"
                        variant="outlined"
                        value={values?.chequeConta}
                        error={Boolean(touched.chequeConta && errors.chequeConta)}
                        fullWidth
                        helperText={touched.chequeConta && errors.chequeConta}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Número da conta"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={2}>
                      <Mui.TextField
                        name="chequeSerie"
                        label="Série"
                        variant="outlined"
                        value={values?.chequeSerie}
                        error={Boolean(touched.chequeSerie && errors.chequeSerie)}
                        fullWidth
                        helperText={touched.chequeSerie && errors.chequeSerie}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Série do cheque"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={2}>
                      <Mui.TextField
                        name="chequeNumero"
                        label="Número"
                        variant="outlined"
                        value={values?.chequeNumero}
                        error={Boolean(touched.chequeNumero && errors.chequeNumero)}
                        fullWidth
                        helperText={touched.chequeNumero && errors.chequeNumero}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Número do cheque"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item md={4}>
                      <DatePicker
                        label={'Vencimento'}
                        name="chequeVencimento"
                        variant="outlined"
                        value={values?.chequeVencimento}
                        // error={Boolean(touched.chequeVencimento && errors.chequeVencimento)}
                        fullWidth
                        // helperText={touched.chequeVencimento && errors.chequeVencimento}
                        disabled={true}
                        onBlur={handleBlur}
                        onChange={e => {}}
                        placeholder="Data 'ideal' para a compensação do cheque"
                        InputLabelProps={{ shrink: true }}
                        renderInput={props => (
                          <Mui.TextField {...props} variant="outlined" fullWidth required />
                        )}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                      <Mui.TextField
                        label="Valor"
                        name="chequeValor"
                        variant="outlined"
                        value={values?.chequeValor}
                        fullWidth
                        disabled={true}
                        placeholder="Valor do cheque"
                        InputProps={{
                          startAdornment: (
                            <Mui.InputAdornment position="start">R$</Mui.InputAdornment>
                          ),
                          inputComponent: CurrencyInputCustom
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                      <Mui.TextField
                        name="chequeCompensacao"
                        label="Compensação"
                        variant="outlined"
                        value={values?.chequeCompensacao}
                        error={Boolean(touched.chequeCompensacao && errors.chequeCompensacao)}
                        fullWidth
                        helperText={touched.chequeCompensacao && errors.chequeCompensacao}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="Câmara de compensação, ex. 018"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item md={12}>
                      <Mui.TextField
                        name="chequeHistorico"
                        label="Histórico"
                        variant="outlined"
                        fullWidth
                        placeholder="Histórico/observação que ficará vinculado ao cheque"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>

                {/* <Mui.TextField
                  variant="outlined"
                  autoFocus
                  focused
                  fullWidth
                  placeholder="Pesquise por razão social, fantasia, CPF, CNPJ"
                  // onChange={changeSearchInput}
                  InputProps={{
                    style: {
                      height: 44
                    },
                    startAdornment: (
                      <Mui.InputAdornment position="end" style={{ position: 'absolute', right: 0 }}>
                        <Mui.IconButton tabIndex={-1} onClick={() => {}}>
                          <MuiIcons.Search />
                        </Mui.IconButton>
                      </Mui.InputAdornment>
                    )
                  }}
                />*/}
                {/* {JSON.stringify(values)}
                <br />
                {JSON.stringify(errors)} */}
                <ErrorFormik errors={errors} />
              </Mui.DialogContent>
              <Mui.DialogActions>
                <Mui.Box p={1}>
                  <Mui.Grid
                    container
                    item
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <Mui.Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      autoFocus
                    >
                      <MuiIcons.CheckCircle />
                      <span
                        style={{
                          paddingLeft: 8
                        }}
                      >
                        Cadastrar
                      </span>
                    </Mui.Button>

                    <Mui.Button
                      startIcon={<MuiIcons.Close />}
                      size="large"
                      onClick={handleClose}
                      style={{ marginLeft: 16 }}
                    >
                      Cancelar (ESC)
                    </Mui.Button>
                  </Mui.Grid>
                </Mui.Box>
              </Mui.DialogActions>
            </form>
          </>
        )}
      </Formik>
    </Mui.Dialog>
  );
};

export default ModalCadastroCheque;
