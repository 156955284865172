import React, { useState, useCallback } from 'react';
import { Grid, ListItem, Typography, IconButton, Menu, MenuItem, Tooltip, Button } from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import { formatCurrency } from '~/utils/format';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { MoreVert as IconMore } from '@material-ui/icons';
import ModalSaldoEstoqueProduto from '../../modal/ModalSaldoEstoqueProduto';
import ModalCondicaoPagamentoProduto from '../../modal/ModalCondicaoPagamentoProduto';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const useStyles = makeStyles((theme) => ({
  quantityInput: {
    '& .MuiOutlinedInput-input': {
      padding: '5px 8px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    width: '100%',
  },
  quantityContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  quantidadeValor: {
    marginRight: theme.spacing(1),
  },
  editIcon: {
    padding: 2,
  },
}));

const calculaValorInicial = (data) => {
  const updatedProduto = { ...data };

  if (updatedProduto.produtoRegras) {
    updatedProduto.produtoRegras = updatedProduto.produtoRegras.map(regra => {
      const quantidadeMinimaOriginal = regra.quantidadeMinima;
      const valorBrutoQntdMinima = regra.quantidadeMinima * regra.precoVenda;
      const descontoAplicado = regra.desconto || regra.valorDescontoRegra || 0;
      const porcentagemDescontoAplicado = regra.descontoPercentual || 0;
      const valorTotalQntdMinima = valorBrutoQntdMinima - descontoAplicado - (valorBrutoQntdMinima * (porcentagemDescontoAplicado / 100));

      return {
        ...regra,
        quantidadeMinimaOriginal,
        valorBrutoQntdMinima,
        descontoAplicado,
        porcentagemDescontoAplicado,
        valorTotalQntdMinima
      };
    });
  }

  if (updatedProduto.tabelaRegraProduto) {
    updatedProduto.tabelaRegraProduto = updatedProduto.tabelaRegraProduto.map(tabela => {
      const valorBruto = tabela.quantidadeMinima * tabela.precoVenda;
      const valorLiquido = valorBruto * (1 + tabela.precoAjuste / 100);

      return {
        ...tabela,
        valorBruto,
        valorLiquido
      };
    });
  }

  return updatedProduto;
};

const ListItemProduto = ({ data, callback }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [produto, setProduto] = useState(() => calculaValorInicial(data));
  const [showModalCondicaoPagamento, setShowModalCondicaoPagamento] = useState(false);
  const [showModalSaldoEstoque, setShowModalSaldoEstoque] = useState(false);
  const [quantidadeValor, setQuantidadeValor] = useState('');
  const [editarQuantidade, setEditarQuantidade] = useState(null);

  const handleEditQuantidade = (event, item) => {
    event.stopPropagation();
    setEditarQuantidade(item);
    setQuantidadeValor(item.quantidadeMinima.toFixed(2).replace('.', ','));
  }

  const handleQuantityChange = useCallback((event, item, type) => {
    const newValue = event.target.value.replace(',', '.');
    setQuantidadeValor(newValue);
  }, []);

  const calcularDescontoMultiplo = useCallback((quantidade, regra) => {
    const quantidadeMinimaOriginal = regra.quantidadeMinimaOriginal || regra.quantidadeMinima;
    const fatorMultiplicador = Math.floor(quantidade / quantidadeMinimaOriginal);
    return fatorMultiplicador > 1 ? fatorMultiplicador : 1;
  }, []);

  const calculateTotals = (newQuantity, item) => {
    const newTotalBruto = newQuantity * item.precoVenda;
    
    if (item.desconto !== undefined) {
      const descontoValor = newQuantity >= item.quantidadeMinimaOriginal ? item.desconto : 0;
      const descontoPercentual = item.porcentagemDescontoRegra || 0;
      const fatorDesconto = calcularDescontoMultiplo(newQuantity, item);
      const descontoAplicado = descontoValor * fatorDesconto;
      const precoComDesconto = newTotalBruto - descontoAplicado;
      const newTotalLiquido = precoComDesconto * (1 - descontoPercentual / 100);
      
      return {
        valorBrutoQntdMinima: newTotalBruto,
        valorTotalQntdMinima: newTotalLiquido,
        descontoAplicado,
        porcentagemDescontoAplicado: item.porcentagemDescontoRegra * fatorDesconto
      };
    } else {
      const newTotalLiquido = newTotalBruto * (1 + item.precoAjuste / 100);
      
      return {
        valorBruto: newTotalBruto,
        valorLiquido: newTotalLiquido
      };
    }
  };

  const updateProdutoRegras = (prevProduto, editarQuantidade, newQuantity, totals) => {
    return {
      ...prevProduto,
      produtoRegras: prevProduto.produtoRegras.map(regra =>
        regra === editarQuantidade ? {
          ...regra,
          quantidadeMinima: newQuantity,
          ...totals
        } : regra
      )
    };
  };
  
  const updateTabelaRegraProduto = (prevProduto, editarQuantidade, newQuantity, totals) => {
    return {
      ...prevProduto,
      tabelaRegraProduto: prevProduto.tabelaRegraProduto.map(tabela =>
        tabela === editarQuantidade ? {
          ...tabela,
          quantidadeMinima: newQuantity,
          ...totals
        } : tabela
      )
    };
  };

  const handleQuantityBlur = useCallback(() => {
    if (editarQuantidade) {
      const newQuantity = parseFloat(quantidadeValor.replace(',', '.'));
      if (!isNaN(newQuantity) && newQuantity >= 0) {
        const totals = calculateTotals(newQuantity, editarQuantidade);
        
        setProduto(prevProduto => {
          if (editarQuantidade.desconto !== undefined) {
            return updateProdutoRegras(prevProduto, editarQuantidade, newQuantity, totals);
          } else {
            return updateTabelaRegraProduto(prevProduto, editarQuantidade, newQuantity, totals);
          }
        });
      }
    }
    setEditarQuantidade(null);
  }, [quantidadeValor, editarQuantidade, calcularDescontoMultiplo]);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let regraDescontoValor = 0;
  let regraDescontoPercentual = 0;

  for (const regra of produto?.fat_regradesconto_produto || []) {
    if (regraDescontoValor === 0) {
      regraDescontoValor = regra.descontoValor;
    }
    if (regraDescontoPercentual === 0) {
      regraDescontoPercentual = regra.descontoPercentual;
    }
    if (regraDescontoValor !== 0 && regraDescontoPercentual !== 0) {
      break;
    }
  }

  let precoVendaComDesconto = produto?.estProdutoImposto[0]?.precoVenda;

  if (regraDescontoValor > 0) {
    precoVendaComDesconto -= regraDescontoValor;
  } else if (regraDescontoPercentual > 0) {
    precoVendaComDesconto -= precoVendaComDesconto * (regraDescontoPercentual / 100);
  }

  const handleProdutoClick = produtoSelecionado => {
    callback(produtoSelecionado);
  };

  return (
    <>
      {produto?.fat_regradesconto_produto?.length > 0 &&
        produto?.produtoRegras.map((regra, index) => (
          <React.Fragment key={index}>
            <Grid container>
              <ListItem disabled={produto?.desativado}>
                <Grid item md={1}>
                  <Typography>{produto?.codigo}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography noWrap>{produto?.descricao}</Typography>
                </Grid>
                <Grid item md={1}>
                  <Typography noWrap>{produto?.ean}</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'center' }}></Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography noWrap>{regra?.descricao}</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{formatCurrency(regra?.precoVenda)}</Typography>
                </Grid>
                <Grid item md={1} className={classes.quantityContainer}>
                  {editarQuantidade === regra ? (
                    <OutlinedInput
                      className={classes.quantityInput}
                      value={quantidadeValor}
                      onChange={(e) => handleQuantityChange(e, regra, 'regra')}
                      onBlur={handleQuantityBlur}
                      onClick={(e) => e.stopPropagation()}
                      autoFocus
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleQuantityBlur();
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  ) : (
                    <>
                      <Typography>{regra?.quantidadeMinima.toFixed(2).replace('.', ',')}</Typography>
                      <IconButton
                        onClick={(e) => handleEditQuantidade(e, regra)}
                        size="small"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </>
                  )}
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{formatCurrency(regra?.valorBrutoQntdMinima)}</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>
                    {regra.descontoAplicado > 0 
                      ? formatCurrency(regra?.descontoAplicado ?? regra?.valorDescontoRegra)
                      : `${(regra.porcentagemDescontoAplicado || 0).toFixed(2).replace('.', ',')}%`
                    }
                  </Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{formatCurrency(regra?.valorTotalQntdMinima)}</Typography>
                </Grid>
                <Button 
                  variant="contained"
                  className="button-success"
                  onClick={event => {
                    if (produto?.desativado) {
                      return;
                    }
                    event.stopPropagation();
                    const produtoSelecionado = {
                      ...produto,
                      produtoSelecionadoRegra: regra
                    };
                    handleProdutoClick(produtoSelecionado);
                  }}
                >
                  <MuiIcons.CheckCircle />
                  <span style={{ marginLeft: 8 }}>Selecionar</span>
                </Button>
              </ListItem>
              {/* <Grid item md={1}>
                <ListItemSecondaryAction>
                  <Tooltip title="Menu">
                    <IconButton tabIndex={-1} aria-label={'Menu'} onClick={handleClick}>
                      <IconMore />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={event => {
                        event.stopPropagation();
                        setShowModalSaldoEstoque(true);
                      }}
                    >
                      Saldo de estoque
                    </MenuItem>
                    <MenuItem
                      onClick={event => {
                        event.stopPropagation();
                        setShowModalCondicaoPagamento(true);
                      }}
                    >
                      Condições de pagamento
                    </MenuItem>
                  </Menu>
                </ListItemSecondaryAction>
              </Grid> */}
            </Grid>
            <Divider />
          </React.Fragment>
        ))}
      {produto?.fatTabelavendaProduto?.length > 0 &&
        produto?.tabelaRegraProduto.map((tabela, index) => (
          <React.Fragment key={index}>
            <Grid container>
              <ListItem disabled={produto?.desativado}>
                <Grid item md={1}>
                  <Typography>{produto?.codigo}</Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography noWrap>{produto?.descricao}</Typography>
                </Grid>
                <Grid item md={1}>
                  <Typography noWrap>{produto?.ean}</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography noWrap>{tabela?.descricao}</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'center' }}></Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{formatCurrency(tabela?.precoVenda)}</Typography>
                </Grid>
                <Grid item md={1} className={classes.quantityContainer}>
                  {editarQuantidade === tabela ? (
                    <OutlinedInput
                      className={classes.quantityInput}
                      value={quantidadeValor}
                      onChange={(e) => handleQuantityChange(e, tabela, 'tabela')}
                      onBlur={handleQuantityBlur}
                      onClick={(e) => e.stopPropagation()}
                      autoFocus
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleQuantityBlur();
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  ) : (
                    <>
                      <Typography>{tabela?.quantidadeMinima.toFixed(2).replace('.', ',')}</Typography>
                      <IconButton
                        onClick={(e) => handleEditQuantidade(e, tabela)}
                        size="small"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </>
                  )}
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{formatCurrency(tabela?.valorBruto)}</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{tabela?.precoAjuste}%</Typography>
                </Grid>
                <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Typography>{formatCurrency(tabela?.valorLiquido)}</Typography>
                </Grid>
                {/* <Grid item md={1}>
                  <ListItemSecondaryAction>
                    <Tooltip title="Menu">
                      <IconButton tabIndex={-1} aria-label={'Menu'} onClick={handleClick}>
                        <IconMore />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={event => {
                          event.stopPropagation();
                          setShowModalSaldoEstoque(true);
                        }}
                      >
                        Saldo de estoque
                      </MenuItem>
                      <MenuItem
                        onClick={event => {
                          event.stopPropagation();
                          setShowModalCondicaoPagamento(true);
                        }}
                      >
                        Condições de pagamento
                      </MenuItem>
                    </Menu>
                  </ListItemSecondaryAction>
                </Grid> */}
                <Button 
                  variant="contained"
                  className="button-success"
                  onClick={event => {
                    if (produto?.desativado) {
                      return;
                    }
                    event.stopPropagation();
                    const produtoSelecionado = {
                      ...produto,
                      produtoSelecionadoTabela: tabela
                    };
                    handleProdutoClick(produtoSelecionado);
                  }}
                >
                  <MuiIcons.CheckCircle />
                  <span style={{ marginLeft: 8 }}>Selecionar</span>
                </Button>
              </ListItem>
            </Grid>
            <Divider />
          </React.Fragment>
        ))}
      {showModalSaldoEstoque && (
        <ModalSaldoEstoqueProduto
          open={showModalSaldoEstoque}
          data={{
            produto: { ...produto },
            saldoEstoque: produto?.saldoEstoque
          }}
          onClose={() => {
            handleClose();
            setShowModalSaldoEstoque(false);
          }}
        />
      )}
      {showModalCondicaoPagamento && (
        <ModalCondicaoPagamentoProduto
          open={showModalCondicaoPagamento}
          data={{
            produto: { ...produto },
            precoVenda: produto?.precoVenda
          }}
          onClose={() => {
            handleClose();
            setShowModalCondicaoPagamento(false);
          }}
        />
      )}
    </>
  );
};

export default ListItemProduto;
