import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Alert, AlertTitle } from '@material-ui/lab';
import styled from 'styled-components';

import IconClose from '@material-ui/icons/Close';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconCancel from '@material-ui/icons/Cancel';

import api from '~/services/api';
import toast from '~/services/toast';
import pedidoService from '~/store/modules/pedido/pedido-service';
import { API_BASE_URL, API_LOCAL_URL, APP_ERROR, TIPO_DOCUMENTO } from '~/constants';
import { formatCurrency } from '~/utils/format';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import PedidoIdentificacao from '../../components/PedidoIdentificacao';
import PedidoHistoricoBloqueios from '../../components/PedidoHistoricoBloqueios';
import PedidoParcelas from '../../components/PedidoParcelas';
import LiberacaoAcesso from '../../components/LiberacaoAcesso';
import ModalMotivoLiberacao from '../ModalObservacaoLiberacao';
import { selectorParametroAsBoolean } from '~/store/modules/auth/auth-selectors';

const Container = styled(Paper)`
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;

const ModalLiberacaoCredito = ({ id, isDocumentoOffline, open, onClose }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const parametroMotivoLiberacaoCredito = useSelector(selectorParametroAsBoolean('PF043'));
  const parametroDuplaChecagem = useSelector(selectorParametroAsBoolean('PF038'));

  const [loading, setLoading] = useState(false);
  const [pedido, setPedido] = useState(null);
  const [disableModal, setDisableModal] = useState(true);
  const [tokenLiberacaoRotina, setTokenLiberacaoRotina] = useState(null);
  const [imprimirAposLiberacao, setImprimirAposLiberacao] = useState(false);
  const [showModalObservacaoLiberacao, setShowModalObservacaoLiberacao] = useState({ show: false });

  const handleSubmitLiberar = async (observacao = null) => {
    try {
      if (parametroMotivoLiberacaoCredito && observacao === null) {
        setShowModalObservacaoLiberacao({ show: true });
        return;
      }

      const { isConfirmed } = await toast.confirm(
        'Deseja liberar o pedido?',
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
        {
          icon: 'success',
          confirmButtonText: 'Liberar',
          cancelButtonText: 'Cancelar'
        }
      );

      if (isConfirmed) {
        pedidoService.setStategy(isDocumentoOffline);
        const { data } = await pedidoService.liberarBloqueioCredito(
          id,
          {
            ...pedido,
            id,
            mensagemLiberacaoBloqueio: observacao
          },
          tokenLiberacaoRotina
        );
        toast.success('Operação realizada com sucesso!');
        if (imprimirAposLiberacao) {
          dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.PEDIDO, data));
        }
        onClose();
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });

      if (error?.response?.data?.[0]) {
        onClose();
      }
    }
  };

  const handleSubmitRejeitar = async () => {
    try {
      const { isConfirmed } = await toast.confirm(
        'Deseja rejeitar o pedido?',
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
        {
          icon: 'danger',
          confirmButtonText: 'Rejeitar',
          cancelButtonText: 'Cancelar'
        }
      );

      if (isConfirmed) {
        pedidoService.setStategy(isDocumentoOffline);
        const { data } = await pedidoService.rejeitarBloqueioCredito(
          id,
          pedido,
          tokenLiberacaoRotina
        );
        toast.success('Operação realizada com sucesso!');

        if (imprimirAposLiberacao) {
          dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.PEDIDO, data));
        }
        onClose();
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);

      const { data } = await api.get(
        isDocumentoOffline
          ? `${API_LOCAL_URL}/faturamento/pedido/${id}`
          : `${API_BASE_URL}/v1/faturamento/pedido/bloqueiocredito/${id}`
      );
      setPedido(data);
      setLoading(false);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <DialogTitle>
          <AppBar color="primary" position="static">
            <Toolbar>
              <Box>
                <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Liberação do pedido - bloqueio por crédito
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <IconButton color="inherit" onClick={onClose} aria-label="close">
                <IconClose />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: 640, padding: 16 }}>
          {!loading && disableModal && (
            <LiberacaoAcesso
              rotina="F011ANP"
              callback={(acesso, token) => {
                setDisableModal(!acesso);
                if (acesso) {
                  setTokenLiberacaoRotina(token);
                }
              }}
            />
          )}
          {loading ? (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <CircularProgress variant="indeterminate" color="primary" />
              </Box>
            </>
          ) : (
            <>
              <PedidoIdentificacao data={pedido} />

              <Container>
                <Box px={2} pt={1}>
                  <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
                    CLIENTE
                  </Typography>
                </Box>

                <Box p={2}>
                  <Grid container item>
                    <Grid container item xs={12} md={6} spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {pedido?.intCliente?.codigoInterno} - {pedido?.intCliente?.razaoSocial}
                        </Typography>
                        <Typography variant="body2">
                          {pedido?.intCliente?.enderecoLogradouro}, Nº
                          {pedido?.intCliente?.enderecoLogradouroNumero},{' '}
                          {pedido?.intCliente?.enderecoBairro}
                        </Typography>
                        <Typography variant="body2">
                          {pedido?.intCliente?.enderecoIntCidade?.cidade} -
                          {pedido?.intCliente?.enderecoIntCidade?.uf}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">Grupo cliente</Typography>
                        <Typography variant="body2">
                          {pedido?.intCliente?.intClienteGrupo?.descricao ?? '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption">Grupo empresarial</Typography>
                        <Typography variant="body2">
                          {pedido?.intCliente?.intGrupoEmpresarial?.descricao ?? '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid container item xs={12} md={6} spacing={2} style={{ paddingLeft: 16 }}>
                      <Grid container item xs={12}>
                        <Grid item xs={12} md={8}>
                          <Typography variant="caption">Limite de compra utilizado</Typography>
                          <Box flex={1} display="flex" flexDirection="row">
                            <Typography variant="h3" color="primary" style={{ fontWeight: 'bold' }}>
                              {formatCurrency(pedido?.creditoUtilizado)}
                            </Typography>
                            {/* <Chip
                              style={{
                                color: theme.palette.primary.main,
                                marginLeft: 16
                              }}
                              label="0%"
                            /> */}
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variant="caption">Limite de compra</Typography>
                          <Typography variant="h5">
                            {formatCurrency(pedido?.limiteCredito)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider orientation="horizontal" flexItem />
                      <Grid item xs={12}>
                        <Typography variant="caption">Saldo disponível</Typography>
                        <Box flex={1} display="flex" flexDirection="row">
                          <Typography
                            variant="h1"
                            style={{
                              color:
                                pedido?.saldoDisponivel <= 0
                                  ? theme.palette.error.main
                                  : theme.palette.success.main,
                              fontWeight: 'bold'
                            }}
                          >
                            {formatCurrency(pedido?.saldoDisponivel)}
                          </Typography>
                          {/* <Chip
                            style={{
                              color:
                                pedido?.saldoDisponivel <= 0
                                  ? theme.palette.error.main
                                  : theme.palette.success.main,
                              marginLeft: 16,
                              marginTop: 8
                            }}
                            label="0%"
                          /> */}
                        </Box>
                        {pedido?.intCliente?.creditoIgnoraInadimplente && (
                          <Box mt={2}>
                            <Alert severity="info">
                              <Typography>Ignora bloqueio por inadimplência</Typography>
                            </Alert>
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Container>

              <PedidoParcelas data={pedido?.parcelas ?? []} />

              <PedidoHistoricoBloqueios data={pedido?.fatPedidoBloqueio ?? []} />
            </>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Box flex={1} display="flex" justifyContent="space-between">
              <Box>
                <Button
                  variant="contained"
                  className="button-success"
                  disabled={disableModal}
                  onClick={() => handleSubmitLiberar()}
                >
                  <IconCheckCircle />{' '}
                  <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                    Liberar pedido
                  </Mui.Typography>
                </Button>
                <Button
                  variant="contained"
                  className="button-danger"
                  disabled={disableModal}
                  style={{ marginLeft: 8 }}
                  onClick={() => handleSubmitRejeitar()}
                >
                  <IconCancel />{' '}
                  <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                    {' '}
                    Rejeitar pedido
                  </Mui.Typography>
                </Button>

                <FormControlLabel
                  style={{ marginLeft: 12 }}
                  control={
                    <Switch
                      checked={imprimirAposLiberacao}
                      onChange={() => setImprimirAposLiberacao(state => !state)}
                    />
                  }
                  label="Imprimir após liberação"
                />
              </Box>
              <Button
                startIcon={<MuiIcons.Close />}
                size="large"
                style={{ marginLeft: 8 }}
                onClick={onClose}
              >
                CANCELAR (ESC)
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
      {showModalObservacaoLiberacao?.show && (
        <ModalMotivoLiberacao
          open={showModalObservacaoLiberacao?.show}
          onClose={() => setShowModalObservacaoLiberacao({ show: false })}
          callback={observacao => {
            setShowModalObservacaoLiberacao({ show: false });
            handleSubmitLiberar(observacao);
          }}
        />
      )}
    </>
  );
};

export default ModalLiberacaoCredito;
