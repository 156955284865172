import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { PEDIDO_SITUACAO } from '~/constants';
import { formatCurrency, formatNumber } from '~/utils/format';

import Label from '~/components/Label';

const getSituacaoLabel = situacao => {
  const colors = ['blue', 'green', 'orange', 'red'];
  return <Label color={colors[situacao] ?? 'grey'}>{PEDIDO_SITUACAO[situacao]}</Label>;
};
const Pedidoitens = ({ data: itens }) => {
  const quantidadeCasasDecimaisValorUnitario = 2;
  const quantidadeCasasDecimais = 2;
  const devolucao = false;

  return (
    <Paper>
      <Box px={2} pt={1}>
        <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
          ITENS
        </Typography>
      </Box>
      <Box p={2}>
        <TableContainer>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell align="left">Situação</TableCell>
                <TableCell align="left">Produto</TableCell>
                <TableCell align="right">Vendedor</TableCell>
                <TableCell align="right">Desconto</TableCell>
                <TableCell align="right">Acréscimo</TableCell>
                <TableCell align="right">Valor total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itens?.map((item, index) => (
                <TableRow key={`item-${index}`}>
                  <TableCell>{item?.itemOrdem}</TableCell>
                  <TableCell>{getSituacaoLabel(item?.itemSituacao)}</TableCell>
                  <TableCell align="left">
                    <Typography>
                      {item?.produtoCodigo} - {item?.produtoDescricao}{' '}
                      <>
                        {item?.produtoEstProdutoGrade != null && (
                          <>
                            {item.produtoEstProdutoGrade.gradeEixoLinha} -{' '}
                            {item.produtoEstProdutoGrade.gradeEixoColuna}
                          </>
                        )}
                      </>
                    </Typography>
                    <Typography>
                      <span class="margin-right-10">
                        {formatNumber(item?.itemQuantidade, quantidadeCasasDecimais)}
                      </span>
                      {item?.produtoUnidadeComercial && devolucao && (
                        <span class="margin-right-10">{item?.produtoUnidadeComercial}</span>
                      )}

                      {item.produtoUnidadeCompra && devolucao && (
                        <span class="margin-right-10">{item.produtoUnidadeCompra ?? ''}</span>
                      )}

                      <span>x </span>
                      <span>
                        {formatCurrency(
                          item.itemValorUnitario,
                          quantidadeCasasDecimaisValorUnitario
                        )}
                      </span>
                      <span ng-if="item.estoqueDisponivel">
                        &nbsp;&nbsp;&nbsp;
                        <i>
                          ( Estoque: {formatNumber(item.estoqueDisponivel, quantidadeCasasDecimais)}
                          )
                        </i>
                      </span>
                      <span>
                        &nbsp;&nbsp; Mod. exped.:
                        <Label color="grey" style={{ marginLeft: 4 }}>
                          {item?.omsModalidadeExpedicao?.descricao}
                        </Label>
                      </span>
                    </Typography>
                  </TableCell>
                  <TableCell align="left">{item.fatVendedorNome}</TableCell>

                  <TableCell align="right">{formatCurrency(item.itemValorDesconto)}</TableCell>
                  <TableCell align="right">{formatCurrency(item.itemValorAcrescimo)}</TableCell>
                  <TableCell align="right">{formatCurrency(item.itemValorTotal)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default Pedidoitens;
