import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SET_LOADING,
  SET_APPLICATION_MODE
} from './cliente-constants';

export const setLoading = loading => {
  return {
    type: SET_LOADING,
    loading
  };
};

export const setApplicationMode = applicationMode => {
  return {
    type: SET_APPLICATION_MODE,
    applicationMode,
  };
};

export const searchRequest = queryText => {
  return {
    type: SEARCH_REQUEST,
    queryText
  };
};

export const searchSuccess = items => {
  return {
    type: SEARCH_SUCCESS,
    items
  };
};
