import React from 'react';
import { BrowserRouter, Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import AuthLayout from './layouts/AuthLayout';
import PainelLayout from './layouts/PainelLayout';
import PdvLayout from './layouts/PdvLayout';

import Login from './pages/Auth/Login';
import PainelVenda from './pages/PainelVenda';
import TelaVenda from './pages/TelaVenda';


import { historyRef } from './services/history';

const Routes = React.memo(() => (
  <BrowserRouter ref={historyRef}>
    {renderRoutes([
      {
        path: '/auth',
        component: AuthLayout,
        routes: [
          {
            path: '/auth/login',
            exact: true,
            component: Login
          },

          {
            component: () => <Redirect to="/errors/error-404" />
          }
        ]
      },

      {
        path: '/pdv',
        exact: true,
        component: PdvLayout,
        routes: [
          {
            path: '*',
            component: TelaVenda
          }
        ]
      },
      
      {
        path: '*',
        component: PainelLayout,
        routes: [
          {
            path: '/',
            exact: true,
            component: PainelVenda
          },
          {
            component: () => <Redirect to="/" />
          }
        ]
      },
      {
        component: () => <Redirect to="/pdv" />
      }
    ])}
  </BrowserRouter>
));

export default Routes;
