import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@material-ui/lab';

import * as appActions from '~/store/modules/app/app-actions';
import { API_LOCAL_URL, APP_ERROR, APP_MODULO_TEF } from '~/constants';
import api from '~/services/api';
import toast from '~/services/toast';

import Label from '~/components/Label';

const ModalConfiguracao = () => {
  const dispatch = useDispatch();
  const { showModalConfiguracao } = useSelector(state => state.app);

  const [configNfce, setConfigNfce] = useState(null);
  const [configTef, setConfigTef] = useState(null);

  const onClose = () => {
    dispatch(appActions.setShowModalConfiguracao({ show: false }));
  };

  const loadConfigTef = async () => {
    const {
      data: { data, path }
    } = await api.get(`${API_LOCAL_URL}/config/tef`);
    setConfigTef({
      data: data ?? {
        Configuracao: {
          CodigoLoja: '',
          EnderecoIP: '',
          NumeroTerminal: ''
        },
        PinPadCompartilhado: {
          Porta: 'AUTO_USB'
        }
      },
      fileExists: data === null,
      path
    });
  };

  const loadConfigNfce = async () => {
    const {
      data: { data, path }
    } = await api.get(`${API_LOCAL_URL}/config/nfce`);

    setConfigNfce({
      data: data ?? {
        Configuracao: {
          PrinterNFCeName: '',
          QtdViasNFCe: 1
        }
      },
      fileExists: data === null,
      path
    });
  };

  const handleSubmit = async () => {
    const toastInstance = toast.loading(`Salvando, aguarde...`);
    try {
      await api.post(`${API_LOCAL_URL}/config/tef`, { ...configTef.data });
      await api.post(`${API_LOCAL_URL}/config/nfce`, { ...configNfce.data });
      toast.success(`Configuração salva com sucesso!`);
      onClose();
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }
  };

  useEffect(() => {
    loadConfigTef();
    loadConfigNfce();
  }, []);

  return (
    <>
      <Mui.Dialog
        open={showModalConfiguracao.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Mui.Paper}
        // fullScreen
        maxWidth={'md'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Configuração
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ minWidth: 640, padding: 16 }}>
          <Mui.Paper></Mui.Paper>
          <Mui.Accordion>
            <Mui.AccordionSummary
              expandIcon={<MuiIcons.ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Mui.Typography>NFCe</Mui.Typography>
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              <Mui.Grid container item spacing={2}>
                <Mui.Grid item md={4}>
                  <Mui.TextField
                    name="PrinterNFCeName"
                    variant="outlined"
                    label={'Impressora NFCe'}
                    required
                    type="text"
                    fullWidth
                    value={configNfce?.data?.Configuracao?.PrinterNFCeName}
                    onChange={event =>
                      setConfigNfce(state => ({
                        ...state,
                        data: {
                          ...state.data,
                          Configuracao: {
                            ...state.data.Configuracao,
                            PrinterNFCeName: event.target.value
                          }
                        }
                      }))
                    }
                  />
                </Mui.Grid>
                <Mui.Grid item md={4}>
                  <Mui.TextField
                    name="QtdViasNFCe"
                    variant="outlined"
                    label={'Quantidades vias'}
                    required
                    type="number"
                    fullWidth
                    value={configNfce?.data?.Configuracao?.QtdViasNFCe}
                    onChange={event =>
                      setConfigNfce(state => ({
                        ...state,
                        data: {
                          ...state.data,
                          Configuracao: {
                            ...state.data.Configuracao,
                            QtdViasNFCe: event.target.value
                          }
                        }
                      }))
                    }
                  />
                </Mui.Grid>
                <Mui.Grid item md={12}>
                  <MuiAlert severity={configNfce?.fileExists ? 'error' : 'info'} variant="filled">
                    <MuiAlertTitle>
                      Caminho da arquivo
                      {configNfce?.fileExists ? (
                        <Label color="grey" style={{ marginLeft: 8 }}>
                          (Não encontrado)
                        </Label>
                      ) : (
                        <></>
                      )}
                      {':'}
                    </MuiAlertTitle>
                    <Mui.Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Mui.Typography variant="body1">{configNfce?.path}</Mui.Typography>
                    </Mui.Box>
                  </MuiAlert>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.AccordionDetails>
          </Mui.Accordion>

          <Mui.Accordion>
            <Mui.AccordionSummary
              expandIcon={<MuiIcons.ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Mui.Typography>TEF</Mui.Typography>
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              <Mui.Grid container item spacing={2}>
                <Mui.Grid item md={4}>
                  <Mui.TextField
                    name="codigoLoja"
                    variant="outlined"
                    label={'Código da empresa'}
                    required
                    type="numeric"
                    fullWidth
                    value={configTef?.data?.Configuracao?.CodigoLoja}
                    onChange={event =>
                      setConfigTef(state => ({
                        ...state,
                        data: {
                          ...state.data,
                          Configuracao: {
                            ...state?.data?.Configuracao,
                            CodigoLoja: event.target.value
                          }
                        }
                      }))
                    }
                  />
                </Mui.Grid>
                <Mui.Grid item md={4}>
                  <Mui.TextField
                    name="enderecoIP"
                    variant="outlined"
                    label={'Endereço IP'}
                    required
                    type="numeric"
                    fullWidth
                    value={configTef?.data?.Configuracao?.EnderecoIP}
                    onChange={event =>
                      setConfigTef(state => ({
                        ...state,
                        data: {
                          ...state.data,
                          Configuracao: {
                            ...state.data?.Configuracao,
                            EnderecoIP: event.target.value
                          }
                        }
                      }))
                    }
                  />
                </Mui.Grid>
                <Mui.Grid item md={4}>
                  <Mui.TextField
                    name="numeroTerminal"
                    variant="outlined"
                    label={'Número terminal'}
                    required
                    type="numeric"
                    fullWidth
                    shrink
                    value={configTef?.data?.Configuracao?.NumeroTerminal}
                    onChange={event =>
                      setConfigTef(state => ({
                        ...state,
                        data: {
                          ...state.data,
                          Configuracao: {
                            ...state.Configuracao,
                            NumeroTerminal: event.target.value
                          }
                        }
                      }))
                    }
                  />
                </Mui.Grid>
                <Mui.Grid item md={4}>
                  <Mui.TextField
                    name="impressora"
                    variant="outlined"
                    label={'Impressora'}
                    required
                    type="string"
                    fullWidth
                    shrink
                    value={configTef?.data?.Configuracao?.Impressora}
                    onChange={event =>
                      setConfigTef(state => ({
                        ...state,
                        data: {
                          ...state.data,
                          Configuracao: {
                            ...state.data?.Configuracao,
                            Impressora: event.target.value
                          }
                        }
                      }))
                    }
                  />
                </Mui.Grid>
                <Mui.Grid item md={4}>
                  <Mui.TextField
                    name="porta"
                    variant="outlined"
                    label={'Porta pinpad'}
                    required
                    type="string"
                    fullWidth
                    value={configTef?.data?.PinPadCompartilhado?.Porta}
                    onChange={event =>
                      setConfigTef(state => ({
                        ...state,
                        data: {
                          ...state.data,
                          PinPadCompartilhado: {
                            ...state.PinPadCompartilhado,
                            Porta: event.target.value
                          }
                        }
                      }))
                    }
                  />
                </Mui.Grid>
                <Mui.Grid item md={12}>
                  <MuiAlert severity={configTef?.fileExists ? 'error' : 'info'} variant="filled">
                    <MuiAlertTitle>
                      Caminho da arquivo
                      {configTef?.fileExists ? (
                        <Label color="grey" style={{ marginLeft: 8 }}>
                          (Não encontrado)
                        </Label>
                      ) : (
                        <></>
                      )}
                      {':'}
                    </MuiAlertTitle>
                    <Mui.Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Mui.Typography variant="body1">{configTef?.path}</Mui.Typography>
                    </Mui.Box>
                  </MuiAlert>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.AccordionDetails>
          </Mui.Accordion>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Box style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
            <Mui.Box>
              <Mui.Button
                size="large"
                variant="contained"
                onClick={() => dispatch(appActions.setShowModalDownload({ show: true }))}
                style={{ marginLeft: 16 }}
              >
                <MuiIcons.Sync />
                <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                  Sincronização
                </Mui.Typography>
              </Mui.Button>
              {location.pathname !== '/pdv' && (
                <Mui.Button
                  size="large"
                  variant="contained"
                  onClick={() => dispatch(appActions.setShowmodalIdentificacaoPDV({ show: true }))}
                  style={{ marginLeft: 16 }}
                >
                  <MuiIcons.Computer />
                  <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                    Terminal
                  </Mui.Typography>
                </Mui.Button>
              )}
            </Mui.Box>
            <Mui.Box>
              <Mui.Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                style={{ marginLeft: 16 }}
              >
                <MuiIcons.Save />
                <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                  Salvar
                </Mui.Typography>
              </Mui.Button>
              <Mui.Button size="large" style={{ marginLeft: 8 }} onClick={() => onClose()}>
                <MuiIcons.Close />
                <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                  Cancelar (ESC)
                </Mui.Typography>
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ModalConfiguracao;
