import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ModalCompartilharDocumento from '../ModalCompartilharDocumento';
import DialogHeader from '../../components/Impressao/DialogHeader';
import PrintDialogActions from '../../components/Impressao/PrintDialogActions';
import UserInfoSection from '../../components/Impressao/UserInfoSection';
import OrcamentoIdentificationSection from '../../components/Impressao/OrcamentoIdentificationSection';
import ItensTable from '../../components/Impressao/ItensTable';
import ComplementaryInfo from '../../components/Impressao/ComplementaryInfo';
import TotalizersSection from '../../components/Impressao/TotalizersSection';
import { TIPO_DOCUMENTO } from '~/constants';

import './index.css'

const ModalImprimirOrcamento = ({ open, orcamento, onClose }) => {
  orcamento.itens = orcamento.itens.filter(item => !item.itemCancelado);
  
  const empresa = useSelector(state => state?.auth?.empresa ?? 0);
  const user = useSelector(state => state?.auth?.usuario ?? 0);
  const componentRef = useRef(null);
  const [modalEnviarOrcamento, setModalEnviarOrcamento] = useState(false);
  const handleShare = () => setModalEnviarOrcamento(true);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Orçamento-${empresa.razaoSocial}-${orcamento?.serie}-${orcamento?.numero}`,
    pageStyle: `
    @page {
      size: auto;
      margin: 8mm;
    }
    .color-print {
      color: #263238 !important;
    }
    @media print {
      body * {
        visibility: hidden;
      }
    #printable-content, #printable-content * {
      visibility: visible;
    }
    #printable-content {
      position: absolute;
      left: 0;
      top: 0;
    }
  }`,
  });

  return (
    <>
      <Mui.Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogHeader title="Impressão de Orçamento" onClose={onClose} />

        <Mui.DialogContent dividers style={{ padding: '10px' }}>
        <div id="printable-content" ref={componentRef}>{
            <>
            <UserInfoSection user={user} empresa={empresa} />
            <OrcamentoIdentificationSection documento={orcamento} title="Orçamento" />
            <ItensTable itens={orcamento.itens} />
            <ComplementaryInfo info={orcamento.informacaoComplementar} />
            <TotalizersSection documento={orcamento} />
            </>
            }
        </div>
        </Mui.DialogContent>

        <PrintDialogActions onPrint={handlePrint} onClose={onClose} onShare={handleShare} />

        {modalEnviarOrcamento && (
          <ModalCompartilharDocumento
            open={modalEnviarOrcamento}
            onClose={() => setModalEnviarOrcamento(false)}
            onCloseImpressao={onClose}
            emailCliente={orcamento?.intCliente?.email}
            telefoneCliente={orcamento?.intCliente?.celular}
            documento={orcamento}
            tipoDocumento={TIPO_DOCUMENTO.ORCAMENTO}
          />
        )}
      </Mui.Dialog>
    </>
  );
};

export default ModalImprimirOrcamento;
