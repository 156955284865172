import {
  LIMPAR_DADOS,
  SEARCH,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  SET_ORDERBY,
  SET_FILTRO_DATA,
  SET_LOADING,
  SET_LOADING_SEND,
  SET_ORDERNACAO,
  SET_STATUS,
  SET_TABINDEX,
  SELECT_ONE_ROW,
  SELECT_ALL_ROWS,
  //* PEDIDO */
  LOAD_PEDIDOS_REQUEST,
  LOAD_PEDIDOS_SUCCESS,
  SEND_PEDIDO_REQUEST,
  SEND_PEDIDO_SUCCESS,
  LOAD_HISTORICOS_PEDIDO_REQUEST,
  LOAD_HISTORICOS_PEDIDO_SUCCESS,
  SET_SHOW_TIMELINE_PEDIDO,
  SET_LOADING_HISTORICOS_PEDIDO,
  //* ORCAMENTO */
  LOAD_ORCAMENTOS_REQUEST,
  LOAD_ORCAMENTOS_SUCCESS,
  SEND_ORCAMENTO_REQUEST,
  SEND_ORCAMENTO_SUCCESS,
  PRINT_DOCUMENTO,
  //* DOCUMENTO SAIDA */
  SEND_DOCUMENTO_REQUEST,
  SEND_DOCUMENTO_SUCCESS,
  LOAD_DOCUMENTOS_REQUEST,
  LOAD_DOCUMENTOS_SUCCESS,
  SET_SHOWMODAL_EDITAR_DOCUMENTO,
  TRANSMITIR_DOCUMENTO,
  CANCELAR_DOCUMENTO,
  GERAR_OFFLINE,
  DOWNLOAD_DANFE,
  DOWNLOAD_XML,
  SET_SHOW_FILA_DOCUMENTOS,
  LOAD_ORCAMENTOS_SEARCH_SUCCESS,
  LOAD_DOCUMENTOS_SEARCH_SUCCESS,
  LOAD_PEDIDOS_SEARCH_SUCCESS
} from './monitor-constants';

export const limparDados = () => {
  return {
    type: LIMPAR_DADOS
  };
};

export const search = queryText => {
  return {
    type: SEARCH,
    queryText
  };
};

export const setLoading = loading => {
  return {
    type: SET_LOADING,
    loading
  };
};

export const setLoadingSend = payload => {
  return {
    type: SET_LOADING_SEND,
    payload
  };
};

export const setStatus = status => {
  return {
    type: SET_STATUS,
    status
  };
};

export const setOrdenacao = ordenadoPor => {
  return {
    type: SET_ORDERNACAO,
    ordenadoPor
  };
};

export const setFiltroData = filtroData => {
  return {
    type: SET_FILTRO_DATA,
    filtroData
  };
};

export const setPage = page => {
  return {
    type: SET_PAGE,
    page
  };
};

export const setRowsPerPage = rowsPerPage => {
  return {
    type: SET_ROWS_PER_PAGE,
    rowsPerPage
  };
};

export const setOrderBy = orderBy => {
  return {
    type: SET_ORDERBY,
    orderBy
  };
};

export const selectOneRow = id => {
  return {
    type: SELECT_ONE_ROW,
    id
  };
};

export const selectAllRows = ids => {
  return {
    type: SELECT_ALL_ROWS,
    ids
  };
};

export const setTabIndex = tabIndex => {
  return {
    type: SET_TABINDEX,
    tabIndex
  };
};

export const loadOrcamentosRequest = hash => {
  return {
    type: LOAD_ORCAMENTOS_REQUEST,
    hash
  };
};

export const loadOrcamentosSuccess = data => {
  return {
    type: LOAD_ORCAMENTOS_SUCCESS,
    data
  };
};

export const loadOrcamentosSearchSuccess = data => {
  return {
    type: LOAD_ORCAMENTOS_SEARCH_SUCCESS,
    data
  };
};

export const sendOrcamentoRequest = orcamento => {
  return {
    type: SEND_ORCAMENTO_REQUEST,
    orcamento
  };
};

export const setLoadingHistoricoPedido = loading => {
  return {
    type: SET_LOADING_HISTORICOS_PEDIDO,
    loading
  };
};

export const setShowTimelinePedido = show => {
  return {
    type: SET_SHOW_TIMELINE_PEDIDO,
    show
  };
};

export const sendPedidoRequest = pedido => {
  return {
    type: SEND_PEDIDO_REQUEST,
    pedido
  };
};

export const loadPedidosRequest = () => {
  return {
    type: LOAD_PEDIDOS_REQUEST
  };
};

export const loadPedidosSuccess = data => {
  return {
    type: LOAD_PEDIDOS_SUCCESS,
    data
  };
};

export const loadPedidosSearchSuccess = data => {
  return {
    type: LOAD_PEDIDOS_SEARCH_SUCCESS,
    data
  };
};

export const loadHistoricosPedido = pedido => {
  return {
    type: LOAD_HISTORICOS_PEDIDO_REQUEST,
    pedido
  };
};

export const loadHistoricosPedidoSuccess = data => {
  return {
    type: LOAD_HISTORICOS_PEDIDO_SUCCESS,
    data
  };
};

export const printDocumento = (tipoDocumento, documento) => {
  return {
    type: PRINT_DOCUMENTO,
    tipoDocumento,
    documento
  };
};

export const setShowModalEditarDocumento = payload => {
  return {
    type: SET_SHOWMODAL_EDITAR_DOCUMENTO,
    payload
  };
};

export const sendDocumentoRequest = documento => {
  return {
    type: SEND_DOCUMENTO_REQUEST,
    documento
  };
};

export const loadDocumentosRequest = () => {
  return {
    type: LOAD_DOCUMENTOS_REQUEST
  };
};

export const loadDocumentosSuccess = data => {
  return {
    type: LOAD_DOCUMENTOS_SUCCESS,
    data
  };
};

export const loadDocumentosSearchSuccess = data => {
  return {
    type: LOAD_DOCUMENTOS_SEARCH_SUCCESS,
    data
  };
};

export const transmitirDocumento = (documento, opts, callback) => {
  return {
    type: TRANSMITIR_DOCUMENTO,
    documento,
    opts,
    callback
  };
};

export const cancelarDocumento = payload => {
  return {
    type: CANCELAR_DOCUMENTO,
    payload
  };
};

export const downloadDanfe = payload => {
  return {
    type: DOWNLOAD_DANFE,
    payload
  };
};

export const downloadXml = payload => {
  return {
    type: DOWNLOAD_XML,
    payload
  };
};
