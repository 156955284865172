export const INITIALISE = '@auth/INITIALISE';

export const SET_LOADING = '@auth/SET_LOADING';
export const SET_SHOWSYNC = '@auth/SET_SHOWSYNC';
export const SET_FILIAL = '@auth/SET_FILIAL';
export const SET_MENSAGEM_SYNC = '@auth/SET_MENSAGEM_SYNC';

export const LOGIN_REQUEST = '@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS';

export const LOGIN_REQUEST_MODAL = '@auth/LOGIN_REQUEST_MODAL';

export const LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS';
