import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import * as Mui from '@material-ui/core';

import { STATUS_MONITOR_PEDIDO } from '~/constants';
import { formatCurrency, formatDate } from '~/utils/format';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import PedidoTableRow from '../PedidoTableRow';
import PedidoListBulkActions from '../PedidoListBulkActions';

const PedidoList = () => {
  const dispatch = useDispatch();

  const {
    status,
    documentos,
    quantidadeDocumentos,
    valorTotalDocumentos,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    orderBy,
    selectedRows,
    enableBulkActions,
    selectedSomeRows,
    selectedAllRows,
    loading
  } = useSelector(state => state.monitor);
  const [columns] = useState([
    { id: 'serieNumero', label: 'Série/Número' },
    { id: 'dataEmissao', label: 'Emissão' },
    { id: 'situcao', label: 'Situação' },
    { id: 'tipoBloqueio', label: 'Tipo de Bloqueio' },
    { id: 'cliente', label: 'Cliente' },
    { id: 'vendedor', label: 'Vendedor' },
    { id: 'omsModalidadeExpedicao', label: 'Modalidade exped.' },   
    { id: 'total', alignment: 'right', label: 'Total', width: 200 }
  ]);

  const handleSelectAllOrders = event => {
    dispatch(
      monitorActions.selectAllRows(event.target.checked ? documentos.map(order => order.id) : [])
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(monitorActions.setPage(newPage));
  };

  const handleChangeRowsPerPage = event => {
    dispatch(monitorActions.setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(monitorActions.setPage(0));
  };

  const handleRequestSort = property => {
    const isAsc = orderBy === property && orderBy.split('|')[1] === 'asc';
    dispatch(monitorActions.setOrderBy(`${property}|${isAsc ? 'desc' : 'asc'}`));
  };

  return (
    <Box style={{ height: 'calc(100vh - 200px)', display: 'flex', flexDirection: 'column' }}>
      <TableContainer style={{ maxHeight: '100%' }}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {status === STATUS_MONITOR_PEDIDO.ABERTOS && (
                  <TableCell padding="checkbox">
                    {!loading && (
                      <Checkbox
                        checked={selectedAllRows}
                        color="primary"
                        indeterminate={selectedSomeRows}
                        onChange={handleSelectAllOrders}
                      />
                    )}
                  </TableCell>
                )}

                <Mui.TableCell align="center">#</Mui.TableCell>

                {columns.map((column, index) => (
                  <TableCell
                    key={`column-${index}`}
                    align={column?.alignment ?? 'left'}
                    style={{ width: column?.width ? `${column?.width}px` : 'auto' }}
                  >
                    {column?.label}
                  </TableCell>
                ))}
                <TableCell style={{ width: 120 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  {[...Array(10).keys()].map(index => (
                    <TableRow key={index}>
                      {[...Array(status === STATUS_MONITOR_PEDIDO.ABERTOS ? 11 : 10).keys()].map(
                        col => (
                          <TableCell key={col} height={44} style={{ padding: 6 }}>
                            <Skeleton variant="text" height={32} />
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {documentos?.map((documento, index) => {
                    const isOrderSelected = selectedRows.includes(documento?.id ?? documento?._id);
                    return (
                      <PedidoTableRow
                        key={`pedido-${index}`}
                        data={documento}
                        isSelected={isOrderSelected}
                      />
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </TableContainer>
      <Divider />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box p={2}></Box>
        {loading ? (
          <Box mr={2}>
            <Skeleton variant="text" width={320} height={32} />
          </Box>
        ) : (
          <TablePagination
            labelRowsPerPage={'Registros por página'}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={quantidadeDocumentos ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>

      <PedidoListBulkActions open={enableBulkActions} selected={selectedRows} />
    </Box>
  );
};

export default PedidoList;
