import styled from 'styled-components';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiButton from '@material-ui/core/Button';
import { alpha } from '@material-ui/core';

export const Container = styled.div`
  padding: 16px;
`;
export const Header = styled.div`
  display: flex;
  padding: 16px;
  color: #fff;
  background-color: ${props => props.theme.palette.common.black};
  box-shadow: ${props => props.theme.shadows[6]};
`;

export const List = styled(MuiList)``;

export const ListItem = styled(MuiListItem)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const ListItemContainer = styled.div`
  padding: 0;
  flex: 1;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';
  border-radius: 6;
  background-color: ${props =>
    props.selected ? alpha(props.theme.palette.common.black, 0.02) : 'unset'};
  box-shadow: ${props => (props.selected ? props.theme.shadows[6] : 'unset')};
  margin-bottom: ${props => (props.selected ? '16px' : 0)};
`;

export const Selected = styled.div`
  background-color: ${props => (props?.selected ? '#09A848' : 'transparent')};
  border-radius: 4px;
  width: 6px;
  height: 54px;
`;

export const ButtonPagamento = styled(MuiButton)`
  padding: 0;
  height: 74px;
  margin-bottom: 16px;

  :focus {
    background-color: rgba(0, 0, 0, 0.1);
    /* border: 1px solid ${props => props.theme.palette.background.paper}; */
  }
`;
