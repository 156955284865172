import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Skeleton from '@material-ui/lab/Skeleton';
import * as Mui from '@material-ui/core';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';
import DocumentosaidaTableRow from '../DocumentosaidaTableRow';

const DocumentosaidaTable = () => {
  const dispatch = useDispatch();
  const {
    status,
    documentos,
    quantidadeDocumentos,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    orderBy,
    selectedRows,
    enableBulkActions,
    selectedSomeRows,
    selectedAllRows,
    loading
  } = useSelector(state => state.monitor);

  const [columns] = useState([
    { id: 'modelo', label: 'Modelo' },
    { id: 'serieNumero', label: 'Série/Número' },
    { id: 'dataEmissao', label: 'Emissão' },
    { id: 'finalidade', label: 'Finalidade' },
    { id: 'transmissao', label: 'Transmissão' },
    { id: 'cliente', label: 'Cliente' },
    { id: 'vendedor', label: 'Vendedor' },
    { id: 'total', alignment: 'right', label: 'Total' }
  ]);

  const handleSelectAllOrders = event => {
    dispatch(
      monitorActions.selectAllRows(event.target.checked ? documentos.map(order => order.id) : [])
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(monitorActions.setPage(newPage));
  };

  const handleChangeRowsPerPage = event => {
    dispatch(monitorActions.setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(monitorActions.setPage(0));
  };

  const handleRequestSort = property => {
    const isAsc = orderBy === property && orderBy.split('|')[1] === 'asc';
    dispatch(monitorActions.setOrderBy(`${property}|${isAsc ? 'desc' : 'asc'}`));
  };

  return (
    <Mui.Box style={{ height: 'calc(100vh - 200px)', display: 'flex', flexDirection: 'column' }}>
      <Mui.TableContainer style={{ maxHeight: '100%' }}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Mui.Table stickyHeader aria-label="sticky table">
            <Mui.TableHead>
              <Mui.TableRow>
                <Mui.TableCell align="center">#</Mui.TableCell>
                {columns.map(column => (
                  <Mui.TableCell key={column?.id} align={column.alignment ?? 'left'}>
                    {column?.label}
                  </Mui.TableCell>
                ))}
                <Mui.TableCell style={{ width: 140 }}></Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {loading ? (
                <>
                  {[...Array(11).keys()].map(index => (
                    <Mui.TableRow key={index}>
                      {[...Array(11).keys()].map(col => (
                        <Mui.TableCell key={col} height={44} style={{ padding: 6 }}>
                          <Skeleton variant="text" height={32} />
                        </Mui.TableCell>
                      ))}
                    </Mui.TableRow>
                  ))}
                </>
              ) : (
                <>
                  {documentos.map((documento, index) => {
                    const isSelected = selectedRows?.includes(documento._id || documento?.id);

                    return (
                      <DocumentosaidaTableRow
                        key={`documento-${index}`}
                        data={documento}
                        isSelected={isSelected}
                      />
                    );
                  })}
                </>
              )}
            </Mui.TableBody>
          </Mui.Table>
        </PerfectScrollbar>
      </Mui.TableContainer>
      <Mui.Divider />
      <Mui.Box display="flex" justifyContent="space-between" alignItems="center">
        <Mui.Box p={2}>
          {loading ? (
            <>
              <Skeleton variant="text" width={140} height={24} />
            </>
          ) : (
            <>
              {/* <Mui.Typography variant="body2">
                Documentos: <b>{quantidadeDocumentos}</b>
              </Mui.Typography> */}
              {/* <Mui.Typography variant="body2">
                Total: <b>{formatCurrency(valorTotalDocumentos)}</b>
              </Mui.Typography> */}
            </>
          )}
        </Mui.Box>
        {loading ? (
          <Mui.Box mr={2}>
            <Skeleton variant="text" width={320} height={32} />
          </Mui.Box>
        ) : (
          <Mui.TablePagination
            labelRowsPerPage={'Registros por página'}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={quantidadeDocumentos ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Box>
    </Mui.Box>
  );
};

export default DocumentosaidaTable;
