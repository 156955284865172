import React from 'react';
import { formatCurrency, formatDate } from '../../../../../utils/format';

const ParcelasTable = ({ parcelas }) => (
  <div style={{ border: '1px solid black', padding: '20px', borderTop: 'none' }}>
    <div style={{ fontSize: '13px', fontWeight: '700', paddingBottom: '10px' }}>
      <strong className="color-black">Pagamento</strong>
    </div>
    <table className="table table-borderless table-striped" style={{ width: '100%', whiteSpace: 'nowrap' }}>
      <thead className="color-black" style={{ fontSize: '12px' }}>
        <tr className="titulo-tabela-produtos">
          <th style={{ textAlign: 'center', padding: 0 }}>Parcela</th>
          <th style={{ textAlign: 'center' }}>Meio de Pagemento</th>
          <th style={{ textAlign: 'center' }}>Vencimento</th>
          <th style={{ textAlign: 'center' }}>Valor</th>
          <th style={{ textAlign: 'center' }}>Valor Juros</th>
          <th style={{ textAlign: 'center' }}>Valor Total</th>
          <th style={{ textAlign: 'center' }}>Qtd. Parcelas</th>
        </tr>
      </thead>

      <tbody>
        {parcelas?.map((item, index) => (
          <tr className="tabela-produtos-index" style={{ fontSize: '12px', textAlign: 'center' }} key={item?.id || index}>
            <td style={{ padding: 0 }}>{index + 1}</td>
            <td style={{ fontSize: '11px' }}>{item?.finMeiopagamento.descricao}</td>
            <td style={{ fontSize: '11px' }}>{formatDate(new Date(item?.parcelaVencimento))}</td>
            <td style={{ textAlign: 'center', fontSize: '11px' }}>{formatCurrency(item?.parcelaValor)}</td>
            <td style={{ textAlign: 'center' }}>{formatCurrency(item?.parcelaValorJuros)}</td>
            <td style={{ textAlign: 'center' }}>{formatCurrency(item?.parcelaValorTotal)}</td>
            <td style={{ textAlign: 'center' }}>{item?.parcelaQuantidade}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default ParcelasTable;
