import { APPLICATION_MODE, THEMES } from '~/constants';
import { SET_SHOWMODAL_MENSAGEM_TEF } from '../pdv/pdv-constants';
import {
  SET_THEME,
  SET_APPLICATION_MODE,
  SET_CONNECTION_MODE,
  SET_SHOWPROGRESS_SYNC,
  SET_SHOWMODAL_SYNC,
  SET_MENSAGEM_SYNC,
  SET_MENSAGEM_TEF,
  SET_SHOWMODAL_IDENTIFICACAO_PDV,
  SET_SHOWMODAL_CONFIGURACAO,
  SET_MESSAGE_SEND_DOCUMENT,
  SET_SHOW_SNACKBAR_SYNC_DOCUMENT,
  SET_SHOW_FILA_DOCUMENTOS,
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  FORCE_OFFLINE_MODE_SUCCESS,
  SET_TERMINAL,
  SET_TRANSACAO_TEF,
  SET_SHOWMODAL_MENU_TEF,
  RESET_MODAL_MENU_TEF,
  SET_SHOWMODAL_DOWNLOAD,
  SET_QUEUES,
  SET_SHOWPROGRESS_QUEUE,
  SET_MENSAGEM_QUEUE
} from './app-constants';

const initialState = {
  theme: THEMES.DEFAULT,
  notifications: [],
  queues: [],
  isOffline: false,
  isInternetReachable: false,
  applicationMode: APPLICATION_MODE.ONLINE,
  showProgressSync: false,
  showModalSync: false,
  showProgressQueue: false,
  mensagemTef: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_THEME: {
      return {
        ...state,
        theme: action.theme
      };
    }

    case SET_CONNECTION_MODE:
      return {
        ...state,
        isInternetReachable: action.isInternetReachable
      };

    case SET_APPLICATION_MODE:
      return {
        ...state,
        applicationMode: action.mode
      };

    case SET_SHOWPROGRESS_SYNC: {
      return {
        ...state,
        showProgressSync: action.progress
      };
    }

    case SET_SHOWMODAL_SYNC: {
      return {
        ...state,
        showModalSync: action.show
      };
    }

    case SET_MENSAGEM_SYNC: {
      return {
        ...state,
        mensagemSync: action.mensagem
      };
    }

    case SET_SHOWPROGRESS_QUEUE: {
      return {
        ...state,
        showProgressQueue: action.show
      };
    }

    case SET_MENSAGEM_QUEUE: {
      return {
        ...state,
        mensagemQueue: action.mensagem
      };
    }

    case SET_MESSAGE_SEND_DOCUMENT: {
      return {
        ...state,
        messsageSendDocument: action.show
      };
    }

    case SET_SHOW_SNACKBAR_SYNC_DOCUMENT: {
      return {
        ...state,
        showSnackbarSyncDocument: action.show
      };
    }

    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key)
      };

    case SET_SHOWMODAL_IDENTIFICACAO_PDV: {
      return {
        ...state,
        showModalIdentificacaoPDV: action.payload
      };
    }

    case SET_SHOWMODAL_CONFIGURACAO: {
      return {
        ...state,
        showModalConfiguracao: action.payload
      };
    }

    case SET_SHOWMODAL_DOWNLOAD: {
      return {
        ...state,
        showModalDownload: action.payload
      };
    }
    case SET_MENSAGEM_TEF: {
      return {
        ...state,
        mensagemTef: action.mensagem,
        montaMenu: false,
        montaCampo: false,
        tef: null
      };
    }

    case SET_SHOWMODAL_MENU_TEF: {
      return {
        ...state,
        showModalMenuTef: {
          ...state.showModalMenuTef,
          ...action.payload
        }
      };
    }

    case RESET_MODAL_MENU_TEF: {
      return {
        ...state,
        showModalMenuTef: {
          show: false
        },
        mensagemTef: '',
        transacaoTef: null
      };
    }

    case SET_TRANSACAO_TEF: {
      return {
        ...state,
        transacaoTef: action.payload
      };
    }
    case SET_TERMINAL: {
      return {
        ...state,
        terminal: action.terminal
      };
    }

    case FORCE_OFFLINE_MODE_SUCCESS: {
      return {
        ...state,
        isOffline: action.isOffline
      };
    }

    case SET_SHOW_FILA_DOCUMENTOS: {
      return {
        ...state,
        showFilaDocumentos: action.show
      };
    }

    case SET_QUEUES: {
      return {
        ...state,
        queues: action.queues
      };
    }

    default:
      return state;
  }
}
