import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ArrowBack as IconArrowBack } from '@material-ui/icons';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import api from '~/services/api';
import Searching from '~/components/Searching';
import ListEmpty from '~/components/ListEmpty';
import { SET_FOCUS_QUANTIDADE } from '~/store/modules/pdv/pdv-constants';
import { API_NODE_URL, API_LOCAL_URL } from '~/constants';
import ListHeader from '../../components/ListHeader';
import ListItemProduto from '../../components/ListItemProduto';
import SearchAdornment from '~/components/MultiSearch';

const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      width: 1,
      height: 32,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  })
);

let cancel;
const CancelToken = axios.CancelToken;

const ModalConsultaProduto = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showModalConsultaProdutoRegra } = useSelector(state => state.pdv);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchBy, setSearchBy] = useState('termo');

  const fetcher = async isOnline => {
    try {
      setLoading(true);

      if (cancel) cancel();
      const cancelToken = new CancelToken(c => {
        cancel = c;
      });

      if (searchInput?.length <= 1) return [];

      setResults([]);

      const { data } = await api.get(
        isOnline
          ? `${API_NODE_URL}/faturamento/documento-saida/produto-grade-venda/pdv?produto=${searchInput?.toUpperCase()}&pesquisaPor=${searchBy}`
          : `${API_LOCAL_URL}/estoque/produto/?queryString=${searchInput?.toUpperCase()}`,
        { cancelToken }
      );

      const produtosExpandidos = data.flatMap(produto => {
        const hasRegra = produto.fat_regradesconto_produto?.length > 0;
        const hasTabela = produto.fatTabelavendaProduto?.length > 0;
        const expansaoRegra = hasRegra
          ? produto.fat_regradesconto_produto.map(regra => ({
              ...produto,
              regraAplicada: regra,
              tabelaAplicada: null
            }))
          : [];
        const expansaoTabela = hasTabela
          ? produto.fatTabelavendaProduto.map(tabela => ({
              ...produto,
              regraAplicada: null,
              tabelaAplicada: tabela
            }))
          : [];
        return expansaoRegra.concat(
          expansaoTabela,
          !hasRegra && !hasTabela ? [{ ...produto }] : []
        );
      });

      setResults(produtosExpandidos);

      let newArray = data.map(item => ({
        ...item,
        produtoEstProdutoId: item.id,
        estProdutoGrupo: { ...item.estProdutoGrupo, prefixoProduto: 'EX' },
        itemQuantidade: 1,
        itemValorUnitario: item?.estProdutoImposto[0]?.precoVenda,
        itemEstoque: item?.saldoEstoque
          ? item?.saldoEstoque?.estoqueReal + item?.saldoEstoque?.estoqueReservado * -1
          : 0,
        precoVenda: item?.estProdutoImposto[0]?.precoVenda,
        codigoDesc: item.codigo.split('.')[0],
        codigoNumero: Number(item.codigo.split('.')[1]),
        modalRegra: true
      }));
      
      setResults(newArray);
      
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalConsultaProdutoRegra({ show: false }));
  };

  useEffect(() => {
    fetcher(true);
  }, [searchInput, orderBy, sortBy, searchBy]);

  return (
    <>
      <Dialog
        fullScreen
        open={showModalConsultaProdutoRegra}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
      >
        <>
          <AppBar color="default" position="fixed">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <IconArrowBack />
              </IconButton>

              <Box style={{ minWidth: 180, width: '22%' }}>
                <Typography>Consulta de Produto por Regra e Tabela</Typography>
              </Box>

              <Divider className={classes.divider} />

              <TextField
                variant="outlined"
                autoFocus
                focused
                fullWidth
                placeholder="Pesquise por código, descrição, cód. barra, cód. fabricante, cód. auxiliar"
                value={searchInput}
                onChange={e => {
                  e.preventDefault();
                  const queryText = e.target.value;
                  setSearchInput(queryText);
                }}
                InputProps={{
                  style: { height: 44 },
                  startAdornment: (
                    <SearchAdornment
                      searchBy={searchBy}
                      setSearchBy={setSearchBy}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                    />
                  )
                }}
              />
            </Toolbar>
          </AppBar>

          {loading ? (
            <>
              <Box style={{ marginTop: 64 }}>
                <LinearProgress variant="indeterminate" color="primary" />
              </Box>
              <Searching />
            </>
          ) : (
            <>
              {results?.length > 0 ? (
                <List style={{ marginTop: 64 }}>
                  <ListHeader
                    dados={results}
                    setOrderBy={setOrderBy}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                  />
                  <Divider />
                  {results.map((produto, index) => (
                    <ListItemProduto
                      key={`${produto.id}-${produto.regraAplicada?.id || produto.tabelaAplicada?.id || index}`}
                      data={produto}
                      callback={origemSelecionado => {
                        dispatch(
                          pdvActions.selecionarProdutoRegra({ ...produto, origemSelecionado }, () => {
                            dispatch({
                              type: SET_FOCUS_QUANTIDADE,
                              focus: true
                            });
                          })
                        );
                      }}
                    />
                  ))}
                </List>
              ) : (
                <ListEmpty />
              )}
            </>
          )}
        </>
      </Dialog>
    </>
  );
};

export default ModalConsultaProduto;
