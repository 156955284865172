import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import { Formik } from 'formik';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as Yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import MaskedInput from 'react-text-mask';

import api from '~/services/api';
import { APP_ERROR, API_BASE_URL } from '~/constants';
import { getParametroAsBoolean, getParametroAsInteger } from '~/utils/parametros';

import { Container, Header } from './styles';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

function ModalEditarClienteDocumento({ data, showModal, onClose }) {
  const dispatch = useDispatch();

  const { empresa, parametros } = useSelector(state => state.auth);
  const [isCadastroCompleto, setIsCadastroCompleto] = useState();
  const [loading, setLoading] = useState(true);
  const [cliente, setCliente] = useState({
    clienteId: data?.clienteId ?? 0,
    clienteNome: data?.clienteNome ?? '',
    clienteCpfCnpj: data?.clienteCpfCnpj ?? '',
    clienteTelefone: data?.clienteTelefone ?? '',
    clienteCelular: data?.clienteCelular ?? '',
    intCliente: { ...data?.intCliente }
  });

  const criticaFaltaCpf = getParametroAsBoolean(parametros, 'PA001') ?? false;
  const criticaCpfDuplicado = getParametroAsBoolean(parametros, 'PA003') ?? false;
  const intClienteGrupoId = getParametroAsInteger(parametros, 'PA004');
  const cadastraClienteTelaVenda = getParametroAsBoolean(parametros, 'PA005') ?? false;

  const [validationSchema] = useState(
    Yup.object().shape({
      clienteNome: Yup.string().required('Nome do cliente é obrigatório'),
      clienteCpfCnpj: Yup.string().when('$other', (_, schema) =>
        criticaFaltaCpf ? schema.required('CPF/CNPJ é obrigatório') : schema
      )
    })
  );

  const handleSubmit = () => {};

  return (
    <>
      <Mui.Dialog
        open={showModal}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Identificar dados do consumidor
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>
        <Formik
          initialValues={cliente}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
            <>
              <form noValidate>
                <Mui.DialogContent dividers style={{ minWidth: 540, padding: 16 }}>
                  <Mui.Grid container item spacing={4}>
                    <Mui.Grid item md={6}>
                      <Mui.TextField
                        name="clienteCpfCnpj"
                        label={'CPF/CNPJ'}
                        value={values?.clienteCpfCnpj}
                        error={Boolean(touched.clienteCpfCnpj && errors.clienteCpfCnpj)}
                        autoFocus
                        fullWidth
                        helperText={touched.clienteCpfCnpj && errors.clienteCpfCnpj}
                        onBlur={handleBlur}
                        onChange={e => {
                          const cpfCnpj = e.target.value;
                          setFieldValue('clienteCpfCnpj', cpfCnpj);
                          // handleChangeCpfCnpj(cpfCnpj);
                        }}
                        variant="outlined"
                        InputProps={{
                          inputComponent: CpfCnpj,
                          endAdornment: (
                            <>
                              {/* {loadingCliente ? (
                                <Mui.CircularProgress color="inherit" size={20} />
                              ) : (
                                <>
                                  {values?.clienteCpfCnpj?.length >= 14 && (
                                    <Mui.IconButton
                                      tabIndex={-1}
                                      color="inherit"
                                      onClick={handleClearCliente}
                                      aria-label="close"
                                    >
                                      <MuiIcons.Close />
                                    </Mui.IconButton>
                                  )}
                                </>
                              )} */}
                            </>
                          )
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>

                    <Mui.Grid item md={6}>
                      <Mui.TextField
                        name="clienteTelefone"
                        label={'Telefone'}
                        value={values?.clienteTelefone}
                        error={Boolean(touched.clienteTelefone && errors.clienteTelefone)}
                        fullWidth
                        helperText={touched.clienteTelefone && errors.clienteTelefone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        InputProps={{
                          inputComponent: TextMaskCustom
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={12}>
                      <Mui.TextField
                        name="clienteNome"
                        label={'Nome'}
                        value={values?.clienteNome}
                        error={Boolean(touched.clienteNome && errors.clienteNome)}
                        fullWidth
                        helperText={touched.clienteNome && errors.clienteNome}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />

                      {values?.intCliente?.enderecoLogradouro && (
                        <Box mt={2}>
                          <Typography variant="body2">
                            {values?.intCliente?.enderecoLogradouro},
                            {values?.intCliente?.enderecoLogradouroNumero}
                            {' - '}
                            {values?.intCliente?.enderecoBairro}
                          </Typography>

                          <Typography variant="body2">
                            {values?.intCliente?.enderecoIntCidade?.cidade}/
                            {values?.intCliente?.enderecoIntCidade?.uf} -{' '}
                            {values?.intCliente?.enderecoCep}
                          </Typography>
                        </Box>
                      )}
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container item spacing={4}></Mui.Grid>
                </Mui.DialogContent>
              </form>
            </>
          )}
        </Formik>
        <Mui.DialogActions>
          <Mui.Box flex={1} display="flex" justifyContent="space-between">
            <Mui.FormControlLabel
              style={{ marginRight: 12 }}
              control={
                <Mui.Switch
                  checked={isCadastroCompleto}
                  onChange={() => setIsCadastroCompleto(state => !state)}
                />
              }
              label="Cadastro completo"
            />
            <Mui.Box flex={1} display="flex" justifyContent="flex-end">
              <Mui.Button
                variant="contained"
                color="primary"
                // onClick={() => handleTransmitirDocumento()}
              >
                <MuiIcons.Save />
                <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                  SALVAR
                </Mui.Typography>
              </Mui.Button>
            </Mui.Box>
            <Mui.Button style={{ marginLeft: 12 }} onClick={onClose}>
              <MuiIcons.Close />{' '}
              <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                CANCELAR (ESC)
              </Mui.Typography>
            </Mui.Button>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
}

export default ModalEditarClienteDocumento;
