import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import IconClose from '@material-ui/icons/Close';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconCancel from '@material-ui/icons/Cancel';

import LiberacaoAcesso from '../../components/LiberacaoAcesso';

const ModalConfirmacao = ({ title, body, options, rotina, open, onClose, onCloseCallback }) => {
  const [disableModal, setDisableModal] = useState(true);
  const [tokenLiberacaoRotina, setTokenLiberacaoRotina] = useState(null);

  return (
    <Dialog
      open={open}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          onClose();
        }
      }}
      PaperComponent={Paper}
      maxWidth={'md'}
    >
      <DialogContent style={{ width: 540, padding: 32 }}>
        <Box textAlign="center" mb={4}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="h5">{body}</Typography>
        </Box>
        {disableModal && (
          <LiberacaoAcesso
            orientation="vertical"
            rotina={rotina}
            callback={(acesso, token) => {
              setDisableModal(!acesso);
              if (acesso) {
                setTokenLiberacaoRotina(token);
              }
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={`button-${options.icon}`}
          disabled={disableModal}
          style={{ marginLeft: 8 }}
          onClick={() => onCloseCallback(tokenLiberacaoRotina)}
        >
          {options.icon === 'warning' ? <IconCancel /> : <IconClose />} {options?.confirmButtonText}
        </Button>
        <Button size="large" style={{ marginLeft: 8 }} onClick={onClose}>
          {options?.cancelButtonText ?? 'Cancelar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmacao;
