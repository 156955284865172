import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import CardOperacao from '../../components/CardOperacao';
import CardDocumento from '../../components/CardDocumento';
import CardVendedor from '../../components/CardVendedor';
import CardCliente from '../../components/CardCliente';
import CardAtalhoPagamento from '../../components/CardAtalhoPagamento';
import CardProfissional from '../../components/CardProfissional';
import { TIPO_DOCUMENTO } from '~/constants';

const Identificacao = () => {
  const { venda, tipoDocumento } = useSelector(state => state.pdv);

  return (
    <>
      {/* <Grid item xs={12}>
        <ConsultaProduto />
      </Grid> */}
      <Box p={2}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <EstoqueProduto />
          </Grid> */}
          {venda?.id && (
            <Grid item xs={12} md={2}>
              <CardDocumento />
            </Grid>
          )}
          <Grid item xs={12} md={venda?.id ? 5 : 6}>
            <CardVendedor />
          </Grid>
          <Grid item xs={12} md={venda?.id ? 5 : 6}>
            <CardOperacao />
          </Grid>

          <Grid item xs={12}>
            <CardCliente wrap={true} />
          </Grid>
            <Grid item xs={12}>
              <CardProfissional wrap={true} />
            </Grid>
          <Grid item xs={12}>
            <CardAtalhoPagamento />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Identificacao;
