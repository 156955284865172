import qs from 'qs';
import api from '~/services/api';
import { API_BASE_URL, API_LOCAL_URL, API_TERMINAL_URL } from '~/constants';

class AuthService {
  _strategy = null;

  constructor() {
    this._strategy = new AuthOnlineService();
  }

  setStategy(isOffline = false) {
    console.log({ isOffline });
    this._strategy = isOffline ? new AuthOfflineService() : new AuthOnlineService();
  }

  getToken(payload) {
    return this._strategy.getToken(payload);
  }

  getFiliais(payload) {
    return this._strategy.getFiliais(payload);
  }

  getConta() {
    return this._strategy.getConta();
  }

  getCompartilhamento() {
    return this._strategy.getCompartilhamento();
  }
}

class AuthOnlineService {
  getFiliais({ login, senha }) {
    return api.get(`${API_BASE_URL}/v1/integracao/empresa/filiais`, {
      headers: {
        login,
        senha
      }
    });
  }

  getToken({ login, senha, FilialID }) {
    var data = qs.stringify({
      grant_type: 'password',
      username: login,
      password: senha,
      FilialID
    });

    return api.post(`${API_BASE_URL}/v1/painelcontrole/usuario/token`, data, {
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  }

  getConta() {
    return api.get(`${API_BASE_URL}/v1/painelcontrole/usuario/conta`);
  }

  getCompartilhamento() {
    return api.get(`${API_BASE_URL}/v1/painelcontrole/compartilhamento`);
  }
}

class AuthOfflineService {
  getFiliais({ login, senha }) {
    return api.get(`${API_LOCAL_URL}/integracao/empresa/filiais`, {
      headers: {
        login,
        senha
      }
    });
  }

  getToken({ login, senha, FilialID }) {
    var data = qs.stringify({
      grant_type: 'password',
      username: login,
      password: senha,
      FilialID
    });

    return api.post(`${API_TERMINAL_URL}/painelcontrole/usuario/token`, data, {
      'Content-Type': 'application/x-www-form-urlencoded'
    });
  }

  getConta() {
    return api.get(`${API_LOCAL_URL}/painelcontrole/usuario/conta`);
  }

  getCompartilhamento() {
    return api.get(`${API_LOCAL_URL}/painelcontrole/compartilhamento`);
  }
}

export default new AuthService();
