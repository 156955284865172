import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Alert as MuiAlert } from '@material-ui/lab';
import { spacing } from '@material-ui/system';
import _ from 'lodash';

const Alert = styled(MuiAlert)(spacing);

const ErrorFormik = React.memo(({ errors }) => {
  const [allErrors, setAllErrors] = useState([]);

  const recursivo = error => {
    const accumulator = Object.entries(error).map(item => (typeof item[1] === 'object' ? recursivo(item[1]) : item));
    return _.flattenDeep(accumulator);
  };

  useEffect(() => {
    setAllErrors(recursivo(errors).filter((item, indice) => indice % 2 !== 0));
  }, [errors]);

  return (
    <>
      {allErrors.length > 0 && (
        <Alert severity="error" my={3}>
          {allErrors.map((values) => (
            <p key={values}>{values}</p>
          ))}
        </Alert>
      )}
    </>
  );
});

export default ErrorFormik;
