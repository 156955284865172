import api from '~/services/api';
import { APP_PRINT_SERVER } from '~/constants';
import { formatCurrency, formatDate } from '~/utils/format';
import toast from './toast';
import moment from 'moment';

class PrintService {
  async savePreference(usuarioId, { tipoDocumento, ...payload }) {
    const dataLocalStorage = localStorage.getItem(APP_PRINT_SERVER);
    const preferenciaLocal = dataLocalStorage  ? JSON.parse(dataLocalStorage ) : null;
    if (!preferenciaLocal) {
      const data = {
        [usuarioId]: {
          [tipoDocumento]: { ...payload }
        }
      };

      localStorage.setItem(APP_PRINT_SERVER, JSON.stringify(data));
      toast.success('Preferências carregadas com sucesso!');
      await this.teste({
        servidorImpressao: `http://${payload?.servidor}:${payload?.porta}`
      });

      return;
    }

    if (!preferenciaLocal[usuarioId]) {
      preferenciaLocal[usuarioId] = {};
      preferenciaLocal[usuarioId][tipoDocumento] = { ...payload };
    }

    const tmpPreferencia = preferenciaLocal[usuarioId][tipoDocumento];
    preferenciaLocal[usuarioId][tipoDocumento] = { ...tmpPreferencia, ...payload };
    localStorage.setItem(APP_PRINT_SERVER, JSON.stringify(preferenciaLocal));
    toast.success('Preferências salvas com sucesso!');
    await this.teste({
      servidorImpressao: `http://${payload?.servidor}:${payload?.porta}`
    });

    return;
  }

  getPreference(usuarioId, tipoDocumento) {
    const dataLocalStorage  = localStorage.getItem(APP_PRINT_SERVER);

    const _preferencia = dataLocalStorage  ? JSON.parse(dataLocalStorage ) : null;
    const _preferenciaUsuario = _preferencia ? _preferencia[usuarioId] : null;
    return _preferenciaUsuario
      ? _preferenciaUsuario[tipoDocumento]
      : {
          servidor: '',
          porta: '',
          utilizaServidorImpressao: false,
          imprimirAposSalvar: false,
          imprimirExpedicao: false
        };
  }

  getServidorUrl(servidor) {
    return `http://${servidor?.servidor}:${servidor?.porta}`;
  }

  buildPayload(payload, imprimeExpedicao){
    const { empresa, tipoDocumento, ...documento } = payload;

    const dataEmissao = (documento?.dataEmissao || documento?.documentoDataEmissao) ?? new Date();

    const data = {
      emitente: {
        logoBase64: empresa?.logo,
        razaoSocial: empresa?.razaoSocial,
        cpfCnpj: empresa?.cnpj,
        ie: empresa?.inscricaoEstadual,
        telefone: empresa?.telefone,
        celular: empresa?.celular,
        emailContato: empresa?.emailContato,
        endereco: {
          logradouro: empresa?.enderecoLogradouro,
          numero: empresa?.enderecoLogradouroNumero,
          bairro: empresa?.enderecoBairro,
          cidade: empresa?.enderecoIntCidade,
          uf: empresa?.uf
        }
      },
      destinatario: {
        codigo: documento?.intCliente?.codigoInterno ?? null,
        razaoSocial: (documento?.clienteNome || documento?.intCliente?.razaoSocial) ?? null,
        cpfCnpj: (documento?.clienteCpfCnpj || (documento?.intCliente?.natureza === 'F' 
          ? documento?.intCliente?.pfCpf
          : documento?.intCliente?.pjCnpj))
          ?? null,
        ie: documento?.intCliente?.pjInscricaoEstadual ?? null,
        telefone: documento?.intCliente ? documento?.clienteTelefone : null,
        celular: documento?.intCliente ? documento?.intCliente?.celular : null,
        endereco: {
          logradouro: (documento?.entregaLogradouro || documento.enderecoLogradouro) ?? null,
          numero: (documento?.entregaLogradouroNumero || documento?.enderecoLogradouroNumero) ?? null,
          complemento: (documento?.entregaComplemento || documento?.enderecoComplemento) ?? null,
          bairro: (documento?.entregaBairro || documento?.enderecoBairro) ?? null,
          cidade: (documento?.entregaIntCidade?.cidade || documento?.enderecoIntCidade?.cidade) ?? null,
          uf: (documento?.entregaUf || documento?.enderecoUf) ?? null
        }
      },
      documento: {
        nome: tipoDocumento,
        numero: documento?.numero,
        serie: documento?.serie,
        dataEmissao: formatDate(dataEmissao),
        documentoFinalidade: documento.fisOperacao.documentoFinalidadeEnum ? ` ${documento.fisOperacao.documentoFinalidadeEnum.chave} - ${documento.fisOperacao.documentoFinalidadeEnum.valor}` : null,
        criadoPor: documento?.logPctUsuarioNome,
        horaCriacao: documento?.logCriacao,
        nomeVendedor: documento?.fatVendedor?.nome ?? null,
        infoComplementar: documento?.informacaoComplementar,
        condicaoPagamento: documento?.fatCondicaopagamento?.descricao
      },
      itens: documento?.itens?.map(item => ({
        ean: item?.produtoEan,
        codigo: item?.produtoCodigo,
        descricao: item?.produtoDescricao,
        unidadeVenda: item?.produtoUnidadeComercial,
        precoUnitario: item?.itemValorUnitario,
        quantidade: item?.itemQuantidade,
        itemCancelado: item?.itemCancelado ? 'S' : null,
        descricaoExpedicao: item?.omsModalidadeExpedicao ? item?.omsModalidadeExpedicao?.descricao : null,
        valorTotalBruto: formatCurrency(item.itemQuantidade * item.itemValorUnitario),
        valorDesconto: item?.itemValorDesconto,
        valorAcrescimo: item?.itemValorAcrescimo,
        valorTotal: item?.itemValorTotal
      })),
      totalizador: {
        valorBruto: documento?.valorMercadoria,
        valorDesconto: documento?.valorDesconto,
        valorAcrescimo: documento?.valorAcrescimo,
        valorFrete: documento?.valorFrete,
        valorTotal: documento?.valorTotal
      },
      pagamentos: documento?.parcelas && documento?.parcelas?.length > 0 ? documento?.parcelas.map(parcela => ({
        meioPagamento: parcela?.finMeiopagamento?.descricao ?? null,
        dataVencimento: formatDate(parcela.parcelaVencimento ? new Date(parcela?.parcelaVencimento) : new Date()),
        valorPago: parcela.parcelaValorTotal,
        valorRecebido: parcela.finMeiopagamento && parcela.finMeiopagamento.nfePagamento === 1 && documento.valorRecebido ? documento.valorRecebido : 0,
      }))
      : 
      [
        {
          meioPagamento: documento?.finMeiopagamento?.descricao,
          dataVencimento: formatDate(new Date()),
          valorPago: documento?.valorTotal,
          valorRecebido: parcela.finMeiopagamento && parcela.finMeiopagamento.nfePagamento === 1 && documento.valorRecebido ? documento.valorRecebido : 0,
        }
      ],
      expedicao: imprimeExpedicao
        ? {
            armazens: Object.keys(documento?.produtosEnderecos).map(endereco => ({
              armazem: endereco,
              produtos: documento?.produtosEnderecos[endereco].map(produto => ({
                codigo: produto.codigo,
                descricao: produto.descricao,
                ean: documento.itens.find(item => item.produtoCodigo === produto.codigo)?.produtoEan,
                unidadeVenda: produto.unidadeVenda,
                quantidade: produto.quantidade,
                enderecos: produto.enderecoProduto
              }))
            }))
          }
        : null,
      profissional: {
        nome: documento?.fatProfissional?.nome
      }
    };

    return data;
  }


  async execute(payload, opts) {
    try {
      if (!opts?.servidorImpressao) {
        return;
      }

      payload.documento = {
        ...payload.documento,
        horaCriacao: moment(payload?.documento?.horaCriacao).format('HH:mm'),
        criadoPor: payload?.documento?.criadoPor,
        documentoFinalidade: payload?.documento?.documentoFinalidade
      }

      const { empresa, tipoDocumento, ...documento } = payload;

      const dataEmissao = (documento?.dataEmissao || documento?.documentoDataEmissao) ?? new Date();

      const data = {
        emitente: {
          logoBase64: empresa?.logo,
          razaoSocial: empresa?.razaoSocial,
          cnpj: empresa?.cnpj,
          ie: empresa?.inscricaoEstadual,
          endereco: {
            logradouro: empresa?.enderecoLogradouro,
            numero: empresa?.enderecoLogradouroNumero,
            bairro: empresa?.enderecoBairro,
            cidade: empresa?.enderecoIntCidade,
            uf: empresa?.uf
          }
        },
        destinatario: {
          codigo: documento?.intCliente?.codigoInterno ?? null,
          razaoSocial: (documento?.clienteNome || documento?.intCliente?.razaoSocial) ?? null,
          cpfCnpj:
            (documento?.clienteCpfCnpj ||
              (documento?.intCliente?.natureza === 'F'
                ? documento?.intCliente?.pfCpf
                : documento?.intCliente?.pjCnpj)) ??
            null,
          ie: documento?.intCliente?.pjInscricaoEstadual ?? null,
          telefone: documento?.intCliente
            ? documento?.intCliente?.telefone
            : documento?.clienteTelefone,
          celular: documento?.intCliente ? documento?.intCliente?.celular : null,
          endereco: {
            logradouro: (documento?.entregaLogradouro || documento.enderecoLogradouro) ?? null,
            numero:
              (documento?.entregaLogradouroNumero || documento?.enderecoLogradouroNumero) ?? null,
            complemento: (documento?.entregaComplemento || documento?.enderecoComplemento) ?? null,
            bairro: (documento?.entregaBairro || documento?.enderecoBairro) ?? null,
            cidade:
              (documento?.entregaIntCidade?.cidade || documento?.enderecoIntCidade?.cidade) ?? null,
            uf: (documento?.entregaUf || documento?.enderecoUf) ?? null
          }
        },
        documento: {
          nome: tipoDocumento,
          numero: documento?.numero,
          serie: documento?.serie,
          dataEmissao: formatDate(dataEmissao),
          nomeVendedor: documento?.fatVendedor?.nome ?? null,
          infoComplementar: documento?.informacaoComplementar,
          condicaoPagamento: documento?.fatCondicaopagamento?.descricao,
          horaCriacao: moment(payload.logCriacao).format('HH:mm'),
          criadoPor: payload.logPctUsuarioNome,
          documentoFinalidade: `${payload?.fisOperacao?.documentoFinalidade} - ${payload?.fisOperacao?.descricao}`
        },
        itens: documento?.itens?.map(item => ({
          codigo: item?.produtoCodigo,
          descricao: item?.produtoDescricao,
          unidadeVenda: item?.produtoUnidadeComercial,
          precoUnitario: item?.itemValorUnitario,
          quantidade: item?.itemQuantidade,
          itemCancelado: item?.itemCancelado ? 'S' : null,
          descricaoExpedicao: item?.omsModalidadeExpedicao
            ? item?.omsModalidadeExpedicao?.descricao
            : null,
          valorTotalBruto: formatCurrency(item.itemQuantidade * item.itemValorUnitario),
          valorDesconto: item?.itemValorDesconto,
          valorAcrescimo: item?.itemValorAcrescimo,
          valorTotal: item?.itemValorTotal
        })),
        totalizador: {
          valorBruto: documento?.valorMercadoria,
          valorDesconto: documento?.valorDesconto,
          valorAcrescimo: documento?.valorAcrescimo,
          valorFrete: documento?.valorFrete,
          valorTotal: documento?.valorTotal
        },
        pagamentos:
          documento?.parcelas && documento?.parcelas?.length > 0
            ? documento?.parcelas.map(parcela => ({
                meioPagamento: parcela?.finMeiopagamento?.descricao ?? null,
                dataVencimento: formatDate(
                  parcela.parcelaVencimento ? new Date(parcela?.parcelaVencimento) : new Date()
                ),
                valorPago: parcela.parcelaValorTotal
              }))
            : [
                {
                  meioPagamento: documento?.finMeiopagamento?.descricao,
                  dataVencimento: formatDate(new Date()),
                  valorPago: documento?.valorTotal
                }
              ],
        expedicao: opts?.imprimeExpedicao
          ? {
              armazens: Object.keys(documento?.produtosEnderecos).map(endereco => ({
                armazem: endereco,
                produtos: documento?.produtosEnderecos[endereco].map(produto => ({
                  codigo: produto.codigo,
                  descricao: produto.descricao,
                  unidadeVenda: produto.unidadeVenda,
                  quantidade: produto.quantidade,
                  enderecos: produto.enderecoProduto
                }))
              }))
            }
          : null,
        profissional: {
          nome: documento?.fatProfissional?.nome
        }
      };

      console.log('IMPRESSÃO', { data, payload, opts });
      // return await new Promise(resolve => setTimeout(resolve, 3000));

      return await api.post(`${opts?.servidorImpressao}/print`, payload);
    } catch (error) {
      console.log(error);
    }
  }

  async printExpedicao(payload, opts){
    try {
      if (!opts?.servidorImpressao) {
        return;
      }

      console.log('IMPRESSÃO EXPEDIÇÃO', {  payload, opts });
      // return await new Promise(resolve => setTimeout(resolve, 3000));

      return await api.post(`${opts?.servidorImpressao}/print/expedicao`, payload);
    } catch (error) {
      console.log(error);
    }
  }

  async teste(opts) {
    try {
      return await api.get(`${opts?.servidorImpressao}/test`);
    } catch (error) {
      console.log(error);
    }
  }
}

export default new PrintService();
