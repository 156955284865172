import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CurrencyInput from 'react-currency-input';
import { selectorParametroAsInteger } from '~/store/modules/auth/auth-selectors';

const CustomNumberInput = props => {
  const { inputRef, onChange, ...other } = props;

  const getCasasDecimais = useSelector(selectorParametroAsInteger('PZ002'));
  let casasDecimais = 2;
  if(props?.id === 'itemQuantidade') {
    casasDecimais = getCasasDecimais;
  }

  useEffect(()=> {
    const res = document?.getElementById('itemQuantidade');
    setTimeout(() => res?.select(), 500);
  }, []);

  return (
    <CurrencyInput
      precision={casasDecimais}
      decimalSeparator=","
      thousandSeparator="."
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onChangeEvent={(event, maskedvalue, floatvalue) => {
        onChange({
          ...event,
          maskedvalue,
          floatvalue
        });
      }}
    />
  );
};

export { CustomNumberInput };
