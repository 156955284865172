import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Skeleton from '@material-ui/lab/Skeleton';
import { Search as IconSearch } from '@material-ui/icons';

import { SHOW_SHORTCUTS } from '~/constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import toast from '~/services/toast';
import { TIPO_DOCUMENTO } from '~/constants';

const CardOperacao = React.memo(() => {
  const dispatch = useDispatch();
  const { venda, showModalLoadingMensagem, tipoDocumento } = useSelector(state => state.pdv);


  const handleConsultarOperacao = () => {
    if(venda?.id && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA) return toast.warning('Não é possível editar a operação!');
    dispatch(pdvActions.setShowModalConsultaOperacao({ show: true }));
  };

  return (
    <Paper>
      <Box p={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Box mr={1}>
          <Typography variant="caption"> Operação {SHOW_SHORTCUTS && '(CTRL+O)'}</Typography>
          {showModalLoadingMensagem?.show ? (
            <Skeleton variant="text" width={240} />
          ) : (
            <Typography variant="body2">
              <b>{venda?.fisOperacao?.descricao}</b>
            </Typography>
          )}
        </Box>

        <Box display="flex" mr={1} justifyContent="center" alignItems="center">
          <Fab
            size="small"
            aria-label="Consulta operação"
            onClick={() => handleConsultarOperacao()}
          >
            <IconSearch />
          </Fab>
        </Box>
      </Box>
    </Paper>
  );
});

export default CardOperacao;
