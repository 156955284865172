import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';

import ConsultaProduto from './components/ConsultaProduto';
import EstoqueProduto from './components/EstoqueProduto';
import PromocaoProduto from './components/PromocaoProduto';

const VendeItem = () => {
  return (
    <>
      <Grid item xs={12}>
        <ConsultaProduto />
      </Grid>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <EstoqueProduto />
          </Grid>
          {/* <Grid item xs={12}>
            <PromocaoProduto />
          </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default VendeItem;
