import api from "~/services/api";
import toast from "~/services/toast";
import erroImage from '~/assets/error_img.svg';
import * as Mui from '@material-ui/core';

const { useState } = require("react");
const { API_NODE_URL } = require("~/constants");


const ErrorFallback = ({ error: { message: mensagemDeErro } }) => {
    const [relatarErro, setRelatarErro] = useState(false);
    const [mensagemUsuario, setMensagemUsuario] = useState('');
  
    const onSubmit = async () => {
      await api.post(`${API_NODE_URL}/pdv/erro/email`, { mensagemDeErro, mensagemUsuario });
      setMensagemUsuario('');
      setRelatarErro(false);
      toast.show('Mensagem enviada, obrigado!');
    }
  
    return (
      <div role="alert" style={{textAlign: 'center', marginTop: '5%'}}>
        <div style={{fontSize: '2rem', fontWeight: 'bold', marginBottom: '20px'}}>Ocorreu um erro Inesperado!</div>
        <img src={erroImage} alt="Erro Image" width={500} fill='red' stroke='green' />
        <div>
          
          <div style={{margin: '8px'}}>Clique abaixo para reportar o erro!</div>
          <button 
            style={{padding: '5px 25px', borderRadius: '8px', backgroundColor: '#FF6363', color: 'white', border: '1px solid white', marginTop: '10px'}}
            onClick={() => setRelatarErro(true)}
            >Relatar o erro!</button>
            {
              relatarErro && 
              <div style={{width: '400px', margin: 'auto', marginTop: '15px'}}>
                <Mui.TextField
                  value={mensagemUsuario ?? 0}
                  onChange={(e) => setMensagemUsuario(e.target.value)}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    style: {
                      height: 54
                    }
                  }}
                />
                <button 
                  style={{padding: '5px 25px', borderRadius: '8px', backgroundColor: 'rgb(0,104,233)', color: 'white', border: '1px solid white', marginTop: '10px'}}
                  onClick={onSubmit}
                  >Enviar</button>
              </div>
            }
        </div>
      </div>
    )
}

export default ErrorFallback;