import { combineReducers } from 'redux';

import app from './app/app-reducer';
import auth from './auth/auth-reducer';
import pdv from './pdv/pdv-reducer';
import monitor from './monitor/monitor-reducer';

const rootReducer = combineReducers({ app, auth, pdv, monitor });

export default rootReducer;
