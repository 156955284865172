
export const verificaItemEstoque = (itens, empresaId) => {
    const newItens = itens.map(item => {
        const allItens = itens.filter(i => i.produtoEstProdutoId === item.produtoEstProdutoId);
        const quantidadeTotal = allItens.reduce((total, item) => total + item.itemQuantidade, 0);
        if(item?.saldoEstoque && Array.isArray(item?.saldoEstoque)) {
          const res = item?.saldoEstoque?.filter(estoque => estoque.intEmpresaId === empresaId);
          if(res.length !== item.saldoEstoque.length) item.saldoEstoque = res;
          const estoqueDisponivel = item.saldoEstoque?.reduce((tot, item) => (tot += item.estoqueReal), 0) - item.saldoEstoque?.reduce((tot, item) => (tot += item.estoqueReservado), 0);
          if(estoqueDisponivel < item.itemQuantidade || estoqueDisponivel < quantidadeTotal) {
            return {
              ...item,
              estoqueInsuficiente: true
            }
          } else {
            return {
              ...item,
              estoqueInsuficiente: false
            }
          }
        } else if(item?.saldoEstoque && !Array.isArray(item?.saldoEstoque)) {
          const estoqueDisponivel = item.saldoEstoque.estoqueReal - item.saldoEstoque.estoqueReservado;
          if(estoqueDisponivel < item.itemQuantidade || estoqueDisponivel < quantidadeTotal) {
            return {
              ...item,
              estoqueInsuficiente: true
            }
          }
          else return {
            ...item,
            estoqueInsuficiente: false
          }
        } 
        else if(!item?.saldoEstoque) {
          return {
            ...item,
            estoqueInsuficiente: true
          }
        }
      });
      return newItens;
}