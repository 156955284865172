import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import * as Mui from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectorParametroAsInteger } from '~/store/modules/auth/auth-selectors';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

import { formatCurrency } from '~/utils/format';

import NotFound from '~/assets/not-found.png';
import { yellow } from '@material-ui/core/colors';

const ConsultaProdutoItem = React.memo(({ data }) => {
  const theme = useTheme();
  const parametroPF051 = useSelector(selectorParametroAsInteger('PF051'))
  const parametroPF052 = useSelector(selectorParametroAsInteger('PF052'))

  let semMovimento = false;
  
  if (parametroPF051 != null && parametroPF052 != null && data.estProdutoImposto.length > 0) {
    const { diasSemVenda, diasSemCompra } = data.estProdutoImposto[0];
    semMovimento = diasSemVenda >= parametroPF051 && diasSemCompra >= parametroPF052;
  }

  return (
    <>
      <Grid container item md={12}>
        <Grid container item md={10} alignContent="center" wrap="nowrap">
          <Grid item md={1}>
            <Paper style={{ backgroundColor: '#FFF', padding: 2 }}>
              <img
                src={data?.imageUrl ? data?.imageUrl : NotFound}
                alt={data?.descricao}
                width="100%"
                style={{ maxHeight: 80 }}
              />
            </Paper>
          </Grid>
          <Grid item md={11} style={{ marginLeft: 8 }}>
            <Typography
              color="textPrimary"
              style={{
                textTransform: 'uppercase',
                fontWeight: 600,
                marginBottom: 2
              }}
            >
              {data?.codigo} - {data?.descricaoCompleta ?? data?.descricao}
            </Typography>
            <div style={{ display: 'flex', flex: 1 }}>
              <Typography variant="body2" color="textSecondary" style={{ marginRight: 16 }}>
                {'Unidade: '}
                {data?.unidadeComercial ?? '-'}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ marginRight: 16 }}>
                {'Cód.aux.: '} {data?.codigoAuxiliar ?? '-'}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ marginRight: 8 }}>
                {'Cód.barra: '} {data?.ean ?? '-'}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ marginRight: 8 }}>
                {'Cód.fab.: '} {data?.codigoFabricante ?? '-'}
              </Typography>
              {semMovimento && (
              <Tooltip title="Produto não movimentado há muito tempo. (PF051)">
                <Typography variant="body2" color="textSecondary" style={{ marginRight: 8 }}>
                  <WarningOutlinedIcon sx={{ color: yellow[500] }} />
                </Typography>
              </Tooltip>
            )}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1
              }}
            >
              <Typography variant="body2" color="textSecondary">
                <b>Estoque disponível: </b>
              </Typography>
              <Chip
                size="small"
                label={(data?.itemEstoque ? data?.itemEstoque : 0).toLocaleString('pt-BR')}
                style={{ color: data?.itemEstoque
                    ? (data?.itemEstoque ?? 0) >= 0
                      ? '#09A848'
                      : 'rgb(255, 4, 4)'
                    : theme.palette.common.black
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container item md={2} justify="flex-end" alignContent="center">
          <Box textAlign="right">
            <Typography variant="caption">Preço venda:</Typography>
            <Typography variant="h4" align="right">
              {formatCurrency(data?.precoVenda)}
            </Typography>
          </Box>
        </Grid>

        <Divider />
      </Grid>
    </>
  );
});

export default ConsultaProdutoItem;
