import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import IconClose from '@material-ui/icons/Close';
import IconLockOpen from '@material-ui/icons/LockOpen';

import api from '~/services/api';
import toast from '~/services/toast';
import { API_LOCAL_URL, API_NODE_URL, APPLICATION_MODE } from '~/constants';
import normalizeError from '~/utils/normalize-error';

const LiberacaoAcesso = ({ rotina, orientation = 'horizontal', callback }) => {
  const { applicationMode } = useSelector(state => state.app);
  const permissoes = useSelector(state => state?.auth?.permissoes ?? []);
  const empresaId = useSelector(state => state?.auth?.empresa?.id ?? 0);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pristine, setPristine] = useState(false);

  const [validationSchema] = useState(
    Yup.object().shape({
      login: Yup.string()
        .email('Informar um email válido!')
        .max(255)
        .required('Email é obrigatório!'),
      senha: Yup.string()
        .max(255)
        .required('Senha é obrigatório!')
    })
  );

  const handleSubmit = async values => {
    try {
      setLoading(true);

      const payload = {
        login: values.login,
        senha: values.senha,
        empresaId,
        rotina
      };

      const { data } =
        applicationMode === APPLICATION_MODE.ONLINE
          ? await api.post(`${API_NODE_URL}/auth/liberacao-rotina`, payload)
          : await api.post(`${API_LOCAL_URL}/auth/liberacao-rotina`, payload);

      const token = data?.token;
      if (callback) callback(true, token);
    } catch (error) {
      const message = normalizeError(error);
      if (error?.response?.status === 401) {
        toast.warning(message);
      } else {
        toast.error(message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (!pristine) {
      const _permissao = permissoes.find(x => x.pctRotinaRotina === rotina);
      const acesso = _permissao?.acesso ?? false;
      if (!acesso) {
        setShowModal(true);
        setPristine(true);
      } else if (callback) callback(acesso);
    }
  }, [permissoes]);

  return (
    <>
      <Box mb={2}>
        <Alert severity="error" variant="filled">
          <AlertTitle>
            <b>USUÁRIO SEM PERMISSÃO</b>
          </AlertTitle>
          <Box
            flex={1}
            display="flex"
            flexDirection={orientation === 'horizontal' ? 'row' : 'column'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Aguardando liberação, para desbloquear o pedido.</Typography>
            <Box mt={2}>
              <Button variant="contained"  onClick={() => setShowModal(true)}>
                <IconLockOpen /> Desbloquear
              </Button>
            </Box>
          </Box>
        </Alert>
      </Box>
      {showModal && (
        <Formik
          initialValues={{
            login: '',
            senha: ''
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Dialog open={showModal} onClose={handleClose} PaperComponent={Paper} maxWidth={'md'}>
                <DialogTitle>
                  <AppBar color="primary" position="static">
                    <Toolbar>
                      <Box>
                        <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                          Liberação de rotina
                        </Typography>
                      </Box>
                      <Box flexGrow={1} />

                      <IconButton color="inherit" onClick={handleClose} aria-label="close">
                        <IconClose />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                </DialogTitle>
                <DialogContent dividers style={{ minWidth: 480, padding: 16 }}>
                  <TextField
                    error={Boolean(touched.login && errors.login)}
                    fullWidth
                    autoFocus
                    helperText={touched.login && errors.login}
                    label="E-mail"
                    margin="normal"
                    name="login"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={loading}
                    type="email"
                    value={values.login}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.senha && errors.senha)}
                    fullWidth
                    helperText={touched.senha && errors.senha}
                    label="Senha"
                    margin="normal"
                    name="senha"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={loading}
                    type="password"
                    value={values.senha}
                    variant="outlined"
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    type="submit"
                    className="button-success"
                    disabled={loading}
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    {loading ? (
                      <>
                        <CircularProgress size={24} style={{ marginRight: 12 }} />
                        Aguarde...
                      </>
                    ) : (
                      <>
                        <IconLockOpen /> DESBLOQUEAR
                      </>
                    )}
                  </Button>
                  <Button
                    size="large"
                    disabled={loading}
                    style={{ marginLeft: 16, width: 180 }}
                    onClick={handleClose}
                  >
                    CANCELAR
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default LiberacaoAcesso;
