import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as IconsMaterial from '@mui/icons-material';

import { APP_TERMINAL_PDV, APP_TOKEN } from '~/constants';
import { sendProcessQueue, getPendingInQueue } from '~/services/events';

import * as appActions from '~/store/modules/app/app-actions';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 340
  }
}));

const ButtonQueueDocuments = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const { queues, showProgressQueue, mensagemQueue } = useSelector(state => state.app);

  return (
    <>
      <Mui.Button
        ref={ref}
        disabled={showProgressQueue}
        onClick={() => {
          dispatch(appActions.setShowFilaDocumentos(true));
        }}
        style={{ borderRadius: 0, color: '#FFF' }}
      >
        {showProgressQueue ? (
          <Mui.CircularProgress style={{ color: '#FFF' }} size={20} />
        ) : (
          <Mui.Badge badgeContent={queues?.length} color="error">
            <MuiIcons.CloudUpload />
          </Mui.Badge>
        )}

        <Mui.Typography style={{ fontSize: 12, marginLeft: 8, textTransform: 'none' }}>
          {mensagemQueue && mensagemQueue !== '' ? mensagemQueue : `Documento(s) pendente(s)`}
        </Mui.Typography>
      </Mui.Button>
    </>
  );
};

export default ButtonQueueDocuments;
