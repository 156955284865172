import { APPLICATION } from '~/constants';

const isWeb = APPLICATION !== 'electron';
let ipcRenderer = null;
if (!isWeb) {
  const electron = window.require('electron');
  ipcRenderer = electron?.ipcRenderer;
}

function sendDownloadRequest(token, terminal) {
  if (ipcRenderer) {
    ipcRenderer.send('@download/REQUEST', token, terminal);
  }
}

function sendDownloadInitialRequest(token, terminal) {
  if (ipcRenderer) {
    ipcRenderer.send('@downloadInitial/REQUEST', token, terminal);
  }
}

function updateLocalData(token, terminal, callback = null) {
  if (ipcRenderer) {
    ipcRenderer.send('@updateLocalData/REQUEST', token, terminal, callback);
  }
}

function subscribleDownloadInitial(subscribleFunction) {
  if (ipcRenderer) {
    ipcRenderer.on('@download/INITIAL', subscribleFunction);
  }
}

function subscribleDownloadUpdateMessage(subscribleFunction) {
  if (ipcRenderer) {
    ipcRenderer.on('@download/MESSAGE', subscribleFunction);
  }
}

function subscribleDownloadListaRetornos(subscribleFunction) {
  if (ipcRenderer) {
    ipcRenderer.on('@download/LISTA_RETORNOS', subscribleFunction);
  }
}

function subscribleDownloadFinish(subscribleFunction) {
  if (ipcRenderer) {
    ipcRenderer.on('@download/FINISH', subscribleFunction);
  }
}

function subscribleDownloadFailure(subscribleFunction) {
  if (ipcRenderer) {
    ipcRenderer.on('@download/FAILURE', subscribleFunction);
  }
}

function sendNotification(body) {
  if (ipcRenderer) {
    ipcRenderer.send('@notification/REQUEST', body);
  }
}

function sendConnectionStatus(status, token) {
  if (ipcRenderer) {
    ipcRenderer.send('@connection/RESTORE', { status, token });
  }
}

function sendPendingInQueue() {
  if (ipcRenderer) {
    ipcRenderer.send('@queue/PENDING_QUEUE_REQUEST');
  }
}
function subscriblePendingInQueue(subscribleFunction) {
  if (ipcRenderer) {
    ipcRenderer.on('@queue/PENDING_QUEUE', subscribleFunction);
  }
}

function sendProcessQueue(token, terminal) {
  if (ipcRenderer) {
    ipcRenderer.send('@queue/PROCESS', token, terminal);
  }
}

function subscribleQueueInitial(subscribleFunction) {
  ipcRenderer.on('@queue/INITIAL', subscribleFunction);
}

function subscribleQueueMessage(subscribleFunction) {
  ipcRenderer.on('@queue/MESSAGE', subscribleFunction);
}

function subscribleQueueFailure(subscribleFunction) {
  ipcRenderer.on('@queue/FAILURE', subscribleFunction);
}

function subscribleQueueFinish(subscribleFunction) {
  ipcRenderer.on('@queue/FINISH', subscribleFunction);
}

export {
  updateLocalData,
  sendDownloadRequest,
  sendDownloadInitialRequest,
  subscribleDownloadInitial,
  subscribleDownloadUpdateMessage,
  subscribleDownloadFinish,
  subscribleDownloadListaRetornos,
  subscribleDownloadFailure,
  sendNotification,
  sendConnectionStatus,
  sendProcessQueue,
  sendPendingInQueue,
  subscriblePendingInQueue,
  subscribleQueueInitial,
  subscribleQueueMessage,
  subscribleQueueFailure,
  subscribleQueueFinish
};
