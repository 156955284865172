import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, alpha } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { Close as IconClose, Search as IconSearch } from '@material-ui/icons';

import api from '~/services/api';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import { API_BASE_URL, API_LOCAL_URL } from '~/constants';

const useStyles = makeStyles(theme =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.25),

      marginLeft: 0,
      width: '100%'
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%'
    }
  })
);

const ModalConsultaProfissional = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { showModalConsultaProfissional } = useSelector(state => state.pdv);
  const isOnline = useSelector(getApplicationIsOnline());

  const [profissionais, setProfissionais] = useState([]);
  const [profissionaisFiltrados, setProfissionaisFiltrados] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangeSearch = queryText => {
    console.log(queryText);

    const results =
      queryText !== ''
        ? [...profissionais].filter(
            item =>
              item?.nome?.toLowerCase()?.startsWith(queryText?.toLowerCase()) ||
              item?.nome?.toLowerCase().includes(queryText?.toLowerCase())
          )
        : [...profissionais];

        setProfissionaisFiltrados(results);
  };

  const handleSelecionarProfissional = profissional => {
    dispatch(pdvActions.selecionarProfissional(profissional));
    handleClose();

  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalConsultaProfissional({ show: false }));
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = isOnline
        ? await api.get(
            `${API_BASE_URL}/v1/faturamento/profissional/?$select=id,nome`
          )
        : await api.get(`${API_LOCAL_URL}/faturamento/profissional`);
      const _profissionais = [...data].sort((a, b) =>
        ('' + a.nome).localeCompare(b.nome)
      );

      setProfissionais(_profissionais);
      setProfissionaisFiltrados(_profissionais);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Dialog
        open={showModalConsultaProfissional?.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <DialogTitle>
          <AppBar color="primary" position="static">
            <Toolbar>
              <Box>
                <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Profissionais
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <IconButton color="inherit" onClick={handleClose} aria-label="close">
                <IconClose />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            style={{
              display: 'flex',
              background: '#000',
              color: '#FFF',
              padding: 16,
              boxShadow: theme.shadows[6]
            }}
          >
            <Box className={classes.search}>
              <div className={classes.searchIcon}>
                <IconSearch />
              </div>
              <InputBase
                onChange={e => handleChangeSearch(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'search' }}
                autoFocus
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: 540 }}>
          {loading ? (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <CircularProgress variant="indeterminate" color="primary" />
              </Box>
            </>
          ) : (
            <Box p={2}>
              <List>
                {profissionaisFiltrados?.map((profissional, index) => (
                  <>
                    <ListItem
                      key={`item-${index}`}
                      button
                      onClick={() => handleSelecionarProfissional(profissional)}
                    >
                      <Grid container item>
                        <Grid container item md={8} wrap="nowrap">
                          <Grid item zeroMinWidth>
                            <Typography
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: 600
                              }}
                            >
                              {profissional?.nome}
                              <small></small>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalConsultaProfissional;
