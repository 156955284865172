import {
  SET_THEME,
  SET_CONNECTION_MODE,
  SET_APPLICATION_MODE,
  SET_SHOWMODAL_SYNC,
  SET_SHOWPROGRESS_SYNC,
  SET_MENSAGEM_SYNC,
  SET_TERMINAL,
  SET_SHOWMODAL_IDENTIFICACAO_PDV,
  SET_SHOWMODAL_CONFIGURACAO,
  SET_SHOWMODAL_DOWNLOAD,
  SET_MESSAGE_SEND_DOCUMENT,
  SET_SHOW_SNACKBAR_SYNC_DOCUMENT,
  SET_SHOW_FILA_DOCUMENTOS,
  DOWNLOAD_DATA,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  INITIALIZE,
  FORCE_OFFLINE_MODE_REQUEST,
  FORCE_OFFLINE_MODE_SUCCESS,
  SET_QUEUES,
  INICIALIZAR_TEF,
  SET_SHOWMODAL_MENU_TEF,
  SET_TRANSACAO_TEF,
  SET_MENSAGEM_TEF,
  IMPRIMIR_COMPROVANTE_TEF,
  MONTAR_RETORNOS_TEF,
  FINALIZAR_TEF,
  RESET_MODAL_MENU_TEF,
  SET_SHOWPROGRESS_QUEUE,
  SET_MENSAGEM_QUEUE,
  EXIBIR_QRCODE_TEF
} from './app-constants';

export function setTheme(theme) {
  return {
    type: SET_THEME,
    theme
  };
}

export const setConnectionMode = isInternetReachable => {
  return {
    type: SET_CONNECTION_MODE,
    isInternetReachable
  };
};

export const setApplicationMode = mode => {
  return {
    type: SET_APPLICATION_MODE,
    mode
  };
};

export const downloadData = (schemas = [], inicialData = false) => {
  return {
    type: DOWNLOAD_DATA,
    schemas,
    inicialData
  };
};

export const setShowModalSync = show => {
  return {
    type: SET_SHOWMODAL_SYNC,
    show
  };
};

export const setShowProgressSync = progress => {
  return {
    type: SET_SHOWPROGRESS_SYNC,
    progress
  };
};

export const setMensagemSync = mensagem => {
  return {
    type: SET_MENSAGEM_SYNC,
    mensagem
  };
};

export const setShowProgressQueue = show => {
  return {
    type: SET_SHOWPROGRESS_QUEUE,
    show
  };
};

export const setMensagemQueue = mensagem => {
  return {
    type: SET_MENSAGEM_QUEUE,
    mensagem
  };
};

export const enqueueSnackbar = notification => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random()
    }
  };
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key
});

export const setShowmodalIdentificacaoPDV = payload => {
  return { type: SET_SHOWMODAL_IDENTIFICACAO_PDV, payload };
};

export const setTerminal = terminal => {
  return {
    type: SET_TERMINAL,
    terminal
  };
};

export const inicializarTef = () => {
  return { type: INICIALIZAR_TEF };
};

export const setShowModalMenuTef = payload => {
  return {
    type: SET_SHOWMODAL_MENU_TEF,
    payload
  };
};

export const resetModalMenuTef = () => {
  return { type: RESET_MODAL_MENU_TEF };
};

export const setMensagemTef = mensagem => {
  return { type: SET_MENSAGEM_TEF, mensagem };
};

export const montarRetornosTef = payload => {
  return { type: MONTAR_RETORNOS_TEF, payload };
};

export const exibirQRCodeTef = payload => {
  return { type: EXIBIR_QRCODE_TEF, payload };
}

export const imprimirComprovanteTef = documentoVinculado => {
  return { type: IMPRIMIR_COMPROVANTE_TEF, documentoVinculado };
};

export const setTransacaoTef = payload => {
  return { type: SET_TRANSACAO_TEF, payload };
};

export const finalizarTef = payload => {
  return { type: FINALIZAR_TEF, payload };
};

export const initialise = () => {
  return { type: INITIALIZE };
};

export const forceOfflineMode = isOffline => {
  return {
    type: FORCE_OFFLINE_MODE_REQUEST,
    isOffline
  };
};

export const forceOfflineModeSuccess = isOffline => {
  return {
    type: FORCE_OFFLINE_MODE_SUCCESS,
    isOffline
  };
};

export const setShowModalConfiguracao = payload => {
  return {
    type: SET_SHOWMODAL_CONFIGURACAO,
    payload
  };
};

export const setShowModalDownload = payload => {
  return {
    type: SET_SHOWMODAL_DOWNLOAD,
    payload
  };
};

export const setMessageSendDocument = message => {
  return {
    type: SET_MESSAGE_SEND_DOCUMENT,
    message
  };
};

export const setShowSnackbarSyncDocument = message => {
  return {
    type: SET_SHOW_SNACKBAR_SYNC_DOCUMENT,
    message
  };
};
export const setShowFilaDocumentos = show => {
  return {
    type: SET_SHOW_FILA_DOCUMENTOS,
    show
  };
};
export const setQueues = queues => {
  return {
    type: SET_QUEUES,
    queues
  };
};
