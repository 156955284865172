import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { formatCurrency } from '~/utils/format';

const PagamentoParcela = React.memo(({ show, selected }) => {
  const { itemPagamento } = useSelector(state => state.pdv);

  if (!show) {
    return null;
  }

  return (
    <Box>
      {selected ? (
        <Typography variant="h5" align="left">
          <>
            {itemPagamento?.condicaoPagamento?.entradaExigida &&
              itemPagamento.parcelas?.length > 0 && (
                <>
                  {formatCurrency(itemPagamento?.parcelas[0]?.parcelaValor)}
                  <small>{' entrada + '}</small>
                </>
              )}
          </>
          {itemPagamento?.condicaoPagamento?.entradaExigida &&
          itemPagamento?.condicaoPagamento?.quantidadeParcela > 1 &&
          itemPagamento?.parcelas?.length > 0 ? (
            <>
              {itemPagamento?.parcelas[1]?.parcelaValor && (
                <>
                  {itemPagamento?.condicaoPagamento?.quantidadeParcela}
                  {'x'}
                  {formatCurrency(
                    itemPagamento?.parcelas[1]?.parcelaValor / itemPagamento?.condicaoPagamento?.quantidadeParcela ?? 0
                  )}
                  {itemPagamento?.parcelas[1]?.parcelaValorJuros > 0 ? (
                    <small>{' com juros'}</small>
                  ) : (
                    <small style={{ color: '#09A848' }}>{' sem juros'}</small>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {itemPagamento?.parcelas[0]?.parcelaValor > 0 && (
                <>
                  {itemPagamento?.condicaoPagamento?.quantidadeParcela}
                  {'x'}
                  {formatCurrency(
                    (itemPagamento.valorTotal / itemPagamento?.condicaoPagamento?.quantidadeParcela) ?? 0
                    // itemPagamento?.parcelas[0]?.parcelaValor
                  )}
                  {itemPagamento?.parcelas[0]?.parcelaValorJuros > 0 ? (
                    <small>{' com juros'}</small>
                  ) : (
                    <small style={{ color: '#09A848' }}>{' sem juros'}</small>
                  )}
                </>
              )}
            </>
          )}
        </Typography>
      ) : (
        <>
          {itemPagamento?.condicaoPagamento?.valorParcela > 0 && (
            <Typography variant="h5" align="left">
              <>
                {itemPagamento?.condicaoPagamento?.quantidadeParcela > 0 && (
                  <>
                    {itemPagamento?.condicaoPagamento?.quantidadeParcela}
                    {'x'}
                  </>
                )}
              </>
              {formatCurrency(
                itemPagamento?.condicaoPagamento?.valorParcela ?? 0
              )}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
});

export default PagamentoParcela;
