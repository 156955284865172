import React from 'react';
import CurrencyInput from 'react-currency-input';
// import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';

const CurrencyInputCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <CurrencyInput
      precision="2"
      decimalSeparator=","
      thousandSeparator="."
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onChangeEvent={(event, maskedvalue, floatvalue) => {
        onChange({
          ...event,
          maskedvalue,
          floatvalue
        });
      }}
    />
  );
};

const CepTextMask = (props)  =>{
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export { CurrencyInputCustom, CepTextMask };
