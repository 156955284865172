import Swal from 'sweetalert2';

const swal = Swal.mixin({
  toast: true,
  target: 'body',
  showConfirmButton: false,
  timer: 2500,
  position: 'top'
});

class Toast {
  success(message) {
    return swal.fire({
      icon: 'success',
      title: message,
      position: 'top-end'
    });
  }

  info(message) {
    return swal.fire({
      icon: 'info',
      title: 'Atenção',
      html: message,
      position: 'top-end',
      confirmButton: `MuiButtonBase-root MuiButton-root MuiButton-contained button-warning`
    });
  }

  produtoMulti(message) {
    return swal.fire({
      icon: 'info',
      title: 'Atenção',
      html: `<div style="text-align: left; white-space: pre-line;">${message}</div>`,
      timer: 7500,
      timerProgressBar: true,
      position: 'top-end',
      showCloseButton: true,
      confirmButton: `MuiButtonBase-root MuiButton-root MuiButton-contained button-warning`
    });
  }

  warning(message) {
    return swal.fire({
      icon: 'warning',
      title: 'Atenção',
      html: message,
      position: 'top-end',
      confirmButton: `MuiButtonBase-root MuiButton-root MuiButton-contained button-warning`
    });
  }

  warningTef(message) {
    return swal.fire({
      icon: 'warning',
      title: 'Atenção',
      html: message,
      confirmButton: `MuiButtonBase-root MuiButton-root MuiButton-contained button-warning`
    });
  }

  warningExpedicao(message) {
    return swal.fire({
      icon: 'warning',
      title: 'Atenção',
      html: message,
      timer: 10000,
      showCloseButton: true
    });
  }

  error(message) {
    return swal.fire({
      icon: 'error',
      title: 'Ops!',
      text: message,
      timer: 3000,
      position: 'top-end'
    });
  }

  show(title, message, opts) {
    return Swal.fire({
      icon: opts?.icon ?? 'success',
      title,
      html: message,

      confirmButtonText: opts?.confirmButtonText ?? 'OK',
      buttonsStyling: false,

      customClass: {
        confirmButton: `MuiButtonBase-root MuiButton-root MuiButton-contained button-${opts?.icon ??
          'primary'}`
      }
    });
  }

  confirm(title, message, opts) {
    return Swal.fire({
      icon: 'question',
      title,
      html: message,

      confirmButtonText: opts?.confirmButtonText ?? 'OK',
      cancelButtonText: opts?.cancelButtonText ?? 'Cancelar',
      showCancelButton: true,
      buttonsStyling: false,

      customClass: {
        confirmButton: `MuiButtonBase-root MuiButton-root MuiButton-contained priorityZIndex button-${opts?.icon ??
          'primary'}`,
        cancelButton: 'MuiButtonBase-root MuiButton-root MuiButton-contained priorityZIndex'
      }
    });
  }

  loading(message) {
    return Swal.fire({
      title: message,
      backdrop: true,
      toast: true,
      // swal: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });
  }
}

export default new Toast();
