import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import { API_TERMINAL_TEF_URL } from '~/constants';

const signalR = new HubConnectionBuilder()
  .withUrl(`${API_TERMINAL_TEF_URL}/hubs/tef`, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets
  })
  .configureLogging(LogLevel.Debug)
  .build();

async function connect() {
  if (signalR) {
    return signalR.start();
  }
}
async function invokeInicializaTef() {
  if (signalR) {
    return signalR.invoke('InicializaTef');
  }
}

function onInicializaTef(subcribleFunction) {
  if (signalR) {
    signalR.on('InicializaTefSucesso', subcribleFunction);
  }
}

function onFinalizacaoTef(subcribleFunction) {
  if (signalR) {
    signalR.on('FinalizacaoTef', subcribleFunction);
  }
}
function onExibeMensagemTef(mensagem) {
  if (signalR) {
    signalR.on('ExibeMensagemTef', mensagem);
  }
}

function onMontaMenuTef(subcribleFunction) {
  if (signalR) {
    signalR.on('MontaMenuTef', subcribleFunction);
  }
}

function onExibeQRCodeTef(subcribleFunction) {
  if (signalR) {
    signalR.on('ExibeQRCodeTef', subcribleFunction);
  }
}

async function invokeEfetuaPagamentoTef(documento, valor, modalidadeTef, multiplosPagamentos) {
  if (signalR) {
    return signalR.invoke('EfetuaPagamentoTef', documento, valor, modalidadeTef, multiplosPagamentos);
  }
}

async function invokeConfirmaPagamentoTef(documento, confirma){
  if (signalR) {
    return signalR.invoke('ConfirmaTransacaoTef', documento, confirma);
  }
}

async function invokeCallbackTef(interromper, respostaSitef) {
  if (signalR) {
    return signalR.invoke('CallbackTef', interromper, respostaSitef);
  }
}

async function invokeAdministracaoTef() {
  if (signalR) {
    return signalR.invoke('AdministracaoTef');
  }
}

async function invokeCancelaPagamentoTef(documento){
  if (signalR) {
    return signalR.invoke('CancelaPagamentoTef', documento?.toString());
  }
}


export default {
  connect,
  onInicializaTef,
  onExibeMensagemTef,
  onMontaMenuTef,
  onFinalizacaoTef,
  onExibeQRCodeTef,
  invokeInicializaTef,
  invokeEfetuaPagamentoTef,
  invokeConfirmaPagamentoTef,
  invokeCancelaPagamentoTef,
  invokeAdministracaoTef,
  invokeCallbackTef,
};
