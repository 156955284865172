import currency from 'currency.js';
import { createSelector } from 'reselect';
import { v4 as uuid } from 'uuid';

import { TIPO_DOCUMENTO } from '~/constants';
import { getParametroAsBoolean } from '~/utils/parametros';

const rootState = state => state;

export const selectorFechamento = (parcelas = []) =>
  createSelector(rootState, substate => {
    const { venda, tipoDocumento, itemPagamento, multiplosPagamentos } = substate?.pdv;
    const { parametros } = substate?.auth;

    const condicaoPagamentoObrigatoria = getParametroAsBoolean(parametros, 'PF018');

    const parcelasACalcular = [...(parcelas.length === 0 ? venda?.parcelas : parcelas)];
    const valorParcelas =
      parcelasACalcular?.reduce((total, item) => (total += item?.parcelaValor), 0) ?? 0;

    const valorSaldoPagar = Number(currency(venda?.valorTotal).subtract(valorParcelas));

    const permiteFechamento = multiplosPagamentos
      ? valorSaldoPagar === 0 && parcelasACalcular?.length > 0
      : (tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO && itemPagamento?.meioPagamento !== null) ||
        (tipoDocumento === TIPO_DOCUMENTO.PEDIDO &&
          itemPagamento?.meioPagamento !== null &&
          itemPagamento?.condicaoPagamento !== null &&
          parcelasACalcular?.length > 0) ||
        (tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA &&
          itemPagamento?.meioPagamento !== null &&
          itemPagamento?.condicaoPagamento !== null &&
          parcelasACalcular?.length > 0);

    const retorno = {
      valorSaldoPagar,
      valorPago: parseFloat((valorParcelas).toFixed(2)),
      permiteFechamento,
      condicaoPagamentoObrigatoria,
      parcelas: [...parcelasACalcular?.map(x => ({ ...x, uuid: x?.uuid ?? uuid() }))]
    };

    // console.tron.log('selectorFechamento', {
    //   ...retorno,
    //   multiplosPagamentos,
    //   parcelasACalcular,
    //   venda
    // });

    return retorno;
  });
