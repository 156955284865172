export const SET_STATUS = '@monitor/SET_STATUS';
export const SET_ORDERNACAO = '@monitor/SET_ORDERNACAO';
export const SET_FILTRO_DATA = '@monitor/SET_FILTRO_DATA';
export const SET_LOADING = '@monitor/SET_LOADING';
export const SET_LOADING_SEND = '@monitor/SET_LOADING_SEND';
export const SET_PAGE = '@monitor/SET_PAGE';
export const SET_ROWS_PER_PAGE = '@monitor/SET_ROWS_PER_PAGE';
export const SET_ORDERBY = '@monitor/SET_ORDERBY';
export const SET_TABINDEX = '@monitor/SET_TABINDEX';
export const SELECT_ONE_ROW = '@monitor/SELECT_ONE_ROW';
export const SELECT_ALL_ROWS = '@monitor/SELECT_ALL_ROWS';
export const SEARCH = '@monitor/SEARCH';

export const LIMPAR_DADOS = '@monitor/LIMPAR_DADOS';

/** ORCAMENTO */
export const SEND_ORCAMENTO_REQUEST = '@monitor/SEND_ORCAMENTO_REQUEST';
export const SEND_ORCAMENTO_SUCCESS = '@monitor/SEND_ORCAMENTO_SUCCESS';
export const LOAD_ORCAMENTOS_REQUEST = '@monitor/LOAD_ORCAMENTOS_REQUEST';
export const LOAD_ORCAMENTOS_SUCCESS = '@monitor/LOAD_ORCAMENTOS_SUCCESS';
export const LOAD_ORCAMENTOS_SEARCH_SUCCESS = '@monitor/LOAD_ORCAMENTOS_SEARCH_SUCCESS';
/** PEDIDO */
export const SET_LOADING_HISTORICOS_PEDIDO = '@monitor/SET_LOADING_HISTORICO_PEDIDO';
export const SET_SHOW_TIMELINE_PEDIDO = '@monitor/SHOW_TIMELINE_PEDIDO';
export const SEND_PEDIDO_REQUEST = '@monitor/SEND_PEDIDO_REQUEST';
export const SEND_PEDIDO_SUCCESS = '@monitor/SEND_PEDIDO_SUCCESS';
export const LOAD_PEDIDOS_REQUEST = '@monitor/LOAD_PEDIDOS_REQUEST';
export const LOAD_PEDIDOS_SUCCESS = '@monitor/LOAD_PEDIDOS_SUCCESS';
export const LOAD_PEDIDOS_SEARCH_SUCCESS = '@monitor/LOAD_PEDIDOS_SEARCH_SUCCESS';
export const LOAD_HISTORICOS_PEDIDO_REQUEST = '@monitor/LOAD_HISTORICOS_PEDIDO_REQUEST';
export const LOAD_HISTORICOS_PEDIDO_SUCCESS = '@monitor/LOAD_HISTORICOS_PEDIDO_SUCCESS';
export const PRINT_DOCUMENTO = '@monitor/PRINT_DOCUMENTO';
/** DOCUMENTO SAIDA */
export const SET_SHOWMODAL_EDITAR_DOCUMENTO = "@monitor/SET_SHOWMODAL_EDITAR_DOCUMENTO";
export const SEND_DOCUMENTO_REQUEST = '@monitor/SEND_DOCUMENTO_REQUEST';
export const SEND_DOCUMENTO_SUCCESS = '@monitor/SEND_DOCUMENTO_SUCCESS';
export const LOAD_DOCUMENTOS_REQUEST = '@monitor/LOAD_DOCUMENTOS_REQUEST';
export const LOAD_DOCUMENTOS_SUCCESS = '@monitor/LOAD_DOCUMENTOS_SUCCESS';
export const LOAD_DOCUMENTOS_SEARCH_SUCCESS = '@monitor/LOAD_DOCUMENTOS_SEARCH_SUCCESS';
export const GERAR_OFFLINE = '@monitor/GERAR_OFFLINE';
export const TRANSMITIR_DOCUMENTO = '@monitor/TRANSMITIR_DOCUMENTO';
export const CANCELAR_DOCUMENTO = '@monitor/CANCELAR_DOCUMENTO';
export const DOWNLOAD_DANFE = '@monitor/DOWNLOAD_DANFE';
export const DOWNLOAD_XML = '@monitor/DOWNLOAD_XML';
