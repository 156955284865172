import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import styled  from 'styled-components';
import Lottie from 'react-lottie';
import animationLoading from '~/assets/animations/loading.json';

const Container = styled(Paper)`
  width: 480px;
  background-color: ${props => props.theme.palette.background.paper};
  box-shadow: ${props => props.theme.shadows[5]};
`

const ModalLoadingMensagem = () => {
  const theme = useTheme();
  const { showModalLoadingMensagem, mensagemLoading } = useSelector(state => state.pdv);

  return (
    <>
      <Dialog open={true} style={{zIndex: '99999'}}>
        <Container>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={4}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationLoading,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
              height={256}
              width={256}
              isStopped={false}
              isPaused={false}
            />
            <Typography variant="h4">{mensagemLoading}</Typography>
          </Box>
        </Container>
      </Dialog>
    </>
  );
};

export default ModalLoadingMensagem;
