import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export const useStyles = makeStyles(theme => ({
  toolbar: {
    height: '54px !important'
  },
  logo: {
    // color: theme.palette.common.white,
    marginRight: theme.spacing(1),
    fontSize: 24,
    '& > span': {
      fontWeight: 300
      // color: theme.palette.common.white,
    }
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));
