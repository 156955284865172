import styled from 'styled-components';
import MuiListItem from '@material-ui/core/ListItem';
import MuiList from '@material-ui/core/List';
import MuiButton from '@material-ui/core/Button';
import { alpha } from '@material-ui/core';

export const Container = styled.div`
  padding: 16px;
`;
export const Header = styled.div`
  display: flex;
  padding: 16px;
  color: #fff;
  justify-content: space-between;
  background-color: ${props => props.theme.palette.common.black};
  box-shadow: ${props => props.theme.shadows[6]};
`;


export const List = styled(MuiList)``;

export const ListItem = styled(MuiListItem)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const ButtonPagamento = styled(MuiButton)`
  padding: 0;
  height: 74px;
  margin-bottom: 16px;

  :focus {
    background-color: rgba(0, 0, 0, 0.1);
    /* border: 1px solid ${props => props.theme.palette.background.paper}; */
  }
`;
