import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';

import api from '~/services/api';
import { formatNumber } from '~/utils/format';
import { APP_ERROR, API_NODE_URL } from '~/constants';

import { Header } from './styles';

const StyledTableRowBody = withStyles(theme =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)(TableRow);

const ModalSaldoEstoqueTransferencia = React.memo(
  ({ open, data: { produto, quantidadeSolicitada }, onClose }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [saldoEstoqueEmpresas, setSaldoEstoqueEmpresas] = useState([]);

    const handleSubmit = (empresa, saldoEstoque) => {
      onClose({ empresa, saldoEstoque });
    };

    const loadSaldoEstoque = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `${API_NODE_URL}/estoque/saldo-estoque/por-filial/${produto?.id}`
        );

        setSaldoEstoqueEmpresas(data);
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (!produto) {
        return;
      }
      loadSaldoEstoque();
    }, [produto]);

    return (
      <Mui.Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'lg'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box style={{ minWidth: 180 }}>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Saldo estoque em outras filiais
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>

          <Mui.Grid item md={12}>
            <Header>
              <Mui.Typography variant="caption">Produto</Mui.Typography>
              <Mui.Typography variant="h3">
                {produto?.codigo} {' - '}
                {produto?.descricao}
              </Mui.Typography>
            </Header>
          </Mui.Grid>
        </Mui.DialogTitle>

        <Mui.DialogContent dividers>
          <>
            {loading ? (
              <Mui.Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={8}
              >
                <Mui.CircularProgress />
                <Mui.Typography>Caregando...</Mui.Typography>
              </Mui.Box>
            ) : (
              <>
                {saldoEstoqueEmpresas?.itens?.map((itemSaldo, index) => (
                  <Mui.Box px={4} py={2} key={`empresa-${index}`}>
                    <Mui.Typography>{itemSaldo?.intEmpresa?.razaoSocial}</Mui.Typography>

                    <Mui.TableContainer component={Paper}>
                      <Mui.Table aria-label="simple table">
                        <Mui.TableHead>
                          <Mui.TableRow>
                            <Mui.TableCell align="left">Armazém</Mui.TableCell>
                            {/* {produto?.grade && <TableCell>Grade linha/coluna</TableCell>} */}

                            {/* <TableCell>Lote</TableCell> */}
                            <Mui.TableCell align="center">Estoque real </Mui.TableCell>
                            <Mui.TableCell align="center">Estoque reserva </Mui.TableCell>
                            <Mui.TableCell align="center">Estoque futuro </Mui.TableCell>
                            <Mui.TableCell align="center">Estoque disponível </Mui.TableCell>
                            <Mui.TableCell align="center">#</Mui.TableCell>
                          </Mui.TableRow>
                        </Mui.TableHead>
                        <Mui.TableBody>
                          {itemSaldo?.saldoEstoque?.map((item, indexItem) => (
                            <StyledTableRowBody key={`item-${indexItem}`}>
                              <Mui.TableCell align="left">
                                {item?.estArmazem?.descricao}
                              </Mui.TableCell>
                              {/* {produto?.grade && produto?.estProdutoGrade && (
                              <Mui.TableCell>
                                {produto?.estProdutoGrade?.gradeEixoLinha} /{' '}
                                {produto?.estProdutoGrade?.gradeEixoColuna}
                              </Mui.TableCell>
                            )} */}
                              {/* <TableCell align="center">{item?.estProdutoLote?.lote}</TableCell> */}
                              <Mui.TableCell align="center">
                                {formatNumber(item?.estoqueReal)}
                              </Mui.TableCell>
                              <Mui.TableCell align="center">
                                {formatNumber(item?.estoqueReservado)}
                              </Mui.TableCell>
                              <Mui.TableCell align="center">
                                {formatNumber(item?.estoqueFuturo)}
                              </Mui.TableCell>
                              <Mui.TableCell align="center">
                                {formatNumber(item?.estoqueReal + item?.estoqueReservado * -1)}
                              </Mui.TableCell>
                              <Mui.TableCell>
                                <Mui.Button
                                 variant="contained" size="large" color="primary"
                                  onClick={() => handleSubmit(itemSaldo?.intEmpresa, item)}
                                >
                                  Transferir
                                </Mui.Button>
                              </Mui.TableCell>
                            </StyledTableRowBody>
                          ))}
                        </Mui.TableBody>
                      </Mui.Table>
                    </Mui.TableContainer>
                  </Mui.Box>
                ))}
              </>
            )}
          </>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Box p={1}>
           <Mui.Button size="large" onClick={onClose} style={{ marginLeft: 16 }}>
              Cancelar
            </Mui.Button>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>
    );
  }
);

export default ModalSaldoEstoqueTransferencia;
