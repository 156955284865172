import api from '~/services/api';
import { API_LOCAL_URL, API_NODE_URL } from '~/constants';

class MonitorService {
  getPedidos({ status, filtroData, offset, limit }) {
    return api.get(
      `${API_NODE_URL}/faturamento/pedidos/monitor-pedidos/${status}/${filtroData}/${offset}/${limit}`
    );
  }

  getDocumentos({ filtroData, offset, limit }) {
    return api.get(`${API_NODE_URL}/faturamento/documento-saida/documentos-monitor-nf`);
  }

  getPedidosOffline(status) {
    return api.get(`${API_LOCAL_URL}/faturamento/pedido/monitor/${status}`);
  }

  getPedido(id) {}

  getPedidoOffline(id) {}

  getTotalizadores() {
    return api.get(`${API_NODE_URL}/faturamento/pedidos/monitor-pedidos/totalizadores`);
  }

  getTotalizadoresOffline() {
    return api.get(`${API_LOCAL_URL}/faturamento/pedido/monitor/totalizadores`);
  }

  getHistoricos(pedidoId) {
    return api.get(`${API_NODE_URL}/faturamento/pedidos/monitor-pedidos/bloqueios/${pedidoId}`);
  }

  getHistoricoOffline(pedidoId) {
    return api.get(`${API_LOCAL_URL}/faturamento/pedido/monitor/bloqueios/${pedidoId}`);
  }
}

export default new MonitorService();
