import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import IconExpandMore from '@material-ui/icons/ExpandMore';


import { formatDate, formatCurrency } from '~/utils/format';

const PedidoParcelas = ({ data: parcelas }) => {
  return (
    <Accordion style={{ marginTop: 16 }}>
      <AccordionSummary
        expandIcon={<IconExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
          PARCELAS
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Parcela</TableCell>
                <TableCell align="left">Meio pagamento</TableCell>
                <TableCell align="left">Vencimento</TableCell>
                <TableCell align="right">Valor</TableCell>
                <TableCell align="right">Valor juros</TableCell>
                <TableCell align="right">Valor total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parcelas?.map((parcela, index) => (
                <TableRow key={`item-${index}`}>
                  <TableCell>{parcela?.parcelaNumero} / {parcela?.parcelaQuantidade}</TableCell>
                  <TableCell align="left">{parcela?.finMeiopagamento?.descricao}</TableCell>
                  <TableCell align="left">{formatDate(parcela?.parcelaVencimento)}</TableCell>
                  <TableCell align="right">{formatCurrency(parcela?.parcelaValor)}</TableCell>
                  <TableCell align="right">{formatCurrency(parcela?.parcelaValorJuros)}</TableCell>
                  <TableCell align="right">{formatCurrency(parcela?.parcelaValorTotal)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default PedidoParcelas;
