import styled from 'styled-components';
import { alpha } from '@material-ui/core';

export const Container = styled.div`
  background-color: ${props => alpha(props.theme.palette.common.black, 0.02)};
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.shape.borderRadius};
  box-shadow: ${props => props.theme.shadows[3]};
  border-radius: 8px;
  /* margin-bottom: 16px; */
  margin-top: 8px;
  padding-left: 32px;
  padding: 8px;
`;
