import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import api from '~/services/api';
import { API_BASE_URL, API_TERMINAL_URL, APP_ERROR, APPLICATION } from '~/constants';
const isWeb = APPLICATION === 'web';

const ModalPreviewDanfe = ({ data, showModal, onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [danfeBase64, setDanfeBase64] = useState(null);

  function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const downloadDanfe = async () => {
    setLoading(true);
    try {
      // const { data: danfe } = await api.get(
      //   `${API_BASE_URL}/v1/faturamento/documentosaida/download/danfe/${data?.documentoId}`, {
      //     responseType: 'arraybuffer'
      //   }
      // )


      const { data: danfe } = isWeb
        ? await api.get(
            `${API_BASE_URL}/v1/faturamento/documentosaida/download/danfe/${data?.documentoId}`,
            {
              responseType: 'arraybuffer'
            }
          )
        : await api.get(
            `${API_TERMINAL_URL}/documentosaida/download/danfe/${data?.documentoChave}`,
            {
              responseType: 'arraybuffer'
            }
          );

      const base64 = arrayBufferToBase64(danfe);
      setDanfeBase64(base64);
    } catch (error) {
      if (error?.response?.data) {
        const decodedString = String.fromCharCode.apply(
          null,
          new Uint8Array(error?.response?.data)
        );
        const obj = JSON.parse(decodedString);
        dispatch({ type: APP_ERROR, error: obj });
      } else {
        dispatch({ type: APP_ERROR, error });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.documentoId) downloadDanfe();
  }, [data]);

  return (
    <Mui.Dialog
      open={showModal}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          onClose();
        }
      }}
      PaperComponent={Paper}
      maxWidth={'lg'}
    >
      <Mui.DialogTitle>
        <Mui.AppBar color="primary" position="static">
          <Mui.Toolbar>
            <Mui.Box style={{ minWidth: 180 }}>
              <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                Danfe
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box flexGrow={1} />

            <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
              <MuiIcons.Close />
            </Mui.IconButton>
          </Mui.Toolbar>
        </Mui.AppBar>

        {/* <Mui.Grid item md={12}>
          <Header>
            <Mui.Button id="btnPrint">
              <MuiIcons.Print />  <Mui.Box ml={2}>Imprimir</Mui.Box>
            </Mui.Button>
            <Mui.Button id="btnDownload">
              <MuiIcons.FontDownload /> <Mui.Box ml={2}>Download</Mui.Box>
            </Mui.Button>
            <Mui.Box ml={6}>
              <Mui.Button id="zoomOut">
                <MuiIcons.ZoomOut />
              </Mui.Button>
              <Mui.Button id="zoomIn">
                <MuiIcons.ZoomIn />
              </Mui.Button>
            </Mui.Box>
          </Header>
        </Mui.Grid> */}
      </Mui.DialogTitle>

      <Mui.DialogContent dividers style={{ minWidth: 480, height: 800 }}>
        {loading ? (
          <>
            <Mui.Box display="flex" justifyContent="center" alignItems="center" p={4}>
              <Mui.CircularProgress variant="indeterminate" color="primary" />
            </Mui.Box>
          </>
        ) : (
          <>
            {danfeBase64 && (
              <object
                style={{ width: '100%', height: 'calc(100% - 5px)' }}
                type="application/pdf"
                data={'data:application/pdf;base64,' + danfeBase64}
              />
            )}
          </>
        )}
      </Mui.DialogContent>
    </Mui.Dialog>
  );
};

export default ModalPreviewDanfe;
