import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { alpha, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { HotKeys } from 'react-hotkeys';
import LinearProgress from '@material-ui/core/LinearProgress';

import { TIPO_DOCUMENTO } from '~/constants';
import TopBar from './TopBar';
import FooterBar from '~/components/FooterBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    width: '100%',

    '&::before': {
      content: '',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
    // paddingBottom: 24
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const PainelLayout = ({ route }) => {
  const classes = useStyles();
  const history = useHistory();

  const { terminal } = useSelector(state => state.app);

  const keyMap = {
    NOVO_DOCUMENTOSAIDA: {
      name: 'Novo documento de saída (CTRL+D)',
      sequences: ['ctrl+d', 'command+d']
    },
    NOVO_PEDIDO: {
      name: 'Novo Pedido (CTRL+P)',
      sequences: ['ctrl+p', 'command+p']
    },
    NOVO_ORCAMENTO: {
      name: 'Novo Orçamento (CTRL+O)',
      sequences: ['ctrl+o', 'command+o']
    }
  };

  const handlers = {
    NOVO_DOCUMENTOSAIDA: () => {
      if (terminal === null) {
        return;
      }
      history.push('/pdv', {
        tipoDocumento: TIPO_DOCUMENTO.DOCUMENTOSAIDA
      });
    },
    NOVO_PEDIDO: () => {
      if (terminal === null) {
        return;
      }
      history.push('/pdv', {
        tipoDocumento: TIPO_DOCUMENTO.PEDIDO
      });
    },
    NOVO_ORCAMENTO: () => {
      if (terminal === null) {
        return;
      }
      history.push('/pdv', {
        tipoDocumento: TIPO_DOCUMENTO.PEDIDO
      });
    }
  };

  return (
    <HotKeys keyMap={keyMap} handlers={handlers} contextMenu tabIndex="-1" className={classes.root}>
      <TopBar />
      <Suspense fallback={<LinearProgress />}>
        <div className={classes.root}>
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>{renderRoutes(route.routes)}</div>
            </div>
          </div>
        </div>
      </Suspense>
      <FooterBar />
    </HotKeys>
  );
};

export default PainelLayout;
