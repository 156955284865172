import numeral from 'numeral';
import 'numeral/locales/pt-br';
import { format, formatISO, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

numeral.locale('pt-br');

export const formatWeight = weight => `${numeral(weight).format('0,0.00')} kg`;
export const formatCurrency = num => `R$ ${numeral(num).format('0,0.00')}`;
export const formatNumber = num => `${numeral(num).format('0,0.00')}`;
export const formatDate = value => {
  try {
    const isoDate =
      typeof value === 'string' ? parseISO(value)?.toISOString() : value?.toISOString();

    const [year, month, day] = isoDate?.substr(0, 10).split('-');
    return format(new Date(year, month - 1, day), 'dd/MM/yyyy');
  } catch (error) {
    console.log('formatDate=>' + value, error);
    return '-';
  }
};

export const formatDatetime = value => {
  try {
    const date = typeof value === 'string' ? new Date(value) : value;
    return format(date, "dd'/'MM'/'yyyy hh:mm:ss", {
      locale: pt
    });
  } catch (error) {
    console.log('formatDatetime=>' + value, error);
    return '-';
  }
};

export const formatTime = date =>
  format(date, 'hh:mm:ss', {
    locale: pt
  });

export const parseCurrency = num =>
  typeof num === 'string' || num instanceof String
    ? num
      ? parseFloat(num.replace(/\./gi, '').replace(/,/gi, '.'))
      : 0
    : num;
