import styled from 'styled-components';
import { alpha } from '@material-ui/core';
import MuiGridList from '@material-ui/core/GridList';
import MuiGridListTile from '@material-ui/core/GridListTile';

export const GridList = styled(MuiGridList)`
  flex-wrap: nowrap;
  padding: 16px 0;
  transform: translateZ(0);
`;

export const GridListItem = styled(MuiGridListTile)`
  background-color: ${props => alpha(props.theme.palette.common.black, 0.02)};
  height: 240px;
  width: 300px;
  box-shadow: ${props => props.theme.shadows[3]};
  border-radius: 8px;
  margin: 0 16px;
`;

export const Container = styled.div`
  background-color: ${props => alpha(props.theme.palette.common.black, 0.02)};
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: ${props => props.theme.shape.borderRadius};
  box-shadow: ${props => props.theme.shadows[3]};
  border-radius: 8px;
  /* margin-bottom: 16px; */
  margin-top: 8px;
  padding-left: 32px;
  padding: 8px;
`;
