import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { ListItem } from '@material-ui/core';

const ConsultaClienteItem = React.memo(({ data }) => {
  return (
    <ListItem button disabled={data?.desativado}>
      <Grid container item md={12} disabled={data?.desativado}>
        <Grid container item md={6} wrap="nowrap">
          <Grid item zeroMinWidth>
            <Typography
              style={{
                textTransform: 'uppercase',
                fontWeight: 600
              }}
            >
              {data?.codigoInterno} - {data?.razaoSocial}
            </Typography>
            {data?.fantasia && (
              <Typography variant="body2">
                <b>Nome fantasia/Apelido:</b> {data?.fantasia}
              </Typography>
            )}

            <Typography variant="body2">
              <b>CPF/CNPJ:</b> {data?.pfCpf ?? data?.pjCnpj}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item md={6} alignItems="center">
          <Grid item zeroMinWidth>
            <Typography style={{ fontSize: 12 }}>
              {data?.enderecoLogradouro}, N.{data?.enderecoLogradouroNumero},{data?.enderecoBairro}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {data?.enderecoIntCidade?.cidade} / {data?.enderecoUf}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
      </Grid>
    </ListItem>
  );
});

export default ConsultaClienteItem;
