import React, { useState } from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Autocomplete from '@mui/material/Autocomplete';

import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as authActions from '~/store/modules/auth/auth-actions';
import Logo from '~/assets/logo.png';
import { makeStyles, alpha, useTheme } from '@material-ui/core';
import { useEffect } from 'react';

import { APP_VERSION, APP_LOGIN, APP_SYNC_DATE, APPLICATION, API_NODE_URL } from '~/constants';
import api from '~/services/api';


const useStyles = makeStyles(theme => ({
    root: {
      width: 520
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column'
    },
  
    logo: {
      color: theme.palette.primary.main,
      fontSize: '62px',
      '& > span': {
        fontWeight: 300,
        color: theme.palette.text.primary
      }
    }
  }));

const ModalTrocaUsuario = ({ className, ...rest }) => {

    const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isOffline } = useSelector(state => state.app);
  const [emails, setEmails] = useState([]);
  const { showModalIdentificacaoPDV } = useSelector(state => state.app);
  const { loading, filiais, empresa } = useSelector(state => state.auth);
  const [dataSincronizacao, setDataSincronizacao] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState({
    login: '',
    senha: '',
    filialSelecionada: empresa,
    byModal: true,
    submit: null
  });

  const [validationSchema] = useState(
    Yup.object().shape({
      login: Yup.string()
        .email('Informar um email válido')
        .nullable()
        .max(255)
        .required('Email é obrigatório'),
      senha: Yup.string()
        .max(255)
        .required('Senha é obrigatório')
    })
  );
    
  const { showModalTrocaUsuario } = useSelector(state => state.pdv);

  const handleClose = () => {
    dispatch(pdvActions.setShowModalTrocaUsuario({ show: false, desativarModalsSubsequentes: false }));
  };

  const handleSubmit = async values => {
    if(showModalTrocaUsuario.desativarModalsSubsequentes)
      dispatch(authActions.loginRequestModal({ ...values, desativarModalsSubsequentes: true }));
    else
      dispatch(authActions.loginRequestModal({ ...values }));
  };

  useEffect(() => {
    const _dataSyncLocal = sessionStorage.getItem(APP_SYNC_DATE, null);
    setDataSincronizacao(_dataSyncLocal ? JSON.parse(_dataSyncLocal) : null);

    const _login = sessionStorage.getItem(APP_LOGIN, '');
    setLogin(state => ({
      ...state,
      login: _login
    }));
  }, []);

  return (
    <Mui.Dialog
      open={showModalTrocaUsuario?.show || showModalTrocaUsuario?.confirmLogin}
      onKeyDown={key => {
        if (key.code === 'Escape' && !showModalTrocaUsuario?.confirmLogin) {
          handleClose();
        }
      }}
      PaperComponent={Mui.Paper}
      maxWidth={'lg'}
    >
      <Mui.DialogTitle>
        <Mui.AppBar color="primary" position="static">
          <Mui.Toolbar>
            <Mui.Box style={{ minWidth: 180 }}>
              <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                Trocar Usuário
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box flexGrow={1} />

            { !showModalTrocaUsuario?.confirmLogin && <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
              <MuiIcons.Close />
            </Mui.IconButton>}
          </Mui.Toolbar>
        </Mui.AppBar>{' '}
      </Mui.DialogTitle>

      <Mui.DialogContent dividers style={{ minWidth: 640 }}>
        
      <Mui.Card className={classes.root} style={{margin: 'auto'}}>
          <Mui.CardContent>
            {/* <Mui.Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 32
              }}
            >
              img
            </Mui.Box>
            <Mui.Divider
              style={{
                marginTop: 16,
                marginBottom: 16,
                marginLeft: -32,
                marginRight: -32
              }}
            /> */}
            <Mui.Box flexGrow={1} mt={3} className={classes.cardContent}>
              <Formik
                initialValues={login}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  values
                }) => (
                  <form noValidate onSubmit={handleSubmit} {...rest}>
                      <>
                        <Mui.TextField
                          error={Boolean(touched.login && errors.login)}
                          fullWidth
                          autoFocus
                          helperText={touched.login && errors.login}
                          label="E-mail"
                          margin="normal"
                          name="login"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={loading}
                          type="email"
                          value={values.login}
                          variant="outlined"
                          tabIndex={1}
                          // inputProps={{ style: { textTransform: "lowercase" } }}
                        />
                        <Mui.TextField
                          error={Boolean(touched.senha && errors.senha)}
                          fullWidth
                          helperText={touched.senha && errors.senha}
                          label="Senha"
                          margin="normal"
                          name="senha"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={loading}
                          type={showPassword ? 'text' : 'password'}
                          value={values.senha}
                          variant="outlined"
                          tabIndex={2}
                          InputProps={{
                            startAdornment: (
                              <>
                                {!loading && (
                                  <>
                                    <Mui.InputAdornment
                                      position="end"
                                      style={{ position: 'absolute', right: 0 }}
                                      tabIndex={-1}
                                    >
                                      <Mui.IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={event => event.preventDefault()}
                                      >
                                        {showPassword ? (
                                          <MuiIcons.Visibility />
                                        ) : (
                                          <MuiIcons.VisibilityOff />
                                        )}
                                      </Mui.IconButton>
                                    </Mui.InputAdornment>
                                  </>
                                )}
                              </>
                            )
                          }}
                        />
                      </>

                    {errors.submit && (
                      <Mui.Box mt={3}>
                        <Mui.FormHelperText error>{errors.submit}</Mui.FormHelperText>
                      </Mui.Box>
                    )}
                    {APPLICATION !== 'web' && (
                      <>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Switch
                              checked={isOffline ?? false}
                              onChange={() => dispatch(appActions.forceOfflineMode(!isOffline))}
                            />
                          }
                          label={'Modo Offline'}
                        />
                        <MuiAlert>
                          <Mui.Typography
                            style={{ fontSize: 12, marginLeft: 8, textTransform: 'none' }}
                          >
                            {dataSincronizacao
                              ? `Data hora última sincronização: ${dataSincronizacao}hrs`
                              : 'Nenhuma sincronização'}
                          </Mui.Typography>
                        </MuiAlert>
                      </>
                    )}

                    <Mui.Box mt={2}>
                      <Mui.Button
                        color="primary"
                        disabled={loading}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        tabIndex={3}
                      >
                        {loading ? (
                          <>
                            <Mui.CircularProgress size={24} style={{ marginRight: 12 }} />
                            <Mui.Typography variant="span">Carregando...</Mui.Typography>
                          </>
                        ) : (
                          <>
                            <Mui.Typography variant="span">
                              {'Acessar'}
                            </Mui.Typography>
                          </>
                        )}
                      </Mui.Button>

                    </Mui.Box>
                  </form>
                )}
              </Formik>
            </Mui.Box>
          </Mui.CardContent>
        </Mui.Card>

      </Mui.DialogContent>
      <Mui.DialogActions>

        { !showModalTrocaUsuario?.confirmLogin && <Mui.Button onClick={handleClose}>
          <MuiIcons.Close />
          <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
            CANCELAR (ESC)
          </Mui.Typography>
        </Mui.Button>}
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default ModalTrocaUsuario;
