import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import { TIPO_DOCUMENTO } from '~/constants';
import { useLocation } from 'react-router-dom';

const useCustomInfoComplementar = (venda) => {
  const { state } = useLocation();
  const [informacaoComplementar, setInformacaoComplementar] = useState('');

  useEffect(() => {
    // Mostrar a informação do fisOperacao apenas se o tipoDocumento for DOCUMENTOSAIDA
    if (state?.tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      setInformacaoComplementar(`${venda?.fisOperacao?.fisInfocomplementar?.informacao || ''} - ${venda?.informacaoComplementar || ''}`);
    } else {
      setInformacaoComplementar(venda?.informacaoComplementar || '');
    }
  }, [state?.tipoDocumento, venda?.fisOperacao?.fisInfocomplementar?.informacao, venda?.informacaoComplementar]);

  return [informacaoComplementar, setInformacaoComplementar];
}

const ModalInformacaoComplementar = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { showModalInformacaoComplementar, venda } = useSelector((state) => state.pdv);

  const [informacaoComplementar, setInformacaoComplementar] = useCustomInfoComplementar(venda);

  const [xPed, setNumeroPedido] = useState(
    venda?.xPed ?? ''
  );

  const [xCont, setNumeroContrato] = useState(
    venda?.xCont ?? ''
  );

  const handleSumit = () => {
    handleClose();
    let infoConcatenada = ''
    if (state?.tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      infoConcatenada = `- ${informacaoComplementar || ''}`;
    } else {
      infoConcatenada = `${informacaoComplementar || ''}`;
    }

    const payload = {
      informacaoComplementar: infoConcatenada,
      xPed,
      xCont,
    };
    dispatch(pdvActions.setInformarComplementar(payload));
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalInformarComplementar({ show: false }));
  };

  return (
    <>
      <Mui.Dialog
        open={showModalInformacaoComplementar?.show}
        onKeyDown={(key) => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Informação complementar
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ minWidth: 540, padding: 16 }}>
          <Mui.Grid container item>
            <Mui.Grid item xs={12}>
              <Mui.TextField
                name="informacaoComplementar"
                label="Informação Complementar"
                value={informacaoComplementar}
                fullWidth
                required
                onChange={(e) => {
                  setInformacaoComplementar(e.target.value);
                }}
                variant="outlined"
              />
            </Mui.Grid>

            <Mui.Grid item xs={6}>
              <Mui.TextField
                label="Nº Pedido"
                name="xPed"
                value={xPed}
                style={{marginTop: '15px'}}
                fullWidth
                required
                onChange={e => {
                  setNumeroPedido(e.target.value);
                }}
                variant="outlined"
              />
            </Mui.Grid>

            <Mui.Grid item xs={6}>
              <Mui.TextField
                label="Nº Contrato"
                name="xCont"
                value={xCont}
                style={{marginTop: '15px', marginLeft: '5px'}}
                fullWidth
                required
                onChange={e => {
                  setNumeroContrato(e.target.value);
                }}
                variant="outlined"
              />
            </Mui.Grid>

          </Mui.Grid>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Box flex={1} display="flex" justifyContent="flex-end">
            <Mui.Box>
              <Mui.Button variant="contained" color="primary" onClick={() => handleSumit()}>
                <MuiIcons.Save /> Salvar
              </Mui.Button>
            </Mui.Box>
            <Mui.Button style={{ marginLeft: 12 }} onClick={handleClose}>
              <MuiIcons.Close />
              CANCELAR (ESC)
            </Mui.Button>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ModalInformacaoComplementar;
