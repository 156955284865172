import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { InputAdornment, SvgIcon } from '@material-ui/core';
//Icons
import * as MuiIcons from '@material-ui/icons';
import IconSearch from '@material-ui/icons/Search';
import IconAddCircle from '@material-ui/icons/AddCircle';

import { SHOW_SHORTCUTS, TIPO_DOCUMENTO } from '~/constants';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import OrcamentoTable from '../../components/OrcamentoTable';
import { OrcamentoOnlineService } from '~/store/modules/orcamento/orcamento.service';

const Orcamento = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  const isOnline = useSelector(getApplicationIsOnline());

  const anchorRef = useRef();
  const { terminal } = useSelector(state => state.app);
  const { loading, filtroData, filtroDataOptions, page, rowsPerPage } = useSelector(
    state => state.monitor
  );

  const handleSearch = async event => {
      try {
        dispatch(monitorActions.setLoading(true));
        let verificaNumero = false;
        const type = isNaN(parseFloat(searchTerm));
        if(!type) verificaNumero = true;
        let filter = verificaNumero 
        ? [`numero eq ${searchTerm} or contains(clienteNome,'${searchTerm}') or contains(fatVendedor/nome,'${searchTerm}')`] 
        : [`contains(clienteNome,'${searchTerm}') or contains(fatVendedor/nome,'${searchTerm}')`];
          const service = new OrcamentoOnlineService();
          const { data } = await service.getAll({
            offset: 0,
            limit: 0,
            filter
          });
          dispatch(monitorActions.loadOrcamentosSearchSuccess({
            orcamentos: data.items,
            valorTotalDocumentos: data.items.reduce((total, item) => total + item.valorTotal, 0),
            quantidadeDocumentos: data.items.length
          }))
      } catch (err) {
        throw err;
      } finally {
        dispatch(monitorActions.setLoading(false));
      }
  };

  useEffect(() => {
    if(!searchTerm) {
      dispatch(monitorActions.loadOrcamentosRequest());
    }
  }, [page, filtroData, rowsPerPage, searchTerm]);

  useEffect(() => {
    return () => {
      dispatch(monitorActions.limparDados());
    };
  }, []);

  useEffect(() => {
    if(searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        handleSearch()
      }, 1000)
  
      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchTerm])

  return (
    <>
      <Box p={2} display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="primary"
          style={{ height: 44, color: 'white', backgroundColor: 'orange' }}
          disabled={terminal === null}
          onClick={() => {
            history.push('/pdv', {
              tipoDocumento: TIPO_DOCUMENTO.ORCAMENTO
            });
          }}
        >
          <MuiIcons.AddCircle />
          <Box component="span" ml={2}>
            Novo Orçamento {SHOW_SHORTCUTS && '(CTRL+O)'}
          </Box>
        </Button>

        <Box display="flex" alignContent="center">
          <TextField
            disabled={loading}
            InputProps={{
              style: {
                height: 44
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <MuiIcons.Search />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Pesquisar documento..."
            variant="outlined"
            onChange={(event) => setSearchTerm(event.target.value)}
          />

          <ButtonGroup
            variant="contained"
            color="default"
            ref={anchorRef}
            aria-label="split button"
            style={{ marginLeft: 16 }}
          >
            {filtroDataOptions.map((f, index) => (
              <Button
                key={`orcamento-filtro-data-${index}`}
                color={f.filtro === filtroData ? 'primary' : 'default'}
                onClick={() => dispatch(monitorActions.setFiltroData(f.filtro))}
              >
                {f.titulo}
              </Button>
            ))}
          </ButtonGroup>
          {/* <Button
            variant="contained"
            style={{ height: 44, marginLeft: 16 }}
            disabled={terminal === null}
            onClick={() => dispatch(monitorActions.loadOrcamentosRequest(new Date().getTime()))}
          >
            <MuiIcons.Refresh />
          </Button> */}
        </Box>
      </Box>
      <Divider />
      <OrcamentoTable />
    </>
  );
};

export default Orcamento;
