import { all, takeLatest, call, put } from 'redux-saga/effects';
import { APP_ERROR } from '~/constants';
import toast from '~/services/toast';
import history from '~/services/history';
import normalizeError from '~/utils/normalize-error';
import * as authActions from './auth/auth-actions';

import app from "./app/app-sagas";
import auth from './auth/auth-sagas';
import pdv from './pdv/pdv-sagas';
import monitor from './monitor/monitor-sagas';

export function* erroHandler({ error }) {
  const message = normalizeError(error);

  if (error?.response?.status === 401 && !error?.response?.headers?.ignoreheaders) {
    yield put(authActions.logoutRequest());

    yield call(history.push, '/auth/login');
    yield call(toast.warning, message);
  } else {
    yield call(toast.error, message);
  }
}

export default function* rootSaga() {
  yield all([takeLatest(APP_ERROR, erroHandler), app, auth, pdv, monitor]);
}
