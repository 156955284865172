import React, { useState } from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

const ModalRetornoDownload = ({ data, callback }) => {
  return (
    <>
      <Mui.Dialog
        open={data?.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            if (callback) callback();
          }
        }}
        PaperComponent={Mui.Paper}
        maxWidth={'md'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Retornos
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton
                color="inherit"
                onClick={() => {
                  if (callback) callback();
                }}
                aria-label="close"
              >
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>

        <Mui.DialogContent dividers style={{ minWidth: 320 }}>
          <Mui.List>
            {data?.retornos?.map((item, index) => (
              <Mui.ListItem key={`index-${index}`}> {item?.entity}</Mui.ListItem>
            ))}
          </Mui.List>
        </Mui.DialogContent>
      </Mui.Dialog>
    </>
  );
};

const SnackbarDownload = ({ open, callback, retornos }) => {
  const [showModal, setShowModal] = useState(false);

  const onClose = () => {
    if (callback) callback();
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <Mui.Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        onClose={onClose}
        autoHideDuration={60000}
        message={'Sincronização finalizada'}
        action={
          <React.Fragment>
            {/* <Mui.Button variant="contained" color="primary" onClick={handleShowModal}>
              <MuiIcons.ExitToApp fontSize="small" /> Visualizar retornos
            </Mui.Button> */}
            <Mui.IconButton aria-label="close" color="inherit" onClick={onClose}>
              <MuiIcons.Close fontSize="small" />
            </Mui.IconButton>
          </React.Fragment>
        }
      />
      {showModal && (
        <ModalRetornoDownload
          data={{ show: showModal, retornos }}
          callback={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default SnackbarDownload;
