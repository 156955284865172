import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import { HotKeys } from 'react-hotkeys';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconArrowRight from '@material-ui/icons/KeyboardTab';
import Paper from '@material-ui/core/Paper';

export const Root = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 0;
  padding: 16px;
  overflow: hidden;
`;

export const Content = styled(Paper)`
  display: flex;
  flex-direction: row;
  height: 100%;
  border-radius: 6px 0 0 6px;
  box-shadow: ${props => props.theme.shadows[6]};
`;

export const MainContainer = styled.div`
  height: 100%;
  flex: 50%;
`;

export const ItensContainer = styled.div`
  height: 100%;
  flex: 50%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  height: 64px;
  padding-top: 8px;
`;

export const ButtonReturn = styled(Button).attrs({
  startIcon: <IconArrowBack />,
  variant: 'contained',
  size: 'large'
})`
  background-color: #fff;
  min-width: 180px;
  height: 44px;
`;

export const ButtonNext = styled(Button).attrs({
  endIcon: <IconArrowRight />,
  variant: 'contained',
  size: 'large',
  // dark: true
})`
  display: flex;
  background-color: #fff;
  margin-left: 16px;
  min-width: 180px;
  height: 44px;
`;

export const ButtonSubmit = styled(Button).attrs({
  variant: 'contained',
  size: 'large'
})`
  margin-left: 16px;
  background-color: ${props => (props.disabled ? '#ccc' : '#09a848')};
  color: #fff;
  min-width: 320px;
  height: 44px;
`;

export const Container = styled.div`
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: #fff;
  background-color: ${props => props.theme.palette.common.black};
  box-shadow: ${props => props.theme.shadows[6]};
`;

export const TextDefault = styled.div`
  font-size: 14px;
  text-align: start;
`;

export const DetalhesOrcamento = styled.div`
  font-size: 14px;
`;

export const TitleDefault = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  padding-bottom: 15px;
`;
