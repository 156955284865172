import socketio from 'socket.io-client';
import { SOCKET_URL } from '~/constants';

const socket = socketio(SOCKET_URL, {
  transports: ['websocket'],
  autoConnect: false
});

function connect(userToken, terminal) {
  socket.io.opts.query = { userToken, terminal };
  socket.connect();
}

function disconnect() {
  if (socket.connected) {
    socket.disconnect();
  }
}

function subscribeMessageSync(subcribleFunction) {
  socket.on('@message/sync', subcribleFunction);
}

function subscribeUpdateQueueJobs(subcribleFunction) {
  socket.on('@queue/updatedJobs', subcribleFunction);
}

function subscribeStartQueue(subcribleFunction) {
  socket.on('@queue/start', subcribleFunction);
}

function subscribeUpdateQueueMessage(subcribleFunction) {
  socket.on('@queue/updateMessage', subcribleFunction);
}

function subscribeFailureQueue(subcribleFunction) {
  socket.on('@queue/failure', subcribleFunction);
}

function subscribeEndQueue(subcribleFunction) {
  socket.on('@queue/end', subcribleFunction);
}

export default {
  connect,
  disconnect,
  subscribeMessageSync,
  subscribeUpdateQueueJobs,
  subscribeStartQueue,
  subscribeUpdateQueueMessage,
  subscribeFailureQueue,
  subscribeEndQueue
};
