import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import IconClose from '@material-ui/icons/Close';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconCancel from '@material-ui/icons/Cancel';

import toast from '~/services/toast';
import api from '~/services/api';
import pedidoService from '~/store/modules/pedido/pedido-service';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';
import {
  API_BASE_URL,
  API_LOCAL_URL,
  APP_ERROR,
  PEDIDO_TIPO_BLOQUEIO,
  TIPO_DOCUMENTO
} from '~/constants';
import { formatCurrency, formatNumber } from '~/utils/format';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';

import PedidoIdentificacao from '../../components/PedidoIdentificacao';
import PedidoHistoricoBloqueios from '../../components/PedidoHistoricoBloqueios';
import LiberacaoAcesso from '../../components/LiberacaoAcesso';
import { selectorParametroAsBoolean } from '~/store/modules/auth/auth-selectors';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

const ModalLiberacaoEstoque = ({ id, isDocumentoOffline, open, onClose }) => {
  const dispatch = useDispatch();
  const isOnline = useSelector(getApplicationIsOnline());

  const obrigaMeioPagamento = useSelector(selectorParametroAsBoolean('PP001'));
  const parametroConfirmaLogin = useSelector(selectorParametroAsBoolean('PP002'));
  const exibirModalCliente = useSelector(selectorParametroAsBoolean('PP003'));
  const { tipoDocumento } = useSelector(state => state.pdv);

  const [loading, setLoading] = useState(false);
  const [pedido, setPedido] = useState(null);
  const [itens, setItens] = useState([]);
  const [armazemSelecionado, setArmazemSelecionado] = useState([]);
  const [disableModal, setDisableModal] = useState(true);
  const [tokenLiberacaoRotina, setTokenLiberacaoRotina] = useState(null);
  const [armazens, setArmazens] = useState([]);
  const [imprimirAposLiberacao, setImprimirAposLiberacao] = useState(false);

  const handleSubmitLiberar = async () => {
    try {
      itens?.forEach(item => {
        if (item.itemLiberacaoQuantidade > item.itemQuantidade) {
          toast.warning(
            `${item.produtoDescricao}.A quantidade liberada não pode ser maior que a quantidade solicitada.`
          );

          return;
        }
      });

      const { isConfirmed } = await toast.confirm(
        'Deseja liberar o pedido?',
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
        {
          icon: 'success',
          confirmButtonText: 'Liberar',
          cancelButtonText: 'Cancelar'
        }
      );

      if (isConfirmed) {
        pedidoService.setStategy(isDocumentoOffline);
        const { data } = await pedidoService.liberarBloqueioEstoque(
          id,
          {
            ...pedido,
            itens: itens.map(item => ({
              ...item,
              produtoEstArmazemId: item.produtoEstArmazem?.id
            }))
          },
          tokenLiberacaoRotina
        );
        toast.success('Operação realizada com sucesso!');
        if (imprimirAposLiberacao) {
          dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.PEDIDO, data));
        }
        onClose();
        if(parametroConfirmaLogin && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA) dispatch(pdvActions.setShowModalTrocaUsuario({show: true, confirmLogin: true}));
        if(tipoDocumento === TIPO_DOCUMENTO.PEDIDO && exibirModalCliente && !parametroConfirmaLogin) dispatch(pdvActions.setShowModalIdentificarCliente({ show: true }));
        if(obrigaMeioPagamento && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA && !exibirModalCliente && !parametroConfirmaLogin) {
          dispatch(pdvActions.setShowModalConsultaPagamento({ show: true }));
        }
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const handleSubmitRejeitar = async () => {
    try {
      const { isConfirmed } = await toast.confirm(
        'Deseja rejeitar o pedido?',
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
        {
          icon: 'danger',
          confirmButtonText: 'Rejeitar',
          cancelButtonText: 'Cancelar'
        }
      );

      if (isConfirmed) {
        pedidoService.setStategy(isDocumentoOffline);
        const { data } = await pedidoService.rejeitarBloqueioEstoque(
          id,
          pedido,
          tokenLiberacaoRotina
        );
        toast.success('Operação realizada com sucesso!');

        if (imprimirAposLiberacao) {
          dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.PEDIDO, data));
        }
        onClose();
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);

      pedidoService.setStategy(isDocumentoOffline);
      const { data } = await pedidoService.getPedidoBloqueado(
        id,
        PEDIDO_TIPO_BLOQUEIO.BloqueadoEstoque
      );
      setPedido(data);
      setItens([
        ...data?.itens?.map(item => ({
          ...item,
          itemLiberacaoQuantidade: item?.itemQuantidade
        }))
      ]);

      const { data: _armazens } = isOnline
        ? await api.get(
            `${API_BASE_URL}/v1/consulta/armazem?$orderby=id&$select=descricao,%20id&$filter=tipo%20eq%201`
          )
        : await api.get(`${API_LOCAL_URL}/estoque/armazem`);

      setArmazens(_armazens);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <DialogTitle>
          <AppBar color="primary" position="static">
            <Toolbar>
              <Box>
                <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Liberação do pedido - bloqueio por estoque
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <IconButton color="inherit" onClick={onClose} aria-label="close">
                <IconClose />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: 640, padding: 16 }}>
          {!loading && disableModal && (
            <LiberacaoAcesso
              rotina="F016ANP"
              callback={(acesso, token) => {
                setDisableModal(!acesso);
                if (acesso) {
                  setTokenLiberacaoRotina(token);
                }
              }}
            />
          )}
          {loading ? (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <CircularProgress variant="indeterminate" color="primary" />
              </Box>
            </>
          ) : (
            <>
              <PedidoIdentificacao data={pedido} />

              <Paper>
                <Box px={2} pt={1}>
                  <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
                    ITENS
                  </Typography>
                </Box>
                <Box p={2}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Produto</TableCell>
                          <TableCell align="center">Quant. </TableCell>
                          <TableCell align="right">Total </TableCell>
                          <TableCell
                            align="right"
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
                          >
                            Saldo Bloqueio{' '}
                          </TableCell>
                          <TableCell align="right">Qtd. Liberada </TableCell>
                          <TableCell>Armazém</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell style={{padding: 0}}> 
                              <Select
                                variant="outlined"
                                fullWidth
                                style={{ height: 44, width: 320 }}
                                value={armazemSelecionado?.produtoEstArmazem?.id  ?? null}
                                onChange={e => {
                                  e.preventDefault();

                                  setItens(state => {
                                    const newItens = [...state];
                                    const armazemSelecionado = armazens.find(
                                      x => x.id === e.target.value
                                    );

                                    newItens.forEach(item => {
                                      item.produtoEstArmazem = armazemSelecionado;
                                    });
                                    const itemSelecionado = {
                                      ...newItens[0]
                                    };
                                    setArmazemSelecionado(itemSelecionado);

                                    return newItens;
                                  });
                                }}
                              >
                                {armazens?.map((item, index) => (
                                  <MenuItem key={index} value={item?.id}>
                                    {item?.descricao}
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itens?.map((item, index) => (
                          <TableRow key={`item-${index.toString()}`}>
                            <TableCell>
                              {item?.produtoDescricao} <br />{' '}
                              <small>
                                PC | Vl unitário: {formatCurrency(item?.itemValorUnitario)}
                              </small>
                            </TableCell>
                            <TableCell align="center">{item?.itemQuantidade}</TableCell>
                            <TableCell align="center">
                              {formatCurrency(item?.itemValorTotal)}
                            </TableCell>
                            <TableCell
                              align="right"
                              style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
                            >
                              <p>{formatNumber(item?.itemBloqueioEstoqueSaldo)}</p>
                              <p>
                                {formatNumber(
                                  item?.itemBloqueioEstoqueSaldo - item?.itemQuantidade
                                )}
                              </p>
                            </TableCell>
                            
                            <TableCell>
                            {item.bloqueio !== 0 && 
                              <TextField
                                type="number"
                                size="small"
                                value={item?.itemLiberacaoQuantidade}
                                disabled={item.bloqueio === 0 ? true : false}
                                onChange={e => {
                                  e.preventDefault();
                                  const itemLiberacaoQuantidade = Number(e?.target?.value);
                                  if(itemLiberacaoQuantidade <= 0 || itemLiberacaoQuantidade > item?.itemQuantidade ){
                                    return;
                                  }

                                  setItens(state => {
                                    const newItens = [...state];
                                    

                                    newItens[
                                      newItens.indexOf(item)
                                    ].itemLiberacaoQuantidade = itemLiberacaoQuantidade;

                                    return newItens;
                                  });
                                }}
                                variant="outlined"
                                fullWidth
                              />}
                            </TableCell>
                            <TableCell>
                            {item.bloqueio !== 0 && 
                              <Select
                                variant="outlined"
                                fullWidth
                                style={{ height: 44, width: 320 }}
                                value={item?.produtoEstArmazem?.id ?? null}
                                disabled={item.bloqueio === 0 ? true : false}
                                onChange={e => {
                                  e.preventDefault();

                                  setItens(state => {
                                    const newItens = [...state];
                                    const armazemSelecionado = armazens.find(
                                      x => x.id === e.target.value
                                    );

                                    newItens[
                                      newItens.indexOf(item)
                                    ].produtoEstArmazem = armazemSelecionado;

                                    return newItens;
                                  });
                                }}
                              >
                                {armazens?.map((item, index) => (
                                  <MenuItem key={index} value={item?.id}>
                                    {item?.descricao}
                                  </MenuItem>
                                ))}
                              </Select>
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                        {/* <TableRow>
                          <TableCell colSpan={2} align="right">
                            <p>Quantidade de itens bloqueados: 1</p>
                            <p>Quantidade total de produtos bloqueados: 5</p>
                          </TableCell>
                          <TableCell colSpan={3} align="right">
                            <p>Quantidade total de itens: 1</p>
                            <p>Quantidade total de produtos: 5</p>
                          </TableCell>
                        </TableRow> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Paper>
            </>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Box flex={1} display="flex" justifyContent="space-between">
              <Box>
                <Button
                  variant="contained"
                  className="button-success"
                  disabled={disableModal}
                  onClick={() => handleSubmitLiberar()}
                >
                  <IconCheckCircle />{' '}
                  <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                    Liberar pedido
                  </Mui.Typography>
                </Button>
                <Button
                  variant="contained"
                  className="button-danger"
                  disabled={disableModal}
                  style={{ marginLeft: 8 }}
                  onClick={() => handleSubmitRejeitar()}
                >
                  <IconCancel />{' '}
                  <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                    {' '}
                    Rejeitar pedido
                  </Mui.Typography>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: 12 }}
                  control={
                    <Switch
                      checked={imprimirAposLiberacao}
                      onChange={() => setImprimirAposLiberacao(state => !state)}
                    />
                  }
                  label="Imprimir após liberação"
                />
              </Box>
              <Button
                startIcon={<MuiIcons.Close />}
                size="large"
                style={{ marginLeft: 8 }}
                onClick={onClose}
              >
                CANCELAR (ESC)
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
export default ModalLiberacaoEstoque;
