import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import * as Mui from '@material-ui/core';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';
import OrcamentoTableRow from '../OrcamentoTableRow';

const OrcamentoTable = () => {
  const dispatch = useDispatch();
  const {
    status,
    documentos,
    quantidadeDocumentos,
    valorTotalDocumentos,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    orderBy,
    selectedRows,
    enableBulkActions,
    selectedSomeRows,
    selectedAllRows,
    loading
  } = useSelector(state => state.monitor);
  const [columns] = useState([
    { id: 'serieNumero', label: 'Série/Número' },
    { id: 'dataEmissao', label: 'Emissão' },
    { id: 'situacao', label: 'Situação' },
    { id: 'condicaoPagamento', label: 'Condição pagamento' },
    { id: 'meioPagamento', label: 'Meio pagamento' },
    { id: 'cliente', label: 'Cliente' },
    { id: 'vendedor', label: 'Vendedor' },
    { id: 'total', alignment: 'right', label: 'Total' }
  ]);

  const handleSelectAllOrders = event => {
    dispatch(
      monitorActions.selectAllRows(event.target.checked ? documentos.map(order => order.id) : [])
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(monitorActions.setPage(newPage));
  };

  const handleChangeRowsPerPage = event => {
    dispatch(monitorActions.setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(monitorActions.setPage(0));
  };

  const handleRequestSort = property => {
    const isAsc = orderBy === property && orderBy.split('|')[1] === 'asc';
    dispatch(monitorActions.setOrderBy(`${property}|${isAsc ? 'desc' : 'asc'}`));
  };

  return (
    <Box style={{ height: 'calc(100vh - 200px)', display: 'flex', flexDirection: 'column' }}>
      <TableContainer style={{ maxHeight: '100%' }}>
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <Mui.TableCell align="center">#</Mui.TableCell>
                {columns.map(column => (
                  <TableCell key={column?.id} align={column.alignment ?? 'left'}>
                    {column?.label}
                  </TableCell>
                ))}
                <TableCell style={{ width: 120 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  {[...Array(10).keys()].map(index => (
                    <TableRow key={index}>
                      {[...Array(10).keys()].map(col => (
                        <TableCell key={col} height={44} style={{ padding: 6 }}>
                          <Skeleton variant="text" height={32} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {documentos.map((documento, index) => {
                    const isOrderSelected = selectedRows.includes(documento._id || documento?.id);

                    return (
                      <OrcamentoTableRow
                        key={`orcamento-${index}`}
                        data={documento}
                        isSelected={isOrderSelected}
                        onRefresh={() => {}}
                      />
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </TableContainer>
      <Divider />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box p={2}></Box>
        {loading ? (
          <Box mr={2}>
            <Skeleton variant="text" width={320} height={32} />
          </Box>
        ) : (
          <TablePagination
            labelRowsPerPage={'Registros por página'}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={quantidadeDocumentos ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Box>
    </Box>
  );
};

export default OrcamentoTable;
