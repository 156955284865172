export const SET_THEME = '@app/SET_THEME';
export const SET_CONNECTION_MODE = '@app/SET_CONNECTION_MODE';
export const SET_APPLICATION_MODE = '@app/SET_APPLICATION_MODE';

export const DOWNLOAD_DATA = '@app/DOWNLOAD_DATA';
export const SET_SHOWMODAL_SYNC = '@pp/SET_SHOWMODAL_SYNC';
export const SET_SHOWPROGRESS_SYNC = '@pp/SET_SHOWPROGRESS_SYNC';
export const SET_MENSAGEM_SYNC = '@app/SET_MENSAGEM_SYNC';


export const SET_SHOWPROGRESS_QUEUE = '@app/SET_SHOWPROGRESS_QUEUE';
export const SET_MENSAGEM_QUEUE = '@app/SET_MENSAGEM_QUEUE';


export const SET_MESSAGE_SEND_DOCUMENT = '@app/SET_MESSAGE_SEND_DOCUMENT';
export const SET_SHOW_SNACKBAR_SYNC_DOCUMENT = '@app/SET_SHOW_SNACKBAR_SYNC_DOCUMENT';

export const SEND_DATA = '@app/SEND_DATA';

export const ENQUEUE_SNACKBAR = '@app/ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = '@app/CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = '@app/REMOVE_SNACKBAR';

export const INITIALIZE = '@app/INITIALIZE';
export const SET_TERMINAL = '@pdv/SET_TERMINAL';
export const FORCE_OFFLINE_MODE_REQUEST = '@app/FORCE_OFFLINE_MODE_REQUEST';
export const FORCE_OFFLINE_MODE_SUCCESS = '@app/FORCE_OFFLINE_MODE_SUCCESS';
export const SET_SHOWMODAL_IDENTIFICACAO_PDV = '@app/SET_SHOWMODAL_IDENTIFICACAO_PDV';
export const SET_SHOWMODAL_CONFIGURACAO = '@app/SET_SHOWMODAL_CONFIGURACAO';
export const SET_SHOWMODAL_DOWNLOAD = '@app/SET_SHOWMODAL_DOWNLOAD';
export const SET_SHOW_FILA_DOCUMENTOS = '@app/SET_SHOW_FILA_DOCUMENTOS';

export const SET_TRANSACAO_TEF = '@app/SET_TRANSACAO_TEF';
export const SET_MENSAGEM_TEF = '@app/SET_MENSAGEM_TEF';
export const SET_SHOWMODAL_MENU_TEF = '@app/SET_SHOWMODAL_MENU_TEF';
export const RESET_MODAL_MENU_TEF = '@app/RESET_MODAL_MENU_TEF';
export const INICIALIZAR_TEF = '@app/INICIALIZAR_TEF';
export const MONTAR_RETORNOS_TEF = '@app/MONTA_RETORNOS_TEF';
export const EXIBIR_QRCODE_TEF = '@EXIBIR_QRCODE_TEF';
export const IMPRIMIR_COMPROVANTE_TEF = '@app/IMPRIMIR_COMPROVANTE_TEF';
export const FINALIZAR_TEF = '@app/FINALIZAR_TEF';

export const SET_QUEUES = '@app/SET_QUEUES';
export const SET_QUEUES_ADD = '@app/SET_QUEUES_ADD';
export const SET_QUEUES_REMOVE = '@app/SET_QUEUES_REMOVE';
