import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import IconExpandMore from '@material-ui/icons/ExpandMore';

import { PEDIDO_BLOQUEIO_TITULO, PEDIDO_SITUACAO } from '~/constants';
import { formatDatetime } from '~/utils/format';

import Label from '~/components/Label';

const getSituacaoLabel = situacao => {
  const colors = ['blue', 'green', 'orange', 'red'];
  return <Label color={colors[situacao] ?? 'grey'}>{PEDIDO_SITUACAO[situacao]}</Label>;
};

const PedidoHistoricoBloqueios = ({ data: bloqueios }) => {
  return (
    <Accordion style={{marginTop: 16}}>
      <AccordionSummary
        expandIcon={<IconExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography variant="h6" color="primary"  style={{ fontWeight: 600 }}>
          HISTÓRICOS DE BLOQUEIOS
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Data e hora</TableCell>
                <TableCell align="left">Situação</TableCell>
                <TableCell align="left">Bloqueio</TableCell>
                <TableCell>Mensagem</TableCell>
                <TableCell align="right">Usuário</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bloqueios?.map((bloqueio, index) => (
                <TableRow key={`item-${index}`}>
                  <TableCell>{formatDatetime(bloqueio?.dataHora)}</TableCell>
                  <TableCell align="left">{getSituacaoLabel(bloqueio?.situacao)}</TableCell>
                  <TableCell align="left">
                    <Label color="grey">{PEDIDO_BLOQUEIO_TITULO[bloqueio?.bloqueio]}</Label>
                  </TableCell>
                  <TableCell>{bloqueio?.mensagem}</TableCell>
                  <TableCell align="right">{bloqueio?.logPctUsuarioNome}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default PedidoHistoricoBloqueios;
