import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

const PrintDialogActions = ({ onPrint, onClose, onShare }) => (
  <Mui.DialogActions>
    <Mui.Box p={1}>
      <Mui.Button size="large" onClick={onShare} style={{ marginLeft: 16 }}>
        Compartilhar
      </Mui.Button>
    </Mui.Box>
    <Mui.Box p={1}>
      <Mui.Button size="large" onClick={onPrint} style={{ marginLeft: 16 }}>
        <MuiIcons.Print fontSize="small" /> <span style={{ marginLeft: '10px' }}>Imprimir</span>
      </Mui.Button>
    </Mui.Box>
    <Mui.Box p={1}>
      <Mui.Button size="large" onClick={onClose} style={{ marginLeft: 16 }}>
        Cancelar
      </Mui.Button>
    </Mui.Box>
  </Mui.DialogActions>
);

export default PrintDialogActions;