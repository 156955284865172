import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@mui/icons-material';

import signalr from '~/services/signalr';
import * as appActions from '~/store/modules/app/app-actions';

import ButtonChangeMode from '~/components/FooterBar/components/ButtonChangeMode';
import ButtonQueueDocuments from '~/components/FooterBar/components/ButtonQueueDocuments';
import ButtonSyncData from '~/components/FooterBar/components/ButtonSyncData';
import { APPLICATION } from '~/constants';

const isWeb = APPLICATION === 'web';

const FooterBar = () => {
  const dispatch = useDispatch();
  // const { terminal } = useSelector(state => state.app);
  const [terminal, setTerminal] = useState(localStorage.getItem('TERMINAL_PDV') || '');

  const handleTerminalChange = (novoValor) => {
    setTerminal(novoValor);
    localStorage.setItem('TERMINAL_PDV', novoValor);
  }

  const handleAdministracaoTEF = async () => {
    try {
      dispatch(
        appActions.setShowModalMenuTef({
          show: true,
          inicializado: false,
          title: 'Administração TEF',
          administracao: true
        })
      );
      await signalr.invokeInicializaTef();

      await signalr.invokeAdministracaoTef();
    } catch (error) {
      dispatch(appActions.setShowModalMenuTef({ show: false }));
    }
  };

  return (
    <Mui.AppBar
      position="static"
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        height: 32,
        maxHeight: 32,
        paddingRight: 16
      }}
    >
      <ButtonChangeMode />
      <Mui.Divider orientation="vertical" flexItem />

      <Mui.Tooltip title="Terminal">
        <Mui.Button
          onClick={() => {
            dispatch(appActions.setShowmodalIdentificacaoPDV({ show: true }));
          }}
          style={{ borderRadius: 0, color: '#FFF' }}
        >
          <MuiIcons.Computer />
          <Mui.Typography style={{ fontSize: 12, marginLeft: 8, textTransform: 'none' }}>
            Terminal:
            <input
              type="text"
              value={terminal}
              onChange={(event) => handleTerminalChange(event.target.value)}
              style={{ paddingLeft: '3px', background: 'transparent', border: 0, color: '#FFFF' }}
            />
          </Mui.Typography>
        </Mui.Button>
      </Mui.Tooltip>
      {!isWeb && (
        <>
          <Mui.Divider orientation="vertical" flexItem />
          <ButtonQueueDocuments />
          <Mui.Divider orientation="vertical" flexItem />
          <ButtonSyncData />
        </>
      )}

      <Mui.Box flexGrow={1} />
      <Mui.Divider orientation="vertical" flexItem />
      <Mui.Button
        onClick={() => {
          handleAdministracaoTEF();
        }}
        style={{ borderRadius: 0, color: '#FFF' }}
      >
        <MuiIcons.CreditCardRounded />
        <Mui.Typography style={{ fontSize: 12, marginLeft: 8, textTransform: 'none' }}>
          Administração TEF
        </Mui.Typography>
      </Mui.Button>
    </Mui.AppBar>
  );
};

export default FooterBar;
