import React, { useState, useEshowModalFreteffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alpha, useTheme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Lottie from 'react-lottie';
import { CurrencyInputCustom } from '~/components/CustomInput';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import currency from 'currency.js';
import toast from '~/services/toast';
const ModalFrete = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { venda, showModalFrete } = useSelector(state => state.pdv);
  const [valorFrete, setValorFrete] = useState(venda?.valorFrete);
  const [percentualFrete, setPercentualFrete] = useState(venda?.percentualFrete);
  const changeFrete = e => {
    const frete = e.floatvalue;
    const percentual = Number(
      currency((frete / venda?.valorMercadoria) * 100, {
        precision: 2
      })
    );
    setValorFrete(frete);
    setPercentualFrete(percentual);
  };
  const changeFretePercentual = e => {
    const percentual = e.floatvalue;
    const frete =
      percentual > 0
        ? Number(
            currency(venda?.valorMercadoria * (percentual / 100), {
              precision: 2
            })
          )
        : 0;
    setValorFrete(frete);
    setPercentualFrete(percentual);
  };
  const handleSubmit = async () => {
    if (venda?.parcelas?.length > 0){
    const { isConfirmed } = await toast.confirm(
      'Deseja alterar a forma de efetuar os pagamentos?',
      'Ao adicionar um frete, irá remover os pagamentos anteriores',
      {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }
    );
    if (isConfirmed) {
      dispatch(pdvActions.limparPagamentos());
      dispatch(pdvActions.selecionarMeioPagamento(null));
      dispatch(pdvActions.selecionarCondicaoPagamento(null));
      dispatch(pdvActions.setMultiplosPagamentos(false));
      dispatch(pdvActions.atualizarFrete(valorFrete));
      handleClose();
    }
    } else {
      dispatch(pdvActions.atualizarFrete(valorFrete));
      handleClose();
    }
  };
  const handleClose = () => {
    dispatch(pdvActions.setShowModalFrete({ show: false }));
  };
  return (
    <>
      <Mui.Dialog
        open={showModalFrete?.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  FRETE
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />
              <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ maxWidth: 480 }}>
          <Mui.Box p={2} mb={2}>
            <Mui.Grid container spacing={2}>
              <Mui.Grid item md={7}>
                <Mui.Typography align="left" variant="caption">
                  Frete (R$)
                </Mui.Typography>
                <Mui.TextField
                  value={valorFrete ?? 0}
                  onChange={changeFrete}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: <Mui.InputAdornment position="start">R$</Mui.InputAdornment>,
                    inputComponent: CurrencyInputCustom,
                    style: {
                      height: 54
                    }
                  }}
                />
              </Mui.Grid>
              <Mui.Grid item md={5}>
                <Mui.Typography align="left" variant="caption">
                  Frete(%)
                </Mui.Typography>
                <Mui.TextField
                  value={percentualFrete ?? 0}
                  onChange={changeFretePercentual}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: CurrencyInputCustom,
                    style: {
                      height: 54
                    }
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Box>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button size="large" onClick={handleSubmit} variant="contained" color="primary">
            <MuiIcons.CheckCircle /> <span style={{ paddingLeft: 8 }}>CONFIRMAR</span>
          </Mui.Button>
          <Mui.Button startIcon={<MuiIcons.Close />} size="large" onClick={handleClose}>
            CANCELAR (ESC)
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};
export default ModalFrete;