import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import Swal from 'sweetalert2';

import api from '~/services/api';
import { formatCurrency, formatDatetime } from '~/utils/format';
import {
  APP_ERROR,
  API_BASE_URL,
  API_LOCAL_URL,
  API_TERMINAL_URL,
  MODELO_DOCUMENTO,
  DOCUMENTO_TRANSMISSAO,
  DOCUMENTO_FINALIDADE_ENUM,
  TIPO_DOCUMENTO,
  API_VENDAS_URL
} from '~/constants';
import toast from '~/services/toast';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import ModalEditarItemDocumento from '../ModalEditarItemDocumento';
import ModalEditarClienteDocumento from '../ModalEditarClienteDocumento';

import { Container } from './styles';
import ModalJustificativaCancelamento from '../ModalJustificativaCancelamento';
import { TRANSMITIR_DOCUMENTO } from '~/store/modules/monitor/monitor-constants';

const ModalVisualizarDocumento = ({
  data: { documentoId, isDocumentoOffline },
  readonly = false,
  showModal,
  onClose
}) => {
  const dispatch = useDispatch();
  const { empresa } = useSelector(state => state?.auth);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cienteMulta, setCienteMulta] = useState(false);
  const [loadingTransmicao, setLoadingTransmissao] = useState(false);
  const [documento, setDocumento] = useState(null);
  const [edicaoDocumento, setEdicaoDocumento] = useState(false);
  const [imprimirAposTransmitir, setImprimirAposTransmitir] = useState(false);
  const [showModalEditarClienteDocumento, setShowModalEditarClienteDocumento] = useState({
    show: false
  });
  const [showModalEditarItemDocumento, setShowModalEditarItemDocumento] = useState({
    show: false
  });
  const [showModalJustificativaCancelamento, setShowModalJustificativaCancelamento] = useState({
    show: false
  });
  const [columnsParcela] = useState([
    { id: 'meioPagamento', label: 'Meio de pagamento' },
    { id: 'autorizacao', label: 'Autorização' },
    { id: 'valor', alignment: 'right', label: 'Valor' }
  ]);
  const [columnsItem] = useState([
    { id: 'produto', label: 'Produto' },
    { id: 'itemQuantidade', alignment: 'center', label: 'Quantidade' },
    { id: 'itemValorUnitario', alignment: 'right', label: 'Valor Unitário' },
    { id: 'itemDesconto', alignment: 'right', label: 'Desconto' },
    { id: 'itemSubtotal', alignment: 'right', label: 'Subtotal' }
  ]);

  const handleEditarCliente = () => {
    setShowModalEditarClienteDocumento({ show: true, data: { ...documento } });
  };

  const handleEditarItem = item => {
    setShowModalEditarItemDocumento({ show: true, data: { ...item } });
  };

  const handleAtualizarDocumento = async () => {
    setLoadingTransmissao(true);
    try {
      await api.put(
        isDocumentoOffline
          ? `${API_LOCAL_URL}/faturamento/documentosaida/${documentoId}`
          : `${API_BASE_URL}/v1/faturamento/documentosaida/${documentoId}`,
        {
          ...documento
        }
      );
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoadingTransmissao(false);
    }
  };

  const handleAtualizarProduto = async itemAtualizado => {
    setLoadingTransmissao(true);
    try {
      const { data: produto } = await api.get(
        `${API_BASE_URL}/v1/estoque/produto/${itemAtualizado?.produtoEstProdutoId}`
      );
      await api.put(`${API_BASE_URL}/v1/estoque/produto/${itemAtualizado?.produtoEstProdutoId}`, {
        ...produto,
        estCodigoBarras: produto?.estCodigoBarras ?? [],
        ncm: itemAtualizado?.ncm,
        cest: itemAtualizado?.cest,
        cfopSaida: itemAtualizado?.itemCfop
      });

      await handleAtualizarDocumento();
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const handleGerarOffline = async () => {
    const toastInstance = toast.loading(`Gerando, aguarde...`);
    try {
      const {
        data: { lote }
      } = await api.get(`${API_LOCAL_URL}/painelcontrole/autosequencia/lote`);

      const { data } = await api.post(`${API_TERMINAL_URL}/documentosaida/gerar-xml`, {
        ...documento,
        nfeLote: lote
      });

      await api.put(
        isDocumentoOffline
          ? `${API_LOCAL_URL}/faturamento/documentosaida/${documentoId}`
          : `${API_BASE_URL}/v1/faturamento/documentosaida/${documentoId}`,
        {
          ...documento,
          nfeChave: data?.chaveNFe,
          nfeLote: lote,
          documentoTransmissao: DOCUMENTO_TRANSMISSAO.AGUARDANDO_TRANSMISSAO
        }
      );
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }
  };

  const handleTransmitirDocumento = () => {
    dispatch(
      monitorActions.transmitirDocumento(
        { ...documento, id: documentoId, isDocumentoOffline },
        {
          tentativa: 1,
          imprimirAposTransmitir: false
        },
        () => {
          loadData();
        }
      )
    );
  };

  const handleCancelarDocumento = async () => {
    try {
      if (documento?.documentoCancelado) {
        toast.error('Não pode cancelar documento já cancelado!');
        return;
      }

      if (
        documento?.documentoModelo === MODELO_DOCUMENTO.NFE ||
        documento?.documentoModelo === MODELO_DOCUMENTO.NFCE
      ) {
        if (documento?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_TRANSMITIDO) {
          setShowModalJustificativaCancelamento({
            show: true,
            title: 'Informe o motivo da inutilização, antes de cancelar o documento.',
            callback: async justificativa => {
              try {
                const dataAtual = new Date();

                const payload = {
                  ano: dataAtual.getFullYear(),
                  uf: empresa?.uf,
                  cnpj: empresa?.cnpj,
                  modelo: documento?.fisOperacao?.documentoModelo,
                  serie: documento?.documentoSerie,
                  numeroInicial: documento?.documentoNumero,
                  numeroFinal: documento?.documentoNumero,
                  justificativa,
                  cancelarDocumento: true,
                  id: documentoId
                };

                await api.post(
                  `${API_BASE_URL}/v1/faturamento/documentosaida/inutilizar/`,
                  payload
                );

                await cancelaDocumento(justificativa);
              } catch (error) {
                dispatch({ type: APP_ERROR, error });
              }
            }
          });
        } else if (documento?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.TRANSMITIDO) {
          setShowModalJustificativaCancelamento({
            show: true,
            callback: async justificativa => {
              const toastInstance = toast.loading('Cancelando, aguarde ...');
              try {
                await api.put(
                  `${API_BASE_URL}/v1/faturamento/documentosaida/transmissao/cancelamento/${documentoId}`,
                  {
                    justificativa,
                    cienteMulta
                  }
                );
                toastInstance.close();

                toast.success('Cancelamento registrado na SEFAZ com sucesso!');

                await cancelaDocumento(justificativa);
              } catch (error) {
                dispatch({ type: APP_ERROR, error });
              }
            }
          });
        } else {
          setShowModalJustificativaCancelamento({
            show: true,
            callback: async justificativa => {
              await cancelaDocumento(justificativa);
            }
          });
        }
      } else {
        setShowModalJustificativaCancelamento({
          show: true,
          callback: async justificativa => {
            await cancelaDocumento(justificativa);
          }
        });
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const handleDownloadXml = () => {
    dispatch(
      monitorActions.downloadXml({ ...documento, documentoId, isOffline: isDocumentoOffline })
    );
  };

  const handleDownloadDanfe = () => {
    dispatch(
      monitorActions.downloadDanfe({ ...documento, documentoId, isOffline: isDocumentoOffline })
    );
  };

  const handleImprimirDocumento = () => {
    dispatch(
      monitorActions.printDocumento(TIPO_DOCUMENTO.DOCUMENTOSAIDA, {
        ...documento,
        isOffline: isDocumentoOffline
      })
    );
  };

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const transmiteDocumento = async (id, tentativa = 1, documentoTransmissao = null) => {
    try {
      if (tentativa === 3) {
        Swal.close();

        Swal.fire({
          title: 'Atenção!',
          text: `Foram feitas ${tentativa -
            1} tentativas de transmisão do documento, deseja tentar novamente?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then(result => {
          if (result?.value) {
            transmiteDocumento(id);
          }
        });

        return;
      }

      Swal.fire({
        title: 'Transmitindo Nota.',
        html:
          documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_SE_APLICA ||
          documentoTransmissao === DOCUMENTO_TRANSMISSAO.EM_PROCESSAMENTO
            ? `A nota fiscal foi recebida pela SEFAZ e está aguardando processamento. Consultando processo...`
            : `${tentativa}º tentativa...`,
        position: 'center',
        timerProgressBar: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onRender: async () => {
          let documentoChave = '';
          let response = null;
          try {
            if (isDocumentoOffline) {
              const { data } = await api.post(
                `${API_TERMINAL_URL}/api/documentosaida/transmitir`,
                {
                  ...documento
                },
                {
                  headers: {
                    pOffLine: true,
                    pJustificativa: 'SEM CONEXAO COM A INTERNET',
                    pImprimir: imprimirAposTransmitir
                  }
                }
              );

              if (response) {
                await api.put(
                  isDocumentoOffline
                    ? `${API_LOCAL_URL}/faturamento/documentosaida/${documentoId}`
                    : `${API_BASE_URL}/v1/faturamento/documentosaida/${documentoId}`,
                  {
                    ...response
                  }
                );
              }

              response = { ...data };
              documentoChave = data?.nfeChave ?? null;
            } else {
              const { data } = await api.put(
                `${API_BASE_URL}/v1/faturamento/documentosaida/transmitir/${id}`
              );

              response = { ...data };
              documentoChave = data?.nfeChave ?? null;
            }
          } catch (error) {
            Swal.close();
            dispatch({ type: APP_ERROR, error });
          }

          if (
            response?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_SE_APLICA ||
            response?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.EM_PROCESSAMENTO
          ) {
            transmiteDocumento(id, tentativa + 1, response?.documentoTransmissao);
          } else if (response?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.TRANSMITIDO) {
            if (documentoChave) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Documento transmitido!',
                html: `<p class="margin-top-20">Chave de acesso: <strong>${documentoChave}</strong></p>`,
                showConfirmButton: true,
                timer: 10000
              }).then(() => {
                loadData();
                if (imprimirAposTransmitir) {
                  dispatch(
                    monitorActions.printDocumento(TIPO_DOCUMENTO.DOCUMENTOSAIDA, { ...documento })
                  );
                }
              });
            }
          }
        }
      });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const cancelaDocumento = async justificativa => {
    try {
      await api.put(`${API_VENDAS_URL}/v1/faturamento/documentosaida/cancelamento/${documentoId}`, {
        cienteMulta,
        justificativa,
        emitidoWeb: true
      });

      toast.success('Documento cancelado com sucesso!');
      loadData();
    } catch (error) {
      if (error?.response?.data?.[0]?.codigo === 'FAT0007') {
        Swal.fire({
          title: error?.response?.data?.[0]?.motivo,
          text: error?.response?.data?.[0]?.solucao,
          type: 'warning',
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonClass: 'md-button md-raised md-accent',
          cancelButtonClass: 'md-button md-raised',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar'
        }).then(function() {
          setCienteMulta(true);
          cancelaDocumento(justificativa);
        });
      } else {
        dispatch({ type: APP_ERROR, error });
      }
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);

      console.log({ isDocumentoOffline, documentoId });

      const { data: documentosaida } = await api.get(
        isDocumentoOffline
          ? `${API_LOCAL_URL}/faturamento/documentosaida/${documentoId}`
          : `${API_BASE_URL}/v1/faturamento/documentosaida/${documentoId}`
      );

      const promises = documentosaida?.itens?.map(item =>
        api.get(
          isDocumentoOffline
            ? `${API_LOCAL_URL}/estoque/produto/${item?.produtoEstProdutoId}`
            : `${API_BASE_URL}/v1/estoque/produto/${item?.produtoEstProdutoId}`
        )
      );
      const produtos = (await Promise.all([...promises])).map(({ data }) => data);

      setDocumento({
        ...documentosaida,
        itens: documentosaida?.itens?.map(item => {
          const produtoEstProduto = produtos.find(x => x.id === item.produtoEstProdutoId);
          return {
            ...item,
            produtoEstProduto
          };
        })
      });
      setEdicaoDocumento(
        (documentosaida?.documentoModelo === MODELO_DOCUMENTO.NFCE &&
          documentosaida?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_TRANSMITIDO) ||
          !readonly
      );
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (documentoId) loadData();
  }, [documentoId]);

  return (
    <>
      <Mui.Dialog
        open={showModal}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'lg'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Documento
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ minWidth: 740, padding: 16 }}>
          {loading ? (
            <>
              <Mui.Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <Mui.CircularProgress variant="indeterminate" color="primary" />
              </Mui.Box>
            </>
          ) : (
            <>
              <Container>
                <Mui.Box
                  display="flex"
                  flex
                  flexDirection="row"
                  justifyContent="space-between"
                  px={2}
                  pt={1}
                >
                  <Mui.Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
                    IDENTIFICAÇÃO
                  </Mui.Typography>

                  <Mui.Box>
                    <Mui.Button
                      variant="contained"
                      color="primary"
                      onClick={handleImprimirDocumento}
                    >
                      <MuiIcons.Print />
                      <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                        Imprimir
                      </Mui.Typography>
                    </Mui.Button>
                    {!documento?.documentoCancelado &&
                      documento?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_TRANSMITIDO && (
                        <Mui.Button
                          variant="contained"
                          style={{ marginLeft: 8, marginRight: 8 }}
                          onClick={handleCancelarDocumento}
                        >
                          <MuiIcons.Cancel />{' '}
                          <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                            Cancelar
                          </Mui.Typography>
                        </Mui.Button>
                      )}
                    {documento?.nfeChave !== null && (
                      <Mui.Tooltip title="Mais ações">
                        <Mui.IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          aria-label={'Mais ações'}
                          onClick={handleOpenMenu}
                        >
                          <MuiIcons.MoreVert />
                        </Mui.IconButton>
                      </Mui.Tooltip>
                    )}
                    <Mui.Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      {(documento?.documentoModelo === MODELO_DOCUMENTO.NFE ||
                        documento?.documentoModelo === MODELO_DOCUMENTO.NFCE) &&
                      documento?.nfeChave !== null ? (
                        <Mui.MenuItem onClick={() => handleDownloadDanfe()}>
                          <MuiIcons.CloudDownload fontSize="small" />{' '}
                          <span style={{ marginLeft: 12 }}>Download danfe</span>
                        </Mui.MenuItem>
                      ) : null}
                      {(documento?.documentoModelo === MODELO_DOCUMENTO.NFE ||
                        documento?.documentoModelo === MODELO_DOCUMENTO.NFCE) &&
                      documento?.nfeChave !== null ? (
                        <Mui.MenuItem onClick={() => handleDownloadXml()}>
                          <MuiIcons.CloudDownload fontSize="small" />{' '}
                          <span style={{ marginLeft: 12 }}>Download xml</span>
                        </Mui.MenuItem>
                      ) : null}
                    </Mui.Menu>
                  </Mui.Box>
                </Mui.Box>
                <Mui.Box p={2}>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">Série / Número</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.documentoSerie} / {documento?.documentoNumero}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">Data emissão</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {formatDatetime(documento?.documentoDataEmissao)}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">Modelo</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.documentoModelo ?? '-'}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">Finalidade</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {DOCUMENTO_FINALIDADE_ENUM[documento?.documentoFinalidade] ?? '-'}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item xs={12} md={6}>
                      <Mui.Typography variant="caption">Operação</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.fisOperacao?.descricao ?? '-'}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">Vendedor</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.fatVendedor?.nome ?? '-'}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">Condição de pagamento</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.fatCondicaopagamento?.descricao ?? '-'}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">Finalidade</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.documentoFinalidadeEnum
                          ? documento?.documentoFinalidadeEnum?.chave +
                            '-' +
                            documento?.documentoFinalidadeEnum?.valor
                          : '-'}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={6}>
                      <Mui.Typography variant="caption">Transmissão</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.documentoTransmissaoEnum
                          ? documento?.documentoTransmissaoEnum?.chave +
                            '-' +
                            documento?.documentoTransmissaoEnum?.valor
                          : '-'}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">Cancelado</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.documentoCancelado ? 'SIM' : 'NÃO'}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">NFe protocolo</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.nfeProtocolo ?? '-'}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={6}>
                      <Mui.Typography variant="caption">NFe chave</Mui.Typography>
                      <Mui.Typography variant="body2">{documento?.nfeChave ?? '-'}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">NFe data hora envio</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.nfeDataHoraEnvio ?? '-'}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">NFe recibo</Mui.Typography>
                      <Mui.Typography variant="body2">{documento?.nfeRecibo ?? '-'}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.Typography variant="caption">NFe lote</Mui.Typography>
                      <Mui.Typography variant="body2">{documento?.nfeLote ?? '-'}</Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Box>
              </Container>
              <Container>
                <Mui.Box
                  display="flex"
                  flex
                  flexDirection="row"
                  justifyContent="space-between"
                  px={2}
                  pt={1}
                >
                  <Mui.Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
                    CLIENTE
                  </Mui.Typography>
                  <Mui.Box>
                    {edicaoDocumento && (
                      <Mui.Button onClick={handleEditarCliente}>
                        <MuiIcons.Edit />
                      </Mui.Button>
                    )}
                  </Mui.Box>
                </Mui.Box>
                <Mui.Box p={2}>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item xs={12} md={6}>
                      <Mui.Typography variant="caption">Nome</Mui.Typography>
                      <Mui.Typography variant="body2">
                        {documento?.clienteNome ?? 'Cliente Padrão'}
                      </Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item xs={12} md={6}>
                      <Mui.Typography variant="caption">Endereço</Mui.Typography>
                      <Mui.Box>
                        {documento?.enderecoLogradouro ? (
                          <>
                            <Mui.Typography variant="body2">
                              {documento?.enderecoLogradouro},{documento?.enderecoLogradouroNumero}
                              {' - '}
                              {documento?.enderecoBairro}
                            </Mui.Typography>
                            <Mui.Typography variant="body2">
                              {documento?.enderecoIntCidade?.cidade}/
                              {documento?.enderecoIntCidade?.uf} - {documento?.enderecoCep}
                            </Mui.Typography>
                          </>
                        ) : (
                          <Mui.Typography variant="body2">Não informado</Mui.Typography>
                        )}
                      </Mui.Box>
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={6}>
                      <Mui.Typography variant="caption">Endereço entrega</Mui.Typography>
                      <Mui.Box>
                        {documento?.entregaLogradouro ? (
                          <>
                            <Mui.Typography variant="body2">
                              {documento?.entregaLogradouro},{documento?.entregaLogradouroNumero}
                              {' - '}
                              {documento?.entregaBairro}
                            </Mui.Typography>

                            <Mui.Typography variant="body2">
                              {documento?.entregaIntCidade?.cidade}/
                              {documento?.entregaIntCidade?.uf} - {documento?.entregaCep}
                            </Mui.Typography>
                          </>
                        ) : (
                          <Mui.Typography variant="body2">Não informado</Mui.Typography>
                        )}
                      </Mui.Box>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Box>
              </Container>
              <Container>
                <Mui.Box
                  display="flex"
                  flex
                  flexDirection="row"
                  justifyContent="space-between"
                  px={2}
                  pt={1}
                >
                  <Mui.Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
                    PARCELAS
                  </Mui.Typography>
                </Mui.Box>

                <Mui.Box p={2}>
                  <Mui.Table stickyHeader aria-label="sticky table">
                    <Mui.TableHead>
                      <Mui.TableRow>
                        <Mui.TableCell style={{ width: 80 }}>#</Mui.TableCell>

                        {columnsParcela.map(column => (
                          <Mui.TableCell key={column?.id} align={column.alignment ?? 'left'}>
                            {column?.label}
                          </Mui.TableCell>
                        ))}
                      </Mui.TableRow>
                    </Mui.TableHead>
                    <Mui.TableBody>
                      {documento?.parcelas?.map((parcela, index) => (
                        <Mui.TableRow index={index}>
                          <Mui.TableCell>{index + 1}</Mui.TableCell>
                          <Mui.TableCell>{parcela?.ffinMeiopagamento?.descricao}</Mui.TableCell>
                          <Mui.TableCell>{parcela?.documentoNumero ?? '-'}</Mui.TableCell>
                          <Mui.TableCell align="right">
                            {formatCurrency(parcela?.parcelaValor)}
                          </Mui.TableCell>
                        </Mui.TableRow>
                      ))}
                    </Mui.TableBody>
                  </Mui.Table>
                </Mui.Box>
              </Container>
              <Container>
                <Mui.Box
                  display="flex"
                  flex
                  flexDirection="row"
                  justifyContent="space-between"
                  px={2}
                  pt={1}
                >
                  <Mui.Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
                    ITENS
                  </Mui.Typography>
                </Mui.Box>
                <Mui.Box p={2}>
                  <Mui.Table stickyHeader aria-label="sticky table">
                    <Mui.TableHead>
                      <Mui.TableRow>
                        <Mui.TableCell style={{ width: 80 }}>#</Mui.TableCell>

                        {columnsItem.map(column => (
                          <Mui.TableCell key={column?.id} align={column.alignment ?? 'left'}>
                            {column?.label}
                          </Mui.TableCell>
                        ))}
                        <Mui.TableCell style={{ width: 80 }}></Mui.TableCell>
                      </Mui.TableRow>
                    </Mui.TableHead>
                    <Mui.TableBody>
                      {documento?.itens?.map((item, index) => (
                        <Mui.TableRow key={index}>
                          <Mui.TableCell>{index + 1}</Mui.TableCell>

                          <Mui.TableCell>
                            {item?.produtoCodigo}-{item?.produtoDescricao}
                          </Mui.TableCell>
                          <Mui.TableCell>{item?.itemQuantidade}</Mui.TableCell>
                          <Mui.TableCell>{formatCurrency(item?.itemValorUnitario)}</Mui.TableCell>
                          <Mui.TableCell>{formatCurrency(item?.itemValorDesconto)}</Mui.TableCell>
                          <Mui.TableCell>{formatCurrency(item?.itemValorTotal)}</Mui.TableCell>

                          <Mui.TableCell>
                            {edicaoDocumento && (
                              <Mui.Button onClick={() => handleEditarItem(item)}>
                                <MuiIcons.Edit />
                              </Mui.Button>
                            )}
                          </Mui.TableCell>
                        </Mui.TableRow>
                      ))}
                    </Mui.TableBody>
                  </Mui.Table>
                </Mui.Box>
              </Container>
              {documento?.mensagemErro && (
                <Alert severity="error" variant="filled" style={{ margin: '16px' }}>
                  <AlertTitle style={{ textTransform: 'uppercase' }}>
                    Erro de transmissão/sincronização
                  </AlertTitle>
                  <Mui.Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Mui.Typography variant="body1">{documento?.mensagemErro}</Mui.Typography>
                  </Mui.Box>
                </Alert>
              )}
            </>
          )}
        </Mui.DialogContent>
        {!loading && (
          <Mui.DialogActions>
            <Mui.Box flex={1} display="flex" justifyContent="space-between">
              {edicaoDocumento && (
                <Mui.FormControlLabel
                  style={{ marginRight: 12 }}
                  control={
                    <Mui.Switch
                      checked={imprimirAposTransmitir}
                      onChange={() => setImprimirAposTransmitir(state => !state)}
                    />
                  }
                  label="Imprimir após transmissão"
                />
              )}
              <Mui.Box flex={1} display="flex" justifyContent="flex-end">
                {edicaoDocumento && (
                  <>
                    {documento.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_TRANSMITIDO && (
                      <Mui.Button
                        variant="contained"
                        className="button-success"
                        onClick={() => {
                          if (isDocumentoOffline) {
                            handleGerarOffline();
                          } else {
                            handleTransmitirDocumento();
                          }
                        }}
                      >
                        <MuiIcons.CheckCircle />
                        <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                          {isDocumentoOffline ? 'Gerar offline documento' : 'Transmitir documento'}
                        </Mui.Typography>
                      </Mui.Button>
                    )}

                    {documento?.documentoTransmissao ===
                      DOCUMENTO_TRANSMISSAO.AGUARDANDO_TRANSMISSAO && (
                      <Mui.Button
                        variant="contained"
                        className="button-success"
                        onClick={() => handleTransmitirDocumento()}
                      >
                        <MuiIcons.CheckCircle />
                        <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                          Transmitir documento
                        </Mui.Typography>
                      </Mui.Button>
                    )}
                  </>
                )}
              </Mui.Box>
              <Mui.Button style={{ marginLeft: 12 }} onClick={onClose}>
                <MuiIcons.Close />
                <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                  CANCELAR(ESC)
                </Mui.Typography>
              </Mui.Button>
            </Mui.Box>
          </Mui.DialogActions>
        )}
      </Mui.Dialog>
      {showModalEditarClienteDocumento.show && (
        <ModalEditarClienteDocumento
          showModal={showModalEditarClienteDocumento.show}
          data={showModalEditarClienteDocumento.data}
          onClose={() => {
            setShowModalEditarClienteDocumento({ show: false });
          }}
        />
      )}
      {showModalEditarItemDocumento.show && (
        <ModalEditarItemDocumento
          showModal={showModalEditarItemDocumento.show}
          data={showModalEditarItemDocumento.data}
          onClose={itemAtualizado => {
            setShowModalEditarItemDocumento({ show: false });

            setDocumento(state => {
              const itens = [...state?.itens];
              const itemIndex = itens?.findIndex(x => x.id === itemAtualizado.id);
              itens[itemIndex] = {
                ...itemAtualizado
              };

              return { ...state, itens };
            });

            handleAtualizarProduto(itemAtualizado);
          }}
        />
      )}

      {showModalJustificativaCancelamento?.show && (
        <ModalJustificativaCancelamento
          title={showModalJustificativaCancelamento?.title}
          showModal={showModalJustificativaCancelamento.show}
          data={showModalJustificativaCancelamento.data}
          onClose={() => {
            setShowModalJustificativaCancelamento({ show: false });
          }}
          callback={showModalJustificativaCancelamento?.callback}
        />
      )}
    </>
  );
};

export default ModalVisualizarDocumento;
