import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetState } from 'redux-localstore';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@mui/icons-material';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { Package as IconPackage } from 'react-feather';
import { ArrowBack as IconArrowBack } from '@material-ui/icons';

import history from '~/services/history';
import toast from '~/services/toast';
import { TIPO_DOCUMENTO, SHOW_SHORTCUTS } from '~/constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as appActions from '~/store/modules/app/app-actions';

// import InputPesquisaCliente from '~/pages/TelaVenda/components/InputPesquisaCliente';
import InputPesquisaProduto from '~/pages/TelaVenda/components/InputPesquisaProduto';

import ChangeTheme from '~/components/ChangeTheme';
import Account from '~/components/Account';
import Logo from '~/components/Logo';
import { useStyles } from './styles';
import './style.css';

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tipoDocumento, venda, itemVenda } = useSelector(state => state.pdv);
  const { queues } = useSelector(state => state.app);

  const handleBack = async () => {
    if (venda?.itens?.length > 0 || itemVenda) {
      const { isConfirmed } = await toast.confirm(
        'ATENÇÃO',
        'Existe uma venda iniciada, deseja sair da tela?',
        {
          icon: 'warning',
          confirmButtonText: 'Sair da tela',
          cancelButtonText: 'Cancelar'
        }
      );
      if (isConfirmed) {
        resetState();
        history.push('/');
      }
    } else {
      history.push('/');
    }
  };

  const handleChange = e => {
    const tipo = e.target.value;
    dispatch(pdvActions.alterarTipoDocumento(tipo));
  };

  const stylePedido = {
      width: 290, 
      height: 40, 
      color: 'white', 
      backgroundColor: 'rgba(2, 100, 247, 0.9)', 
      fontWeight: 'bold',
      textAlign: 'center'
  }

  const styleOrcamento = {
    width: 290, 
    height: 40, 
    color: 'white', 
    backgroundColor: 'orange', 
    fontWeight: 'bold',
    textAlign: 'center'
  }

  const styleDocSaida = {
    width: 290, 
    height: 40, 
    color: 'white', 
    backgroundColor: 'rgb(9, 168, 72)', 
    fontWeight: 'bold',
    textAlign: 'center'
  }

  let styleSelected;
  if(tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) styleSelected = styleDocSaida;
  if(tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) styleSelected = styleOrcamento;
  if(tipoDocumento === TIPO_DOCUMENTO.PEDIDO) styleSelected = stylePedido;


  return (
    <Mui.AppBar color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <IconButton onClick={handleBack}>
          <IconArrowBack />
        </IconButton>
        <Logo />

        <Divider className={classes.divider} />
        <Select
          variant="outlined"
          style={{...styleSelected}}
          //disabled={venda?.importado ?? false}
          disabled
          value={tipoDocumento}
          onChange={handleChange}
          id='selectInput'
          inputProps={{ IconComponent: () => null }}
        >
          <MenuItem value={TIPO_DOCUMENTO.ORCAMENTO}>Orçamento</MenuItem>
          <MenuItem value={TIPO_DOCUMENTO.PEDIDO}>Pedido</MenuItem>
          <MenuItem value={TIPO_DOCUMENTO.DOCUMENTOSAIDA}>Documento de saída</MenuItem>
        </Select>

        <Divider className={classes.divider} />

        <InputPesquisaProduto />
        <Box flexGrow={1} />
        <Box display="flex" flexDirection="row" ml={1}>
          <Mui.Button
            variant="outlined"
            onClick={() => dispatch(pdvActions.setShowModalConsultaProduto({ show: true }))}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              alignSelf="center"
              width={80}
            >
              <IconPackage />
              <Typography variant="caption" color="inherit" style={{ fontSize: 10 }}>
                Produto {SHOW_SHORTCUTS && '(SHIFT+F4)' || SHOW_SHORTCUTS && '(F4)'}
              </Typography>
            </Box>
          </Mui.Button>
          {tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO && (
            <Mui.Button
              style={{ marginLeft: 8 }}
              variant="outlined"
              onClick={() =>
                dispatch(
                  pdvActions.setShowModalImportarDocumento({
                    show: true,
                    title:
                      tipoDocumento === TIPO_DOCUMENTO.PEDIDO
                        ? 'Importar orçamento'
                        : 'Importar pedido',
                    type:
                      tipoDocumento === TIPO_DOCUMENTO.PEDIDO
                        ? TIPO_DOCUMENTO.ORCAMENTO
                        : TIPO_DOCUMENTO.PEDIDO
                  })
                )
              }
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                alignSelf="center"
                width={80}
              >
                <MuiIcons.ImportExport />
                <Typography variant="caption" color="inherit" style={{ fontSize: 10 }}>
                  Importar {SHOW_SHORTCUTS && '(F11)'}
                </Typography>
              </Box>
            </Mui.Button>
          )}
          {tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && (
            <Mui.Button
              style={{ marginLeft: 8 }}
              variant="outlined"
              onClick={() => {
                dispatch(
                  pdvActions.setShowModalImportarDocumento({
                    show: true,
                    title: 'Importar/Copiar documento',
                    type: TIPO_DOCUMENTO.DOCUMENTOSAIDA
                  })
                );
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                alignSelf="center"
                width={80}
              >
                <MuiIcons.FileCopy />
                <Typography variant="caption" color="inherit" style={{ fontSize: 10 }}>
                  Copiar
                </Typography>
              </Box>
            </Mui.Button>
          )}
        </Box>
        <Divider className={classes.divider} />

        <Box display="flex" flexDirection="row">
          <ChangeTheme />
          {/* <Mui.Tooltip title="Notificações">
            <Mui.IconButton>
              <Mui.Badge badgeContent={99} color="error">
                <MuiIcons.Notifications />
              </Mui.Badge>
            </Mui.IconButton>
          </Mui.Tooltip> */}
          <Account showPreferences={true} showHelp={true} />
        </Box>
      </Toolbar>
    </Mui.AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
