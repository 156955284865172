import axios from 'axios';
import { all, call, put, takeLatest, delay, select } from '@redux-saga/core/effects';

import api from '~/services/api';
import { API_NODE_URL, API_LOCAL_URL, APPLICATION_MODE, STORAGE_URL } from '~/constants';
import { SEARCH_REQUEST } from './produto-constants';
import * as actions from './produto-actions';

let cancel;
const CancelToken = axios.CancelToken;

export function* pesquisaProduto({ queryText, queryType, callback }) {
  try {
    yield delay(200);
    yield put(actions.setLoading(true));

    const applicationMode = yield select(state => state?.applicationMode);
    if (cancel != undefined) cancel();
    const cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    });

    if (queryText?.length < 3) {
      yield put(actions.searchSuccess([]));
      return;
    }

    const { data } =
      applicationMode === APPLICATION_MODE.ONLINE
        ? yield call(
            api.get,
            `${API_NODE_URL}/faturamento/documento-saida/produto-grade-venda?produto=${queryText?.toUpperCase()}`,
            {
              cancelToken
            }
          )
        : yield call(
            api.get,
            `${API_LOCAL_URL}/estoque/produto/?queryString=${queryText?.toUpperCase()}`,
            {
              cancelToken
            }
          );

    const items = [
      ...data?.map(item => ({
        ...item,
        produtoEstProdutoId: item.id,
        estProdutoGrupo: { ...item.estProdutoGrupo, prefixoProduto: 'EX' },
        itemQuantidade: 1,
        itemValorUnitario: item?.estProdutoImposto[0]?.precoVenda,
        itemEstoque: item?.saldoEstoque?.estoqueReal + item?.saldoEstoque?.estoqueReservado * -1,
        precoVenda: item?.estProdutoImposto[0]?.precoVenda,
        imageUrl: item?.foto ? `${STORAGE_URL}produtos/${item?.foto}` : null
      }))
    ];

    if (items?.length === 1 && callback) {
      yield put(actions.searchSuccess([]));
      callback(items[0]);
      return;
    }

    yield put(actions.searchSuccess([...items]));
  } catch (error) {
    yield put(actions.setLoading(false));
  }
}

export default all([takeLatest(SEARCH_REQUEST, pesquisaProduto)]);
