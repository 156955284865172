import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { SHOW_SHORTCUTS, TIPO_DOCUMENTO } from '~/constants';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import PedidoTable from '../../components/PedidoTable';
import PedidoTimeline from '../../components/PedidoTimeline';
import ModalImportarDocumento from '~/pages/TelaVenda/modal/ModalImportarDocumento';
import { PedidoOnlineService } from '~/store/modules/pedido/pedido-service';

const Pedido = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const anchorRef = useRef();
  const [searchTerm, setSearchTerm] = useState('');

  const isOnline = useSelector(getApplicationIsOnline())
  const { terminal } = useSelector(state => state.app);
  const {
    loading,
    filtroData,
    filtroDataOptions,
    status,
    statusOptions,
    totalizadores,
    showTimelinePedido,
    page,
    rowsPerPage
  } = useSelector(state => state.monitor);
  const [showModalImportarDocumento, setShowModalImportarDocumento] = useState({
    show: false
  });

  const handleTabsChange = (event, value) => {
    dispatch(monitorActions.setStatus(value));
  };

  const handleSearch = async event => {
    try {
      dispatch(monitorActions.setLoading(true));
    let verificaNumero = false;
      const type = isNaN(parseFloat(searchTerm));
      if(!type) verificaNumero = true;
      let filter = verificaNumero 
      ? [`(numero eq ${searchTerm} or contains(clienteNome,'${searchTerm}') or contains(fatVendedor/nome,'${searchTerm}')) and fisOperacao/documentoFinalidade ne 4`] 
      : [`(contains(clienteNome,'${searchTerm}') or contains(fatVendedor/nome,'${searchTerm}')) and fisOperacao/documentoFinalidade ne 4`];
      const service = new PedidoOnlineService();
      const { data } = await service.getAllPedidos({
        offset: 0,
        limit: 0,
        filter
      });
      dispatch(monitorActions.loadPedidosSearchSuccess({
        showTimelinePedido: false,
        pedidoHistoricos: [],
        pedidos: data.items,
        quantidadeDocumentos: data.items.length,
        valorTotalDocumentos: data.items.reduce((total, item) => total + item.valorTotal, 0)
      }))
    } catch (err) {
      throw err;
    } finally {
      dispatch(monitorActions.setLoading(false));
    }
  };

  useEffect(() => {
    if(!searchTerm) {
      dispatch(monitorActions.loadPedidosRequest());
    }
  }, [status, page, filtroData, rowsPerPage, searchTerm]);

  useEffect(() => {
    dispatch(monitorActions.setStatus('abertos'));
    return () => {
      dispatch(monitorActions.limparDados());
    };
  }, [isOnline]);

  useEffect(() => {
    if(searchTerm) {
      const delayDebounceFn = setTimeout(() => {
        handleSearch()
      }, 1000)
  
      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchTerm])

  return (
    <>
      <Mui.Grid container>
        <Mui.Grid item md={12}>
          <Mui.Box p={2} display="flex" justifyContent="space-between">
            <Mui.Box>
              <Mui.Button
                variant="contained"
                color="primary"
                disabled={terminal === null}
                style={{ height: 44 }}
                onClick={() => {
                  history.push('/pdv', {
                    tipoDocumento: TIPO_DOCUMENTO.PEDIDO
                  });
                }}
              >
                <MuiIcons.AddCircle />

                <Mui.Box component="span" ml={2}>
                  Novo Pedido {SHOW_SHORTCUTS && '(CTRL+P)'}
                </Mui.Box>
              </Mui.Button>
              <Mui.Button
                variant="contained"
                disabled={terminal === null}
                style={{ marginLeft: 12, height: 44 }}
                onClick={() => setShowModalImportarDocumento({ show: true })}
              >
                <MuiIcons.ImportExport /> <span style={{ marginLeft: 12 }}>Importar</span>
              </Mui.Button>
            </Mui.Box>

            <Mui.Box display="flex" alignContent="center">
              <Mui.TextField
                disabled={loading}
                InputProps={{
                  style: {
                    height: 44
                  },
                  startAdornment: (
                    <Mui.InputAdornment position="start">
                      <Mui.SvgIcon fontSize="small" color="action">
                        <MuiIcons.Search />
                      </Mui.SvgIcon>
                    </Mui.InputAdornment>
                  )
                }}
                placeholder="Pesquisar documento..."
                variant="outlined"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              <Mui.ButtonGroup
                variant="contained"
                color="default"
                ref={anchorRef}
                aria-label="split button"
                style={{ marginLeft: 16 }}
              >
                {filtroDataOptions.map((f, index) => (
                  <Mui.Button
                    key={`pedido-filtro-data-${index}`}
                    color={f.filtro === filtroData ? 'primary' : 'default'}
                    onClick={() => dispatch(monitorActions.setFiltroData(f.filtro))}
                  >
                    {f.titulo}
                  </Mui.Button>
                ))}
              </Mui.ButtonGroup>
            </Mui.Box>
          </Mui.Box>
          <Mui.Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            value={status}
            variant="scrollable"
          >
            {statusOptions
              ?.map(s => ({ ...s, count: totalizadores ? totalizadores[s?.value] ?? 0 : 0 }))
              ?.map(tab => (
                <Mui.Tab
                  key={tab?.value}
                  value={tab?.value}
                  label={
                    <Mui.Box>
                      <Mui.Badge badgeContent={tab?.count} color="primary" />
                      <Mui.Typography>{tab?.label}</Mui.Typography>
                    </Mui.Box>
                  }
                />
              ))}
          </Mui.Tabs>

          <Mui.Divider />

          <PedidoTable />
        </Mui.Grid>
      </Mui.Grid>
      {showTimelinePedido && <PedidoTimeline />}
      {showModalImportarDocumento.show && (
        <ModalImportarDocumento
          type={TIPO_DOCUMENTO.ORCAMENTO}
          show={showModalImportarDocumento.show}
          onClose={() => setShowModalImportarDocumento({ show: false })}
          callback={data => {
            history.push('/pdv', data);
          }}
        />
      )}
    </>
  );
};

export default Pedido;
