import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Search as IconSearch, People as IconPeople, House as IconHouse, HighlightOff as IconHighlightOff } from '@material-ui/icons';
import ModalHistoricoPedido from '../../modal/ModalHistoricoPedido';

import { SHOW_SHORTCUTS, TIPO_DOCUMENTO } from '~/constants';
import { formatCurrency } from '~/utils/format';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import ModalIdentificarCliente from '../../modal/ModalIdentificarCliente';
import { Hidden } from '@material-ui/core';
import toast from '~/services/toast';

const CardCliente = React.memo(({ wrap }) => {
  const dispatch = useDispatch();
  const { venda, showModalLoadingMensagem, tipoDocumento } = useSelector(state => state.pdv);
  const [showAvisoCliente, setshowAvisoCliente] = useState(true);
  const [showHistoricoCliente, setShowHistoricoCliente] = useState(false);

  const handleCopiarEndereco = () => {
    const entrega = {
      id: venda?.intClienteEnderecoId,
      logradouro: venda?.enderecoLogradouro,
      logradouroNumero: venda?.enderecoLogradouroNumero,
      bairro: venda?.enderecoBairro,
      intCidade: venda?.enderecoIntCidade,
      intCidadeId: venda?.enderecoIntCidade?.id,
      uf: venda?.enderecoIntCidade?.uf,
      cep: venda?.enderecoCep,
      entregaIntClienteEnderecoId: venda?.intClienteEnderecoId
    };

    dispatch(pdvActions.selecionarEnderecoEntrega(entrega));
  };

  const handleConsultarCliente = () => {
    dispatch(pdvActions.setShowModalConsultaCliente({ show: true }));
  };

  const handleRemoverCliente = () => {
    dispatch(pdvActions.removeCliente());
  };

  const handleIdentificarCliente = () => {
    const key = "SIAFPLUS_SETTINGS";
    let valueSessionStorage = sessionStorage.getItem(key)
    if (valueSessionStorage === null) {
      const theme = {theme:"DEFAULT"};
      sessionStorage.setItem(key, JSON.stringify(theme));
    }
    dispatch(pdvActions.setShowModalIdentificarCliente({ show: true, desativarModalsSubsequentes: true }));
  };

  const handleEnderecoCliente = type => {
    if (
      (type === 'entrega' && !venda?.entregaIntClienteEnderecoId) ||
      (type === 'endereco' && !venda?.intClienteEnderecoId)
    ) {
      let _endereco = null;
      if (type === 'endereco') {
        _endereco = {
          logradouro: venda?.enderecoLogradouro,
          logradouroNumero: venda?.enderecoLogradouroNumero,
          bairro: venda?.enderecoBairro,
          cep: venda?.enderecoCep,
          intCidade: venda?.enderecoIntCidade,
          intCidadeId: venda?.enderecoIntCidade?.id,
          uf: venda?.enderecoUf,
          referencia: venda?.enderecoComplemento
        };
      } else {
        _endereco = {
          logradouro: venda?.entregaLogradouro,
          logradouroNumero: venda?.entregaLogradouroNumero,
          bairro: venda?.entregaBairro,
          cep: venda?.entregaCep,
          intCidade: venda?.entregaIntCidade,
          intCidadeId: venda?.entregaIntCidade?.id,
          uf: venda?.entregaUf
        };
      }

      dispatch(
        pdvActions.setShowModalEnderecoCliente({
          show: true,
          type,
          cadastroEndereco: false,
          data: { ..._endereco }
        })
      );
    } else {
      dispatch(
        pdvActions.setShowModalEnderecoCliente({
          show: true,
          type
        })
      );
    }
  };

  return (
    <>
      <Paper>
        <Mui.Box p={2}>
          <Mui.Grid container>
            <Mui.Grid container item xs={12} md={12}>
              <Mui.Grid container item xs={12} md={8}>
                <Mui.Grid container item xs={10} md={11} style={{ marginRight: -54 }}>
                  <Mui.Grid container item xs={12} md={12}>
                    <Mui.Grid item md={8}>
                      <Typography variant="caption">Cliente {SHOW_SHORTCUTS && '(F2)'}</Typography>
                      {showModalLoadingMensagem?.show ? (
                        <Skeleton variant="text" width={240} />
                      ) : (
                        <Typography variant="body2">
                          <b>{venda?.clienteNome ?? 'INFORMAR CLIENTE'}</b>
                        </Typography>
                      )}
                      {showModalLoadingMensagem?.show ? (
                        <Skeleton variant="text" width={180} />
                      ) : (
                        <Typography variant="body2">
                          <div style={{display: 'flex', flexWrap: 'wrap'}}>
                          <div> <b>CPF/CNPJ:</b> {venda?.clienteCpfCnpj ?? '000.000.000-00'}</div>
                          {(!venda?.clienteId && !venda?.intCliente?.id) && venda?.clienteNome && <div style={{ backgroundColor: 'red', borderRadius: '12px', width: 'fit-content', padding: '2px 5px', marginLeft: '10px', fontSize: '11px' }}>Cliente nao cadastrado!</div>}
                          </div>
                          
                        </Typography>
                      )}
                    </Mui.Grid>
                    <Mui.Grid item md={4}>
                      <Typography variant="caption">Telefone</Typography>
                      {showModalLoadingMensagem?.show ? (
                        <Skeleton variant="text" width={120} />
                      ) : (
                        <Typography variant="body2">
                          {venda?.clienteTelefone?.length ? venda?.clienteTelefone : '-'}
                        </Typography>
                      )}
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid item md={12}>
                    <Mui.Box mt={1}>
                      <Typography variant="caption">
                        Crédito disponível:{' '}
                        <b
                          style={{
                            color:
                              venda?.creditoCliente?.saldoCreditoDisponivel >= 0 ? 'green' : 'red'
                          }}
                        >
                          {formatCurrency(venda?.creditoCliente?.saldoCreditoDisponivel ?? 0)}
                        </b>
                      </Typography>
                      {' | '}
                      <Typography variant="caption">
                        Limite disponível:{' '}
                        <b>{formatCurrency(venda?.creditoCliente?.saldoLimiteDisponivel ?? 0)}
                        {
                          venda?.creditoCliente?.clienteInadimplente && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO  && (<Tooltip title="Procure o setor de crédito"><MuiIcons.WarningSharp style={{color: '#fc9712', marginLeft: '10px', marginTop: '-6px', position: 'absolute'}} /></Tooltip>)
                        }
                        </b>
                      </Typography>

                        {
                          venda?.clienteNome && <div>
                            <Mui.Button
                              variant="contained"
                              onClick={
                                () => {
                                  if(!venda?.intCliente?.id)
                                    return toast.warning('Cliente precisa ser cadastrado para buscar histórico!');
                                  else
                                    setShowHistoricoCliente(true);
                                }
                              }
                              color="primary"
                              style={{color: 'white', fontSize: '12px'}}
                            >
                              <MuiIcons.PersonSharp />
                              <span>
                                Histórico
                              </span>
                            </Mui.Button>
                          </div>
                        }

                    </Mui.Box>
                  </Mui.Grid>
                </Mui.Grid>

                <Mui.Box mr={2} justifyContent="center" alignItems="center">
                  <div>
                    <Fab
                      size="small"
                      aria-label="Consulta cliente"
                      title="Consultar cliente"
                      onClick={() => handleConsultarCliente()}
                    >
                      <IconSearch />
                    </Fab>
                  </div>

                  { venda?.clienteNome &&
                  <div style={{marginTop: '15px'}}>
                    <Fab
                      size="small"
                      aria-label="Remover cliente"
                      title="Remover cliente"
                      onClick={() => handleRemoverCliente()}
                    >
                      <IconHighlightOff />
                    </Fab>
                  </div>
                  }
                  
                </Mui.Box>
                
              </Mui.Grid>

              <Hidden mdDown>
                <Divider orientation="vertical" />
              </Hidden>
              <Mui.Grid item xs={12} md={4} style={{ display: 'flex', marginLeft: -1 }}>
                <Mui.Box
                  mx={2}
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="caption">Tabela de venda</Typography>

                  {showModalLoadingMensagem?.show ? (
                    <Skeleton variant="text" width={180} />
                  ) : (
                    <Typography variant="body2">
                      <b>
                        {venda?.fatTabelavenda ? venda?.fatTabelavenda?.descricao : 'NÃO VINCULADA'}
                      </b>
                    </Typography>
                  )}
                </Mui.Box>
              </Mui.Grid>
            </Mui.Grid>

            {venda?.intCliente?.mensagemVenda && showAvisoCliente && (
              <Mui.Grid container item md={12}>
                <Mui.Box flex={1} mt={1}>
                  <Alert severity="info" onClose={() => setshowAvisoCliente(false)}>
                    <AlertTitle>AVISO</AlertTitle>
                    <Typography variant="body2">
                      <b>{venda?.intCliente?.mensagemVenda}</b>
                    </Typography>
                  </Alert>
                </Mui.Box>
              </Mui.Grid>
            )}
          </Mui.Grid>
        </Mui.Box>
        <>
          <Divider style={{ margin: '0 8px' }} />
          <Mui.Box p={2}>
            <Mui.Grid container>
              <Mui.Grid item md={6}>
                <Mui.Box pb={2}>
                  <Typography variant="caption">Endereço</Typography>
                  {venda?.enderecoLogradouro && (
                    <>
                      <Typography variant="body2">
                        {venda?.enderecoLogradouro},{venda?.enderecoLogradouroNumero}
                        {' - '}
                        {venda?.enderecoBairro}
                      </Typography>

                      <Typography variant="body2">
                      {venda?.enderecoIntCidade?.cidade}/{venda?.enderecoIntCidade?.uf} -{' '}
                        {venda?.enderecoCep}
                      </Typography>
                    </>
                  )}
                </Mui.Box>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEnderecoCliente('endereco')}
                >
                  <MuiIcons.House />
                  <span style={{ paddingLeft: 8 }}>
                    {venda?.enderecoLogradouro ? 'Alterar' : 'Adicionar'}
                  </span>
                </Mui.Button>
              </Mui.Grid>
              <Divider orientation="vertical" />
              <Mui.Grid item md={6} style={{ marginLeft: -1 }}>
                <Mui.Box pb={2}>
                  <Typography variant="caption">Endereço de entrega</Typography>
                  {venda?.entregaLogradouro && (
                    <>
                      <Typography variant="body2">
                        {venda?.entregaLogradouro},{venda?.entregaLogradouroNumero}
                        {' - '}
                        {venda?.entregaBairro}
                      </Typography>

                      <Typography variant="body2">
                        {venda?.entregaIntCidade?.cidade}/{venda?.entregaIntCidade?.uf} -{' '}
                        {venda?.entregaCep}
                      </Typography>
                    </>
                  )}
                </Mui.Box>
                <Mui.Box flex flexDirection={'column'}>
                  {venda?.enderecoLogradouro && (
                    <Mui.Button variant="contained" onClick={() => handleCopiarEndereco()}>
                      <MuiIcons.FileCopy />
                      <span style={{ paddingLeft: 8 }}> Copiar end.</span>
                    </Mui.Button>
                  )}

                  <Mui.Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEnderecoCliente('entrega')}
                    style={{ marginLeft: 8 }}
                  >
                    <MuiIcons.House />
                    <span style={{ paddingLeft: 8 }}>
                      {venda?.entregaLogradouro ? 'Alterar' : 'Adicionar'}
                    </span>
                  </Mui.Button>
                </Mui.Box>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Box>
        </>
        <Divider style={{ margin: '0 8px' }} />
        <Mui.Box p={2} justifyContent="center" textAlign="center">
          <Mui.Button
            variant="contained"
            style={{ marginRight: 16 }}
            onClick={() => dispatch(pdvActions.setShowModalInformarComplementar({ show: true }))}
          >
            <MuiIcons.Notes /> <Mui.Box ml={2}>Informação complementar</Mui.Box>
          </Mui.Button>

          <Mui.Button
            variant="contained"
            color="primary"
            onClick={() => handleIdentificarCliente()}
          >
            <MuiIcons.People />
            <span style={{ paddingLeft: 8 }}>
              {' Identificar cliente'} {SHOW_SHORTCUTS && '(CTRL+I)'}
            </span>
          </Mui.Button>
        </Mui.Box>
        {
          showHistoricoCliente &&
          <ModalHistoricoPedido 
            id={venda?.intCliente?.id} 
            clienteSearched={venda?.intCliente}
            open={showHistoricoCliente} 
            onClose={() => {
              setShowHistoricoCliente(false);
            }} />
        }
      </Paper>
    </>
  );
});

export default CardCliente;
