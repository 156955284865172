import React from 'react';
import styled from 'styled-components';
import * as colors from '@material-ui/core/colors';

import PropTypes from 'prop-types';

export const LabelRoot = styled.span`
  align-items: center;
  background-color: ${props => colors[props?.color][100]};
  border-radius: ${props => props.theme.shape.borderRadius};
  color: ${props => colors[props?.color][900]};
  cursor: default;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.pxToRem(12)};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  justify-content: center;
  letter-spacing: 0.5;
  min-width: 20;
  padding-bottom: ${props => props.theme.spacing(0.5)}px;
  padding-left: ${props => props.theme.spacing(1)}px;
  padding-right: ${props => props.theme.spacing(1)}px;
  padding-top: ${props => props.theme.spacing(0.5)}px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Label = props => {
  const { color = 'blue', children, ...other } = props;

  return (
    <LabelRoot color={color} {...other}>
      {children}
    </LabelRoot>
  );
};

Label.propTypes = {
  children: PropTypes.node
  // color: PropTypes.oneOf([])
};

export default Label;
