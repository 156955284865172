import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';

import api from '~/services/api';
import { APP_ERROR, API_NODE_URL, API_BASE_URL } from '~/constants';

import { Header } from './styles';
import { formatNumber } from '~/utils/format';

const ModalProdutoSimilar = ({ open, data: { produto }, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [columns] = useState([
    { id: 'armazem', label: 'Armazem' },
    { id: 'produtoCodigo', label: 'Codigo' },
    { id: 'produtoDescricao', label: 'Produto similar' },
    { id: 'unidade', label: 'Unidade' },
    { id: 'unidadeCompra', label: 'Unidade compra' },
    { id: 'valorUnitario', alignment: 'right', label: 'Valor unitario', width: 200 },
    { id: 'estoque', alignment: 'right', label: 'Estoque', width: 200 }
  ]);
  const [similares, setSimilares] = useState([]);

  const handleSubmit = item => {
    if (onClose) {
      onClose(item);
    }
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `${API_BASE_URL}/v1/faturamento/subrotina/produtosimilar?$select=${[
          'descricaoCompleta',
          'descricao',
          'codigo',
          'estoque',
          'unidade',
          'unidadeCompra',
          'ean',
          'lote',
          'fracionavel',
          'codigoAuxiliar',
          'descricaoGrupo',
          'estProdutoId',
          'estProdutoSimilarId',
          'valorUnitario',
          'estArmazemId'
        ].join(',')}&$expand=${[
          'estProduto($expand=estProdutoGrupo)',
          'estProdutoGrade',
          'estProdutoImposto($expand=estoqueEstArmazem,fisModelotributacao($expand=fisModelotributacaoIcms))',
          'estArmazem'
        ].join(',')}&$filter=estProdutoId eq ${produto?.id}`
      );

      setSimilares(data ? [...data] : []);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!produto) {
      return;
    }
    loadData();
  }, [produto]);

  return (
    <>
      <Mui.Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'lg'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box style={{ minWidth: 180 }}>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Produto similar
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>

          <Mui.Grid item md={12}>
            <Header>
              <Mui.Typography variant="caption">Produto</Mui.Typography>
              <Mui.Typography variant="h3">
                {produto?.codigo} {' - '}
                {produto?.descricao}
              </Mui.Typography>
            </Header>
          </Mui.Grid>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers>
          <>
            {loading ? (
              <Mui.Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={8}
              >
                <Mui.CircularProgress />
                <Mui.Typography>Caregando...</Mui.Typography>
              </Mui.Box>
            ) : (
              <>
                <Mui.Table>
                  <Mui.TableHead>
                    {columns.map((column, index) => (
                      <Mui.TableCell
                        key={`column-${index}`}
                        align={column?.alignment ?? 'left'}
                        style={{ width: column?.width ? `${column?.width}px` : 'auto' }}
                      >
                        {column?.label}
                      </Mui.TableCell>
                    ))}
                    <Mui.TableCell align="center" style={{ width: '90px' }}>
                      #
                    </Mui.TableCell>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {similares?.map((item, index) => (
                      <Mui.TableRow key={index}>
                        <Mui.TableCell>
                          <Mui.Typography>{item?.estArmazem?.descricao}</Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.Typography variant="h6">{item?.codigo}</Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.Typography variant="h6" noWrap>
                            {item?.descricao}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.Typography variant="h6">{item?.unidade}</Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell align="right">
                          <Mui.Typography variant="h6">{item?.unidadeCompra}</Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell align="right">
                          <Mui.Typography variant="h6">
                            {formatNumber(item?.valorUnitario)}
                          </Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell align="right">
                          <Mui.Typography variant="h6">{item?.estoque}</Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.Button
                            variant="contained"
                            className="button-success"
                            onClick={() => handleSubmit(item)}
                          >
                            <MuiIcons.ArrowRight />
                          </Mui.Button>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ))}
                  </Mui.TableBody>
                </Mui.Table>
              </>
            )}
          </>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Box p={1}>
            <Mui.Button size="large" onClick={onClose} style={{ marginLeft: 16 }}>
              Cancelar
            </Mui.Button>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ModalProdutoSimilar;
