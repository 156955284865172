import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Box, Button, Drawer, Grid, Hidden, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { red, orange } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

import IconCancelRounded from '@material-ui/icons/CancelRounded';
import IconCheckCircle from '@material-ui/icons/CheckCircle';

import toast from '~/services/toast';
import { PedidoOnlineService } from '~/store/modules/pedido/pedido-service';
import { APP_ERROR } from '~/constants'

import ModalConfirmacao from '~/pages/PainelVenda/modal/ModalConfirmacao';

const ButtonCancelar = withStyles(() => ({
  root: {
    color: orange[500]
  }
}))(Button);

const ButtonFechar = withStyles(() => ({
  root: {
    color: red[500]
  }
}))(Button);

const OrderListBulkActions = ({ open, selected, ...other }) => {
  const dispatch = useDispatch();
  const selectedRows = useSelector(state => state.monitor.selectedRows);

  const [showModal, setShowModal] = useState({
    show: false
  });

  const handleCancelarPedidos = async tokenLiberacaoRotina => {
    try {
      setShowModal({ open: false });
      const pedidoService = new PedidoOnlineService();
      await pedidoService.cancelarPedidos(selectedRows, tokenLiberacaoRotina);
      toast.success('Operação realizada com sucesso!');
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const handleFecharPedidos = async tokenLiberacaoRotina => {
    try {
      setShowModal({ open: false });

      const pedidoService = new PedidoOnlineService();
      await pedidoService.fecharPedidos(selectedRows, tokenLiberacaoRotina);
      toast.success('Operação realizada com sucesso!');
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  return (
    <>
      <Drawer anchor="bottom" open={open} PaperProps={{ elevation: 1 }} variant="persistent">
        <Box p={2} {...other}>
          <Grid alignItems="center" container spacing={2}>
            <Hidden smDown>
              <Grid item md={3}>
                <Typography color="textSecondary" variant="subtitle1">
                  {selected.length} selecionados
                </Typography>
              </Grid>
            </Hidden>
            <Grid item md={6} xs={12}>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  '& > * + *': {
                    ml: 2
                  }
                }}
              >
                <ButtonCancelar
                  onClick={() => {
                    setShowModal({
                      open: true,
                      rotina: 'F060PED',
                      title: 'Deseja cancelar os pedidos selecionados?',
                      body:
                        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
                      options: {
                        icon: 'warning',
                        confirmButtonText: 'Confirma cancelamento do(s) pedido(s)',
                        cancelButtonText: 'Voltar'
                      },
                      onClose: () => {
                        setShowModal({ open: false });
                      },
                      onCloseCallback: handleCancelarPedidos
                    });
                  }}
                  startIcon={<IconCancelRounded fontSize="small" />}
                  variant="text"
                >
                  Cancelar selecionado(s)
                </ButtonCancelar>

                <ButtonFechar
                  onClick={() =>
                    setShowModal({
                      open: true,
                      rotina: 'F070PED',
                      title: 'Deseja encerar os pedidos selecionados?',
                      body:
                        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
                      options: {
                        icon: 'danger',
                        confirmButtonText: 'Confirma encerramento do(s) pedido(s)',
                        cancelButtonText: 'Voltar'
                      },
                      onClose: () => {
                        setShowModal({ open: false });
                      },
                      onCloseCallback: handleFecharPedidos
                    })
                  }
                  startIcon={<IconCheckCircle fontSize="small" />}
                  variant="text"
                >
                  Fechar selecionados
                </ButtonFechar>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      {showModal.open && <ModalConfirmacao {...showModal} />}
    </>
  );
};

OrderListBulkActions.propTypes = {
  onDelete: PropTypes.func,
  onMarkPaid: PropTypes.func,
  onMarkUnpaid: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.array.isRequired
};

OrderListBulkActions.defaultProps = {
  open: false
};

export default OrderListBulkActions;
