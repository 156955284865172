import React, { useEffect } from 'react';
import Lottie from 'react-lottie';
import { useSelector, useDispatch } from 'react-redux';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import * as appActions from '~/store/modules/app/app-actions';

import animationDownload from '~/assets/animations/download.json';
import { APP_VERSION } from '~/constants';

const useStyles = makeStyles(theme => ({
  logo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'end',
    marginRight: theme.spacing(2),
    fontSize: 24,
    '& > span': {
      fontWeight: 300
    }
  }
}));

const Logo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { showProgressSync, showModalSync, mensagemSync } = useSelector(state => state.app);

  // useEffect(() => {
  //   if (mensagemSync && !  ) {
  //     dispatch(
  //       appActions.enqueueSnackbar({
  //         message: mensagemSync,
  //         preventDuplicate: false,
  //         options: {
  //           key: mensagemSync
  //         }
  //       })
  //     );
  //   }
  // }, [mensagemSync, showModalSync]);

  return (
    <h1 className={classes.logo}>
      Gol<span>ERP</span>  PDV
      {/* <Tooltip title={'Download'}>
        <IconButton
          onClick={() => {
            dispatch(appActions.downloadData());
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationDownload,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
              }
            }}
            height={24}
            width={24}
            isStopped={false}
            isPaused={false}
          />
        </IconButton>
      </Tooltip> */}
      <small style={{fontSize: 12}}>{APP_VERSION}</small>
    </h1>
  );
};

export default Logo;
