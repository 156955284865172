import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { green, blue } from '@material-ui/core/colors';
import { useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import ModalImprimirOrcamento from '~/pages/TelaVenda/modal/ModalImprimirOrcamento';

import api from '~/services/api';
import history from '~/services/history';
import toast from '~/services/toast';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import { formatCurrency, formatDate } from '~/utils/format';
import {
  API_BASE_URL,
  API_LOCAL_URL,
  TIPO_DOCUMENTO,
  APP_ERROR,
  ORCAMENTO_SITUACAO
} from '~/constants';

import Label from '~/components/Label';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  badgeOffline: {
    backgroundColor: '#ccc',
    color: 'white'
  },
  badgeOnline: {
    backgroundColor: '#4caf50',
    color: 'white'
  },
  buttonPrimary: {
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700]
    }
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const getSituacaoLabel = situacao => {
  const colors = ['red', 'green'];
  return <Label color={colors[situacao] ?? 'grey'}>{ORCAMENTO_SITUACAO[situacao]}</Label>;
};

const OrcamentoTableRow = React.memo(({ data, isSelected, onRefresh, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const { sendingRows } = useSelector(state => state.monitor);
  const { terminal } = useSelector(state => state.app);
  const isOnline = useSelector(getApplicationIsOnline());
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModalImpressao, setShowModalImpressao] = useState(false);

  const handleEnviarOrcamento = () => {
    dispatch(monitorActions.sendOrcamentoRequest(data));
  };

  const handleImprimirOrcamento = async data => {
    const toastInstance = toast.loading(`Carregando, aguarde...`);
    try {
      const { data: orcamento } = await api.get(
        data?.isOffline
          ? `${API_LOCAL_URL}/faturamento/orcamento/${data?._id}`
          : `${API_BASE_URL}/v1/faturamento/orcamento/${data?.id}`
      );

      dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.ORCAMENTO, orcamento));
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }
  };

  const handleEditarOrcamento = async data => {
    const toastInstance = toast.loading(`Carregando, aguarde...`);
    if(data.situacao === 0) return toast.warning('Não é possível editar com situação fechada!');
    try {
      const { data: orcamento } = await api.get(
        data?.isOffline
          ? `${API_LOCAL_URL}/faturamento/orcamento/${data?._id}`
          : `${API_BASE_URL}/v1/faturamento/orcamento/${data?.id}`
      );

      history.push('/pdv', {
        tipoDocumento: TIPO_DOCUMENTO.ORCAMENTO,
        venda: { ...orcamento, editar: true }
      });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      toastInstance.close();
    }
  };

  const handleDeletarOrcamento = async data => {
    const { isConfirmed } = await toast.confirm(
      'Deseja excluir este registro?',
      'Certifique-se de que realmente deseja fazer isso, pois esta ação é irreversível.',
      {
        icon: 'danger',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }
    );
    if (isConfirmed) {
      const toastInstance = toast.loading(`Aguarde...`);
      try {
        await api.delete(
          data?.isOffline
            ? `${API_LOCAL_URL}/faturamento/orcamento/${data?._id}`
            : `${API_BASE_URL}/v1/faturamento/orcamento/${data?.id}`
        );
        dispatch(monitorActions.loadOrcamentosRequest());
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
      } finally {
        toastInstance.close();
      }
    }
  };

  const handleImportarOrcamento = async data => {
    dispatch(pdvActions.importarOrcamento([data], location?.pathname, () => {}));
  };

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
    <TableRow hover {...props} height={48}>
      <Mui.TableCell align="center">
        {data?.isOffline && !data?.id ? (
          <Mui.Tooltip title="Offline">
            <Mui.Badge
              classes={{ badge: classes.badgeOffline }}
              overlap="circular"
              badgeContent={''}
            />
          </Mui.Tooltip>
        ) : (
          <Mui.Tooltip title="Online">
            <Mui.Badge
              classes={{ badge: classes.badgeOnline }}
              overlap="circular"
              badgeContent={''}
            />
          </Mui.Tooltip>
        )}
      </Mui.TableCell>
      <TableCell>
        <Typography variant="h6">
          {data?.serie}/{data?.numero}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h6">{formatDate(new Date(data?.dataEmissao))}</Typography>
      </TableCell>
      <Mui.TableCell>{getSituacaoLabel(data?.situacao)}</Mui.TableCell>
      <TableCell>{data?.fatCondicaopagamento?.descricao ?? '-'}</TableCell>
      <TableCell>{data?.finMeiopagamento?.descricao ?? '-'}</TableCell>

      <TableCell>
        <Typography variant="h6">{data?.clienteNome ?? '-'}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h6">{data?.fatVendedor?.nome}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="h6">{formatCurrency(data?.valorTotal)}</Typography>
      </TableCell>

      <Mui.TableCell style={{ width: 140 }}>
        <Mui.Box display="flex" flexDirection="row" justifyContent="flex-end">
          {data?.isOffline && !data?.id && isOnline && (
            <div className={classes.root}>
              <div className={classes.wrapper}>
                <IconButton size="small" onClick={() => handleEnviarOrcamento()}>
                  <>
                    {sendingRows && sendingRows[data?._id]?.loading ? (
                      <>
                        <MuiIcons.CloudUpload />
                        <CircularProgress size={38} className={classes.fabProgress} />
                      </>
                    ) : (
                      <MuiIcons.CloudUpload />
                    )}
                  </>
                </IconButton>
              </div>
            </div>
          )}

          <Mui.IconButton onClick={() => handleImprimirOrcamento(data)}>
            <MuiIcons.Print fontSize="small" />
          </Mui.IconButton>

          <Mui.Tooltip title="Mais ações">
            <Mui.IconButton
              disabled={terminal === null}
              aria-controls="simple-menu"
              aria-haspopup="true"
              aria-label={'Mais ações'}
              onClick={handleOpenMenu}
            >
              <MuiIcons.MoreVert />
            </Mui.IconButton>
          </Mui.Tooltip>

          <Mui.Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <Mui.MenuItem onClick={() => handleEditarOrcamento(data)}>
              <MuiIcons.Edit fontSize="small" /> <span style={{ marginLeft: 12 }}>Editar</span>
            </Mui.MenuItem>
            <Mui.MenuItem onClick={() => handleDeletarOrcamento(data)}>
              <MuiIcons.Delete fontSize="small" /> <span style={{ marginLeft: 12 }}>Deletar</span>
            </Mui.MenuItem>
            <Mui.Divider />
            <Mui.MenuItem onClick={() => handleImportarOrcamento(data)}>
              <MuiIcons.ImportExport fontSize="small" />{' '}
              <span style={{ marginLeft: 12 }}>Exportar</span>
            </Mui.MenuItem>

            <Mui.Divider />

            <Mui.MenuItem onClick={() => setShowModalImpressao(true)}>
              <MuiIcons.Print fontSize="small" /> <span style={{ marginLeft: 12 }}> Imprimir</span>
            </Mui.MenuItem>

          </Mui.Menu>
        </Mui.Box>
      </Mui.TableCell>
    </TableRow>
    {
      showModalImpressao &&
      <ModalImprimirOrcamento 
        open={showModalImpressao}
        orcamento={data}
        onClose={() => {
          setShowModalImpressao(false);
        }}
      />
    }
</>
    
  );
});

export default OrcamentoTableRow;
