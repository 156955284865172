import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import Button from '@material-ui/core/Button';
import {
  Search as IconSearch,
  ArrowBack as IconArrowBack,
  MoreVert as IconMore
} from '@material-ui/icons';

import useReducerAndSaga from '~/hooks/useReducerAndSaga';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as actions from '~/store/modules/cliente/cliente-actions';
import saga from '~/store/modules/cliente/cliente-sagas';
import reducer from '~/store/modules/cliente/cliente-reducer';

import ModalIdentificarCliente from '../ModalIdentificarCliente';
import Searching from '~/components/Searching';
import ListEmpty from '~/components/ListEmpty';

const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      width: 1,
      height: 32,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  })
);

const ListItemCliente = React.memo(({ row, callback }) => (
  <>
    <ListItem button disabled={row?.desativado} onClick={ row?.desativado ? null : callback}>
      <Grid container item>
        <Grid container item md={6} wrap="nowrap">
          <Grid item zeroMinWidth>
            <Typography
              style={{
                textTransform: 'uppercase',
                fontWeight: 600
              }}
            >
              {row?.codigoInterno} - {row?.razaoSocial}
            </Typography>
            {row?.fantasia && (
              <Typography variant="body2"><b>Nome fantasia/Apelido:</b> {row?.fantasia}</Typography>
            )}
            <Typography variant="body2"><b>CPF/CNPJ:</b> {row?.pfCpf ?? row?.pjCnpj}</Typography>
          </Grid>
        </Grid>
        <Grid container item md={6} alignItems="center">
          <Grid item zeroMinWidth>
            <Typography style={{ fontSize: 12 }}>
              {row?.enderecoLogradouro}, N.{row?.enderecoLogradouroNumero},{row?.enderecoBairro}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              {row?.enderecoIntCidade?.cidade} / {row?.enderecoUf}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container item md={1} justify="flex-end">
          <Tooltip title="Menu">
            <IconButton tabIndex={-1} aria-label={'Menu'} onClick={() => {}}>
              <IconMore />
            </IconButton>
          </Tooltip>
        </Grid> */}
      </Grid>
    </ListItem>
    <Divider />
  </>
));

const ModalConsultaCliente = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showModalConsultaCliente } = useSelector(state => state.pdv);
  const applicationMode = useSelector(
    state => state.app.applicationMode
  );
  const [{ loading, items }, dispatchCliente] = useReducerAndSaga(reducer, saga, {
    loading: false,
    items: []
  });

  const handleClose = () => {
    dispatch(pdvActions.setShowModalConsultaCliente({ show: false }));
  };

  const changeSearchInput = async e => {
    const queryText = e.target.value;

    dispatchCliente(actions.searchRequest(queryText));
  };

  useEffect(() => {
    dispatchCliente(actions.setApplicationMode(applicationMode));
  }, [applicationMode]);

  return (
    <>
      <Dialog
        fullScreen
        open={showModalConsultaCliente?.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
      >
        <DialogTitle>
          <AppBar color="default" position="fixed">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <IconArrowBack />
              </IconButton>

              <Box style={{ minWidth: 180 }}>
                <Typography>Consulta de Cliente</Typography>
              </Box>

              <Divider className={classes.divider} />

              <TextField
                variant="outlined"
                autoFocus
                focused
                fullWidth
                placeholder="Pesquise por razão social, fantasia, CPF, CNPJ"
                onChange={changeSearchInput}
                InputProps={{
                  style: {
                    height: 44
                  },
                  startAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: 0 }}>
                      <IconButton tabIndex={-1} onClick={() => {}}>
                        <IconSearch />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              {/* <Divider className={classes.divider} />
              <Select variant="outlined" style={{ width: 280, height: 44 }}>
                <MenuItem value={'pedido'}>A/Z Razão social</MenuItem>
                <MenuItem value={'orcamento'}>Z/A Razão social</MenuItem>
              </Select>
              <Divider className={classes.divider} />
              <Button color="inherit" style={{ padding: '8px 16px' }}>
                <IconSearch /> {'  Filtro'}
              </Button> */}
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <>
          {loading ? (
            <>
              <Box style={{ marginTop: 64 }}>
                <LinearProgress variant="indeterminate" color="primary" />
              </Box>
              <Searching />
            </>
          ) : (
            <>
              {items?.length > 0 ? (
                <List style={{ marginTop: 64 }}>
                  {items.map((row, index) => (
                    <ListItemCliente
                      key={`${row.id}-${index}`}
                      row={row}
                      callback={() => {
                        dispatch(
                          pdvActions.setShowModalIdentificarCliente({
                            show: true,
                            clienteSelecionado: row
                          })
                        );
                        dispatch(pdvActions.setShowModalConsultaCliente({ show: false }));
                      }}
                    />
                  ))}
                </List>
              ) : (
                <ListEmpty />
              )}
            </>
          )}
        </>
      </Dialog>
    </>
  );
};

export default ModalConsultaCliente;
