import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Header } from './styles';
import { formatCurrency, formatDate } from '~/utils/format';
import { useDispatch, useSelector } from 'react-redux';
import { green } from '@mui/material/colors';
import Icon from '@mui/material/Icon';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';
import useSettings from '~/hooks/useSettings';

import api from '~/services/api';
import { API_NODE_URL, FILTRO_DATA } from '~/constants';

const situacoes = ['Fechado', 'Aberto', 'Parcialmente faturado', 'Bloqueado'];

const filtroDataOptions = [
  {
    titulo: 'Hoje',
    filtro: FILTRO_DATA.HOJE
  },
  {
    titulo: 'Ontem',
    filtro: FILTRO_DATA.ONTEM
  },
  {
    titulo: 'Esta semana',
    filtro: FILTRO_DATA.ESTA_SEMANA
  },
  {
    titulo: 'Este mês',
    filtro: FILTRO_DATA.ESTE_MES
  },
  {
    titulo: 'Todos',
    filtro: FILTRO_DATA.TODOS
  }
];

function createData(name, dataEmissao, situacao, vendedor, valorTotal, meioPagamento, condicoesPagamento, itens = []) {
  return {
    name,
    dataEmissao,
    situacao,
    vendedor,
    valorTotal,
    meioPagamento,
    condicoesPagamento,
    history: itens.map(item => (
      {
        ordem: item.itemOrdem,
        situacao: situacoes[item.itemSituacao],
        date: `${item.produtoCodigo} - ${item.produtoDescricao}`,
        quantidadeValor: `${item.itemQuantidade} X ${formatCurrency(item.itemValorUnitario)}`,
        descricao: item.produtoDescricao,
        amount: formatCurrency(item.itemValorTotal),
        desconto: formatCurrency(item.itemValorDesconto),
        acrescimo: formatCurrency(item.itemValorAcrescimo),
        vendedor: item?.vendedor?.nome
      }
    ))
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const { settings: { theme }, saveSettings } = useSettings();

  let color;
  if(row.situacao == 'Aberto') color = 'green';
  if(row.situacao == 'Fechado') color = '#0d47a1';
  if(row.situacao == 'Bloqueado') color = 'red';

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <RemoveCircleOutlineIcon style={{color: 'red'}} /> : <Icon sx={{ color: green[500] }}>add_circle</Icon>}
          </IconButton>
        </TableCell>
        <TableCell align="center" style={{fontSize: '14px', color: theme === 'DARK' ? 'white' : 'black'}}>
          {row.name}
        </TableCell>
        <TableCell align="center" style={{fontSize: '14px', color: theme === 'DARK' ? 'white' : 'black'}}>{row.dataEmissao}</TableCell>
        <TableCell align="left" style={{color:  color, textTransform: 'uppercase', fontSize: '14px'}}>{row.situacao}</TableCell>
        <TableCell align="center" style={{width: '185px', fontSize: '14px', color: theme === 'DARK' ? 'white' : 'black'}}>{row.condicoesPagamento}</TableCell>
        <TableCell align="center" style={{width: '185px', fontSize: '14px', color: theme === 'DARK' ? 'white' : 'black'}}>{row.vendedor}</TableCell>
        <TableCell align="center" style={{fontSize: '14px', color: theme === 'DARK' ? 'white' : 'black'}}>{row.valorTotal}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases" style={{marginLeft: '20px'}}>
                <TableHead>
                  <TableRow>
                  <TableCell style={{fontSize: '12px',fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>#</TableCell>
                    <TableCell style={{fontSize: '12px',fontWeight: 'bold', textAlign: 'left', color: theme === 'DARK' ? 'white' : 'black'}}>Situação</TableCell>
                    <TableCell style={{fontSize: '12px',fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>Produto</TableCell>
                    <TableCell style={{fontSize: '12px',fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>Vendedor</TableCell>
                    <TableCell style={{fontSize: '12px',fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>Desconto</TableCell>
                    <TableCell style={{fontSize: '12px',fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>Acréscimo</TableCell>
                    <TableCell align="left" style={{fontSize: '12px',fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>Valor Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell style={{fontSize: '12px', color: theme === 'DARK' ? 'white' : 'black'}}>{historyRow.ordem}</TableCell>
                      <TableCell style={{fontSize: '12px', color:  historyRow.situacao == 'Aberto' ? 'green': 'red', textTransform: 'uppercase', fontSize: '12px'}}>{historyRow.situacao}</TableCell>
                      <TableCell style={{fontSize: '12px', color: theme === 'DARK' ? 'white' : 'black'}}>{historyRow.date} <br /> {historyRow.quantidadeValor} </TableCell>
                      <TableCell style={{fontSize: '12px', color: theme === 'DARK' ? 'white' : 'black'}}>{historyRow.vendedor}</TableCell>
                      <TableCell style={{fontSize: '12px', color: theme === 'DARK' ? 'white' : 'black'}}>{historyRow.desconto}</TableCell>
                      <TableCell style={{fontSize: '12px', color: theme === 'DARK' ? 'white' : 'black'}}>{historyRow.acrescimo}</TableCell>
                      <TableCell align="left" style={{fontSize: '12px', color: theme === 'DARK' ? 'white' : 'black'}}>{historyRow.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const createRows = (itens) => {
  return itens.map(item => createData(`${item.serie}/${item.numero}`,
  formatDate(item.dataEmissao),
  situacoes[item.situacao],
  item?.fatVendedor?.nome,
  formatCurrency(item.valorTotal),
  item?.fatCondicaopagamento?.descricao,
  item?.fatCondicaopagamento?.parcelaFinMeiopagamento?.descricao,
  item.itens));
}

const ModalHistoricoPedido = ({ id, clienteSearched, onClose, open }) => {

  const [cliente, setCliente] = useState([]);
  const [filtroData, setFiltroData] = useState(FILTRO_DATA.ESTE_MES);
  const [ordenacao, setOrdenacao] = useState('dataEmissao');
  const anchorRef = useRef();
  const dispatch = useDispatch();
  const { settings: { theme }, saveSettings } = useSettings();
  

  useEffect(() => {

    const asyncFunc = async () => {
      const res = await api.get(`${API_NODE_URL}/integracao/cliente/${id}/historico-pedido/?filtro=${filtroData}&ordenacao=${ordenacao}`);

      setCliente(res.data.dados);
    }

    asyncFunc();

  }, [filtroData]);

  return (
    <>
      <Mui.Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Mui.Paper}
        maxWidth={'lg'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box style={{ minWidth: 180 }}>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Histórico
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>

          <Mui.Grid item md={12}>
            <Header>
              <Mui.Typography variant="caption">Cliente</Mui.Typography>
              <Mui.Typography variant="h4">
                {clienteSearched &&
                <span>
                  {clienteSearched.codigoInterno} - {clienteSearched.razaoSocial}
                </span>}
                <Mui.ButtonGroup
                variant="contained"
                color="default"
                ref={anchorRef}
                aria-label="split button"
                style={{ marginLeft: 350 }}
              >
                {filtroDataOptions.map((f, index) => (
                  <Mui.Button
                    key={`pedido-filtro-data-${index}`}
                    color={f.filtro === filtroData ? 'primary' : 'default'}
                    onClick={() => setFiltroData(f.filtro)}
                  >
                    {f.titulo}
                  </Mui.Button>
                ))}
              </Mui.ButtonGroup>
              </Mui.Typography>
            </Header>
          </Mui.Grid>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{minWidth: '1200px', padding: '20px'}}>
          
            <Mui.Grid item xs={24} md={24}>
            
              
              <TableContainer component={Mui.Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow style={{color: 'white'}}>
                      <TableCell style={{width: '65px', fontWeight: 'bold'}} />
                      <TableCell align="center" style={{fontSize: '14px', padding: 0, width: '140px', fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>Série / Número</TableCell>
                      <TableCell align="center" style={{fontSize: '14px', fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>Emissão</TableCell>
                      <TableCell align="left" style={{fontSize: '14px', fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>Situação</TableCell>
                      <TableCell align="center" style={{fontSize: '14px', fontWeight: 'bold', width: '110px', color: theme === 'DARK' ? 'white' : 'black'}}>Meio Pagamento</TableCell>
                      <TableCell align="center" style={{fontSize: '14px', fontWeight: 'bold', width: '110px', color: theme === 'DARK' ? 'white' : 'black'}}>Vendedor</TableCell>
                      <TableCell align="center" style={{fontSize: '14px', fontWeight: 'bold', color: theme === 'DARK' ? 'white' : 'black'}}>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cliente.length > 0 && createRows(cliente).map((row) => (
                      <Row key={row.name} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Mui.Grid>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Box p={1}>
            <Mui.Button size="large" onClick={onClose} style={{ marginLeft: 16 }}>
              Cancelar
            </Mui.Button>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ModalHistoricoPedido;
