import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SET_LOADING,
  SET_QUERY_TYPE,
  SET_APPLICATION_MODE,
} from './produto-constants';

export const setLoading = loading => {
  return {
    type: SET_LOADING,
    loading
  };
};
export const setApplicationMode = applicationMode => {
  return {
    type: SET_APPLICATION_MODE,
    applicationMode,
  };
};

export const setQueryType = queryType => {
  return {
    type: SET_QUERY_TYPE,
    queryType
  };
};

export const searchRequest = (queryText, callback) => {
  return {
    type: SEARCH_REQUEST,
    queryText,
    callback
  };
};

export const searchSuccess = items => {
  return {
    type: SEARCH_SUCCESS,
    items
  };
};
