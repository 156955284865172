import api from '~/services/api';
import { API_BASE_URL, API_LOCAL_URL, API_NODE_URL, PEDIDO_TIPO_BLOQUEIO } from '~/constants';

class PedidoService {
  _strategy;

  constructor() {
    this._strategy = new PedidoOnlineService();
  }

  setStategy(isOffline) {
    this._strategy = isOffline ? new PedidoOfflineService() : new PedidoOnlineService();
  }

  getId(id) {
    return this._strategy.getId(id);
  }

  getPedidoBloqueado(id, bloqueio) {
    return this._strategy.getPedidoBloqueado(id, bloqueio);
  }

  rejeitarBloqueioEstoque(id, pedido, tokenLiberacaoRotina = null) {
    return this._strategy.rejeitarBloqueioEstoque(id, pedido, tokenLiberacaoRotina);
  }

  liberarBloqueioEstoque(id, pedido, tokenLiberacaoRotina = null) {
    return this._strategy.liberarBloqueioEstoque(id, pedido, tokenLiberacaoRotina);
  }

  rejeitarBloqueioComercialNegociacao(id, pedido, tokenLiberacaoRotina = null) {
    return this._strategy.rejeitarBloqueioComercialNegociacao(id, pedido, tokenLiberacaoRotina);
  }

  liberarBloqueioComercialNegociacao(id, pedido, tokenLiberacaoRotina = null) {
    return this._strategy.liberarBloqueioComercialNegociacao(id, pedido, tokenLiberacaoRotina);
  }

  rejeitarBloqueioComercialDesconto(id, pedido, tokenLiberacaoRotina = null) {
    return this._strategy.rejeitarBloqueioComercialDesconto(id, pedido, tokenLiberacaoRotina);
  }

  liberarBloqueioComercialDesconto(id, pedido, tokenLiberacaoRotina = null) {
    return this._strategy.liberarBloqueioComercialDesconto(id, pedido, tokenLiberacaoRotina);
  }

  rejeitarBloqueioCredito(id, tokenLiberacaoRotina = null) {
    return this._strategy.rejeitarBloqueioCredito(id, tokenLiberacaoRotina);
  }

  liberarBloqueioCredito(id, pedido, tokenLiberacaoRotina = null) {
    return this._strategy.liberarBloqueioCredito(id, pedido, tokenLiberacaoRotina);
  }
}

export class PedidoOnlineService {
  getId(id) {
    return api.get(`${API_BASE_URL}/v1/faturamento/pedido/${id}`);
  }
  getAll({ status, filtroData, offset, limit }) {
    return api.get(
      `${API_NODE_URL}/faturamento/pedidos/pdv/monitor-pedidos/${status}/${filtroData}/${offset}/${limit}`
    );
  }

  getAllPedidos({ skip, top, filter }, opts) {
    const params = {
      $top: top ? top : 100,
      $skip: skip,
      $orderby: 'logCriacao desc',
      $select: [
        'id',
        'serie',
        'numero',
        'situacao',
        'bloqueio',
        'dataEmissao',
        'clienteNome',
        'clienteCpfCnpj',
        'valorTotal',
        'logCriacao',
        'logAtualizacao',
        'entregaUf',
        'entregaCep',
        'entregaLogradouro',
        'entregaLogradouroNumero',
        'entregaComplemento',
        'entregaBairro',
        'entregaIntCidadeId',
        'enderecoUf',
        'enderecoCep',
        'enderecoLogradouro',
        'enderecoLogradouroNumero',
        'enderecoComplemento',
        'enderecoBairro',
        'enderecoIntCidadeId',
        'informacaoComplementar'
      ].join(','),
      $expand: [
        'fisOperacao($select=descricao,documentoFinalidade)',
        'fatVendedor($select=nome)',
        'fatCondicaopagamento($select=descricao)',
        'intCliente($select=razaoSocial,telefone,celular,pjCnpj,pfCpf,email)',
        'enderecoIntCidade',
        'entregaIntCidade',
        'parcelas($expand=finMeiopagamento($select=descricao,id))',
        'itens($expand=omsModalidadeExpedicao($select=descricao,id);$select=produtoCodigo,produtoDescricao,produtoUnidadeComercial,itemValorUnitario,itemValorBruto,itemValorAcrescimoCondicao,itemQuantidade,itemValorDesconto,itemValorAcrescimo,itemValorFrete,itemValorSeguro,itemValorDespesa,itemValorTotal,itemOrdem,itemCancelado)'
      ].join(',')
    };
    return api.get(
      `${API_BASE_URL}/v1/faturamento/pedido/`,
      {
        params:
          filter?.length > 0 ? { ...params, $filter: [...filter]?.join(` and `) } : { ...params }
      },
      opts
    );
  }
  getPedidoBloqueado(id, bloqueio) {
    if (
      bloqueio === PEDIDO_TIPO_BLOQUEIO.BloqueadoRegraComercial ||
      bloqueio === PEDIDO_TIPO_BLOQUEIO.BloqueadoRegraDesconto
    ) {
      return api.get(`${API_BASE_URL}/v1/faturamento/pedido/bloqueioregracomercial/${id}`);
    } else if (bloqueio === PEDIDO_TIPO_BLOQUEIO.BloqueadoEstoque) {
      return api.get(`${API_BASE_URL}/v1/faturamento/pedido/bloqueioestoque/${id}`);
    } else if (bloqueio === PEDIDO_TIPO_BLOQUEIO.BloqueadoCredito) {
      return api.get(`${API_BASE_URL}/v1/faturamento/pedido/bloqueiocredito/${id}`);
    }
  }

  rejeitarBloqueioEstoque(id, pedido, tokenLiberacaoRotina) {
    return api.put(`${API_BASE_URL}/v1/faturamento/pedido/rejeitarestoque/${id}`, pedido, {
      headers: {
        Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
      }
    });
  }

  liberarBloqueioEstoque(id, pedido, tokenLiberacaoRotina) {
    return api.put(`${API_BASE_URL}/v1/faturamento/pedido/liberacaoestoque/${id}`, pedido, {
      headers: {
        Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
      }
    });
  }

  rejeitarBloqueioComercialNegociacao(id, pedido, tokenLiberacaoRotina) {
    return api.put(
      `${API_BASE_URL}/v1/faturamento/pedido/rejeitarcomercialnegociacao/${id}`,
      pedido,
      {
        headers: {
          Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
        }
      }
    );
  }

  liberarBloqueioComercialNegociacao(id, pedido, tokenLiberacaoRotina) {
    return api.put(
      `${API_BASE_URL}/v1/faturamento/pedido/liberacaocomercialnegociacao/${id}`,
      pedido,
      {
        headers: {
          Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
        }
      }
    );
  }

  rejeitarBloqueioComercialDesconto(id, pedido, tokenLiberacaoRotina) {
    return api.put(
      `${API_BASE_URL}/v1/faturamento/pedido/rejeitarcomercialdesconto/${id}`,
      pedido,
      {
        headers: {
          Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
        }
      }
    );
  }

  liberarBloqueioComercialDesconto(id, pedido = null, tokenLiberacaoRotina) {
    return api.put(
      `${API_BASE_URL}/v1/faturamento/pedido/liberacaocomercialdesconto/${id}`,
      pedido,
      {
        headers: {
          Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
        }
      }
    );
  }

  rejeitarBloqueioCredito(id, pedido = null, tokenLiberacaoRotina) {
    return api.put(`${API_BASE_URL}/v1/faturamento/pedido/rejeitarcredito/${id}`, pedido, {
      headers: {
        Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
      }
    });
  }

  liberarBloqueioCredito(id, pedido = null, tokenLiberacaoRotina) {
    return api.put(`${API_BASE_URL}/v1/faturamento/pedido/liberacaocredito/${id}`, pedido, {
      headers: {
        Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
      }
    });
  }

  cancelarPedidos(pedidoId, tokenLiberacaoRotina) {
    return api.put(
      `${API_NODE_URL}/faturamento/pedidos/cancelar/`,
      { pedidoId },
      {
        headers: {
          Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
        }
      }
    );
  }

  fecharPedidos(pedidoId, tokenLiberacaoRotina) {
    return api.put(
      `${API_NODE_URL}/faturamento/pedidos/encerrar/`,
      { pedidoId },
      {
        headers: {
          Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
        }
      }
    );
  }
}

export class PedidoOfflineService {
  syncManual(payload) {
    return api.post(`${API_LOCAL_URL}/sync/pedido`, payload);
  }

  getId(id) {
    return api.get(`${API_LOCAL_URL}/faturamento/pedido/${id}`);
  }

  getAll({ status }) {
    return api.get(`${API_LOCAL_URL}/faturamento/pedido/monitor/${status}`);
  }

  getPedidoBloqueado(id, bloqueio) {
    return api.get(`${API_LOCAL_URL}/faturamento/pedido/${id}`);
  }

  rejeitarBloqueioEstoque(id, pedido, tokenLiberacaoRotina) {
    return api.put(`${API_LOCAL_URL}/faturamento/pedido/rejeitar-estoque/${id}`, pedido, {
      headers: {
        Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
      }
    });
  }

  liberarBloqueioEstoque(id, pedido, tokenLiberacaoRotina) {
    return api.put(`${API_LOCAL_URL}/faturamento/pedido/liberar-estoque/${id}`, pedido, {
      headers: {
        Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
      }
    });
  }

  rejeitarBloqueioComercialNegociacao(id, pedido, tokenLiberacaoRotina) {
    return api.put(
      `${API_BASE_URL}/api/faturamento/pedido/rejeitar-comercial/negociacao/${id}`,
      pedido,
      {
        headers: {
          Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
        }
      }
    );
  }

  liberarBloqueioComercialNegociacao(id, pedido, tokenLiberacaoRotina) {
    return api.put(
      `${API_BASE_URL}/api/faturamento/pedido/liberar-comercial-negociacao/${id}`,
      pedido,
      {
        headers: {
          Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
        }
      }
    );
  }

  rejeitarBloqueioComercialDesconto(id, pedido, tokenLiberacaoRotina) {
    return api.put(
      `${API_BASE_URL}/api/faturamento/pedido/rejeitar-comercial/desconto/${id}`,
      pedido
    );
  }

  liberarBloqueioComercialDesconto(id, pedido, tokenLiberacaoRotina) {
    return api.put(
      `${API_BASE_URL}/api/faturamento/pedido/liberar-comercial/desconto/${id}`,
      pedido,
      {
        headers: {
          Authorization: tokenLiberacaoRotina ? `Bearer ${tokenLiberacaoRotina}` : null
        }
      }
    );
  }

  rejeitarBloqueioCredito(id, tokenLiberacaoRotina) {
    throw new Error('Rejeitar Bloqueio de Crédito não foi implementado!');
  }

  liberarBloqueioCredito(id, tokenLiberacaoRotina) {
    throw new Error('Rejeitar Bloqueio de Crédito não foi implementado!');
  }
}

export default new PedidoService();
