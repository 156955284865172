import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { alpha, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Lottie from 'react-lottie';

import syncAnimation from '~/assets/animations/sync';

import * as appActions from '~/store/modules/app/app-actions';

const Root = styled.div`
  background-color: ${props => alpha(props.theme.palette.common.black, 0.05)};
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const ModalSync = React.memo(() => {
  const dispatch = useDispatch();
  const { mensagemSync } = useSelector(state => state.app);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Root>
        <Card
          style={{
            width: 520,
            padding: 0
          }}
        >
          <CardContent style={{ padding: 0 }}>
            <Box p={4} mt={4} display="flex" flexDirection="column" justifyContent="center">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: syncAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                isStopped={false}
                isPaused={false}
              />

              <Typography variant="h3" align="center">
                <strong> Sincronização</strong>
              </Typography>
              <Typography variant="h5" align="center">
                {mensagemSync}
              </Typography>

              <Box mt={4} display="flex" flexDirection="column" justifyContent="center">
                <Button
                  onClick={() => {
                    dispatch(appActions.setShowModalSync(false));
                  }}
                >
                  Minimizar
                </Button>
              </Box>
            </Box>
            <LinearProgress />
          </CardContent>
        </Card>
      </Root>
    </Modal>
  );
});

export default ModalSync;
