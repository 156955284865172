import * as Mui from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useState } from 'react';
import { InputAdornment, IconButton, CircularProgress } from '@mui/material';
import html2pdf from 'html2pdf.js';
import { API_NODE_URL } from '~/constants';
import api from '~/services/api';
import toast from '~/services/toast';
import useSettings from '~/hooks/useSettings';
import './index.css';
import { selectorParametroAsInteger } from '~/store/modules/auth/auth-selectors';
import { useSelector } from 'react-redux';
import DialogHeader from '../../components/Impressao/DialogHeader';
import { Email, Whatsapp } from 'mdi-material-ui';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Grid } from '@mui/material';
import { TIPO_DOCUMENTO } from '~/constants';

const ModalCompartilharDocumento = ({
  open,
  onClose,
  onCloseImpressao,
  emailCliente,
  telefoneCliente,
  documento,
  tipoDocumento
}) => {
  const [email, setEmail] = useState([{ email: emailCliente || '' }]);
  const [telefone, setTelefone] = useState([{ whatsapp: telefoneCliente || '' }]);
  const [loading, setLoading] = useState(false);
  const {
    settings: { theme }
  } = useSettings();
  const parametroPZ009 = useSelector(selectorParametroAsInteger('PZ009'));

  const addEmailField = () => {
    setEmail([...email, { email: '' }]);
  };

  const addWhatsappField = () => {
    setTelefone([...telefone, { whatsapp: '' }]);
  };

  const removeEmailField = index => {
    if (index !== 0) {
      setEmail(email.filter((_, i) => i !== index));
    }
  };

  const removeWhatsappField = index => {
    if (index !== 0) {
      setTelefone(telefone.filter((_, i) => i !== index));
    }
  };

  const handleEmailChange = (index, value) => {
    const newEmails = email.map((item, i) => (i === index ? { email: value } : item));
    setEmail(newEmails);
  };

  const handleWhatsappChange = (index, value) => {
    const newWhatsapps = telefone.map((item, i) => (i === index ? { whatsapp: value } : item));
    setTelefone(newWhatsapps);
  };

  const sendClient = () => {
    const emailInformado = email.some(item => item.email.trim() !== '');
    const telefoneInformado = telefone.some(item => item.whatsapp.trim() !== '');

    if (!emailInformado && !telefoneInformado) {
      return toast.warning('Informe um email ou telefone para continuar!');
    }

    if (!parametroPZ009 || parametroPZ009 === undefined || isNaN(parametroPZ009)) {
      return toast.warning('Email não cadastrado no parâmetro PZ009!');
    }

    var element = document.getElementById('printable-content');
    if (theme === 'DARK') {
      element.classList.add('black-color');
    }

    var opt = {
      margin: 0,
      image: { type: 'png', quality: 0.2 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'p' }
    };

    html2pdf().set(opt).from(element).outputPdf().then(async function(pdf) {
      const base64pdf = btoa(pdf);
      setLoading(true);
      
      let whatsappResponse, res;
      
      switch (tipoDocumento) {
        
        case TIPO_DOCUMENTO.ORCAMENTO:
          if (telefoneInformado) {
            whatsappResponse = await api.post(`${API_NODE_URL}/faturamento/orcamento/enviar-whatsapp/pdv`, {
              phoneNumber: telefone,
              base64pdf,
              serieNumero: `${documento?.serie}/${documento?.numero}`,
              cliente: documento?.clienteNome,
              vendedor: documento?.fatVendedor
            })
            if (whatsappResponse?.data?.urls?.length) {
              whatsappResponse.data.urls.forEach(url => {
                window.open(url, '_blank')
              })
            }
          }

          if (emailInformado) {
            res = await api.post(`${API_NODE_URL}/faturamento/orcamento/enviar-email/pdv`, {
              email: email.map(e => e.email),
              pdf: base64pdf,
              serieNumero: `${documento?.serie}/${documento?.numero}`,
              cliente: documento?.clienteNome,
              vendedor: documento?.fatVendedor
            })
          }
        break;
          
        case TIPO_DOCUMENTO.PEDIDO:
          if (telefoneInformado) {
            whatsappResponse = await api.post(`${API_NODE_URL}/faturamento/pedido/enviar-whatsapp/pdv`, {
              phoneNumber: telefone,
              base64pdf,
              serieNumero: `${documento?.serie}/${documento?.numero}`,
              cliente: documento?.clienteNome,
              vendedor: documento?.fatVendedor
            })
            if (whatsappResponse?.data?.urls?.length) {
              whatsappResponse.data.urls.forEach(url => {
                window.open(url, '_blank')
              })
            }
          }

          if (emailInformado) {
            res = await api.post(`${API_NODE_URL}/faturamento/pedido/enviar-email/pdv`, {
              email: email.map(e => e.email),
              pdf: base64pdf,
              serieNumero: `${documento?.serie}/${documento?.numero}`,
              cliente: documento?.clienteNome,
              vendedor: documento?.fatVendedor
            })
          }
        break;

        case TIPO_DOCUMENTO.DOCUMENTOSAIDA:
          if (telefoneInformado) {
            whatsappResponse = await api.post(`${API_NODE_URL}/faturamento/documento-saida/enviar-whatsapp/pdv`, {
              phoneNumber: telefone,
              base64pdf,
              serieNumero: `${documento?.documentoSerie}/${documento?.documentoNumero}`,
              cliente: documento?.clienteNome,
              vendedor: documento?.fatVendedor
            })
            if (whatsappResponse?.data?.urls?.length) {
              whatsappResponse.data.urls.forEach(url => {
                window.open(url, '_blank')
              })
            }
          }

          if (emailInformado) {
            res = await api.post(`${API_NODE_URL}/faturamento/documento-saida/enviar-email/pdv`, {
              email: email.map(e => e.email),
              pdf: base64pdf,
              serieNumero: `${documento?.documentoSerie}/${documento?.documentoNumero}`,
              cliente: documento?.clienteNome,
              vendedor: documento?.fatVendedor
            })
          }
        break;
        
        default:
        toast.error('Erro ao enviar documento.');
        setLoading(false);
        return;
      }
      
      if (res || whatsappResponse) {
        toast.success('Operação realizada com sucesso!');
        setLoading(true);
        onClose();
        onCloseImpressao();
      }
    })
  };

  return (
    <>
      <Mui.Dialog
        style={{ color: 'white' }}
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') onClose();
        }}
        PaperComponent={Paper}
        maxWidth={'xl'}
      >
        <DialogHeader title="Compartilhar Documento" onClose={onClose} />

        {loading ? (
          <Mui.DialogContent
            dividers
            style={{ 
              padding: '20px',
              display: 'flex',
              justifyContent: 'center', 
              alignItems: 'center',
              minWidth: '400px'
            }}
          >
            <CircularProgress />
            <p style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'center', 
              alignItems: 'center',
              minWidth: '400px' }}
            >
              Aguarde enquanto enviamos o documento
            </p>
          </Mui.DialogContent>
        ) : (
          <>
            <Mui.DialogContent
              dividers
              style={{ padding: '20px', display: 'flex', justifyContent: 'center', gap: '20px' }}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={5}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {email.map((field, index) => (
                      <Mui.TextField
                        key={index}
                        style={{ color: '#fff', width: '100%' }}
                        fullWidth
                        autoFocus={index === 0}
                        label="E-mail"
                        margin="normal"
                        name="email"
                        onChange={e => handleEmailChange(index, e.target.value)}
                        type="email"
                        value={field.email}
                        placeholder="Email do cliente"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <>
                                {index !== 0 && (
                                  <IconButton
                                    aria-label="remove"
                                    onClick={() => removeEmailField(index)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                )}
                                <IconButton aria-label="add" onClick={addEmailField}>
                                  <AddIcon />
                                </IconButton>
                              </>
                            </InputAdornment>
                          )
                        }}
                      />
                    ))}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={1}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Mui.Divider orientation="vertical" style={{ height: '100%' }} />
                </Grid>

                <Grid item xs={5}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {telefone.map((field, index) => (
                      <Mui.TextField
                        key={index}
                        style={{ color: '#fff', width: '100%' }}
                        fullWidth
                        label="Whatsapp"
                        margin="normal"
                        name="whatsapp"
                        onChange={e => handleWhatsappChange(index, e.target.value)}
                        type="tel"
                        value={field.whatsapp}
                        placeholder="Telefone do cliente"
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Whatsapp />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <>
                                {index !== 0 && (
                                  <IconButton
                                    aria-label="remove"
                                    onClick={() => removeWhatsappField(index)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                )}
                                <IconButton aria-label="add" onClick={addWhatsappField}>
                                  <AddIcon />
                                </IconButton>
                              </>
                            </InputAdornment>
                          )
                        }}
                      />
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Mui.DialogContent>

            <Mui.DialogActions>
              <Mui.Box p={1}>
                <Mui.Button
                  size="large"
                  onClick={sendClient}
                  style={{ marginLeft: 16 }}
                  disabled={loading}
                >
                  Enviar
                </Mui.Button>
              </Mui.Box>
              <Mui.Box p={1}>
                <Mui.Button
                  size="large"
                  onClick={onClose}
                  style={{ marginLeft: 16 }}
                  disabled={loading}
                >
                  Cancelar
                </Mui.Button>
              </Mui.Box>
            </Mui.DialogActions>
          </>
        )}
      </Mui.Dialog>
    </>
  );
};

export default ModalCompartilharDocumento;
