import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

const ModalJustificativaCancelamento = ({ title, showModal, onClose, callback }) => {
  const [justificativa, setJustificativa] = useState(null);

  const handleSubmit = () => {
    if (justificativa?.length < 15) {
      toast.warning('O motivo deve ter no mínimo 15 caracteres.');
      return false;
    }

    onClose();
    if (callback) {
      callback(justificativa);
    }
  };

  return (
    <Mui.Dialog
      open={showModal}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          onClose();
        }
      }}
      PaperComponent={Mui.Paper}
      maxWidth={'md'}
    >
      <Mui.DialogContent style={{ width: 540, padding: 32 }}>
        <Mui.Box textAlign="center" mb={4}>
          <Mui.Typography variant="h2" style={{ marginBottom: 16 }}>
            {title ? title : 'Cancelamento documento '}
          </Mui.Typography>
          <Mui.TextField
            label="Justificativa"
            variant="outlined"
            minRows={3}
            fullWidth
            value={justificativa}
            onChange={e => setJustificativa(e.target.value)}
            error={!justificativa || justificativa?.length > 300}
          />
          <span>{justificativa?.length ?? 0}/300</span>
        </Mui.Box>
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginLeft: 8 }}
          disabled={!justificativa || justificativa?.length > 300}
        >
          <MuiIcons.CheckCircle />{' '}
          <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
            Confirmar e Fechar
          </Mui.Typography>
        </Mui.Button>
        <Mui.Button size="large" style={{ marginLeft: 8 }} onClick={onClose}>
          <MuiIcons.Cancel />{' '}
          <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
            Cancelar (ESC)
          </Mui.Typography>
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default ModalJustificativaCancelamento;
