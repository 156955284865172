import jwtDecode from 'jwt-decode';

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded?.exp ? decoded.exp > currentTime : true;
};

export { isValidToken };
