import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recordKeyCombination, getApplicationKeyMap } from 'react-hotkeys';
import { makeStyles, useTheme, Container } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Close as IconClose, Search as IconSearch } from '@material-ui/icons';

import * as pdvActions from '~/store/modules/pdv/pdv-actions';

const ModalAtalhosPdv = () => {
  const dispatch = useDispatch();
  const { showModalAtalhosPdv } = useSelector(state => state.pdv);
  const [keyMap] = useState(getApplicationKeyMap());
  const handleClose = () => {
    dispatch(pdvActions.setShowModalAtalhosPdv({ show: false }));
  };

  return (
    <>
      <Dialog
        open={showModalAtalhosPdv}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <DialogTitle>
          <AppBar color="primary" position="static">
            <Toolbar>
              <Box>
                <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Atalhos
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <IconButton color="inherit" onClick={handleClose} aria-label="close">
                <IconClose />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>

        <DialogContent dividers style={{ minWidth: 320}}>
          <List>
            {Object.keys(keyMap).reduce((memo, actionName) => {
              const { sequences, name } = keyMap[actionName];

              return [...memo, <ListItem key={name || actionName}> {name}</ListItem>];
            }, [])}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalAtalhosPdv;
