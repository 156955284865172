import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

import api from '~/services/api';
import { APP_ERROR, API_BASE_URL } from '~/constants';

import { Container, Header } from './styles';

const ModalEditarItemDocumento = ({ data, showModal, onClose }) => {
  const dispatch = useDispatch();
  const { empresa } = useSelector(state => state.auth);

  const [itemDocumento, setItemDocumento] = useState({ ...data });
  const [loading, setLoading] = useState(false);
  const [loadingCfop, setLoadingCfop] = useState(false);
  const [loadingNcm, setLoadingNcm] = useState(false);
  const [selects, setSelects] = useState({
    simplescso: [],
    situacaotributaria: [],
    situacaotributariaipi: [],
    situacaotributariapiscofins: []
  });

  const handleSubmit = () => {
    if(onClose) {
      onClose(itemDocumento)
    }
  }

  const loadData = async () => {
    try {
      setLoading(true);
      const [
        { data: simplescso },
        { data: situacaotributaria },
        { data: situacaotributariapiscofins }
      ] = await Promise.all([
        api.get(`${API_BASE_URL}/v1/recurso/simplescso`),
        api.get(`${API_BASE_URL}/v1/recurso/situacaotributaria`),
        api.get(`${API_BASE_URL}/v1/recurso/situacaotributariapiscofins`)
      ]);

      setSelects({
        simplescso,
        situacaotributaria,
        situacaotributariapiscofins
      });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Mui.Dialog
        open={showModal}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Editar item
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
          <Mui.Grid item md={12}>
            <Header>
              <Mui.Typography variant="caption">Produto</Mui.Typography>
              <Mui.Typography variant="h3">
                {itemDocumento?.produtoCodigo}-{itemDocumento?.produtoDescricao}
              </Mui.Typography>
            </Header>
          </Mui.Grid>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ minWidth: 540, padding: 16 }}>
          {loading ? (
            <>
              <Mui.Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <Mui.CircularProgress variant="indeterminate" color="primary" />
              </Mui.Box>
            </>
          ) : (
            <>
              <Container>
                <Mui.Box p={2}>
                  <Mui.Grid container item spacing={2}>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.TextField
                        name="itemCfop"
                        label={'CFOP'}
                        value={itemDocumento?.itemCfop ?? ''}
                        fullWidth
                        required
                        onChange={e => {
                          setItemDocumento(state => ({ ...state, itemCfop: e.target.value }));
                        }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {loadingCfop ? (
                                <Mui.CircularProgress color="inherit" size={20} />
                              ) : null}
                            </>
                          )
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.TextField
                        name="ncm"
                        label={'NCM'}
                        value={itemDocumento?.ncm ?? ''}
                        fullWidth
                        required
                        onChange={e => {
                          setItemDocumento(state => ({ ...state, ncm: e.target.value }));
                        }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <>
                              {loadingNcm ? (
                                <Mui.CircularProgress color="inherit" size={20} />
                              ) : null}
                            </>
                          )
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.TextField
                        name="cest"
                        label={'CEST'}
                        value={itemDocumento?.cest ?? ''}
                        fullWidth
                        required
                        onChange={e => {
                          setItemDocumento(state => ({ ...state, cest: e.target.value }));
                        }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12} md={3}>
                      <Mui.TextField
                        name="produtoQuantidadeTributavel"
                        label={'Quantidade tributável'}
                        value={itemDocumento?.produtoQuantidadeTributavel ?? 0}
                        fullWidth
                        required
                        onChange={e => {
                          setItemDocumento(state => ({
                            ...state,
                            produtoQuantidadeTributavel: e.target.value
                          }));
                        }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Mui.Grid>
                    {empresa?.regimeTributario === 1 && (
                      <Mui.Grid item xs={12}>
                        <Autocomplete
                          id="simplescso-autocomplete"
                          options={selects?.simplescso ?? []}
                          value={itemDocumento?.produtoEstProduto?.estProdutoImposto[0]?.simplesCso}
                          fullWidth
                          onChange={(event, value) => {
                            event.persist();

                            setItemDocumento(state => ({
                              ...state,
                              produtoEstProduto: {
                                ...state.produtoEstProduto,
                                estProdutoImposto: [
                                  {
                                    ...state.produtoEstProduto?.estProdutoImposto,
                                    simplesCso: value?.chave
                                  }
                                ]
                              }
                            }));
                          }}
                          getOptionSelected={(option, value) => {
                            return option.chave === value;
                          }}
                          getOptionLabel={value => {
                            const option =
                              typeof value === 'string'
                                ? selects?.simplescso?.find(x => x.chave === value)
                                : { ...value };
                            return `${option?.chave}-${option?.valor}`;
                          }}
                          renderInput={params => (
                            <Mui.TextField
                              {...params}
                              required
                              variant="outlined"
                              label={'CSO'}
                              placeholder={'Consultar....'}
                              fullWidth
                            />
                          )}
                        />
                      </Mui.Grid>
                    )}
                    {empresa?.regimeTributario !== 1 && (
                      <Mui.Grid item xs={12}>
                        <Autocomplete
                          id="situacaotributaria-autocomplete"
                          options={selects?.situacaotributaria}
                          value={itemDocumento?.impostoIcmsSituacao}
                          fullWidth
                          onChange={(event, value) => {
                            event.persist();

                            setItemDocumento(state => ({
                              ...state,
                              impostoIcmsSituacao: value?.chave
                            }));
                          }}
                          getOptionSelected={(option, value) => {
                            return option.chave === value;
                          }}
                          getOptionLabel={value => {
                            const option =
                              typeof value === 'string'
                                ? selects?.situacaotributaria?.find(x => x.chave === value)
                                : { ...value };
                            return `${option?.chave}-${option?.valor}`;
                          }}
                          renderInput={params => (
                            <Mui.TextField
                              {...params}
                              required
                              variant="outlined"
                              label={'Situação tributária'}
                              placeholder={'Consultar....'}
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </Mui.Grid>
                    )}

                    <Mui.Grid item xs={12}>
                      <Autocomplete
                        id="situacaotributariapis-autocomplete"
                        options={selects?.situacaotributariapiscofins}
                        value={itemDocumento?.impostoPisSituacao}
                        fullWidth
                        onChange={(event, value) => {
                          event.persist();

                          setItemDocumento(state => ({
                            ...state,
                            impostoPisSituacao: value?.chave
                          }));
                        }}
                        getOptionSelected={(option, value) => {
                          return option.chave === value;
                        }}
                        getOptionLabel={value => {
                          const option =
                            typeof value === 'string'
                              ? selects?.situacaotributariapiscofins?.find(x => x.chave === value)
                              : { ...value };
                          return `${option?.chave}-${option?.valor}`;
                        }}
                        renderInput={params => (
                          <Mui.TextField
                            {...params}
                            required
                            variant="outlined"
                            label={'Situação PIS'}
                            placeholder={'Consultar....'}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Autocomplete
                        id="situacaotributariacofins-autocomplete"
                        options={selects?.situacaotributariapiscofins ?? []}
                        value={itemDocumento?.impostoCofinsSituacao ?? null}
                        fullWidth
                        onChange={(event, value) => {
                          event.persist();

                          setItemDocumento(state => ({
                            ...state,
                            impostoCofinsSituacao: value?.chave
                          }));
                        }}
                        getOptionSelected={(option, value) => {
                          return option.chave === value;
                        }}
                        getOptionLabel={value => {
                          const option =
                            typeof value === 'string'
                              ? selects?.situacaotributariapiscofins?.find(x => x.chave === value)
                              : { ...value };
                          return `${option?.chave}-${option?.valor}`;
                        }}
                        renderInput={params => (
                          <Mui.TextField
                            {...params}
                            required
                            variant="outlined"
                            label={'Situação Cofins'}
                            placeholder={'Consultar....'}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Box>
              </Container>
            </>
          )}
        </Mui.DialogContent>
        {!loading && (
          <Mui.DialogActions>
            <Mui.Box flex={1} display="flex" justifyContent="flex-end">
              <Mui.Box>
                <Mui.Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  <MuiIcons.Save />
                  <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                    SALVAR
                  </Mui.Typography>
                </Mui.Button>
              </Mui.Box>
              <Mui.Button style={{ marginLeft: 12 }} onClick={onClose}>
                <MuiIcons.Close />{' '}
                <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                  CANCELAR (ESC)
                </Mui.Typography>
              </Mui.Button>
            </Mui.Box>
          </Mui.DialogActions>
        )}
      </Mui.Dialog>
    </>
  );
};

export default ModalEditarItemDocumento;
