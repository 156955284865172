import axios from 'axios';
import { call, all, put, takeLatest, select } from 'redux-saga/effects';
import { resetState } from 'redux-localstore';

import api from '~/services/api';
import history from '~/services/history';
import { pathname } from '~/services/history';
import { isValidToken } from '~/utils/auth';
import {
  APP_ERROR,
  APP_MENU,
  APP_THEME,
  APP_TOKEN,
  APP_USER,
  APP_LOGIN,
  API_BASE_URL,
  APPLICATION,
  THEMES,
  APP_SYNC_DATE,
  API_LOCAL_URL,
  TIPO_DOCUMENTO
} from '~/constants';
import { INITIALISE, LOGIN_REQUEST, LOGIN_REQUEST_MODAL, LOGOUT_REQUEST } from './auth-constants';
import { getApplicationIsOnline } from '../app/app-selectors';
import * as appActions from '~/store/modules/app/app-actions';
import * as authActions from './auth-actions';
import * as pdvActions from '../pdv/pdv-actions';
import authService from './auth-service';
import toast from '~/services/toast';
import { INITIAL_STATE } from '~/store/modules/pdv/pdv-reducer';
import { selectorParametroAsBoolean } from './auth-selectors';
import CryptoJS from 'crypto-js';
const isWeb = APPLICATION === 'web';

export function* initialise({ token }) {
  try {
    //Validated token
    const accessToken = token ?? sessionStorage.getItem(APP_TOKEN);
    if (!accessToken) {
      yield call(history.push, '/auth/login');
      return;
    }

    if (!isValidToken(accessToken)) {
      yield put(logout());
      return;
    }

    if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    //Recovery User Data and Menu
    const user = sessionStorage.getItem(APP_USER);
    const menu = sessionStorage.getItem(APP_MENU);
    yield put(
      authActions.loginSuccess({
        data: user ? JSON.parse(user) : null,
        menu: menu ? JSON.parse(menu) : []
      })
    );

    //Recovery Theme
    const currentTheme = sessionStorage.getItem(APP_THEME);
    const { theme } = yield select(state => state.app);
    if (currentTheme !== theme) {
      yield put(appActions.setTheme(currentTheme ?? THEMES.DEFAULT));
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* login({ payload: { login, senha, filialSelecionada } }) {
  try {
    yield put(authActions.setLoading(true));

    const isOnline = yield select(getApplicationIsOnline());

    // yield put(
    //   appActions.enqueueSnackbar({
    //     message: 'authActions.setLoading!',
    //     options: {
    //       variant: 'warning'
    //     }
    //   })
    // );

    authService.setStategy(APPLICATION === 'electron' && !isOnline);

    let FilialID = filialSelecionada ? filialSelecionada?.id : 0;
    if (!filialSelecionada) {
        const { data: filiais } = yield call(authService.getFiliais.bind(authService), {
          login,
          senha
        });
  
        if(filiais.length === 1) {
          filialSelecionada = filiais?.[0];
        }
  
        if (filiais.length > 1) {
          yield put(authActions.setFilial(filiais));
          yield put(authActions.setLoading(false));
  
          return;
        } else {
          FilialID = filiais?.[0].id;
        }
      }

    //Seta o token antes de carregar os dados do usuário
    const { data: auth } = yield call(authService.getToken.bind(authService), {
      login,
      senha,
      FilialID
    });
    const { access_token } = auth;
    sessionStorage.setItem(APP_TOKEN, access_token);
    sessionStorage.setItem(APP_LOGIN, login);

    //Carrega os dados do usuario
    const { data: conta } = yield call(authService.getConta.bind(authService));
    // const { data: compartilhamentos } = yield call(authService.getCompartilhamento.bind(authService));
    const {
      menu,
      cmpComprador: comprador,
      intColaborador: colaborador,
      fatVendedor: vendedor,
      intEmpresa: empresa,
      pctUsuarioPerfil: usuarioPerfil,
      pctParametros: parametros,
      pctPermissoes: permissoes,
      pctMascaras: mascaras,
      filiaisAcesso: filiais,
      ...rest
    } = conta;

    const data = {
      ...rest,
      comprador,
      colaborador,
      vendedor,
      empresa,
      usuarioPerfil,
      parametros,
      permissoes,
      mascaras,
      filiais
      // compartilhamentos: compartilhamentos.map(c => ({
      //   [c.dominio]: c.compartilhado
      // }))
    };

    yield put(
      authActions.loginSuccess({
        data,
        menu
      })
    );

    const userData = {
      login: CryptoJS.AES.encrypt(login, 'pdvgol2023').toString(),
      password: CryptoJS.AES.encrypt(senha, 'pdvgol2023').toString()
    }

    sessionStorage.setItem('userData', JSON.stringify(userData));

    sessionStorage.setItem(APP_USER, JSON.stringify(data));
    sessionStorage.setItem(APP_MENU, JSON.stringify(menu));
    sessionStorage.removeItem('cacheEmpresaId');
    sessionStorage.removeItem('cacheVendedores');
    sessionStorage.removeItem('cachedOperacaoFilter');
    sessionStorage.removeItem('cacheOperacoes');
    sessionStorage.removeItem('condicoesPagamentos');
    sessionStorage.removeItem('bandeiras');
    sessionStorage.removeItem('bancos');

    // yield put(
    //   appActions.enqueueSnackbar({
    //     message: 'Login efetuado com sucesso!',
    //     options: {
    //       variant: 'success'
    //     }
    //   })
    // );

    if (APPLICATION === 'electron' && isOnline) {
      yield put(appActions.downloadData([], true));
      yield call(history.push, '/');
    } else {
      yield call(history.push, '/pdv');
    }
  } catch (error) {
    sessionStorage.removeItem(APP_USER);
    sessionStorage.removeItem(APP_MENU);
  
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(authActions.setLoading(false));
  }
}

export function* loginModal({ payload: { login, senha, filialSelecionada, desativarModalsSubsequentes = false } }) {
  try {
    
    const { empresa: dadosEmpresa } = yield select(state => state.auth);

    const { tipoDocumento, venda, showModalTrocaUsuario } = yield select(state => state.pdv);

    const userDataCached = sessionStorage.getItem('userData') ? JSON.parse(sessionStorage.getItem('userData')) : null;
    
    if(userDataCached) {
      let confirmLogin = false;
      let confirmPassword = false;
      const bytesLogin     = CryptoJS.AES.decrypt(userDataCached.login, 'pdvgol2023');
      const bytesPassword  = CryptoJS.AES.decrypt(userDataCached.password, 'pdvgol2023');
      const loginDecrypted    = bytesLogin.toString(CryptoJS.enc.Utf8);
      const passwordDecrypted = bytesPassword.toString(CryptoJS.enc.Utf8);
      if(login === loginDecrypted) confirmLogin = true;
      if(senha === passwordDecrypted) confirmPassword = true;
      if(confirmLogin && confirmPassword) {
        yield put(pdvActions.setShowModalTrocaUsuario({ show: false, desativarModalsSubsequentes: false }));
        yield put(pdvActions.iniciarVenda({ tipoDocumento, venda: null, limparVenda: true}));
        return toast.success(`Dados confirmado!`);
      }
    }

    const obrigaMeioPagamento = yield select(selectorParametroAsBoolean('PP001'));
    const exibirModalCliente = yield select(selectorParametroAsBoolean('PP003'));

    const pdv = yield select(state => state.pdv);

    yield put(authActions.setLoading(true));

    let FilialID = filialSelecionada ? filialSelecionada?.id : dadosEmpresa.id;
    
    const { data: filiaiss } = yield call(authService.getFiliais.bind(authService), {
      login,
      senha
    });

    const usuarioLoginFiliaisId = filiaiss.map(f => f.id);
    const verificaAcessoFilial = usuarioLoginFiliaisId.includes(FilialID);

    if(!verificaAcessoFilial) return toast.warning(`Usuário não tem acesso a filial ${dadosEmpresa.fantasia}`);

    sessionStorage.removeItem(APP_TOKEN);
    const { data: auth } = yield call(authService.getToken.bind(authService), {
      login,
      senha,
      FilialID
    });

    const { access_token } = auth;
    sessionStorage.setItem(APP_TOKEN, access_token);
    sessionStorage.setItem(APP_LOGIN, login);

    const { data: conta } = yield call(authService.getConta.bind(authService));
    const {
      menu,
      cmpComprador: comprador,
      intColaborador: colaborador,
      fatVendedor: vendedor,
      intEmpresa: empresa,
      pctUsuarioPerfil: usuarioPerfil,
      pctParametros: parametros,
      pctPermissoes: permissoes,
      pctMascaras: mascaras,
      filiaisAcesso: filiais,
      ...rest
    } = conta;

    const dataUser = {
      ...rest,
      comprador,
      colaborador,
      vendedor,
      empresa,
      usuarioPerfil,
      parametros,
      permissoes,
      mascaras,
      filiais
    };


    if(history?.pathname() === '/pdv') {

      const operacaoFilter = ['pdvExclusivo eq true'];
    switch (tipoDocumento) {
      case TIPO_DOCUMENTO.ORCAMENTO:
        operacaoFilter.push('orcamentoSerie gt 0');
        break;
      case TIPO_DOCUMENTO.PEDIDO:
        operacaoFilter.push('pedidoSerie gt 0');
        break;
      case TIPO_DOCUMENTO.DOCUMENTOSAIDA:
        operacaoFilter.push("operacao eq 'S'");
        operacaoFilter.push('documentoFinalidade eq 1');
        break;

      default:
        break;
    }

      const { data: vendedores } = yield call(
          api.get,
          `${API_BASE_URL}/v1/consulta/vendedor?$orderby=nome&$select=${[
            'nome',
            'cpfCnpj',
            'natureza',
            'id'
          ].join(
            ','
          )}&$filter=desativado eq false&$expand=fatEquipevenda($expand=operacoes)&v=${FilialID}`
        )

      let fatVendedorId = vendedor?.id ?? null;

      const fatVendedor = vendedores?.find(x => x.id === fatVendedorId);

      let operacoes = [];

      const { data } = yield call(
        api.get,
        `${API_BASE_URL}/v1/consulta/operacao?$filter=${operacaoFilter.join(' and ')}&$select=id`
      );

      if (data) {
        const promisedOperacao = data?.map(item => {
          return call(api.get, `${API_BASE_URL}/v1/fiscal/operacao/${item?.id}`);
        });
        const _operacoes = yield all(promisedOperacao);
        if (_operacoes) {
          operacoes = [..._operacoes.map(x => x.data)];
        }
      }
    const operacoesPermitidas = operacoes.reduce((array, operacao) => {
      if (fatVendedor?.fatEquipevenda?.operacoes.find(x => operacao.id === x.fisOperacaoId)) {
        return [...array, operacao];
      } else {
        return [...array];
      }
    }, []);

    const fisOperacaoId = venda?.fisOperacao?.id ?? venda?.fisOperacaoId;
    let fisOperacao = [
      ...(operacoesPermitidas?.length > 0 ? operacoesPermitidas : operacoes)
    ]?.find(x => x.id === fisOperacaoId);
    if (!fisOperacao) {
      fisOperacao = operacoesPermitidas[0] ?? operacoes[0];
    }

    yield put(
      authActions.loginSuccess({
        data: dataUser,
        menu
      })
    );
    
    sessionStorage.setItem(APP_USER, JSON.stringify(dataUser));
    sessionStorage.setItem(APP_MENU, JSON.stringify(menu));

    toast.success(`Bem vindo, ${conta.nome}!`);

    if(!venda.serie) {
      yield put(pdvActions.iniciarVenda({ tipoDocumento, venda: null, limparVenda: true}));
    }
    
    yield put(
      pdvActions.iniciarVendaSuccess({
        ...(pdv),
        tipoDocumento,
        showModalTrocaUsuario: false,
        venda:
          {
            ...(venda),
            fatVendedor : fatVendedor,
            fisOperacao
          },
      })
    );


    } else {

      yield put(
        authActions.loginSuccess({
          data: dataUser,
          menu
        })
      );
      
      sessionStorage.setItem(APP_USER, JSON.stringify(dataUser));
      sessionStorage.setItem(APP_MENU, JSON.stringify(menu));

      yield put(pdvActions.setShowModalTrocaUsuario({ show: false, desativarModalsSubsequentes: false }));
      toast.success(`Bem vindo, ${conta.nome}!`);
    }

    const userData = {
      login: CryptoJS.AES.encrypt(login, 'pdvgol2023').toString(),
      password: CryptoJS.AES.encrypt(senha, 'pdvgol2023').toString()
    }

    sessionStorage.setItem('userData', JSON.stringify(userData));

    if(!desativarModalsSubsequentes && history?.pathname() === '/pdv') {
      if(tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA && exibirModalCliente) yield put(pdvActions.setShowModalIdentificarCliente({ show: true }));
      if(obrigaMeioPagamento && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA && !exibirModalCliente) {
        yield put(pdvActions.setShowModalConsultaPagamento({ show: true }));
      }
    }

  } catch (error) {
    return toast.warning('Senha ou email incorreto!');
  } finally {
    yield put(authActions.setLoading(false));
  }
}

export function* logout(redirect = true) {
  try {
    yield call(resetState);

    delete axios.defaults.headers.common.Authorization;

    sessionStorage.removeItem(APP_TOKEN);
    sessionStorage.removeItem(APP_USER);
    sessionStorage.removeItem(APP_MENU);
    sessionStorage.removeItem('cacheEmpresaId');
    sessionStorage.removeItem('cacheVendedores');
    sessionStorage.removeItem('cachedOperacaoFilter');
    sessionStorage.removeItem('cacheOperacoes');
    sessionStorage.removeItem('condicoesPagamentos');
    sessionStorage.removeItem('bandeiras');
    sessionStorage.removeItem('bancos');
    sessionStorage.removeItem('userData');
    //sessionStorage.removeItem(APP_SYNC_DATE);

    yield put(authActions.logoutSuccess());

    if(redirect) yield call(history.push, '/auth/login');
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export default all([
  takeLatest(INITIALISE, initialise),
  takeLatest(LOGIN_REQUEST, login),
  takeLatest(LOGIN_REQUEST_MODAL, loginModal),
  takeLatest(LOGOUT_REQUEST, logout)
]);
