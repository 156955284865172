import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { alpha, useTheme } from '@material-ui/core/styles';
import { green, blue } from '@material-ui/core/colors';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Lottie from 'react-lottie';

import * as appActions from '~/store/modules/app/app-actions';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import { APP_TERMINAL_PDV, APP_TOKEN } from '~/constants';
import { sendProcessQueue } from '~/services/events';
import { formatDate } from '~/utils/format';

import emptyStateAnimation from '~/assets/animations/empty-state.json';

import Label from '../Label';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  drawer: {
    width: 440,
    maxWidth: '100%'
  },
  toolbar: {
    minHeight: 44
  },
  wrapper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonPrimary: {
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700]
    }
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const statusOptions = [
  {
    label: 'Pendentes',
    value: 'pendentes'
  },
  {
    label: 'Sincronizados',
    value: 'sincronizados'
  }
];

const FilaDocumentos = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(statusOptions?.[0].value);

  const isOnline = useSelector(getApplicationIsOnline());
  const { queues, showFilaDocumentos } = useSelector(state => state.app);
  const { sendingRows } = useSelector(state => state.monitor);

  const handleTabsChange = (event, value) => {
    setStatus(value);
  };

  const handleSyncManual = documento => {
    if (documento.jobKey === '@job/ENVIAR_ORCAMENTO') {
      dispatch(monitorActions.sendOrcamentoRequest(documento));
    } else if (documento.jobKey === '@job/ENVIAR_PEDIDO') {
      dispatch(monitorActions.sendPedidoRequest(documento));
    } else if (documento.jobKey === '@job/ENVIAR_DOCUMENTOSAIDA') {
      dispatch(monitorActions.sendDocumentoRequest(documento));
    }
  };

  const handleClose = () => {
    dispatch(appActions.setShowFilaDocumentos(false));
  };

  return (
    <>
      <Mui.Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: false } }}
        onClose={handleClose}
        open={showFilaDocumentos}
        variant="temporary"
      >
        <Mui.AppBar color="default" position="static">
          <Mui.Toolbar className={classes.toolbar}>
            <Mui.Box display={'flex'} flexDirection={'row'} alignItems="center">
              <Mui.Typography variant="h5">Documento(s) pendente(s)</Mui.Typography>
            </Mui.Box>

            <Mui.Box ml={2} flexGrow={1} />
            <Mui.IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <MuiIcons.Close />
            </Mui.IconButton>
          </Mui.Toolbar>
        </Mui.AppBar>
        <Mui.Divider />
        <Mui.Box flex={1} display="flex" flexDirection="column">
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            {queues?.length === 0 && (
              <Mui.Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginTop: 64
                }}
              >
                <Lottie
                  style={{
                    backgroundColor: alpha(theme.palette.common.black, 0.08),
                    borderRadius: '100%',
                    marginBottom: 16
                  }}
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: emptyStateAnimation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                  height={256}
                  width={256}
                  isStopped={false}
                  isPaused={false}
                />
              </Mui.Box>
            )}
            {queues?.length > 0 && (
              <>
                {queues?.map(x => (
                  <Mui.Card style={{ margin: 8, flex: 1, width: '100%', padding: 16 }}>
                    <Mui.Box flex display="flex" justifyContent="space-between" flexDirection="row">
                      <Mui.Box>
                        <Label color={'grey'}>{x?.tipoDocumento}</Label>
                        <Mui.Box flex display="flex" justifyContent="space-between">
                          <Mui.Typography variant="h6">
                            Série/Número: {x?.serie ?? x?.documentoSerie} {'/'}
                            {x?.numero ?? x?.documentoNumero}
                          </Mui.Typography>

                          <Mui.Typography variant="h6" style={{ marginLeft: 18 }}>
                            Emissão:{' '}
                            {formatDate(new Date(x?.dataEmissao ?? x?.documentoDataEmissao))}
                          </Mui.Typography>
                        </Mui.Box>
                      </Mui.Box>
                      <Mui.Box display="flex" flexDirection="row">
                        <div className={classes.root}>
                          <div className={classes.wrapper}>
                            <Mui.IconButton size="small" onClick={() => handleSyncManual(x)}>
                              <>
                                {sendingRows && sendingRows[x?._id]?.loading ? (
                                  <>
                                    <MuiIcons.CloudUpload />
                                    <Mui.CircularProgress
                                      size={38}
                                      className={classes.fabProgress}
                                    />
                                  </>
                                ) : (
                                  <MuiIcons.CloudUpload />
                                )}
                              </>
                            </Mui.IconButton>
                          </div>
                        </div>
                        {/* <Mui.IconButton onClick={() => {}}>
                          <MuiIcons.Delete />
                        </Mui.IconButton>
                        */}
                      </Mui.Box> 
                    </Mui.Box>
                  </Mui.Card>
                ))}
              </>
            )}
          </PerfectScrollbar>
          <Mui.Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={queues?.length === 0}
            onClick={() => {
              let userToken = sessionStorage.getItem(APP_TOKEN);
              let terminalPdv = sessionStorage.getItem(APP_TERMINAL_PDV);
              sendProcessQueue(userToken, terminalPdv);

              handleClose();
            }}
          >
            <MuiIcons.CloudUpload />
            <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
              Sincronizar
            </Mui.Typography>
          </Mui.Button>
        </Mui.Box>
        {/* </Mui.Grid>
          </Mui.Grid> */}
        {/* </Mui.Box> */}
      </Mui.Drawer>
    </>
  );
};

export default FilaDocumentos;
