import axios from 'axios';
import { setup } from 'axios-cache-adapter';
import localforage from 'localforage';
import memoryDriver from 'localforage-memoryStorageDriver';
import _ from 'lodash';

import { APP_TOKEN, API_BASE_URL, API_VENDAS_URL, APP_CACHE } from '~/constants';

localforage.defineDriver(memoryDriver);
const localforageStore = localforage.createInstance({
  driver: [localforage.sessionStorage, localforage.INDEXEDDB],
  name: APP_CACHE
});

const URL_CACHED = [
  `${API_BASE_URL}/v1/consulta/condicaopagamento/terminalpreco/`,
  `${API_BASE_URL}/v1/consulta/cartaobandeira/`,
  `${API_BASE_URL}/v1/consulta/instituicaofinanceira/`
];

const getURLCached = req => {
  const { url } = req;

  return !URL_CACHED.includes(url);

  // const urlMapped = URL_CACHED.map(x => {
  //   return url.includes(x);
  // });

  /*
  const urlMapped = URL_CACHED.map(pattern => {
    const patternRegexp = new RegExp(pattern);
    console.log(patternRegexp)
    return patternRegexp.test(url);
  });
  const shouldCached = urlMapped.includes(true);
  return !shouldCached;
  */
};

const interceptor = function(config) {
  const token = sessionStorage.getItem(APP_TOKEN);

  return {
    ...config,
    headers: !config.headers['Authorization']
      ? token
        ? {
            ..._.merge(config.headers, {
              Authorization: `Bearer ${token}`
            })
          }
        : { ...config.headers }
      : { ...config.headers }
  };
};

const api = axios.create({
  baseURL: API_BASE_URL
});

// const api = setup({
//   baseURL: API_BASE_URL,
//   cache: {
//     maxAge: 2 * 60 * 1000,
//     store: localforageStore,
//     exclude: {
//       filter: req => getURLCached(req), // true não cacheia, false cacheia
//       query: false
//     }
//   }
// });

api.interceptors.request.use(interceptor);

const apiVendas = axios.create({
  baseURL: API_VENDAS_URL,
  withCredentials: false
});

apiVendas.interceptors.request.use(interceptor);

export { apiVendas };
export default api;
