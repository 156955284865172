import React, { useState } from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';

import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import { formatCurrency, formatDate } from '~/utils/format';
import toast from '~/services/toast';
import { MEIO_PAGAMENTO } from '~/constants';

const ModalListaPagamentos = () => {
  const dispatch = useDispatch();
  const { venda, selects, showModalListaPagamentos } = useSelector(state => state.pdv);
  const [itemsSelecteds, setItemsSelecteds] = useState([]);
  const [parcelas, setParcelas] = useState([...venda?.parcelas]);

  const handleSelectOneOrder = (event, itemId) => {
    setItemsSelecteds(state =>
      event.target.checked ? [...state, itemId] : [...state.filter(x => x !== itemId)]
    );
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalListaPagamentos({ show: false }));
  };

  const handleRemovePagamentos = () => {
    dispatch(pdvActions.removerPagamentos(itemsSelecteds));
  };

  const handleSavePagamentos = () => {
    dispatch(pdvActions.atualizarParcelas(parcelas));
    dispatch(pdvActions.setShowModalListaPagamentos({ show: false }));
    dispatch(pdvActions.fecharVenda());
  };

  return (
    <Mui.Dialog
      open={showModalListaPagamentos?.show}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          handleClose();
        }
      }}
      PaperComponent={Mui.Paper}
      maxWidth={'lg'}
    >
      <Mui.DialogTitle>
        <Mui.AppBar color="primary" position="static">
          <Mui.Toolbar>
            <Mui.Box style={{ minWidth: 180 }}>
              <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                Pagamentos
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box flexGrow={1} />

            <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
              <MuiIcons.Close />
            </Mui.IconButton>
          </Mui.Toolbar>
        </Mui.AppBar>{' '}
      </Mui.DialogTitle>

      <Mui.DialogContent dividers style={{ minWidth: 640 }}>
        <Mui.Box p={2}>
          <Mui.TableContainer component={Mui.Paper}>
            <Mui.Table aria-label="spanning table">
              <Mui.TableHead>
                <Mui.TableRow>
                  <Mui.TableCell align="center">#</Mui.TableCell>
                  <Mui.TableCell align="left">Parcela</Mui.TableCell>
                  <Mui.TableCell align="left">Meio de pagamento</Mui.TableCell>
                  <Mui.TableCell align="center">Data</Mui.TableCell>
                  <Mui.TableCell align="right">Valor</Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {parcelas?.map((parcela, index) => (
                  <React.Fragment key={`itemPagamento-${index}`}>
                    <Mui.TableRow>
                      <Mui.TableCell align="left">
                        <Mui.Checkbox
                          onChange={event => {
                            handleSelectOneOrder(event, parcela?.uuid);
                          }}
                        />
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        <>{`${parcela?.parcelaNumero}/${parcela?.parcelaQuantidade}`}</>
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        <>{`${parcela?.finMeiopagamento?.descricao}`}</>
                      </Mui.TableCell>
                      <Mui.TableCell align="center">
                        <Mui.Typography>
                          {formatDate(new Date(parcela?.parcelaVencimento))}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell align="right">
                        <Mui.Typography>
                          <b>{formatCurrency(parcela?.parcelaValor)}</b>
                        </Mui.Typography>
                      </Mui.TableCell>
                    </Mui.TableRow>
                    {parcela?.finMeiopagamento?.tef && itemsSelecteds?.length === 0 && (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={5}>
                          <Mui.Grid container spacing={2}>
                            {parcela?.finMeiopagamento?.tefModalidade === null && (
                              <Mui.Grid item md={6}>
                                <Mui.Typography align="left" variant="caption">
                                  Número do Documento
                                </Mui.Typography>
                                <Mui.TextField
                                  value={parcela?.cartaoAutorizacao ?? ''}
                                  onChange={e => {
                                    setParcelas(state => {
                                      const newParcelas = [...state];
                                      const index = newParcelas.findIndex(
                                        x => x.uuid === parcela.uuid
                                      );
                                      newParcelas[index] = {
                                        ...parcela,
                                        cartaoAutorizacao: e.target.value,
                                      };

                                      return newParcelas;
                                    });
                                  }}
                                  variant="outlined"
                                  fullWidth
                                />
                              </Mui.Grid>
                            )}

                            <Mui.Grid
                              item
                              md={parcela?.finMeiopagamento?.tefModalidade === null ? 6 : 12}
                            >
                              <Mui.Typography align="left" variant="caption">
                                Bandeira do cartão
                              </Mui.Typography>
                              <Mui.Select
                                variant="outlined"
                                value={parcela?.fatCartaobandeira ?? null}
                                onChange={e => {
                                  setParcelas(state => {
                                    const newParcelas = [...state];
                                    const index = newParcelas.findIndex(
                                      x => x.uuid === parcela.uuid
                                    );
                                    newParcelas[index] = {
                                      ...parcela,
                                      fatCartaobandeira: e.target.value,
                                      fatCartaobandeiraId: e.target.value.id
                                    };

                                    return newParcelas;
                                  });
                                }}
                                fullWidth
                              >
                                {selects?.bandeiras
                                  ?.filter(x =>
                                    x.operacao ==
                                    (parcela?.finMeiopagamento?.nfePagamento ===
                                      MEIO_PAGAMENTO.CARTAO_CREDITO)
                                      ? 0
                                      : 1
                                  )
                                  ?.map((x, index) => (
                                    <Mui.MenuItem key={index} value={x}>
                                      {x.descricao}
                                    </Mui.MenuItem>
                                  ))}
                              </Mui.Select>
                            </Mui.Grid>
                          </Mui.Grid>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}
                  </React.Fragment>
                ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        </Mui.Box>
      </Mui.DialogContent>
      <Mui.DialogActions>
        {itemsSelecteds?.length > 0 && (
          <Mui.Button
            size="large"
            onClick={() => handleRemovePagamentos()}
            variant="contained"
            color="primary"
          >
            <MuiIcons.CheckCircle />
            <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
              ESTORNAR PAGAMENTOS
            </Mui.Typography>
          </Mui.Button>
        )}
        {itemsSelecteds?.length === 0 && (
          <Mui.Button
            size="large"
            onClick={() => handleSavePagamentos()}
            variant="contained"
            color="primary"
          >
            <MuiIcons.CheckCircle />
            <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
              SALVAR/FECHAR VENDA
            </Mui.Typography>
          </Mui.Button>
        )}

        <Mui.Button onClick={handleClose}>
          <MuiIcons.Close />
          <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
            CANCELAR (ESC)
          </Mui.Typography>
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default ModalListaPagamentos;
