import { FILTRO_DATA, STATUS_MONITOR_PEDIDO } from '~/constants';
import {
  LIMPAR_DADOS,
  SET_LOADING,
  SET_LOADING_SEND,
  SET_FILTRO_DATA,
  SET_STATUS,
  SET_ORDERNACAO,
  SET_PAGE,
  SET_ROWS_PER_PAGE,
  SET_TABINDEX,
  SET_LOADING_HISTORICOS_PEDIDO,
  SET_SHOW_TIMELINE_PEDIDO,
  SET_SHOW_FILA_DOCUMENTOS,
  SET_SHOWMODAL_EDITAR_DOCUMENTO,
  LOAD_HISTORICOS_PEDIDO_SUCCESS,
  LOAD_PEDIDOS_SUCCESS,
  LOAD_ORCAMENTOS_SUCCESS,
  LOAD_ORCAMENTOS_SEARCH_SUCCESS,
  LOAD_DOCUMENTOS_SUCCESS,
  SELECT_ALL_ROWS,
  SELECT_ONE_ROW,
  SEARCH,
  LOAD_DOCUMENTOS_SEARCH_SUCCESS,
  LOAD_PEDIDOS_SEARCH_SUCCESS
} from './monitor-constants';

const LIMIT = 10;

export const INITIAL_STATE = {
  tabIndex: 0,
  status: STATUS_MONITOR_PEDIDO.ABERTOS,
  statusOptions: [
    // {
    //   value: STATUS_MONITOR_PEDIDO.PENDETES,
    //   label: 'Pedentes (não sincronizados)'
    // },
    {
      value: STATUS_MONITOR_PEDIDO.ABERTOS,
      label: 'Abertos'
    },
    {
      value: STATUS_MONITOR_PEDIDO.BLOQUEADOS,
      label: 'Bloqueados'
    },
    {
      value: STATUS_MONITOR_PEDIDO.FATURADOS,
      label: 'Faturados'
    },
    {
      value: STATUS_MONITOR_PEDIDO.CANCELADOS,
      label: 'Cancelados'
    },
    {
      value: STATUS_MONITOR_PEDIDO.REJEITADOS,
      label: 'Rejeitados'
    }
  ],
  filtroData: FILTRO_DATA.HOJE,
  filtroDataOptions: [
    {
      titulo: 'Hoje',
      filtro: FILTRO_DATA.HOJE
    },
    {
      titulo: 'Ontem',
      filtro: FILTRO_DATA.ONTEM
    },
    {
      titulo: 'Esta semana',
      filtro: FILTRO_DATA.ESTA_SEMANA
    },
    {
      titulo: 'Este mês',
      filtro: FILTRO_DATA.ESTE_MES
    },
    {
      titulo: 'Todos',
      filtro: FILTRO_DATA.TODOS
    }
  ],
  orderBy: 'dataEmissao|asc',
  sortOptions: [
    {
      value: 'dataEmissao|desc',
      label: 'Data emissão'
    },
    {
      value: 'dataEmissao|asc',
      label: 'Data emissão'
    }
  ],
  loading: false,
  enableBulkActions: false,
  selectedSomeRows: false,
  selectedAllRows: false,
  selectedRows: [],
  sendingRows: {},
  page: 0,
  rowsPerPage: LIMIT,
  rowsPerPageOptions: [10, 25, 50, 100],
  documentos: [],
  documentosAll: [],
  valorTotalDocumentos: 0,
  quantidadeDocumentos: 0,
  showTimelinePedido: false,
  loadingHistoricos: false,
  pedidoHistoricos: [],
  pedidoSelecionado: null,
  totalizadores: {
    abertos: 0,
    bloqueados: 0,
    faturados: 0,
    cancelados: 0,
    rejeitados: 0
  }
};

const monitorReducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
    case LIMPAR_DADOS: {
      return { ...state, ...INITIAL_STATE, loading: true, tabIndex: state.tabIndex };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case SEARCH: {
      const { queryText } = action;
      const documentos =
        queryText !== ''
          ? state.documentosAll.filter(item => {
            const documentoNumero = item?.numero?.toString() || item?.documentoNumero?.toString();

            if(documentoNumero?.includes(queryText) || item?.clienteNome?.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) || item?.fatVendedor?.nome?.toLocaleLowerCase().includes(queryText.toLocaleLowerCase())) {
              return item;
            }
          })
          : state.documentosAll;

      return {
        ...state,
        documentos
      };
    }

    case SET_LOADING_SEND: {
      const { payload } = action;
      return {
        ...state,
        sendingRows: {
          ...state.sendingRows,
          [payload.itemId]: {
            loading: payload.loading
          }
        }
      };
    }

    case SET_TABINDEX: {
      return {
        ...state,
        tabIndex: action.tabIndex,
        loading: true
      };
    }

    case SET_STATUS: {
      return {
        ...state,
        status: action.status,
        page: 0,
        selectedRows: [],
        enableBulkActions: false,
        selectedSomeRows: false,
        selectedAllRows: false
      };
    }

    case SET_ORDERNACAO: {
      return {
        ...state,
        ordenadoPor: action.ordenadoPor
      };
    }

    case SET_FILTRO_DATA: {
      return {
        ...state,
        filtroData: action.filtroData
      };
    }

    case SET_PAGE: {
      return {
        ...state,
        page: action.page
      };
    }

    case SET_ROWS_PER_PAGE: {
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    }

    case SELECT_ONE_ROW: {
      const prevSelected = [...state.selectedRows];
      const orderId = action.id;

      const selectedRows = !prevSelected.includes(orderId)
        ? [...prevSelected, orderId]
        : [...prevSelected.filter(id => id !== orderId)];

      return {
        ...state,
        selectedRows,
        enableBulkActions: selectedRows.length > 0,
        selectedSomeRows: selectedRows.length > 0 && selectedRows.length < state.documentos.length,
        selectedAllRows: selectedRows.length === state.documentos.length
      };
    }

    case SELECT_ALL_ROWS: {
      return {
        ...state,
        selectedRows: [...action.ids],
        enableBulkActions: action.ids.length > 0,
        selectedSomeRows: action.ids.length > 0 && action.ids.length < state.documentos.length,
        selectedAllRows: action.ids.length === state.documentos.length
      };
    }

    case LOAD_ORCAMENTOS_SUCCESS: {
      return {
        ...state,
        documentos: action?.data?.orcamentos,
        documentosAll: action?.data?.orcamentos,
        quantidadeDocumentos: action?.data?.quantidadeDocumentos,
        valorTotalDocumentos: action?.data?.valorTotalDocumentos
      };
    }

    case LOAD_ORCAMENTOS_SEARCH_SUCCESS: {
      return {
        ...state,
        filtroData: 'todos',
        documentos: action?.data?.orcamentos,
        documentosAll: action?.data?.orcamentos,
        quantidadeDocumentos: action?.data?.quantidadeDocumentos,
        valorTotalDocumentos: action?.data?.valorTotalDocumentos
      };
    }

    case SET_LOADING_HISTORICOS_PEDIDO: {
      return {
        ...state,
        loadingHistoricos: action.loading
      };
    }

    case SET_SHOW_TIMELINE_PEDIDO: {
      return {
        ...state,
        showTimelinePedido: action.show,
        pedidoHistoricos: action.show ? [...state.pedidoHistoricos] : [],
        pedidoSelecionado: action.show ? state?.pedidoSelecionado : null
      };
    }
    
    case LOAD_PEDIDOS_SUCCESS: {
      return {
        ...state,
        showTimelinePedido: false,
        pedidoHistoricos: [],
        documentos: action?.data?.pedidos,
        documentosAll: action?.data?.pedidos,
        totalizadores: action?.data?.totalizadores,
        quantidadeDocumentos: action?.data?.quantidadeDocumentos ?? 0,
        valorTotalDocumentos: action?.data?.valorTotalDocumentos ?? 0
      };
    }

    case LOAD_PEDIDOS_SEARCH_SUCCESS: {
      return {
        ...state,
        showTimelinePedido: false,
        pedidoHistoricos: [],
        filtroData: 'todos',
        documentos: action?.data?.pedidos,
        documentosAll: action?.data?.pedidos,
        totalizadores: action?.data?.totalizadores,
        quantidadeDocumentos: action?.data?.quantidadeDocumentos ?? 0,
        valorTotalDocumentos: action?.data?.valorTotalDocumentos ?? 0
      };
    }

    case LOAD_HISTORICOS_PEDIDO_SUCCESS: {
      const { pedido, historicos } = action?.data;
      return {
        ...state,
        pedidoSelecionado: { ...pedido },
        pedidoHistoricos: [...historicos]
      };
    }

    case LOAD_DOCUMENTOS_SUCCESS: {
      return {
        ...state,
        documentos: action?.data?.documentos,
        documentosAll: action?.data?.documentos,
        totalizadores: action?.data?.totalizadores,
        quantidadeDocumentos: action?.data?.quantidadeDocumentos ?? 0
      };
    }

    case LOAD_DOCUMENTOS_SEARCH_SUCCESS: {
      return {
        ...state,
        filtroData: 'todos',
        documentos: action?.data?.documentos,
        documentosAll: action?.data?.documentos,
        totalizadores: action?.data?.totalizadores,
        quantidadeDocumentos: action?.data?.quantidadeDocumentos ?? 0
      };
    }

    case SET_SHOWMODAL_EDITAR_DOCUMENTO: {
      return {
        ...state,
        showModalEditarDocumento: action.payload
      };
    }

    default:
      return state;
  }
};

export default monitorReducer;
