import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import * as MuiIcons from '@material-ui/icons';

import api from '~/services/api';
import { APP_ERROR, API_NODE_URL } from '~/constants';
import { formatNumber } from '~/utils/format';

import { Header } from './styles';

const ModalConversorUnidadeMedida = ({
  open,
  data: { produto, unidade, quantidade, valorUnitario },
  onClose
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [conversoresProduto, setConversoresProduto] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const { data } = await api.get(
          `${API_NODE_URL}/estoque/unidade-conversao/${produto?.id}`
        );

        // A unidade de origem sempre é a mesma em um produto
        const unidadeComercialOrigem = data?.[0]?.unidadeOrigem ?? null;

        setConversoresProduto(
          unidade === unidadeComercialOrigem
            ? [
                ...data?.map(item => {
                  return {
                    ...item,
                    qntdConvertida: quantidade / item?.conversor,
                    itemUnitarioConvertido: valorUnitario * item.conversor
                  };
                })
              ]
            : [
                ...data
                  ?.filter(x => x.unidadeDestino === unidade)
                  ?.map(item => ({
                    ...item,
                    // utilizado pra "reverter" uma conversao, a unidade de origem sempre vai ser a do produto
                    unidadeDestino: unidadeComercialOrigem,

                    qntdConvertida: quantidade * item.conversor,
                    itemUnitarioConvertido: valorUnitario / item.conversor
                  }))
              ]
        );
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  return (
    <Dialog
      open={open}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          handleClose();
        }
      }}
      PaperComponent={Paper}
      maxWidth={'sm'}
    >
      <DialogTitle>
        <AppBar color="primary" position="static">
          <Toolbar>
            <Box style={{ minWidth: 180 }}>
              <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                Conversão de unidade
              </Typography>
            </Box>
            <Box flexGrow={1} />

            <IconButton color="inherit" onClick={onClose} aria-label="close">
              <MuiIcons.Close />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Grid item md={12}>
          <Header>
            <Box p={1}>
              <Typography variant="caption">Produto</Typography>
              <Typography variant="h4">
                {produto?.codigo} {' - '}
                {produto?.descricao}
              </Typography>
            </Box>
            <Divider />
            <Box p={1} display={'flex'} justifyContent="space-between">
              <Box textAlign="center">
                <Typography variant="caption">Unidade venda</Typography>
                <Typography variant="h4">{unidade}</Typography>
              </Box>
              <Box textAlign="center" ml={1}>
                <Typography variant="caption">Quantidade</Typography>
                <Typography variant="h4">{quantidade}</Typography>
              </Box>
            </Box>
          </Header>
        </Grid>
      </DialogTitle>
      <DialogContent dividers style={{ padding: 16 }}>
        {loading ? (
          <>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={4}
            >
              <CircularProgress variant="indeterminate" color="primary" />
            </Box>
          </>
        ) : (
          <Box p={2}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Unid. Destino</TableCell>
                  <TableCell align="center">Conversor</TableCell>
                  <TableCell align="center">Quantidade convertida</TableCell>
                  <TableCell align="center">#</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conversoresProduto?.map((conversor, index) => (
                  <TableRow hover style={{ padding: 0 }} key={`index-${index}`}>
                    <TableCell>
                      <Typography align="center">
                        {conversor?.unidadeDestino}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align="center">
                        {formatNumber(conversor?.conversor)}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography align="center">
                        {formatNumber(conversor?.qntdConvertida)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => onClose(conversor)}
                        color="primary"
                      >
                        Utilizar esta unidade
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button startIcon={<MuiIcons.Close />} onClick={onClose}>
          Cancelar (ESC)
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalConversorUnidadeMedida;
