import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Search as IconSearch } from '@material-ui/icons';

import { APP_ERROR } from '~/constants';
import useReducerAndSaga from '~/hooks/useReducerAndSaga';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as clientActions from '~/store/modules/cliente/cliente-actions';
import saga from '~/store/modules/cliente/cliente-sagas';
import reducer from '~/store/modules/cliente/cliente-reducer';

import ConsultaClienteItem from '../ConsultaClienteItem';

const InputPesquisaCliente = ({ value, cliente, callback, setFocus }) => {
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const autoCompleteRef = useRef(null);

  const applicationMode = useSelector(state => state.app.applicationMode);
  const [{ loading, items }, dispatchCliente] = useReducerAndSaga(reducer, saga, {
    loading: false,
    items: []
  });
  const [searchInput, setSearchInput] = useState(value ?? '');

  const changeSearchInput = queryText => {
    setSearchInput(queryText);

    try {
      dispatchCliente(clientActions.searchRequest(queryText));
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const handleChange = value => {
    autoCompleteRef.current = {
      value: null
    };
    setSearchInput('');
    searchRef.current = {
      value: ''
    };

    if (callback) {
      callback(value);
    } else {
      dispatch(
        pdvActions.setShowModalIdentificarCliente({
          show: true,
          clienteSelecionado: value,
          callback: () => {
            dispatch(pdvActions.setShowModalConsultaCliente({ show: false }));
          }
        })
      );
    }
  };

  useEffect(() => {
    dispatchCliente(clientActions.setApplicationMode(applicationMode));
  }, [applicationMode]);

  useEffect(() => {
    if (setFocus) searchRef?.current?.focus();
  }, [setFocus]);

  return (
    <>
      <Autocomplete
        id="autocomplete-cliente"
        ref={autoCompleteRef}
        freeSolo
        loading={loading}
        loadingText="Carregando..."
        noOptionsText="Nenhum registro encontrado"
        fullWidth
        options={[...items]}
        getOptionLabel={option =>
          `${option?.pjCnpj?.toString()} | ${option?.pfCpf?.toString()} | ${option?.codigoInterno?.toString()} | ${
            option?.razaoSocial
          } | ${option?.fantasia}`
        }
        inputValue={searchInput ?? ''}
        onInputChange={(_, value) =>{ 
          changeSearchInput(value)}}
        onChange={(event, value) => {
          if (value?.desativado) {
            autoCompleteRef.current = {
              value: null
            };
            setSearchInput('');
            searchRef.current = {
              value: ''
            };
            return;
          }
          handleChange(value);
        }}
        value={autoCompleteRef?.current?.value ?? null}
        renderOption={item => <ConsultaClienteItem data={item} />}
        renderInput={params => (
          <TextField
            {...params}
            size="medium"
            variant="outlined"
            placeholder="Pesquise por código, razão social, nome fantasia, cpf/cnpj ou grupo de cliente"
            inputRef={searchRef}
            InputProps={{
              ...params.InputProps,
              startdornment: (
                <>
                  <IconSearch />
                  {params.InputProps.startdornment}
                </>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </>
  );
};

export default InputPesquisaCliente;
