import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

const CarDocumento = React.memo(() => {
  const { venda, showModalLoadingMensagem } = useSelector(state => state.pdv);

  return (
    <Paper>
      <Box p={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Box mr={1}>
          <Typography variant="caption">Serie/Numero</Typography>
          {showModalLoadingMensagem?.show ? (
            <Skeleton variant="text" width={240} />
          ) : (
            <Typography variant="body2">
              <b>
                {venda?.serie ?? venda?.documentoSerie}/{venda?.numero ?? venda?.documentoNumero}
              </b>
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
});

export default CarDocumento;
