import React from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

const DialogHeader = ({ title, onClose }) => {
  return (
    <Mui.DialogTitle>
      <Mui.AppBar color="primary" position="static">
        <Mui.Toolbar>
          <Mui.Box style={{ minWidth: 180, alignItems: 'center' }}>
            <Mui.Typography
              variant="h5"
              style={{ textAlign: 'center', textTransform: 'upperCase' }}
            >
              {title}
            </Mui.Typography>
          </Mui.Box>
          <Mui.Box flexGrow={1} />

          <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
            <MuiIcons.Close />
          </Mui.IconButton>
        </Mui.Toolbar>
      </Mui.AppBar>
    </Mui.DialogTitle>
  );
};

export default DialogHeader;