import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { DialogContent } from '@mui/material';
import { Dialog } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DialogHeader from '../../components/Impressao/DialogHeader';
import UserInfoSection from '../../components/Impressao/UserInfoSection';
import OrcamentoIdentificationSection from '../../components/Impressao/OrcamentoIdentificationSection';
import ItensTable from '../../components/Impressao/ItensTable';
import ParcelasTable from '../../components/Impressao/ParcelasTable';
import ComplementaryInfo from '../../components/Impressao/ComplementaryInfo';
import TotalizersSection from '../../components/Impressao/TotalizersSection';
import PrintDialogActions from '../../components/Impressao/PrintDialogActions';
import ModalCompartilharDocumento from '../ModalCompartilharDocumento';
import { TIPO_DOCUMENTO } from '~/constants';
import '../ModalImprimirOrcamento/index.css'

const ModalImprimirPedido = ({ open, pedido, onClose }) => {
  pedido.itens = pedido.itens.filter(item => !item.itemCancelado);
  const empresa = useSelector(state => state?.auth?.empresa ?? 0);
  const user = useSelector(state => state?.auth?.usuario ?? 0);
  const componentRef = useRef(null);
  const [modalEnviarOrcamento, setModalEnviarOrcamento] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Pedido-${empresa.razaoSocial}-${pedido?.serie}-${pedido?.numero}`,
    pageStyle: `
    @page {
      size: auto;
      margin: 8mm;
    }
    .color-print {
      color: #263238
    }
    @media print {
      body * {
        visibility: hidden;
      }
    #printable-content, #printable-content * {
      visibility: visible;
    }
    #printable-content {
      position: absolute;
      left: 0;
      top: 0;
    }
  }`,
  });

  const handleShare = () => setModalEnviarOrcamento(true);

  return (
    <>
      <Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'lg'}
        fullWidth={true}
      >
        <DialogHeader title="Impressão de Pedido" onClose={onClose} />
        <DialogContent dividers style={{ padding: '10px' }}>
          <div id="printable-content" ref={componentRef}>{
            <>
            <UserInfoSection user={user} empresa={empresa} />
            <OrcamentoIdentificationSection documento={pedido} title="Pedido" />
            <ItensTable itens={pedido.itens} />
            <ParcelasTable parcelas={pedido.parcelas} />
            <ComplementaryInfo info={pedido.informacaoComplementar} />
            <TotalizersSection documento={pedido} />
            </>
            }
          </div>
        </DialogContent>
        <PrintDialogActions onPrint={handlePrint} onClose={onClose} onShare={handleShare} />

        {modalEnviarOrcamento && (
          <ModalCompartilharDocumento
            open={modalEnviarOrcamento}
            onClose={() => setModalEnviarOrcamento(false)}
            onCloseImpressao={onClose}
            emailCliente={pedido?.intCliente?.email}
            telefoneCliente={pedido?.intCliente?.celular}
            documento={pedido}
            tipoDocumento={TIPO_DOCUMENTO.PEDIDO}
          />
        )}
      </Dialog>
    </>
  );
};

export default ModalImprimirPedido;
