import { createRef } from 'react';

export const historyRef = createRef();

export const push = (name, params) => {
  historyRef.current?.history?.push(name, params);
};

export const pathname = () => {
  return historyRef.current?.history?.location?.pathname ?? null;
};

export default {
  historyRef,
  pathname,
  push
};
