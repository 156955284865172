export const APP_ERROR = 'SIAFPLUS_ERROR';
export const APP_TOKEN = 'SIAFPLUS_TOKEN';
export const APP_USER = 'SIAFPLUS_USER';
export const APP_LOGIN = 'SIAFPLUS_LOGIN';
export const APP_MENU = 'SIAFPLUS_MENU';
export const APP_THEME = 'SIAFPLUS_THEME';
export const APP_SETTINGS = 'SIAFPLUS_SETTINGS';
export const APP_PRINT_SERVER = 'SIAFPLUS_SERVIDOR_IMPRESSAO';
export const APP_SYNC_DATE = 'SIAFPLUS_DATE_SYNC';
export const APP_SYNC_CONFIGURATION = 'APP_SYNC_CONFIGURATION';
export const APP_CACHE = 'SIAFPLUS_CACHE';

export const APP_TERMINAL_PDV = 'TERMINAL_PDV';
export const APP_TERMINAIS = 'APP_TERMINAIS';
export const APP_FORCE_OFFLINE = 'APP_FORCE_OFFLINE';
export const APP_MODULO_TEF = 'APP_MODULO_TEF';

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_NODE_URL = process.env.REACT_APP_API_NODE_URL;
export const API_VENDAS_URL = process.env.REACT_APP_API_VENDAS_URL;
export const API_TERMINAL_URL = process.env.REACT_APP_API_TERMINAL_URL;
export const API_TERMINAL_TEF_URL = process.env.REACT_APP_API_TEF_URL;
export const API_LOCAL_URL = process.env.REACT_APP_API_LOCAL_URL + '/api';
export const SOCKET_URL = process.env.REACT_APP_API_LOCAL_URL;
export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

export const APPLICATION = process.env.REACT_APP_APPLICATION;
export const APPLICATION_MODE = {
  ONLINE: 'online', // APPLICATION = "WEB" ou "ELECTRON"  e tiver internet "isInternetReachable == true"
  OFFLINE: 'offlline' // APLICATION = "ELECTRON" e não tiver internet "isInternetReachable == false"
};

export const SHOW_SHORTCUTS = APPLICATION === 'electron';

export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  UNICORN: 'UNICORN'
};

export const STATUS_PDV = {
  IDENTIFICACAO: 'IDENTIFICACAO',
  VENDA: 'VENDA'
};

export const TIPO_DOCUMENTO = {
  ORCAMENTO: 'ORCAMENTO',
  PEDIDO: 'PEDIDO',
  DOCUMENTOSAIDA: 'DOCUMENTOSAIDA'
};

export const PDV_ACTION = {
  ERRO: 0,
  SALVO: 1,
  BLOQUEADO: 2,
  TRANSMITIDO: 3,
  RETENTATIVA: 4,
  OFFLINE: 5
};

export const MEIO_PAGAMENTO = {
  DINHEIRO: 1,
  CHEQUE: 2,
  CARTAO_CREDITO: 3,
  CARTAO_DEBITO: 4,
  CREDITO_LOJA: 5,
  VALE_ALIMENTACAO: 10,
  VALE_REFEICAO: 11,
  VALE_PRESENTE: 12,
  VALE_COMBUSTIVEL: 13,
  BOLETO_BANCARIO: 15,
  DEPOSITO_BANCARIO: 16,
  PAGAMENTO_INSTANTANEO: 17,
  TRANSFERENCIA_BANCARIA: 18,
  PROGRAMA_FIDELIDADE: 19,
  SEM_PAGAMENTO: 90,
  OUTROS: 99
};

export const DOCUMENTO_TRANSMISSAO = {
  NAO_SE_APLICA: 0,
  NAO_TRANSMITIDO: 1,
  TRANSMITIDO: 2,
  AGUARDANDO_TRANSMISSAO: 3,
  EM_PROCESSAMENTO: 4,
  DENEGADO: 5
};

export const DOCUMENTO_TRANSMISSAO_ENUM = [
  'Não se aplica',
  'Não transmitido',
  'Documento transmitido',
  'Aguardando transmissão',
  'Em processamento',
  'Denegado'
];

export const DOCUMENTO_FINALIDADE_ENUM = [
  'Não se aplica (quando cupom fiscal)',
  'NFe Normal',
  'NFe complementar',
  'NFe de ajuste',
  'Devolução de mercadoria'
];

export const STATUS_MONITOR_PEDIDO = {
  PENDETES: 'pendentes',
  ABERTOS: 'abertos',
  BLOQUEADOS: 'bloqueados',
  FATURADOS: 'faturados',
  CANCELADOS: 'cancelados',
  REJEITADOS: 'rejeitados'
};

export const STATUS_MONITOR_NFCE = {
  DOCUMENTOS: 'documentos',
  NAO_TRANSMITIDO: 'nao-transmitidos',
  TRANSMITIDOS: 'transmitidos',
  CANCELADOS: 'cancelados'
};

export const FILTRO_DATA = {
  HOJE: 'hoje',
  ONTEM: 'ontem',
  ESTA_SEMANA: 'esta-semana',
  ESTE_MES: 'este-mes',
  TODOS: 'todos'
};

export const MODAL_RESULT = {
  mrOk: 1,
  mrCancel: 2,
  mrRetry: 4
};

/**
 * Os títulos financeiros podem ter finalidades diferentes,
 * pensando nisso o campo tipo serve para categorizar os títulos nos tipos:
 *
 *  0 - Título normal,
 *  1 - Notas de crédito (Saldo de crédito do cliente),
 *  2 - Recebimento antecipado (crédito antecipado),
 *  3 - Notas de débito (saldo crédito com o fornecedor),
 *  4 - Pagamento antecipado ao fornecedor
 * */
export const TITULOS_TIPO = [
  'Título normal',
  'Notas de crédito',
  'Recebimento antecipado',
  'Notas de débito',
  'Pagamento antecipado'
];
export const DOCUMENTO_FINALIDADE = [
  'Não se aplica (quando cupom fiscal)',
  'NFe Normal',
  'NFe complementar',
  'NFe de ajuste',
  'Devolução de mercadoria'
];

export const ORCAMENTO_SITUACAO = ['Fechado', 'Aberto'];

export const PEDIDO_SITUACAO = [
  'Fechado',
  'Aberto',
  'Parcialmente faturado',
  'Bloqueado',
  'Rejeitado crédito',
  'Rejeitado estoque',
  'Rejeitado regra comercial de negócio',
  'Rejeitado regra comercial de desconto'
];
export const PEDIDO_BLOQUEIO_TITULO = [
  'Pedido liberado',
  'Bloqueado por crédito',
  'Bloqueado por estoque',
  'Bloqueado por regra comercial negocio',
  'Bloqueado por inadimplência',
  'Bloqueado por importação PDV',
  'Bloqueado por regra comercial desconto'
];

export const PEDIDO_TIPO_BLOQUEIO = {
  PedidoLiberado: 0,
  BloqueadoCredito: 1,
  BloqueadoEstoque: 2,
  BloqueadoRegraComercial: 3,
  BloqueadoInadimplencia: 4,
  ImportacaoPDV: 5,
  BloqueadoRegraDesconto: 6
};

export const MODELO_DOCUMENTO = {
  NFCE: '65',
  NFE: '55'
};

export const DATATYPE_TEF = {
  ALPHANUMERIC: 0,
  NUMERIC: 1,
  CURRENCY: 2,
  INFORMATION: 3,
  CONFIRMATION: 4,
  AWAIT: 5,
  MENU: 6,
  QRCODE: 7
};

export const SYNC_TYPE = {
  MANUAL: 'manual',
  BACKGROUND: 'background',
  LOGIN: 'login'
};
