import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, alpha, Container } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { Close as IconClose, Search as IconSearch } from '@material-ui/icons';

import api from '~/services/api';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import { getApplicationIsOnline } from "~/store/modules/app/app-selectors"
import { APP_ERROR, API_BASE_URL, API_LOCAL_URL } from '~/constants';

const useStyles = makeStyles(theme =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.25),

      marginLeft: 0,
      width: '100%'
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%'
    }
  })
);

const ModalConsultaOperacao = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { showModalConsultaOperacao, selects } = useSelector(state => state.pdv);
  const isOnline = useSelector(getApplicationIsOnline());

  const [operacoes, setOperacoes] = useState([...selects?.operacoesPermitidas]);
  const [operacoesFiltradas, setOperacoesFiltradas] = useState([...selects?.operacoesPermitidas]);
  const [loading, setLoading] = useState(false);

  const handleChangeSearch = queryText => {
    const results =
      queryText !== ''
        ? [...operacoes].filter(
            item =>
              item?.descricao
                ?.toLowerCase()
                ?.startsWith(queryText?.toLowerCase()) ||
              item?.descricao?.toLowerCase().includes(queryText?.toLowerCase())
          )
        : [...operacoes];

    setOperacoesFiltradas(results);
  };

  const handleSelecionarOperacao = operacao => {
    dispatch(pdvActions.selecionarOperacao(operacao));
    handleClose();
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalConsultaOperacao(false));
  };

  // useEffect(() => {
  //   const boostrapAsync = async () => {
  //     setLoading(true);
  //     try {
  //       const selects = [
  //         'id',
  //         'descricao',
  //         'operacao',
  //         'documentoModelo',
  //         'documentoSerie',
  //         'orcamentoSerie',
  //         'pedidoSerie',
  //         'documentoPresenca',
  //         'documentoFinalidade',
  //         'itemCfop',
  //         'movimentoEstoque',
  //         'movimentoFinanceiro',
  //         'desativado'
  //       ];
  //       const { data } = isOnline
  //         ? await api.get(
  //             `${API_BASE_URL}/v1/fiscal/operacao/?$select=${selects.join(',')}`
  //           )
  //         : await api.get(`${API_LOCAL_URL}/fiscal/operacao`);

  //       const _operacoes = [...data]?.sort((a, b) =>
  //         ('' + a.descricao).localeCompare(b.descricao)
  //       );

  //       setOperacoes(_operacoes);
  //       setOperacoesFiltradas(_operacoes);
  //     } catch (error) {
  //       dispatch({ type: APP_ERROR, error });
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   boostrapAsync();
  // }, []);

  return (
    <>
      <Dialog
        open={showModalConsultaOperacao?.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <DialogTitle>
          <AppBar color="primary" position="static">
            <Toolbar>
              <Box>
                <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Operação
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <IconClose />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box
            style={{
              display: 'flex',
              background: '#000',
              color: '#FFF',
              padding: 16,
              boxShadow: theme.shadows[6]
            }}
          >
            <Box className={classes.search}>
              <div className={classes.searchIcon}>
                <IconSearch />
              </div>
              <InputBase
                onChange={e => handleChangeSearch(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'search' }}
                autoFocus
              />
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent dividers style={{ minWidth: 540 }}>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={4}
            >
              <CircularProgress variant="indeterminate" color="primary" />
            </Box>
          ) : (
            <>
              <List>
                {operacoesFiltradas?.map((operacao, index) => (
                  <>
                    <ListItem
                      key={`item-${index}`}
                      button
                      onClick={() => handleSelecionarOperacao(operacao)}
                    >
                      <Grid container item>
                        <Grid container item md={8} wrap="nowrap">
                          <Grid item zeroMinWidth>
                            <Typography
                              variant="body1"
                              style={{
                                textTransform: 'uppercase',
                                fontWeight: 600
                              }}
                            >
                              {operacao?.descricao}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalConsultaOperacao;
