import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';

import api from '~/services/api';
import toast from '~/services/toast';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import {  BASE_ITEM } from '~/store/modules/pdv/pdv-helper';
import { APP_ERROR, API_NODE_URL, API_BASE_URL } from '~/constants';

import { Header } from './styles';

const ModalVendaCasada = ({ open, data: { produto }, onClose }) => {
  const dispatch = useDispatch();
  const { itemVenda, venda } = useSelector(state => state.pdv);

  const [loading, setLoading] = useState(true);
  const [columns] = useState([
    { id: 'produtoCodigo', label: 'Codigo' },
    { id: 'produtoDescricao', label: 'Produto' },
    { id: 'estoque', alignment: 'right', label: 'Estoque disponivel' }
  ]);
  const [produtosCasado, setProdutosCasado] = useState([]);
  const [idsSelecionados, setIdsSelecionados] = useState([]);

  const handleSubmit = async () => {
    const itensSelecionados = produtosCasado.filter(x => idsSelecionados.includes(x.id));

    const { data } = await api.get(
      `${API_NODE_URL}/faturamento/documento-saida/produto-grade-venda-casada?produto=${itensSelecionados
        ?.map(x => x.casadoEstProduto.codigo)
        .join(',')}`
    );

    if (data?.length > 0) {
      dispatch(pdvActions.adicionarItem(itemVenda));

      for (const item of data) {
        if (!item.id) {
          toast.warning(`Produto "${item.produtoCodigo}" não encontrado.`);
          return;
        }

        let impostoRecord = item.estProdutoImposto[0];
        if (!impostoRecord.estoqueEstArmazem) {
          toast.warning(
            `Produto "${item.codigo} - ${item.descricao}" sem armazém padrão associado não pode ser importado.`
          );
          return;
        }

       
        dispatch(
          pdvActions.adicionarItem({
            ...BASE_ITEM,
            produtoEstProduto: item,
            produtoEstProdutoId: item.id,
            produtoEstProdutoGrade: item?.estProdutoGrade ?? null,
            produtoEstProdutoGradeId:
              item?.estProdutoGrade?.length > 0 ? item.estProdutoGrade[0]?.id : null,
            produtoGradeLinha: item?.estProdutoGrade?.gradeEixoLinha ?? '',
            produtoGradeColuna: item?.estProdutoGrade?.gradeEixoColuna ?? '',
            produtoCodigo: item.codigo,
            produtoDescricao: item.descricao,
            produtoUnidadeComercial: item.unidadeComercial,
            produtoUnidadeTributavel: item.unidadeTributavel,
            produtoQuantidadeTributavel: item.quantidadeTributavel,
            produtoEanTributavel: item.eanTributavel,
            produtoQuantidadeEmbalagem: item.embalagemQuantidade,
            produtoPrecoVenda: impostoRecord.precoVenda,
            produtoEstArmazem: impostoRecord.estoqueEstArmazem,
            produtoEstArmazemId: impostoRecord.estoqueEstArmazemId,
            produtoEan: item.ean,
            produtoCodigoAuxiliar: item.codigoAuxiliar,
            produtoOrigem: item.mercadoriaOrigem,
            estProdutoImposto: [impostoRecord],
            itemCfop: venda.fisOperacao ? venda.fisOperacao.itemCfop : item.cfopSaida,
            itemValorUnitario: impostoRecord.precoVenda,
            itemQuantidade: item.itemQuantidade || 1,
            itemTipo: item.itemTipo || null,
            itemEstoque: item.saldoEstoque.estoqueReal || 0,
            estoqueReal: item.saldoEstoque.estoqueReal || 0,
            itemValorTotal: impostoRecord.precoVenda,
            estoqueReservado: item.saldoEstoque.estoqueReservado || 0,
            estoqueFuturo: item.saldoEstoque.estoqueFuturo || 0,
            estoqueDisponivel: item.saldoEstoque.estoqueReal
              ? item.saldoEstoque.estoqueReal + item.saldoEstoque.estoqueReservado * -1
              : 0,
            fracionavel: item.fracionavel,
            permiteCustomizar: impostoRecord.permiteCustomizar || false
          })
        );
      }
    }

    if (onClose) {
      onClose();
    }
  };

  const handleSelectAll = event => {
    setIdsSelecionados(event.target.checked ? produtosCasado.map(p => p.id) : []);
  };

  const handleSelectOne = (event, id) => {
    setIdsSelecionados(prevSelected =>
      !prevSelected.includes(id) ? [...prevSelected, id] : [...prevSelected.filter(x => x !== id)]
    );
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`${API_NODE_URL}/estoque/produto/casado/0/${produto?.id}`);

      const { estProdutoCasado, ..._produtoPrincipal } = data;

      // setProdutoPrincipal(_produtoPrincipal);
      setProdutosCasado(estProdutoCasado ? [...estProdutoCasado] : []);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!produto) {
      return;
    }
    loadData();
  }, [produto]);

  return (
    <>
      <Mui.Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'lg'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box style={{ minWidth: 180 }}>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Venda casada
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>

          <Mui.Grid item md={12}>
            <Header>
              <Mui.Typography variant="caption">Produto</Mui.Typography>
              <Mui.Typography variant="h3">
                {produto?.codigo} {' - '}
                {produto?.descricao}
              </Mui.Typography>
            </Header>
          </Mui.Grid>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers>
          <>
            {loading ? (
              <Mui.Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={8}
              >
                <Mui.CircularProgress />
                <Mui.Typography>Caregando...</Mui.Typography>
              </Mui.Box>
            ) : (
              <>
                <Mui.Table>
                  <Mui.TableHead>
                    <Mui.TableCell align="center" style={{ width: '90px' }} padding="checkbox">
                      <Mui.Checkbox
                        checked={idsSelecionados.length === produtosCasado.length}
                        color="primary"
                        indeterminate={
                          idsSelecionados.length > 0 &&
                          idsSelecionados.length < produtosCasado.length
                        }
                        onChange={handleSelectAll}
                      />
                    </Mui.TableCell>
                    {columns.map((column, index) => (
                      <Mui.TableCell
                        key={`column-${index}`}
                        align={column?.alignment ?? 'left'}
                        style={{ width: column?.width ? `${column?.width}px` : 'auto' }}
                      >
                        {column?.label}
                      </Mui.TableCell>
                    ))}
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {produtosCasado?.map((item, index) => {
                      const isSelected = idsSelecionados.indexOf(item?.id) !== -1;
                      return (
                        <Mui.TableRow key={index}>
                          <Mui.TableCell
                            align="center"
                            style={{ width: '90px' }}
                            padding="checkbox"
                          >
                            <Mui.Checkbox
                              checked={isSelected}
                              color="primary"
                              value={isSelected}
                              onChange={event => handleSelectOne(event, item?.id)}
                            />
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Typography variant="h6">
                              {item?.casadoEstProduto?.codigo}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Typography variant="h6" noWrap>
                              {item?.casadoEstProduto?.descricao}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell align="right">
                            <Mui.Typography variant="h6">
                              {item?.casadoEstProduto?.saldoEstoque[0]?.estoqueReal}
                            </Mui.Typography>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      );
                    })}
                  </Mui.TableBody>
                </Mui.Table>
              </>
            )}
          </>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Box p={1}>
            {!loading &&  <Mui.Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              autoFocus
              onClick={handleSubmit}
            >
              Confirmar
            </Mui.Button>}
           
            <Mui.Button size="large" onClick={onClose} style={{ marginLeft: 16 }}>
              Cancelar
            </Mui.Button>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ModalVendaCasada;
