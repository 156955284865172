import axios from 'axios';
import { all, call, put, select, takeLatest, takeEvery } from '@redux-saga/core/effects';
import Swal from 'sweetalert2';

import {
  API_TERMINAL_URL,
  APPLICATION,
  APP_ERROR,
  MODELO_DOCUMENTO,
  TIPO_DOCUMENTO,
  STATUS_MONITOR_NFCE,
  API_BASE_URL,
  DOCUMENTO_TRANSMISSAO,
  API_LOCAL_URL
} from '~/constants';
import {
  LOAD_DOCUMENTOS_REQUEST,
  LOAD_PEDIDOS_REQUEST,
  LOAD_HISTORICOS_PEDIDO_REQUEST,
  LOAD_ORCAMENTOS_REQUEST,
  SEND_ORCAMENTO_REQUEST,
  SEND_PEDIDO_REQUEST,
  SEND_DOCUMENTO_REQUEST,
  PRINT_DOCUMENTO,
  TRANSMITIR_DOCUMENTO,
  CANCELAR_DOCUMENTO,
  DOWNLOAD_DANFE,
  DOWNLOAD_XML
} from './monitor-constants';
import * as monitorActions from './monitor-actions';
import monitorService from './monitor-service';
import { getFilterByData } from './monitor-helper';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import { OrcamentoOfflineService, OrcamentoOnlineService } from '../orcamento/orcamento.service';
import { PedidoOfflineService, PedidoOnlineService } from '../pedido/pedido-service';
import {
  DocumentoSaidaOfflineService,
  DocumentoSaidaOnlineService
} from '../documento-saida/documento-saida.service';
import toast from '~/services/toast';
import print from '~/services/print';
import api from '~/services/api';
import store from '~/store';

const TITLE_BLOQUEIO = [
  '',
  'Análise de crédito',
  'Análise de estoque',
  'Analise comercial',
  'Importando no PDV',
  'Encerrado',
  'Cancelado',
  'Faturado'
];

const BLOQUEIO_LIBERACAO = [
  '',
  'Liberado por crédito',
  'Liberado por estoque',
  'Liberação comercial',
  'Importado no PDV',
  'Encerrado',
  'Cancelado'
];

let cancel;
const CancelToken = axios.CancelToken;

export function* sendOrcamento({ orcamento }) {
  try {
    yield put(
      monitorActions.setLoadingSend({
        type: TIPO_DOCUMENTO.ORCAMENTO,
        itemId: orcamento?._id,
        loading: true
      })
    );

    if (orcamento) {
      const service = new OrcamentoOfflineService();
      yield call(service.syncManual, orcamento);

      yield call(toast.success, 'Orçamento sincronizado com sucesso!');
      yield put(monitorActions.loadOrcamentosRequest());
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(
      monitorActions.setLoadingSend({
        type: TIPO_DOCUMENTO.ORCAMENTO,
        itemId: orcamento?._id,
        loading: false
      })
    );
  }
}

export function* loadOrcamentos() {
  try {
    if (cancel !== undefined) cancel();
    const cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    });

    yield put(monitorActions.setLoading(true));

    const isOnline = yield select(getApplicationIsOnline());

    const { showProgressSync } = yield select(state => state.app);
    const { page, rowsPerPage, filtroData } = yield select(state => state.monitor);

    const pagination = {
      skip: page === 0 ? 0 : page * rowsPerPage,
      top: rowsPerPage
    };

    let orcamentosOffline = [];
    if (APPLICATION !== 'web' && !showProgressSync) {
      try {
        const service = new OrcamentoOfflineService();
        const { data } = yield call(service.getAll.bind(service), { cancelToken });
        if (data) {
          orcamentosOffline = [...data];
        }
      } catch (error) {}
    }

    let orcamentosOnline = null;
    if (isOnline) {
      try {
        const service = new OrcamentoOnlineService();
        const { data } = yield call(
          service.getAll.bind(service),
          { ...pagination, filter: [...getFilterByData(filtroData)] },
          {
            cancelToken
          }
        );
        if (data) {
          orcamentosOnline = { ...data };
        }
      } catch (error) {}
    }

    yield put(
      monitorActions.loadOrcamentosSuccess({
        orcamentos: [
          ...orcamentosOffline.map(x => ({ ...x, isOffline: !isOnline && x?._id })),
          ...(orcamentosOnline
            ? orcamentosOnline?.items?.filter(
                x => !orcamentosOffline?.items?.map(y => y.id)?.includes(x.id)
              )
            : [])
        ],
        quantidadeDocumentos: orcamentosOnline?.count
      })
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(monitorActions.setLoading(false));
  }
}

export function* sendPedido({ pedido }) {
  try {
    yield put(
      monitorActions.setLoadingSend({
        type: TIPO_DOCUMENTO.PEDIDO,
        itemId: pedido?._id,
        loading: true
      })
    );

    if (pedido) {
      const service = new PedidoOfflineService();
      yield call(service.syncManual, pedido);
      yield call(toast.success, 'Pedido sincronizado com sucesso!');

      yield put(monitorActions.loadPedidosRequest());
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(
      monitorActions.setLoadingSend({
        type: TIPO_DOCUMENTO.PEDIDO,
        itemId: pedido?._id,
        pedido: false,
        success: false
      })
    );
  }
}

export function* loadPedidos() {
  try {
    if (cancel !== undefined) cancel();
    const cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    });

    yield put(monitorActions.setLoading(true));

    const isOnline = yield select(getApplicationIsOnline());
    const { showProgressSync } = yield select(state => state.app);
    const { status, filtroData, page, rowsPerPage } = yield select(state => state.monitor);

    const pagination = {
      offset: page === 0 ? 0 : page * rowsPerPage,
      limit: rowsPerPage
    };

    let pedidosOffline = null;
    if (APPLICATION !== 'web' && !showProgressSync) {
      try {
        const service = new PedidoOfflineService();
        const { data } = yield call(service.getAll, { status }, { cancelToken });
        if (data) {
          pedidosOffline = { ...data };
        }
      } catch (error) {}
    }

    let pedidosOnline = null;
    let totalizadores = null;
    if (isOnline) {
      try {
        const service = new PedidoOnlineService();
        const { data: _pedidos } = yield call(
          service.getAll,
          {
            status,
            filtroData,
            ...pagination
          },
          {
            cancelToken
          }
        );

        const { data: _totalizadores } = yield call(monitorService.getTotalizadores);

        pedidosOnline = { ..._pedidos };
        totalizadores = { ..._totalizadores };
      } catch (error) {}
    }

    yield put(
      monitorActions.loadPedidosSuccess({
        pedidos: [
          ...(pedidosOffline
            ? pedidosOffline?.pedidos?.map(x => ({ ...x, isOffline: !isOnline && x?._id }))
            : []),
          ...(pedidosOnline
            ? pedidosOnline?.pedidos?.filter(
                x => !pedidosOffline?.pedidos?.map(y => y.id)?.includes(x.id)
              )
            : [])
        ],
        totalizadores,
        valorTotalDocumentos: pedidosOnline?.pedidosTotalizador?.pedidosValorTotal ?? 0,
        quantidadeDocumentos: pedidosOnline?.count
      })
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(monitorActions.setLoading(false));
  }
}

export function* loadHistoricosPedido({ pedido }) {
  try {
    yield put(monitorActions.setShowTimelinePedido(true));
    yield put(monitorActions.setLoadingHistoricoPedido(true));

    const isOnline = yield select(getApplicationIsOnline());

    let historicos = null;

    if (pedido?.isOffline) {
      const { data } = yield call(monitorService.getHistoricoOffline, pedido?._id);
      historicos = data ? [...data] : [];
    } else if (isOnline) {
      const { data } = yield call(monitorService.getHistoricos, pedido?.id);
      historicos = data ? [...data] : [];
    }

    if (historicos) {
      yield put(
        monitorActions.loadHistoricosPedidoSuccess({
          pedido,
          historicos: [
            {
              titulo: 'Pedido criado',
              author: 'Sem vendedor',
              dataHora: new Date(),
              icone: 'folder',
              mensagem: 'Pedido criado'
            },
            ...historicos
              ?.sort((a, b) => a.dataHora - b.dataHora)
              .map(item => {
                const getIcone = ({ situacao, bloqueio, acao }) => {
                  let icone = situacao === 1 && bloqueio === 0 ? 'lock_open' : 'lock';
                  if (acao === 7) {
                    icone = 'description';
                  } else if (acao === 4) {
                    icone = 'shopping_cart';
                  } else if (acao === 6) {
                    icone = 'block';
                  } else if (acao === 5) {
                    icone = 'delete';
                  }

                  return icone;
                };
                const titulo =
                  item?.situacao === 1
                    ? BLOQUEIO_LIBERACAO[item?.acao ?? 0]
                    : TITLE_BLOQUEIO[item?.acao ?? 0];

                console.log({
                  situacao: item?.situacao,
                  acao: item?.acao,
                  BLOQUEIO_LIBERACAO,
                  TITLE_BLOQUEIO
                });

                return {
                  ...item,
                  titulo,
                  icone: getIcone(item)
                };
              })
          ]
        })
      );
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(monitorActions.setLoadingHistoricoPedido(false));
  }
}

export function* printDocumento({ documento, tipoDocumento }) {
  const toastInstance = toast.loading(`Imprimindo, aguarde...`);
  try {
    const { usuario, empresa } = yield select(state => state.auth);

    if (
      tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA &&
      documento?.documentoModelo === MODELO_DOCUMENTO.NFCE
    ) {
      if (documento?.nfeChave === null) {
        toast.warning('Não encontrado chave do documento!');
        return;
      }

      console.log(documento);

      yield call(api.post, `${API_TERMINAL_URL}/documentosaida/imprimir/${documento?.nfeChave}`);
      return;
    }

    const servidor = print.getPreference(usuario?.id, tipoDocumento);
    if (servidor?.utilizaServidorImpressao) {
      const payloadPrint = yield call(
        print.buildPayload,
        {
          ...documento,
          numero: documento?.documentoNumero ?? documento?.numero,
          serie: documento?.documentoSerie ?? documento?.serie,
          fatVendedor: documento?.fatVendedor,
          intCliente: documento?.intCliente,
          documentoFinalidade: documento?.documentoFinalidadeEnum?.valor ? documento?.documentoFinalidadeEnum?.valor : documento?.fisOperacao?.documentoFinalidadeEnum?.valor,
          criadoPor: documento?.logPctUsuarioNome,
          horaCriacao: documento?.logCriacao,
          tipoDocumento: {
            [TIPO_DOCUMENTO.ORCAMENTO]: 'Orçamento',
            [TIPO_DOCUMENTO.PEDIDO]: 'Pedido',
            [TIPO_DOCUMENTO.DOCUMENTOSAIDA]: 'Documento auxiliar de venda'
          }[tipoDocumento],
          empresa
        },
        false
      );
      yield call(print.execute, payloadPrint, {
        servidorImpressao: print.getServidorUrl(servidor)
      });
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    toastInstance.close();
  }
}

export function* sendDocumento({ documento }) {
  try {
    yield put(
      monitorActions.setLoadingSend({
        type: TIPO_DOCUMENTO.DOCUMENTOSAIDA,
        itemId: documento?._id,
        loading: true
      })
    );

    if (documento) {
      const service = new DocumentoSaidaOfflineService();
      const { data } = yield call(service.syncManual, documento);

      yield call(toast.success, 'Documento sincronizado com sucesso!');
      yield put(monitorActions.loadOrcamentosRequest());
    }
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(
      monitorActions.setLoadingSend({
        type: TIPO_DOCUMENTO.DOCUMENTOSAIDA,
        itemId: documento?._id,
        loading: false
      })
    );
  }
}

export function* loadDocumentos() {
  try {
    if (cancel !== undefined) cancel();
    const cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    });

    yield put(monitorActions.setLoading(true));

    const isOnline = yield select(getApplicationIsOnline());
    const { showProgressSync } = yield select(state => state.app);
    const { page, rowsPerPage, filtroData, status } = yield select(state => state.monitor);

    const pagination = {
      offset: page === 0 ? 0 : page * rowsPerPage,
      limit: rowsPerPage
    };

    let documentosOffline = [];
    if (APPLICATION !== 'web' && !showProgressSync) {
      try {
        const service = new DocumentoSaidaOfflineService();
        const { data } = yield call(service.getAll.bind(service), { cancelToken });
        if (data) {
          documentosOffline = [...data];
        }
      } catch (error) {}
    }

    let documentosOnline = null;
    let totalizadores = null;
    let countTotal = 0;
    if (isOnline) {
      try {
        const service = new DocumentoSaidaOnlineService();

        let _totalizadores = null;
        const { data } = yield call(service.getTotalizadores.bind(service), filtroData);
        _totalizadores = { ...data };

        let _documentos = [];
        if (status === STATUS_MONITOR_NFCE.DOCUMENTOS) {
          const {
            data: { items, count }
          } = yield call(
            service.getAllDocumentos.bind(service),
            {
              ...pagination,
              filter: [
                ...getFilterByData(filtroData, 'documentoDataEmissao'),
                `documentoModelo eq '01'`
              ],
            },
            {
              cancelToken
            }
          );
          _documentos = items ? [...items] : [];
          _totalizadores = { ..._totalizadores, documentos: count };
          countTotal = count;
        } else {
          const {
            data: { documentos: items }
          } = yield call(
            service.getAll.bind(service),
            {
              status,
              filtroData,
              ...pagination
            },
            {
              cancelToken
            }
          );
          _documentos = items ? [...items] : [];
        }

        documentosOnline = [..._documentos];
        totalizadores = { ..._totalizadores };
      } catch (error) {
        console.log(error);
      }
    }

    yield put(
      monitorActions.loadDocumentosSuccess({
        documentos: [
          ...documentosOffline.map(x => ({ ...x, isOffline: !isOnline && x?._id })),
          ...(documentosOnline ? documentosOnline : [])
        ],
        totalizadores,
        quantidadeDocumentos: countTotal
      })
    );
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  } finally {
    yield put(monitorActions.setLoading(false));
  }
}

export function* transmitirDocumento({ documento, opts, callback }) {
  let tentativa = opts?.tentativa ?? 1;
  let imprimirAposTransmitir = opts?.imprimirAposTransmitir ?? false;
  try {
    if (tentativa === 3) {
      Swal.close();

      const result = yield call(Swal.fire, {
        title: 'Atenção!',
        text: `Foram feitas ${tentativa -
          1} tentativas de transmisão do documento, deseja tentar novamente?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      });

      if (result?.value) {
        store.dispatch(
          monitorActions.transmitirDocumento(
            documento,
            {
              tentativa: 1,
              imprimirAposTransmitir
            },
            callback
          )
        );
      }

      return;
    }

    Swal.fire({
      title: 'Transmitindo Nota.',
      html:
        documento?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_SE_APLICA ||
        documento?.documentoTransmissao === DOCUMENTO_TRANSMISSAO.EM_PROCESSAMENTO
          ? `A nota fiscal foi recebida pela SEFAZ e está aguardando processamento. Consultando processo...`
          : `${tentativa}º tentativa...`,
      position: 'center',
      timerProgressBar: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
      onRender: async () => {
        let documentoChave = '';
        let documentoTransmissao = null;
        try {
          if (documento?.isDocumentoOffline) {
            const { data } = await api.post(
              `${API_TERMINAL_URL}/documentosaida/transmitir`,
              {
                ...documento
              },
              {
                headers: {
                  pOffLine: true,
                  pJustificativa: 'SEM CONEXAO COM A INTERNET',
                  pImprimir: imprimirAposTransmitir
                }
              }
            );

            await api.put(
              documento?.isDocumentoOffline
                ? `${API_LOCAL_URL}/faturamento/documentosaida/${documento?._id}`
                : `${API_BASE_URL}/v1/faturamento/documentosaida/${documento?.id}`,
              {
                ...data,
                documentoTransmissao: DOCUMENTO_TRANSMISSAO.AGUARDANDO_TRANSMISSAO
              }
            );

            documentoTransmissao = data?.documentoTransmissao ?? null;
            documentoChave = data?.nfeChave ?? null;
          } else {
            const { data } = await api.put(
              `${API_BASE_URL}/v1/faturamento/documentosaida/transmitir/${documento?.id}`
            );

            documentoTransmissao = data?.documentoTransmissao ?? null;
            documentoChave = data?.nfeChave ?? null;
          }
        } catch (error) {
          Swal.close();
          store.dispatch({ type: APP_ERROR, error });
        }

        if (
          documentoTransmissao === DOCUMENTO_TRANSMISSAO.NAO_SE_APLICA ||
          documentoTransmissao === DOCUMENTO_TRANSMISSAO.EM_PROCESSAMENTO
        ) {
          store.dispatch(
            monitorActions.transmitirDocumento(
              { ...documento, documentoTransmissao },
              imprimirAposTransmitir,
              tentativa + 1
            )
          );
        } else if (documentoTransmissao === DOCUMENTO_TRANSMISSAO.TRANSMITIDO) {
          if (documentoChave) {
            const result = await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Documento transmitido!',
              html: `<p class="margin-top-20">Chave de acesso: <strong>${documentoChave}</strong></p>`,
              showConfirmButton: true,
              timer: 10000
            });

            if (result) {
              if (callback) {
                callback();
              }
              if (imprimirAposTransmitir) {
                store.dispatch(
                  monitorActions.printDocumento(TIPO_DOCUMENTO.DOCUMENTOSAIDA, { ...documento })
                );
              }
            }
          }
        }
      }
    });
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* cancelarDocumento({}) {
  try {
  } catch (error) {
    yield put({ type: APP_ERROR, error });
  }
}

export function* downloadDanfe({ payload }) {
  const toastInstance = toast.loading(`Carregando, aguarde...`);
  try {
    // const { data } = yield call(
    //   api.get,
    //   `${API_BASE_URL}/v1/faturamento/documentosaida/download/danfe/${payload?.id}`,
    //   { responseType: 'arraybuffer' }
    // );

    const { data } = yield call(
      api.get,
      payload?.isOffline && !payload?.id
        ? `${API_TERMINAL_URL}/documentosaida/download/danfe/${payload?.nfeChave}`
        : `${API_BASE_URL}/v1/faturamento/documentosaida/download/danfe/${payload?.id}`,
      { responseType: 'arraybuffer' }
    );

    require('downloadjs')(data, payload?.nfeChave + '-danfe.pdf', 'application/pdf');
  } catch (error) {
    console.log(error);
    dispatch({ type: APP_ERROR, error });
  } finally {
    toastInstance.close();
  }
}

export function* downloadXml({ payload }) {
  const toastInstance = toast.loading(`Carregando, aguarde...`);
  try {
    // const { data } = yield call(
    //   api.get,
    //   `${API_BASE_URL}/v1/faturamento/documentosaida/download/xml/${payload?.id}`,
    //   { responseType: 'arraybuffer' }
    // );

    const { data } = yield call(
      api.get,
      payload?.isOffline && !payload?.id
        ? `${API_TERMINAL_URL}/documentosaida/download/xml/${payload?.nfeChave}`
        : `${API_BASE_URL}/v1/faturamento/documentosaida/download/xml/${payload?.id}`
    );

    require('downloadjs')(data, payload?.nfeChave + '-Nfe.xml', 'application/xml');
  } catch (error) {
    console.log(error);
    dispatch({ type: APP_ERROR, error });
  } finally {
    toastInstance.close();
  }
}

export default all([
  takeLatest(LOAD_DOCUMENTOS_REQUEST, loadDocumentos),
  takeLatest(SEND_DOCUMENTO_REQUEST, sendDocumento),
  takeLatest(LOAD_ORCAMENTOS_REQUEST, loadOrcamentos),
  takeLatest(SEND_ORCAMENTO_REQUEST, sendOrcamento),
  takeLatest(LOAD_PEDIDOS_REQUEST, loadPedidos),
  takeLatest(SEND_PEDIDO_REQUEST, sendPedido),
  takeLatest(LOAD_HISTORICOS_PEDIDO_REQUEST, loadHistoricosPedido),
  takeLatest(TRANSMITIR_DOCUMENTO, transmitirDocumento),
  takeLatest(CANCELAR_DOCUMENTO, cancelarDocumento),
  takeEvery(PRINT_DOCUMENTO, printDocumento),
  takeEvery(DOWNLOAD_DANFE, downloadDanfe),
  takeEvery(DOWNLOAD_XML, downloadXml)
]);
