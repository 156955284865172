import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@mui/icons-material';

import * as monitorActions from '~/store/modules/monitor/monitor-actions';
import * as appActions from '~/store/modules/app/app-actions';
import { selectorRotina } from '~/store/modules/auth/auth-selectors';

import animationDownload from '~/assets/animations/download.json';
import Account from '~/components/Account';
import ChangeTheme from '~/components/ChangeTheme';
import Logo from '~/components/Logo';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100
  },
  toolbar: {
    minHeight: 44
  },
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  }
}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`
  };
}

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { showProgressSync, showModalSync, mensagemSync, queues } = useSelector(state => state.app);
  const { empresa } = useSelector(state => state.auth);
  const { tabIndex, loading } = useSelector(state => state.monitor);
  const rotinaF010DSA = useSelector(selectorRotina('F040DSA'));

  const handleChangeTabIndex = (_, newValue) => {
    dispatch(monitorActions.setTabIndex(newValue));
  };

  return (
    <AppBar color="default" className={clsx(classes.root, className)} {...rest}>
      {loading && <LinearProgress variant="indeterminate" />}
      <Toolbar className={classes.toolbar}>
        <Logo />
        <Box ml={2} flexGrow={1} />
        <Tabs
          value={tabIndex}
          onChange={handleChangeTabIndex}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Orçamento" {...a11yProps(0)} />
          <Tab label="Pedido" {...a11yProps(1)} />
          {rotinaF010DSA && <Tab label="Monitor NFCe" {...a11yProps(2)} />}
        </Tabs>
        <Box ml={2} flexGrow={1} />

        {/* <Mui.Button onClick={() => {}}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            alignSelf="center"
            pl={1}
            pr={1}
            width={120}
          >
            <IconPackage />
            <Typography variant="caption" color="inherit">
              Produto {SHOW_SHORTCUTS && ' (F4)'}
            </Typography>
          </Box>
        </Mui.Button> */}
        {/* {showProgressSync && (
          <Mui.Tooltip title={'Download'}>
            <Mui.IconButton
              // onClick={() => {
              //   dispatch(appActions.downloadData());
              // }}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationDownload,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                height={24}
                width={24}
                isStopped={false}
                isPaused={false}
              />
            </Mui.IconButton>
          </Mui.Tooltip>
        )} */}

        <Divider orientation="vertical" flexItem />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="spacing-between"
          alignItems="center"
          px={2}
        >
          <MuiIcons.Apartment className={classes.avatar} />
          <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start">
            <Typography variant="h6" noWrap color="inherit">
              {empresa?.fantasia}
            </Typography>
            <Typography variant="caption" color="inherit">
              {empresa?.cnpj ?? empresa?.cpf}
            </Typography>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box display="flex" flexDirection="row">
          <ChangeTheme />
          {/* <Mui.Tooltip title="Notificações">
            <Mui.IconButton>
              <Mui.Badge badgeContent={99} color="error">
              <MuiIcons.Notifications />
              </Mui.Badge>
            </Mui.IconButton>
          </Mui.Tooltip> */}
          <Account showHelp={false} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
