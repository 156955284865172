import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { MEIO_PAGAMENTO, TIPO_DOCUMENTO } from '~/constants';
import { formatCurrency, formatDate } from '~/utils/format';
import toast from '~/services/toast';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import { CurrencyInputCustom } from '~/components/CustomInput';
import ModalCadastroCheque from '../../modal/ModalCheque';

const ParcelaPagamentoTableRow = ({ parcela, ...props }) => {
  const dispatch = useDispatch();
  const { tipoDocumento, itemPagamento, showModalCadastroCheque } = useSelector(state => state.pdv);

  const [anchorEl, setAnchorEl] = useState(null);
  const [itemEdicao, setItemEdicao] = useState(null);

  const handleDeletarParcela = async () => {
    const { isConfirmed } = await toast.confirm(
      'ATENÇÃO',
      'Deseja excluir este registro?</br></br>Certifique-se de que realmente deseja fazer isso, pois esta ação é irreversível.',
      {
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }
    );

    if (isConfirmed) {
      const parcelaIndex = itemPagamento?.parcelas?.findIndex(x => x?.uuid === parcela?.uuid);
      const parcelas = [...itemPagamento?.parcelas];

      parcelas.splice(parcelaIndex, 1);

      dispatch(
        pdvActions.atualizarItemPagamento({
          ...itemPagamento,
          parcelas: parcelas?.map((p, i) => ({
            ...p,
            parcelaNumero: i + 1
          })),
          parcelaQuantidade: parcelas.length,
          valorRecebido: parcelas?.reduce((total, item) => (total += item?.parcelaValor), 0) ?? 0
        })
      );
      setItemEdicao(null);
    }
  };

  const handleSalvarParcela = () => {
    const parcelaIndex = itemPagamento?.parcelas?.findIndex(x => x?.uuid === parcela?.uuid);
    const parcelas = [...itemPagamento?.parcelas];
    parcelas[parcelaIndex] = {
      ...itemEdicao
    };
    dispatch(
      pdvActions.atualizarItemPagamento({
        ...itemPagamento,
        parcelas,
        valorRecebido:
          parcelas?.reduce((total, item) => (total += item?.parcelaValor), 0) ?? 0
      })
    );
    setItemEdicao(null);
  };

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Mui.TableRow {...props}>
        <Mui.TableCell align="left">
          <>{`${parcela?.parcelaNumero}/${
            itemPagamento?.condicaoPagamento
              ? itemPagamento?.condicaoPagamento?.quantidadeParcela
              : itemPagamento?.parcelaQuantidade
          }`}</>
        </Mui.TableCell>
        <Mui.TableCell align="center">
          <Mui.Typography>{formatDate(new Date(parcela?.parcelaVencimento))}</Mui.Typography>
        </Mui.TableCell>
        <Mui.TableCell align="right">
          <Mui.Typography>
            <b>{formatCurrency(parcela?.parcelaValor)}</b>
          </Mui.Typography>
        </Mui.TableCell>

        <Mui.TableCell align="center">
          <Mui.Tooltip title="Mais ações">
            <Mui.IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              aria-label={'Mais ações'}
              onClick={e => handleOpenMenu(e)}
            >
              <MuiIcons.MoreVert />
            </Mui.IconButton>
          </Mui.Tooltip>

          <Mui.Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <Mui.MenuItem
              onClick={() => {
                setItemEdicao(parcela);
                handleCloseMenu();
              }}
            >
              <MuiIcons.Edit fontSize="small" />
              <Mui.Typography style={{ marginLeft: 12 }}>Editar</Mui.Typography>
            </Mui.MenuItem>

            <Mui.MenuItem onClick={async () => handleDeletarParcela()}>
              <MuiIcons.Delete fontSize="small" />
              <Mui.Typography style={{ marginLeft: 12 }}>Deletar</Mui.Typography>
            </Mui.MenuItem>
            <Mui.Divider />

            {parcela?.finMeiopagamento?.nfePagamento === MEIO_PAGAMENTO.CHEQUE &&
              tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA && (
                <>
                  <Mui.MenuItem
                    onClick={() =>
                      dispatch(
                        pdvActions.setShowModalCadastroCheque({
                          show: true,
                          uuid: parcela?.uuid,
                          data: parcela?.cheque
                            ? { ...parcela?.cheque, chequeValor: parcela?.parcelaValor }
                            : {
                               
                                chequeValor: parcela?.parcelaValor,
                                chequeVencimento: parcela?.parcelaVencimento
                              }
                        })
                      )
                    }
                  >
                    <MuiIcons.Add fontSize="small" />
                    <Mui.Typography style={{ marginLeft: 12 }}>Adicionar Cheque</Mui.Typography>
                  </Mui.MenuItem>

                  <Mui.MenuItem
                    onClick={() => {
                      dispatch(pdvActions.removerChequeParcela(parcela.uuid));
                      // setItemPagamento(state => {
                      //   const parcelas = [state.parcelas];
                      //   parcelas[parcelaIndex].cheque = null;
                      //   return parcelas;
                      // });
                    }}
                  >
                    <MuiIcons.Remove fontSize="small" />
                    <Mui.Typography style={{ marginLeft: 12 }}>Remover Cheque</Mui.Typography>
                  </Mui.MenuItem>
                </>
              )}
          </Mui.Menu>
        </Mui.TableCell>
      </Mui.TableRow>
      {itemEdicao && (
        <Mui.TableRow>
          <Mui.TableCell colSpan={4}>
            <Mui.Grid container item spacing={2}>
              <Mui.Grid item md={4}>
                <DatePicker
                  label={'Vencimento'}
                  value={itemEdicao?.parcelaVencimento}
                  onChange={date => {
                    setItemEdicao(state => ({
                      ...state,
                      parcelaVencimento: date
                    }));
                  }}
                  renderInput={props => (
                    <Mui.TextField {...props} variant="outlined" fullWidth required />
                  )}
                />
              </Mui.Grid>
              <Mui.Grid item md={4}>
                <Mui.TextField
                  label={'Valor'}
                  variant="outlined"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: <Mui.InputAdornment position="start">R$</Mui.InputAdornment>,
                    inputComponent: CurrencyInputCustom
                  }}
                  value={itemEdicao?.parcelaValor}
                  onChange={e => {
                    const parcelaValor = Number(e.floatvalue);
                    setItemEdicao(state => ({
                      ...state,
                      parcelaValor
                    }));
                  }}
                />
              </Mui.Grid>
              <Mui.Grid item md={4}>
                <Mui.Box mt={2}>
                  <Mui.Button
                    className="button-success"
                    onClick={() => handleSalvarParcela()}
                  >
                    <MuiIcons.Save />
                    <span style={{ marginLeft: 4 }}>Salvar</span>
                  </Mui.Button>
                  <Mui.Button
                    style={{ marginLeft: 4 }}
                    onClick={() => {
                      setItemEdicao(null);
                    }}
                  >
                    <MuiIcons.Cancel />
                  </Mui.Button>
                </Mui.Box>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.TableCell>
        </Mui.TableRow>
      )}

      {showModalCadastroCheque?.show && (
        <ModalCadastroCheque />
      )}
    </>
  );
};

export default ParcelaPagamentoTableRow;
