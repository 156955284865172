
import { createSelector } from 'reselect';
import { APPLICATION, APPLICATION_MODE } from '~/constants';

const rootState = state => state?.app;

export const getApplicationIsOnline = () => createSelector(rootState, substate => {
  return substate.isInternetReachable && substate.applicationMode === APPLICATION_MODE.ONLINE
});

export const isWeb = APPLICATION === 'web';