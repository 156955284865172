import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import api from '~/services/api';
import { APP_ERROR, API_NODE_URL, API_LOCAL_URL } from '~/constants';
import { getApplicationIsOnline } from "~/store/modules/app/app-selectors"
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import { formatNumber, formatWeight } from '~/utils/format';

const ModalExpedicaoMercadoria = () => {
  const dispatch = useDispatch();
  const isOnline = useSelector(getApplicationIsOnline());
  const { showModalExpedicaoMercadoria, venda } = useSelector(state => state.pdv);
  const { parametros } = useSelector(state => state.auth);
  const [listaModalidadesExpedicao, setListaModalidadesExpedicao] = useState([]);
  const [modalidadeExpedicao, setModalidadeExpedicao] = useState(null);
  const [itens, setItens] = useState([...venda?.itens]);
  const [loading, setLoading] = useState(false);

  const handleChangeModalidadeExpedidcao = (omsModalidadeExpedicao, validaItem = false) => {
    setModalidadeExpedicao(omsModalidadeExpedicao);
    setItens(state => [
      ...state.map(item => {
        const modalidade = validaItem
          ? item?.omsModalidadeExpedicao ?? omsModalidadeExpedicao
          : omsModalidadeExpedicao;
        return {
          ...item,
          omsModalidadeExpedicao: modalidade
        };
      })
    ]);
  };

  const handleSubmit = () => {
    dispatch(
      pdvActions.alterarExpedicaoMercadorias({
        modalidadeExpedicao,
        itens
      })
    );
    dispatch(pdvActions.setShowModalExpedicaoMercadoria({ show: false }));

    if (showModalExpedicaoMercadoria?.confirmation) {
      dispatch(pdvActions.fecharVenda());
    }
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalExpedicaoMercadoria({ show: false }));
  };

  useEffect(() => {
    const parametro = parametros?.find(x => x.parametro === 'PH001');
    const omsModalidadeExpedicaoId = parametro ? parseInt(parametro?.valor) : null;

    const loadData = async () => {
      setLoading(true);
      try {
        const $select = ['descricao', 'desativado', 'controleEntrega', 'id'];
        const { data } = isOnline
          ? await api.get(
              `${API_NODE_URL}/consulta/modalidadeexpedicao?$orderby=id&$select=${$select}&$filter=desativado%20eq%20false`
            )
          : await api.get(`${API_LOCAL_URL}/oms/modalidadeexpedicao`);

        const omsModalidadeExpedicao = parametro
          ? data.find(x => x.id === omsModalidadeExpedicaoId)
          : data[0];

        setListaModalidadesExpedicao([...data]);
        handleChangeModalidadeExpedidcao(omsModalidadeExpedicao, true);
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const quantidadeTotal = itens ?.filter(x => !x.itemCancelado) ?.reduce((total, item) => total + item?.itemQuantidade, 0);

  const pesoLiquidoTotal = itens ?.filter(x => !x.itemCancelado) ?.reduce((total, item) => total + item?.produtoEstProduto?.pesoLiquido * item?.itemQuantidade, 0);

  const pesoBrutoTotal = itens ?.filter(x => !x.itemCancelado) ?.reduce((total, item) => total + item?.produtoEstProduto?.pesoBruto * item?.itemQuantidade, 0);

  return (
    <Mui.Dialog
      open={showModalExpedicaoMercadoria}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          handleClose();
        }
      }}
      PaperComponent={Mui.Paper}
      maxWidth={'lg'}
    >
      <Mui.DialogTitle>
        <Mui.AppBar color="primary" position="static">
          <Mui.Toolbar>
            <Mui.Box style={{ minWidth: 180 }}>
              <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                Modalidade de Expedição
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box flexGrow={1} />

            <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
              <MuiIcons.Close />
            </Mui.IconButton>
          </Mui.Toolbar>
        </Mui.AppBar>
      </Mui.DialogTitle>
      <Mui.DialogContent dividers style={{ minWidth: 720 }}>
        {loading ? (
          <>
            <Mui.Box display="flex" justifyContent="center" alignItems="center" p={4}>
              <Mui.CircularProgress variant="indeterminate" color="primary" />
            </Mui.Box>
          </>
        ) : (
          <Mui.Box p={2}>
            <Mui.Box display="flex" flexDirection="column" style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Mui.Typography variant="caption" align="left">
                Modalidade de expedição
              </Mui.Typography>

              <Mui.Box display="flex" flexDirection="row" justifyContent="space-between">
                <Mui.Select
                  id="modalidadeExpedicao"
                  variant="outlined"
                  fullWidth
                  disabled={loading}
                  style={{ height: 44, width: 320, marginLeft: 490 }}
                  value={modalidadeExpedicao}
                  onChange={e => handleChangeModalidadeExpedidcao(e.target.value)}
                >
                  {listaModalidadesExpedicao?.map(item => (
                  <Mui.MenuItem key={item?.id} value={item}>
                    {item?.descricao}
                  </Mui.MenuItem>
                  ))}
                </Mui.Select>
              </Mui.Box>
            </Mui.Box>

            <Mui.Table stickyHeader aria-label="sticky table">
              <Mui.TableHead>
                <Mui.TableRow>
                  <Mui.TableCell>Item</Mui.TableCell>
                  <Mui.TableCell>Produto</Mui.TableCell>
                  <Mui.TableCell>Quantidade</Mui.TableCell>
                  <Mui.TableCell>Peso Líquido</Mui.TableCell>
                  <Mui.TableCell>Peso Bruto</Mui.TableCell>
                  <Mui.TableCell>Modalidade</Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {itens?.filter(x => !x.itemCancelado)?.map((item, index) => (
                  <Mui.TableRow hover style={{ padding: 0 }} key={`index-${index}`}>

                    <Mui.TableCell>
                      <Mui.Typography variant="h4" align="center">
                        {item?.itemOrdem}
                      </Mui.Typography>
                    </Mui.TableCell>

                    <Mui.TableCell>
                      <Mui.Typography variant="body1">
                        {item?.produtoCodigo} {'-'} {item?.produtoDescricao}
                      </Mui.Typography>
                    </Mui.TableCell>

                    <Mui.TableCell>
                      <Mui.Typography variant="body1" align="center">
                        {formatNumber(item?.itemQuantidade)}
                      </Mui.Typography>
                    </Mui.TableCell>

                    <Mui.TableCell>
                      <Mui.Typography variant="body1" align="center">
                        {formatNumber(item?.produtoEstProduto?.pesoLiquido * item?.itemQuantidade)}
                      </Mui.Typography>
                    </Mui.TableCell>

                    <Mui.TableCell>
                      <Mui.Typography variant="body1" align="center">
                        {formatNumber(item?.produtoEstProduto?.pesoBruto * item?.itemQuantidade)}
                      </Mui.Typography>
                    </Mui.TableCell>

                    <Mui.TableCell>
                      <Mui.Select
                        variant="outlined"
                        fullWidth
                        style={{ height: 44, width: 320 }}
                        value={item?.omsModalidadeExpedicao?.id ?? null}
                        onChange={e => {
                          e.preventDefault();

                          setItens(state => {
                            const newItens = [...state];
                            const modalidade = listaModalidadesExpedicao.find(
                              x => x.id === e.target.value
                            );

                            newItens[newItens.indexOf(item)].omsModalidadeExpedicao = modalidade;

                            return newItens;
                          });
                        }}
                      >
                        {listaModalidadesExpedicao?.map((item, index) => (
                          <Mui.MenuItem key={index} value={item?.id}>
                            {item?.descricao}
                          </Mui.MenuItem>
                        ))}
                      </Mui.Select>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.Box>
        )}
      </Mui.DialogContent>
      <Mui.DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left" }}>
          <Mui.Typography align="center">
            Qtd. Total
            <br />
            {formatNumber(quantidadeTotal)}
          </Mui.Typography>
        </div>

        <div style={{ textAlign: "left" }}>
          <Mui.Typography align="center">
            Peso Liq. Total
            <br />
            {formatWeight(pesoLiquidoTotal)}
          </Mui.Typography>
        </div>

        <div style={{ textAlign: "left" }}>
          <Mui.Typography align="center">
            Peso Bruto Total
            <br />
            {formatWeight(pesoBrutoTotal)}
          </Mui.Typography>
        </div>

        <div style={{ textAlign: "right" }}>
          <Mui.Button
            size="large"
            startIcon={<MuiIcons.CheckCircle />}
            onClick={() => handleSubmit()}
            variant="contained"
            color="primary"
          >
            <span style={{ paddingLeft: 8 }}> CONFIRMAR</span>
          </Mui.Button>

          <Mui.Button startIcon={<MuiIcons.Close />} onClick={handleClose}>
            CANCELAR (ESC)
          </Mui.Button>
        </div>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default ModalExpedicaoMercadoria;
