import styled from 'styled-components';
import { withTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconArrowBack from '@material-ui/icons/ArrowBack';
import IconArrowRight from '@material-ui/icons/KeyboardTab';

export const Container = styled.div`
  background-color: ${props => props.theme.palette.common.black};
  /* background-color: rgba(0, 0, 0, 0.8); */
  color: #fff;
  height: 174px;
  border-radius: 0 0 6px 0;
  box-shadow: ${props => props.theme.shadows[6]};
`;

export const ButtonReturn = styled(Button).attrs({
  startIcon: <IconArrowBack />,
  variant: 'contained',
  size: 'large'
})`
  background-color: #fff;
  min-width: 180px;
  height: 44px;
`;

export const ButtonNext = styled(Button).attrs({
  endIcon: <IconArrowRight />,
  variant: 'contained',
  size: 'large'
  // dark: true
})`
  display: flex;
  background-color: #fff;
  margin-left: 16px;
  min-width: 160px;
  height: 44px;
`;

export const ButtonPrimary = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
  size: 'large',
  dark: true
})`
  display: flex;
  margin-left: 16px;
  min-width: 140px;
  height: 44px;

`;

export const ButtonSubmit = withTheme(styled(Button).attrs({
  variant: 'contained',
  size: 'large'
})`
  margin-left: 16px;
  background-color: ${props => props.disabled ? '#999 !important' : '#09a848'} ;
  color: ${props => props.disabled ? '#666 !important' : '#FFF'};
  min-width: 300px;
  height: 44px;
`);
