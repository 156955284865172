import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import IconClose from '@material-ui/icons/Close';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import { formatDatetime } from '~/utils/format';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import Label from '~/components/Label';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 640,
    maxWidth: '100%'
  },
  toolbar: {
    minHeight: 44
  }
}));

const TimelinePedido = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    loadingHistoricos,
    pedidoSelecionado,
    pedidoHistoricos,
    showTimelinePedido
  } = useSelector(state => state.monitor);

  const handleClose = () => {
    dispatch(monitorActions.setShowTimelinePedido(false));
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      ModalProps={{ BackdropProps: { invisible: false } }}
      onClose={handleClose}
      open={showTimelinePedido}
      variant="temporary"
    >
      <AppBar color="default" position="static">
        <Toolbar className={classes.toolbar}>
          <Box display={'flex'} flexDirection={'row'} alignItems="center">
            <Typography variant="h5">Timeline do pedido {' - '}</Typography>

            {loadingHistoricos ? (
              <></>
            ) : (
              <Button onClick={() => {}}>
                Nº {pedidoSelecionado?.serie}/{pedidoSelecionado?.numero}
              </Button>
            )}
          </Box>

          <Box ml={2} flexGrow={1} />
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <IconClose />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Divider />
      <Box
        style={{
          display: 'flex',
          flex: 1,
          height: 'calc(100vh - 258px)',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {loadingHistoricos ? (
          <>
            <CircularProgress color="secondary" />
            <p>Carregando...</p>
          </>
        ) : (
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Timeline align="alternate" style={{ margin: 16 }}>
              {pedidoHistoricos.map((item, index) => (
                <TimelineItem key={item.id}>
                  <TimelineSeparator>
                    <TimelineDot
                      color={
                        index === 0 || pedidoHistoricos.length - 1 === index ? 'primary' : 'grey'
                      }
                      variant="default"
                    >
                      <Icon>{item?.icone}</Icon>
                    </TimelineDot>
                    {pedidoHistoricos.length - 1 !== index && <TimelineConnector />}
                  </TimelineSeparator>

                  <TimelineContent>
                    <Label color="blue" style={{ marginBottom: 8 }}>
                      {formatDatetime(new Date(item?.dataHora))}
                    </Label>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<IconExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column'
                          }}
                        >
                          <Typography variant="h5" component="h1">
                            {item?.titulo}
                          </Typography>
                          <Typography variant="caption" component="p">
                            {item?.usuario?.nome}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails>
                        <Box p={1}>
                          <Typography variant="body1">{item?.mensagem}</Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </PerfectScrollbar>
        )}
      </Box>
    </Drawer>
  );
};

export default TimelinePedido;
