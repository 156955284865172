import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Search as IconSearch } from '@material-ui/icons';
import * as MuiIcons from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

const SearchAdornment = ({ searchBy, setSearchBy, anchorEl, setAnchorEl }) => {
  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <InputAdornment position="end" style={{ position: 'absolute', right: 0 }}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        aria-label={'Mais ações'}
        onClick={handleMenuOpen}
      >
        {searchBy === 'termo' && <IconSearch />}
        {searchBy === 'exato' && <MuiIcons.ZoomIn />}
        {searchBy === 'inicio' && <MuiIcons.ArrowForward />}
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => {
          setSearchBy('termo');
          handleMenuClose();
        }}>
          <IconSearch fontSize="small" />
          <span style={{ marginLeft: 12 }}>Pesquisa por termo</span>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => {
          setSearchBy('exato');
          handleMenuClose();
        }}>
          <MuiIcons.ZoomIn fontSize="small" />
          <span style={{ marginLeft: 12 }}>Pesquisa por valor exato</span>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => {
          setSearchBy('inicio');
          handleMenuClose();
        }}>
          <MuiIcons.ArrowForward fontSize="small" />
          <span style={{ marginLeft: 12 }}>Inicia com</span>
        </MenuItem>
      </Menu>
    </InputAdornment>
  );
};

export default SearchAdornment;
