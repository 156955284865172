import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import IconClose from '@material-ui/icons/Close';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconCancel from '@material-ui/icons/Cancel';

import api from '~/services/api';
import toast from '~/services/toast';
import pedidoService from '~/store/modules/pedido/pedido-service';
import { API_BASE_URL, API_LOCAL_URL, APP_ERROR, TIPO_DOCUMENTO } from '~/constants';
import { formatCurrency } from '~/utils/format';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import PedidoIdentificacao from '../../components/PedidoIdentificacao';
import PedidoHistoricoBloqueios from '../../components/PedidoHistoricoBloqueios';
import PedidoParcelas from '../../components/PedidoParcelas';
import LiberacaoAcesso from '../../components/LiberacaoAcesso';

const ModalLiberacaoComercial = ({ id, isDocumentoOffline, open, onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [pedido, setPedido] = useState(null);
  const [disableModal, setDisableModal] = useState(true);
  const [tokenLiberacaoRotina, setTokenLiberacaoRotina] = useState(null);
  const [imprimirAposLiberacao, setImprimirAposLiberacao] = useState(false);

  const handleSubmitLiberar = async () => {
    try {
      const { isConfirmed } = await toast.confirm(
        'Deseja liberar o pedido?',
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
        {
          icon: 'success',
          confirmButtonText: 'Liberar',
          cancelButtonText: 'Cancelar'
        }
      );

      if (isConfirmed) {
        pedidoService.setStategy(isDocumentoOffline);
        const { data } = await pedidoService.liberarBloqueioComercialNegociacao(
          isDocumentoOffline ? pedido?._id : pedido?.id,
          pedido,
          tokenLiberacaoRotina
        );
        toast.success('Operação realizada com sucesso!');

        if (imprimirAposLiberacao) {
          dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.PEDIDO, data));
        }
        onClose();
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const handleSubmitRejeitar = async () => {
    try {
      const { isConfirmed } = await toast.confirm(
        'Deseja rejeitar o pedido?',
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
        {
          icon: 'danger',
          confirmButtonText: 'Rejeitar',
          cancelButtonText: 'Cancelar'
        }
      );

      if (isConfirmed) {
        pedidoService.setStategy(isDocumentoOffline);
        await pedidoService.rejeitarBloqueioComercialNegociacao(id, pedido, tokenLiberacaoRotina);
        toast.success('Operação realizada com sucesso!');
        onClose();
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);

      const { data } = await api.get(
        isDocumentoOffline
          ? `${API_LOCAL_URL}/faturamento/pedido/${id}`
          : `${API_BASE_URL}/v1/faturamento/pedido/bloqueioregracomercial/${id}`
      );
      setPedido(data);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <DialogTitle>
          <AppBar color="primary" position="static">
            <Toolbar>
              <Box>
                <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Liberação do pedido - regra comercial
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <IconButton color="inherit" onClick={onClose} aria-label="close">
                <IconClose />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: 640, padding: 16 }}>
          {!loading && disableModal && (
            <LiberacaoAcesso
              rotina="F021ANP"
              callback={(acesso, token) => {
                setDisableModal(!acesso);
                if (acesso) {
                  setTokenLiberacaoRotina(token);
                }
              }}
            />
          )}
          {loading ? (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <CircularProgress variant="indeterminate" color="primary" />
              </Box>
            </>
          ) : (
            <>
              <PedidoIdentificacao data={pedido} />

              <Paper>
                <Box px={2} pt={1}>
                  <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
                    ITENS
                  </Typography>
                </Box>
                <Box p={2}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Produto</TableCell>
                          <TableCell align="center">Quant. </TableCell>
                          <TableCell align="right">Total bruto </TableCell>
                          <TableCell align="right">Total líquido</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pedido?.itens?.map((item, index) => (
                          <TableRow key={`item-${index.toString()}`}>
                            <TableCell>
                              {item?.produtoDescricao} <br />{' '}
                              <small>
                                PC | Vl unitário: {formatCurrency(item?.itemValorUnitario)}
                              </small>
                            </TableCell>
                            <TableCell align="center">{item?.itemQuantidade}</TableCell>
                            <TableCell align="right">
                              <Typography variant="h5">
                                {formatCurrency(item?.itemValorTotal)}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography variant="h5">
                                {formatCurrency(item?.itemValorTotal)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Paper>

              <PedidoParcelas data={pedido?.parcelas ?? []} />

              <PedidoHistoricoBloqueios data={pedido?.fatPedidoBloqueio ?? []} />
            </>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Box flex={1} display="flex" justifyContent="space-between">
              <Box>
                <Button
                  variant="contained"
                  className="button-success"
                  disabled={disableModal}
                  onClick={() => handleSubmitLiberar()}
                >
                  <IconCheckCircle /> <Mui.Typography variant="span" style={{marginLeft: 8}}>Liberar pedido</Mui.Typography>
                </Button>
                <Button
                  variant="contained"
                  className="button-danger"
                  disabled={disableModal}
                  style={{ marginLeft: 8 }}
                  onClick={() => handleSubmitRejeitar()}
                >
                  <IconCancel /> <Mui.Typography variant="span" style={{marginLeft: 8}}> Rejeitar pedido</Mui.Typography>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: 12 }}
                  control={
                    <Switch
                      checked={imprimirAposLiberacao}
                      onChange={() => setImprimirAposLiberacao(state => !state)}
                    />
                  }
                  label="Imprimir após liberação"
                />
              </Box>
              <Button
                startIcon={<MuiIcons.Close />}
                size="large"
                style={{ marginLeft: 8 }}
                onClick={onClose}
              >
                CANCELAR (ESC)
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
export default ModalLiberacaoComercial;
