import axios from 'axios';
import { all, call, put, takeLatest, delay, select } from '@redux-saga/core/effects';

import { API_LOCAL_URL, APPLICATION_MODE } from '~/constants';
import { SEARCH_REQUEST } from './cliente-constants';
import * as actions from './cliente-actions';
import clienteService from './cliente-service';
import api from '~/services/api';

let cancel;
const CancelToken = axios.CancelToken;

export function* pesquisaCliente({ queryText }) {
  yield delay(200);

  try {
    if (cancel != undefined) cancel();
    const cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    });

    if (queryText && queryText?.length < 3 && isNaN(parseInt(queryText))) {               
      yield put(actions.searchSuccess([]));
      return;
   }

    yield put(actions.setLoading(true));

    const applicationMode = yield select(state => state?.applicationMode);
    const {
      data: { items }
    } =
      applicationMode === APPLICATION_MODE.ONLINE
        ? yield call(clienteService.query, queryText, {
            cancelToken
          })
        : yield call(
            api.get,
            `${API_LOCAL_URL}/integracao/cliente/?queryString=${queryText?.toUpperCase()}`,
            {
              cancelToken
            }
          );

    yield put(actions.searchSuccess([...items]));
  } catch (error) {
    // throw new Error(error);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(SEARCH_REQUEST, pesquisaCliente)]);
}
