import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@mui/icons-material';

import * as appActions from '~/store/modules/app/app-actions';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import { APP_SYNC_DATE } from '~/constants';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 340
  }
}));

const ButtonQueueDocuments = () => {
  const dispatch = useDispatch();

  const { showProgressSync, mensagemSync } = useSelector(state => state.app);
  const [dataSincronizacao, setDataSincronizacao] = useState();
  const isOnline = useSelector(getApplicationIsOnline());

  useEffect(() => {
    const _dataSyncLocal = sessionStorage.getItem(APP_SYNC_DATE, null);
    setDataSincronizacao(_dataSyncLocal ? JSON.parse(_dataSyncLocal) : null);
  }, []);

  return (
    <Mui.Button
      disabled={!isOnline}
      onClick={() => {
        dispatch(appActions.downloadData([]));
      }}
      style={{ borderRadius: 0, color: '#FFF' }}
    >
      {showProgressSync ? (
        <Mui.CircularProgress style={{ color: '#FFF' }} size={20} />
      ) : (
        <MuiIcons.Downloading />
      )}

      <Mui.Typography style={{ fontSize: 12, marginLeft: 8, textTransform: 'none' }}>
        <>
          {showProgressSync ? (
            mensagemSync
          ) : (
            <>
              {dataSincronizacao
                ? `Sincronizado: ${dataSincronizacao}hrs`
                : 'Nenhuma sincronização'}
            </>
          )}
        </>
      </Mui.Typography>
    </Mui.Button>
  );
};

export default ButtonQueueDocuments;
