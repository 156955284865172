import React, { useState, useEffect, useRef, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import {
  NavigateNext as IconNavigateNext,
  NavigateBefore as IconNavigateBefore,
  CheckCircle as IconCheckCircle,
  CreditCard as IconCreditCard,
  Close as IconClose,
  ArrowBack as IconArrowBack
} from '@material-ui/icons';

import { STATUS_PDV, TIPO_DOCUMENTO } from '~/constants';
import { formatCurrency, formatDate } from '~/utils/format';
import { getParametroAsBoolean } from '~/utils/parametros';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import PagamentoParcela from './components/PagamentoParcela';
import { Header, List, ListItem, ButtonPagamento, Selected } from './styles';
import { selectorParametroAsBoolean } from '~/store/modules/auth/auth-selectors';

const ModalConsultaPagamento = () => {
  const [obg, setObg] = useState(false);
  const obrigaMeioPagamento = useSelector(selectorParametroAsBoolean('PP001'));
  const dispatch = useDispatch();
  const buttonSubmitRef = createRef();
  const {
    venda,
    tipoDocumento,
    matrizCondicaoPagamento,
    itemPagamento,
    showModalConsultaPagamento
  } = useSelector(state => state.pdv);
  const { parametros } = useSelector(state => state.auth);
  const { showModalLoadingMensagem } = useSelector(state => state.pdv);
  const [montarParcelas] = useState(
    venda?.valorTotal > 0 && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO
  );
  const [condicoesMeioPagamento, setCondicoesMeioPagamento] = useState(
    showModalConsultaPagamento?.pagamentoSelecionado?.condicoes ?? []
  );
  const [meioPagamentoSelecionado, setMeioPagamentoSelecionado] = useState(
    showModalConsultaPagamento?.pagamentoSelecionado?.meioPagamento ?? null
  );
  const [condicaoPagamentoSelecionado, setCondicaoPagamentoSelecionado] = useState(
    showModalConsultaPagamento?.pagamentoSelecionado?.condicaoPagamento ?? null
  );

  const condicaoPagamentoObrigatoria = getParametroAsBoolean(parametros, 'PF018') ?? false;

  const idsClientePagamento = venda?.intCliente?.clienteMeioPagamento?.map(pagamento => pagamento.finMeiopagamentoId);

  const clienteCondicaoPagamento = matrizCondicaoPagamento?.map(pagamento => {
    if(idsClientePagamento?.includes(pagamento.id) || pagamento.nfePagamento === 5) {
      return {
        ...pagamento,
        enabled: true
      };
    } else {
      return {
        ...pagamento
      }
    }
  });


  let condicoesPagamento = venda?.intCliente?.id ? clienteCondicaoPagamento : matrizCondicaoPagamento;
  if(idsClientePagamento?.length === 0 || !venda?.intCliente || !idsClientePagamento) {
    condicoesPagamento = matrizCondicaoPagamento?.map(pagamento => {
      return {
        ...pagamento,
        enabled: true
      };
    });
  }

  const handleClose = (arg) => {
    if(arg === 'canceled') dispatch(pdvActions.removerParcela(itemPagamento?.finMeiopagamento?.id));
      dispatch(
        pdvActions.setShowModalConsultaPagamento({
          show: false
        })
      );
      setObg(false);
  };

  const handleConfirmarPagamento = () => {
    dispatch(
      pdvActions.selecionarMeioPagamento({
        ...meioPagamentoSelecionado,
        condicoes: [...condicoesMeioPagamento]
      })
    );
    dispatch(
      pdvActions.selecionarCondicaoPagamento(condicaoPagamentoSelecionado, () => {
        if (showModalConsultaPagamento?.fecharVenda) {
          handleClose();
          dispatch(pdvActions.fecharVenda());
        } else if (showModalConsultaPagamento?.confirmaPagamento) {
          handleClose();
          dispatch(pdvActions.setShowModalConfirmaPagamento({ show: true }));
        } else {
          handleClose();
        }
      })
    );
  };

  useEffect(() => {
    if (meioPagamentoSelecionado && condicaoPagamentoSelecionado) {
      buttonSubmitRef?.current?.focusVisible();
    }
  }, [meioPagamentoSelecionado, condicaoPagamentoSelecionado]);

  useEffect(() => {
    if(obrigaMeioPagamento && !meioPagamentoSelecionado && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA) {
      dispatch(
        pdvActions.setShowModalConsultaPagamento({
          show: true
        })
      );
      setObg(true);
    } else {
      setObg(false);
    }
  }, []);


  return (
    <Dialog
      open={showModalConsultaPagamento?.show}
      onKeyDown={key => {
        if (key.code === 'Escape' && !obg) {
          handleClose();
        }

        if (key.code === 'Enter' && meioPagamentoSelecionado && condicaoPagamentoSelecionado) {
          handleConfirmarPagamento();
        }
      }}
      PaperComponent={Paper}
      maxWidth={'md'}
    >
      <DialogTitle>
        <AppBar color="primary" position="static">
          <Toolbar>
            <Box style={{ minWidth: 180 }}>
              <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                Consulta Pagamento
              </Typography>
            </Box>
            <Box flexGrow={1} />

            { !obg && <IconButton color="inherit" onClick={handleClose} aria-label="close" tabIndex="-1">
              <IconClose />
            </IconButton>}
          </Toolbar>
        </AppBar>
        <Header>
          {meioPagamentoSelecionado && (
            <Fab
              size="small"
              onClick={() => {
                setMeioPagamentoSelecionado(null);
              }}
              tabIndex="-1"
            >
              <IconArrowBack />
            </Fab>
          )}

          <Box ml={1}>
            <Typography variant="caption">Meio de pagamento</Typography>
            {meioPagamentoSelecionado ? (
              <Typography variant="h5">{meioPagamentoSelecionado?.descricao}</Typography>
            ) : (
              <Typography variant="h5">SELECIONAR PAGAMENTO</Typography>
            )}
          </Box>
        </Header>
      </DialogTitle>
      <DialogContent dividers style={{ width: 640 }}>
        <List>
          {!meioPagamentoSelecionado &&
            condicoesPagamento?.map((meio, index) => {
              const isSelected = meioPagamentoSelecionado?.id === meio?.id;
              return (
                <ListItem key={`index-${index}`}>
                  <ButtonPagamento
                    // autoFocus
                    fullWidth
                    focusRipple={false}
                    variant="outlined"
                    disabled={meio?.enabled ? false : true}
                    onClick={() => {
                      setMeioPagamentoSelecionado(meio);
                      setCondicoesMeioPagamento(meio?.condicoes);
                      setCondicaoPagamentoSelecionado(
                        meio?.condicoes?.length === 1 ? meio?.condicoes[0] : null
                      );
                      if (meio?.condicoes?.length === 1) {
                        buttonSubmitRef?.current?.focusVisible();
                      }

                      // if (condicaoPagamentoObrigatoria) {
                      //   setMeioPagamentoSelecionado(meio);
                      //   setCondicoesMeioPagamento(meio?.condicoes);
                      //   setCondicaoPagamentoSelecionado(
                      //     meio?.condicoes?.length === 1 ? meio?.condicoes[0] : null
                      //   );
                      //   if (meio?.condicoes?.length === 1) {
                      //     buttonSubmitRef?.current?.focusVisible();
                      //   }
                      // } else {
                      //   handleClose();
                      //   dispatch(
                      //     pdvActions.setShowModalMultiploPagamento({
                      //       show: true,
                      //       pagamentoSelecionado: {
                      //         meioPagamento: meio
                      //       }
                      //     })
                      //   );
                      // }
                    }}
                  >
                    <Box
                      display="flex"
                      flex={1}
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      px={2}
                      py={2}
                    >
                      <Typography
                        align="left"
                        style={{
                          color: isSelected ? '#33cb8e' : 'unset',
                          fontWeight: isSelected ? 600 : 500
                        }}
                      >
                        {meio?.descricao}
                      </Typography>
                      <IconNavigateNext />
                    </Box>
                  </ButtonPagamento>
                </ListItem>
              );
            })}

          {meioPagamentoSelecionado && condicoesMeioPagamento?.length > 0 && (
            <>
              <Typography variant="caption" style={{ marginLeft: 16, marginBottom: 16 }}>
                Condição de pagamento
              </Typography>
              {condicoesMeioPagamento?.map((condicao, index) => {
                const selected = condicaoPagamentoSelecionado?.id === condicao?.id;
                return (
                  <ListItem key={`index-${index}`}>
                    <ButtonPagamento
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        setCondicaoPagamentoSelecionado(condicao);
                        buttonSubmitRef?.current?.focusVisible();
                      }}
                    >
                      <Selected selected={selected} />
                      <Box
                        display="flex"
                        flex={1}
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px={2}
                        py={2}
                      >
                        <Box p={1}>
                          <Typography align="left" variant="body1">
                            {condicao?.descricao}
                          </Typography>
                          {/* <PagamentoParcela
                            show={montarParcelas}
                            selected={selected}
                          /> */}
                        </Box>

                        {/* {montarParcelas && (
                          <Box display="flex" flexDirection="row">
                            <Typography>
                              <b>{formatCurrency(condicao?.valorTotal ?? 0)}</b>
                            </Typography>

                            <IconNavigateNext />
                          </Box>
                        )} */}
                      </Box>
                    </ButtonPagamento>
                  </ListItem>
                );
              })}
            </>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          action={buttonSubmitRef}
          size="large"
          disabled={!meioPagamentoSelecionado || !condicaoPagamentoSelecionado || showModalLoadingMensagem.show}
          onClick={handleConfirmarPagamento}
          variant="contained"
          color="primary"
        >
          <MuiIcons.CheckCircle />
          <span style={{ paddingLeft: 8 }}> CONFIRMAR SELEÇÃO</span>
        </Button>
        {!obg && <Button
          startIcon={<MuiIcons.Close />}
          size="large"
          style={{ marginLeft: 8 }}
          onClick={() => handleClose('canceled')}
        >
          CANCELAR (ESC)
        </Button>}
      </DialogActions>
    </Dialog>
  );
};

export default ModalConsultaPagamento;
