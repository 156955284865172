import React from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const SnackbarOffline = ({ open, callback }) => {
  const isInternetReachable = useSelector(state => state.app.isInternetReachable);

  const onClose = () => {
    if (callback) callback();
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={5000}
      message={
        isInternetReachable
          ? 'Sua conexão com a internet foi restaurada.'
          : 'Você está offline no momento.'
      }
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

export default SnackbarOffline;
