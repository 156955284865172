import { SEARCH_SUCCESS, SET_APPLICATION_MODE, SET_LOADING } from './cliente-constants';

const produtoReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case SET_APPLICATION_MODE:
      return {
        ...state,
        applicationMode: action.applicationMode
      };

    case SEARCH_SUCCESS: {
      return {
        ...state,
        items: [...action.items],
        loading: false
      };
    }

    default:
      return state;
  }
};

export default produtoReducer;
