import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import currency from 'currency.js';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {
  Close as IconClose,
  ExpandMore as IconExpandMore
} from '@material-ui/icons';

import { formatCurrency } from '~/utils/format';
import { Header } from './styles';

const ModalCondicaoPagamentoProduto = React.memo(
  ({ open, data: { produto, precoVenda }, onClose }) => {
    const theme = useTheme();
    const { matrizCondicaoPagamento } = useSelector(state => state.pdv);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
      <Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'sm'}
      >
        <DialogTitle>
          <AppBar color="primary" position="static">
            <Toolbar>
              <Box style={{ minWidth: 180 }}>
                <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Condição pagamento
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <IconButton color="inherit" onClick={onClose} aria-label="close">
                <IconClose />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Grid item md={12}>
            <Header>
              <Typography variant="caption">Produto</Typography>
              <Typography variant="h3">
                {produto?.codigo} {' - '}
                {produto?.descricao}
              </Typography>
            </Header>
          </Grid>
        </DialogTitle>
        <DialogContent dividers style={{ padding: 16 }}>
          {matrizCondicaoPagamento.map((meioPagamento, index) => (
            <Accordion
              key={`index-${index}`}
              expanded={expanded === meioPagamento?.id}
              onChange={handleChange(meioPagamento?.id)}
            >
              <AccordionSummary
                expandIcon={<IconExpandMore />}
                aria-controls={`panel${meioPagamento?.id}bh-content`}
                id={`panel${meioPagamento?.id}bh-header`}
              >
                <Typography variant={'h5'}>
                  {meioPagamento?.descricao}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List component="nav" style={{ width: '100%' }}>
                  {meioPagamento?.condicoes?.map(condicao => (
                    <>
                      <ListItem key={condicao?.id}>
                        <Box
                          display="flex"
                          flex="1"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItem="center"
                        >
                          <Typography variant="body2">
                            {condicao?.descricao}
                          </Typography>

                          <Typography variant="h5">
                            {formatCurrency(
                              currency(precoVenda).add(
                                currency(precoVenda).multiply(
                                  condicao?.percentualAcrescimo
                                )
                              )
                            )}
                          </Typography>
                        </Box>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ModalCondicaoPagamentoProduto;
