import React from 'react';
import { STORAGE_URL } from '~/constants';
import { TextDefault } from '../../../../TelaVenda/styles';

const UserInfoSection = ({ user, empresa }) => {
  const urlLogo = `${STORAGE_URL}logo/${empresa?.imagemLogo}`;

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
      <div className="color-black">
        <TextDefault>
          <strong style={{ fontSize: '13px' }}>Usuário:</strong> {user.nome}
        </TextDefault>
        <TextDefault>
          <strong style={{ fontSize: '13px' }}>Data Hora:</strong> {new Date().toLocaleString('pt-BR')}
        </TextDefault>
      </div>

      <div style={{ textAlign: 'end' }}>
        <div className="color-black" style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ alignItems: 'end' }}>
            <div style={{ fontSize: '13px' }}>{empresa.razaoSocial}</div>
            <div style={{ fontSize: '13px' }}>
              {empresa.enderecoLogradouro}, {empresa.enderecoLogradouroNumero}, {empresa.enderecoIntCidade} / {empresa.uf}
            </div>
            <div style={{ fontSize: '13px' }}>{empresa.cnpj}</div>
          </div>
          <div className="container-logo">
            {urlLogo && <img className="img-logo" src={urlLogo} alt="logo" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoSection;
