import React from 'react';
import { Grid, ListItem, makeStyles, Typography } from '@material-ui/core';
import SwapVertIcon from '@material-ui/icons/SwapVert';

const useStyles = makeStyles({
  typographyButton: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center'
  }
});

const ListHeader = ({ setOrderBy, sortBy, setSortBy }) => {
  const classes = useStyles();
  const Ordenacao = name => {
    setOrderBy(name);
    if (sortBy === '') setSortBy('asc');
    if (sortBy === 'asc') setSortBy('desc');
    if (sortBy === 'desc') setSortBy('asc');
  };

  return (
    <ListItem>
      <Grid container item>
        <Grid item md={1}>
          <Typography className={classes.typographyButton}>Código</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography className={classes.typographyButton}>Descrição</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography className={classes.typographyButton}>Cód. Barra</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography className={classes.typographyButton}>Tabela Desc.</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography className={classes.typographyButton}>Regra Desc.</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography className={classes.typographyButton}>Pr. Venda</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography className={classes.typographyButton}>Qtd. Min.</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography className={classes.typographyButton}>Total Bruto</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography className={classes.typographyButton}>Desconto</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography className={classes.typographyButton}>Total Liq.</Typography>
        </Grid>
        <Grid item md={1}>
          <Typography align="left" style={{ fontWeight: 600, cursor: 'pointer' }}></Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ListHeader;
