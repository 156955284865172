import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';

import { APP_TERMINAIS, APP_TERMINAL_PDV } from '~/constants';
import * as appActions from '~/store/modules/app/app-actions';

const ModalIdentificacaoPDV = ({ allowCloseModal = true }) => {
  const dispatch = useDispatch();

  const { showModalIdentificacaoPDV } = useSelector(state => state.app);
  const [pdvIdentificacao, setPdvIdentificacao] = useState('');
  const [terminais, setTerminais] = useState([]);

  const handleSubmit = () => {
    if (pdvIdentificacao) {
      localStorage.setItem(APP_TERMINAL_PDV, pdvIdentificacao);
      localStorage.setItem(APP_TERMINAIS, JSON.stringify(terminais));

      dispatch(appActions.setTerminal(pdvIdentificacao ?? null));
      dispatch(appActions.setShowmodalIdentificacaoPDV({ show: false }));
    }
  };

  const handleClose = () => {
    dispatch(appActions.setShowmodalIdentificacaoPDV({ show: false }));
  };

  useEffect(() => {
    const _pdvIdentificacao = localStorage.getItem(APP_TERMINAL_PDV);
    const _terminais = JSON.parse(localStorage.getItem(APP_TERMINAIS) ?? '[]');

    if (_pdvIdentificacao) {
      setPdvIdentificacao(_pdvIdentificacao);
    }

    if (_terminais) {
      setTerminais(_terminais);
    }
  }, []);

  return (
    <Mui.Dialog
      open={showModalIdentificacaoPDV?.show}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          if (handleClose && allowCloseModal) handleClose();
        }
      }}
      PaperComponent={Mui.Paper}
      maxWidth={'lg'}
    >
      <Mui.DialogTitle>
        <Mui.AppBar color="primary" position="static">
          <Mui.Toolbar>
            <Mui.Box>
              <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                Identificação do PDV
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box flexGrow={1} />
          </Mui.Toolbar>
        </Mui.AppBar>
      </Mui.DialogTitle>
      <Mui.DialogContent dividers style={{ width: 640, height: 440 }}>
        <Mui.Box p={2}>
          <Mui.Grid container>
            <Mui.Grid item md={12}>
              <Mui.TextField
                label="Será necessário informar a identificação única do PDV."
                fullWidth
                variant="outlined"
                value={pdvIdentificacao}
                onChange={e => setPdvIdentificacao(e.target?.value?.toUpperCase())}
                InputProps={{
                  style: {
                    fontSize: 24
                  }
                }}
              />
            </Mui.Grid>
            <Mui.Grid item md={12}>
              <Alert severity="error" variant="filled" style={{ margin: '8px 0 ' }}>
                <AlertTitle>AVISO</AlertTitle>
                <Mui.Typography variant="h5">
                  Necessário liberar a porta <b>3335</b> nos terminais.
                </Mui.Typography>
              </Alert>
            </Mui.Grid>
            <Mui.Grid item md={12}>
              <Mui.Paper>
                <Mui.Table>
                  <Mui.TableHead>
                    <Mui.TableCell align="left">Terminal</Mui.TableCell>
                    <Mui.TableCell align="left">IP Local</Mui.TableCell>
                    <Mui.TableCell align="center" style={{ width: '160px' }}>
                      #
                    </Mui.TableCell>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {terminais?.map((terminal, index) => (
                      <Mui.TableRow key={index}>
                        <Mui.TableCell align="center">
                          <Mui.TextField
                            label="Terminal"
                            type={'text'}
                            fullWidth
                            variant="outlined"
                            value={terminal?.identificacaoTerminal}
                            onChange={e =>
                              setTerminais(state => {
                                const _terminais = [...state];
                                _terminais[index].identificacaoTerminal = e.target?.value?.toUpperCase();

                                return [..._terminais];
                              })
                            }
                          />
                        </Mui.TableCell>
                        <Mui.TableCell align="center">
                          <Mui.TextField
                            label="IP Local"
                            type={'text'}
                            fullWidth
                            variant="outlined"
                            value={terminal?.ipLocalTerminal}
                            onChange={e =>
                              setTerminais(state => {
                                const _terminais = [...state];
                                _terminais[index].ipLocalTerminal = e.target.value;

                                return [..._terminais];
                              })
                            }
                          />
                        </Mui.TableCell>
                        <Mui.TableCell align="center" style={{ width: 44 }}>
                          <Mui.Button
                            onClick={() => {
                              setTerminais(state => {
                                const _terminais = [...state];
                                _terminais.splice(index, 1);

                                return [..._terminais];
                              });
                            }}
                          >
                            <MuiIcons.Delete />
                          </Mui.Button>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ))}
                  </Mui.TableBody>
                </Mui.Table>
              </Mui.Paper>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Box>
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button
          className="button-success"
          onClick={() => {
            setTerminais(state => [...state, { uuid: uuid() }]);
          }}
        >
          <MuiIcons.Add /> Adicionar terminal
        </Mui.Button>
        <Mui.Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginLeft: 16 }}
        >
          <MuiIcons.Save />
          <span style={{ paddingLeft: 8 }}> Salvar </span>
        </Mui.Button>
        {allowCloseModal && (
          <Mui.Button
            size="large"
            onClick={() => {
              if (handleClose) {
                handleClose();
              }
            }}
          >
            <MuiIcons.Close />
            <span style={{ paddingLeft: 8 }}> Cancelar (ESC) </span>
          </Mui.Button>
        )}
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default ModalIdentificacaoPDV;
