import styled from 'styled-components';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiButton from '@material-ui/core/Button';
import { alpha } from '@material-ui/core';

export const Container = styled.div`
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 16px;
  color: #fff;
  background-color: ${props => props.theme.palette.common.black};
  box-shadow: ${props => props.theme.shadows[6]};
`;

export const List = styled(MuiList)`
  margin: 0;
  padding: 0;
`;

export const ListItem = styled(MuiListItem)`
  padding: 0;
`;

export const Selected = styled.div`
  background-color: ${props => (props?.selected ? '#09A848' : 'transparent')};
  border-radius: 4px;
  width: 6px;
  height: 54px;
`;

export const ButtonMenu = styled(MuiButton).attrs({
  variant: 'outlined'
})`
  margin: 0;
  height: 74px;
  margin-bottom: 16px;
  width: 100%;
  font-size: 16px;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */

  /* :focus {
    background-color: rgba(0, 0, 0, 0.1);
  } */
`;
