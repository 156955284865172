import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import IconClose from '@material-ui/icons/Close';
import IconCheckCircle from '@material-ui/icons/CheckCircle';
import IconCancel from '@material-ui/icons/Cancel';
import IconUp from '@material-ui/icons/ArrowDropUp';

import { API_BASE_URL, API_LOCAL_URL, APP_ERROR, TIPO_DOCUMENTO } from '~/constants';
import { formatCurrency, formatNumber } from '~/utils/format';
import api from '~/services/api';
import toast from '~/services/toast';
import pedidoService from '~/store/modules/pedido/pedido-service';
import * as monitorActions from '~/store/modules/monitor/monitor-actions';

import PedidoIdentificacao from '../../components/PedidoIdentificacao';
import PedidoHistoricoBloqueios from '../../components/PedidoHistoricoBloqueios';
import LiberacaoAcesso from '../../components/LiberacaoAcesso';
import { selectorParametroAsBoolean } from '~/store/modules/auth/auth-selectors';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

const ModalLiberacaoDesconto = ({ id, isDocumentoOffline, open, onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [pedido, setPedido] = useState(null);
  const [disableModal, setDisableModal] = useState(true);
  const [tokenLiberacaoRotina, setTokenLiberacaoRotina] = useState(null);
  const [itemsSelecteds, setItemsSelecteds] = useState([]);
  const [imprimirAposLiberacao, setImprimirAposLiberacao] = useState(false);
  const obrigaMeioPagamento = useSelector(selectorParametroAsBoolean('PP001'));
  const parametroConfirmaLogin = useSelector(selectorParametroAsBoolean('PP002'));
  const exibirModalCliente = useSelector(selectorParametroAsBoolean('PP003'));
  const { tipoDocumento } = useSelector(state => state.pdv);

  const isSelected = id => itemsSelecteds?.indexOf(id) !== -1 ?? false;

  const selectedAllOrders = itemsSelecteds?.length === pedido?.itens?.length;

  const selectedSomeOrders = itemsSelecteds?.length !== pedido?.itens?.length;

  const handleSelectAllOrders = event => {
    setItemsSelecteds(() => (event.target.checked ? [...pedido?.itens?.map(item => item.id)] : []));
    setPedido(state => ({
      ...state,
      itens: state?.itens?.map(item => {
        return event.target.checked
          ? {
              ...item,
              liberacaoManualComercial: true,
              rejeicaoManualComercial: false
            }
          : {
              ...item,
              liberacaoManualComercial: false,
              rejeicaoManualComercial: true
            };
      })
    }));
  };

  const handleSelectOneOrder = (event, itemId) => {
    setItemsSelecteds(state =>
      event.target.checked ? [...state, itemId] : [...state.filter(x => x !== itemId)]
    );
    setPedido(state => {
      const newItens = [...state.itens];

      const itemSelected = newItens[newItens.findIndex(x => x.id === itemId)];

      if (event.target.checked) {
        itemSelected.liberacaoManualComercial = true;
        itemSelected.rejeicaoManualComercial = false;
      } else {
        itemSelected.liberacaoManualComercial = false;
        itemSelected.rejeicaoManualComercial = true;
      }

      return { ...state, itens: [...newItens] };
    });
  };

  const handleSubmitLiberar = async () => {
    try {
      const { isConfirmed } = await toast.confirm(
        'Deseja liberar o pedido?',
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
        {
          icon: 'success',
          confirmButtonText: 'Liberar',
          cancelButtonText: 'Cancelar'
        }
      );

      const itensLiberado = pedido?.itens?.filter(item => item.liberacaoManualComercial);

      if (itensLiberado?.length === 0) {
        toast.error(
          'Selecione ao menos um item para ser liberado ou o pedido será automaticamente rejeitado!'
        );
        return;
      }

      if (isConfirmed) {
        pedidoService.setStategy(isDocumentoOffline);
        const { data } = await pedidoService.liberarBloqueioComercialDesconto(
          id,
          pedido,
          tokenLiberacaoRotina
        );
        toast.success('Operação realizada com sucesso!');

        if (imprimirAposLiberacao) {
          dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.PEDIDO, data));
        }
        onClose();
        if(parametroConfirmaLogin && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA) dispatch(pdvActions.setShowModalTrocaUsuario({show: true, confirmLogin: true}));
        if(tipoDocumento === TIPO_DOCUMENTO.PEDIDO && exibirModalCliente && !parametroConfirmaLogin) dispatch(pdvActions.setShowModalIdentificarCliente({ show: true }));
        if(obrigaMeioPagamento && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA && !exibirModalCliente && !parametroConfirmaLogin) {
          dispatch(pdvActions.setShowModalConsultaPagamento({ show: true }));
        }
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const handleSubmitRejeitar = async () => {
    try {
      const { isConfirmed } = await toast.confirm(
        'Deseja rejeitar o pedido?',
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
        {
          icon: 'danger',
          confirmButtonText: 'Rejeitar',
          cancelButtonText: 'Cancelar'
        }
      );

      if (isConfirmed) {
        pedidoService.setStategy(isDocumentoOffline);
        const { data } = await pedidoService.rejeitarBloqueioComercialDesconto(
          id,
          pedido,
          tokenLiberacaoRotina
        );
        toast.success('Operação realizada com sucesso!');

        if (imprimirAposLiberacao) {
          dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.PEDIDO, data));
        }
        onClose();
      }
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);

      const { data } = await api.get(
        isDocumentoOffline
          ? `${API_LOCAL_URL}/faturamento/pedido/${id}`
          : `${API_BASE_URL}/v1/faturamento/pedido/bloqueioregracomercial/${id}`
      );
      const itens = [
        ...data?.itens?.map(item => {
          let valorSemDesconto = item?.itemValorUnitario * item?.itemQuantidade;
          let descontoMaximo = valorSemDesconto * (item?.descontoMaximo / 100);

          return {
            ...item,
            liberacaoManualComercial: false,
            rejeicaoManualComercial: true,
            percDescontoMaximo: item.descontoMaximo,
            descontoMaximo: descontoMaximo,
            descontoDiferenca: item.itemValorDesconto - item.descontoMaximo,
            perc: (item.itemValorDesconto * 100) / item.descontoMaximo,
            percAplicado: (item.itemValorDesconto * 100) / valorSemDesconto
          };
        })
      ];

      setPedido({ ...data, itens });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <DialogTitle>
          <AppBar color="primary" position="static">
            <Toolbar>
              <Box>
                <Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Liberação do pedido - bloqueio por desconto
                </Typography>
              </Box>
              <Box flexGrow={1} />

              <IconButton color="inherit" onClick={onClose} aria-label="close">
                <IconClose />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent dividers style={{ minWidth: 640, padding: 16 }}>
          {!loading && disableModal && (
            <LiberacaoAcesso
              rotina="F022ANP"
              callback={(acesso, token) => {
                setDisableModal(!acesso);
                if (acesso) {
                  setTokenLiberacaoRotina(token);
                }
              }}
            />
          )}
          {loading ? (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <CircularProgress variant="indeterminate" color="primary" />
              </Box>
            </>
          ) : (
            <>
              <PedidoIdentificacao data={pedido} />

              <Paper>
                <Box px={2} pt={1}>
                  <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
                    ITENS
                  </Typography>
                </Box>
                <Box p={2}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedAllOrders}
                              color="primary"
                              // indeterminate={selectedSomeOrders}
                              onChange={handleSelectAllOrders}
                            />
                          </TableCell>

                          <TableCell>Produto</TableCell>
                          <TableCell align="center">Quant. </TableCell>
                          <TableCell align="right">Total bruto </TableCell>
                          <TableCell align="right">Total líquido</TableCell>
                          <TableCell
                            align="right"
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
                          >
                            Diferença
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pedido?.itens?.map((item, index) => {
                          const isOrderSelected = isSelected(item.id);
                          return (
                            <TableRow key={`item-${index.toString()}`}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isOrderSelected}
                                  color="primary"
                                  onChange={event => handleSelectOneOrder(event, item.id)}
                                  value={isOrderSelected}
                                />
                              </TableCell>
                              <TableCell>
                                {item?.produtoDescricao} <br />{' '}
                                <small>
                                  PC | Vl unitário: {formatCurrency(item?.itemValorUnitario)}
                                </small>
                              </TableCell>
                              <TableCell align="center">{item?.itemQuantidade}</TableCell>
                              <TableCell align="right">
                                <Typography variant="h5">
                                  {formatCurrency(item?.itemValorUnitario)}
                                </Typography>
                                <Chip
                                  style={{ color: '#4CAF50', marginTop: 4 }}
                                  label={`Máx.: ${formatCurrency(
                                    item.descontoMaximo
                                  )} (${formatNumber(item.percDescontoMaximo)}%)`}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="h5">
                                  {formatCurrency(item?.itemValorTotal)}
                                </Typography>
                                <Chip
                                  style={{ color: '#d00202', marginTop: 4 }}
                                  label={`Desc.: ${formatCurrency(
                                    item.itemValorDesconto
                                  )} (${formatNumber(item.percAplicado)}%)`}
                                />
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
                              >
                                <Typography variant="h4">
                                  {formatCurrency(item?.descontoDiferenca)}
                                </Typography>
                                <Chip
                                  style={{
                                    backgroundColor: '#d00202',
                                    color: '#FFF',
                                    marginTop: 4
                                  }}
                                  icon={<IconUp style={{ color: '#FFF' }} />}
                                  label={`(${formatNumber(item.perc)}%)`}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Paper>

              <PedidoHistoricoBloqueios data={pedido?.fatPedidoBloqueio ?? []} />
            </>
          )}
        </DialogContent>
        {!loading && (
          <DialogActions>
            <Box flex={1} display="flex" justifyContent="space-between">
              <Box>
                <Button
                  variant="contained"
                  className="button-success"
                  disabled={disableModal}
                  onClick={() => handleSubmitLiberar()}
                >
                  <IconCheckCircle />{' '}
                  <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                    Liberar pedido
                  </Mui.Typography>
                </Button>
                <Button
                  variant="contained"
                  className="button-danger"
                  disabled={disableModal}
                  style={{ marginLeft: 8 }}
                  onClick={() => handleSubmitRejeitar()}
                >
                  <IconCancel />{' '}
                  <Mui.Typography variant="span" style={{ marginLeft: 8 }}>
                    {' '}
                    Rejeitar pedido
                  </Mui.Typography>
                </Button>
                <FormControlLabel
                  style={{ marginLeft: 12 }}
                  control={
                    <Switch
                      checked={imprimirAposLiberacao}
                      onChange={() => setImprimirAposLiberacao(state => !state)}
                    />
                  }
                  label="Imprimir após liberação"
                />
              </Box>
              <Button
                startIcon={<MuiIcons.Close />}
                size="large"
                style={{ marginLeft: 8 }}
                onClick={onClose}
              >
                CANCELAR (ESC)
              </Button>
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
export default ModalLiberacaoDesconto;
