import React from 'react';
import { formatCurrency } from '../../../../../utils/format';

const TotalizersSection = ({ documento }) => (
  <div className="totalizadores" style={{ border: '1px solid black', padding: '20px', borderTop: 'none', fontSize: '13px' }}>
    <div className="color-black size-16" style={{ textAlign: 'left' }}>
      <strong>Totalizadores</strong>
    </div>
    <div style={{ display: 'flex', width: '100%', paddingTop: '20px' }}>
      <div
        className=".sub-total"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '150%',
          alignItems: 'flex-end',
        }}
      >
        <span>
          <strong>Subtotal:</strong>
        </span>
        <span>
          <strong>Desconto:</strong>
        </span>
        <span>
          <strong>Acréscimo:</strong>
        </span>
        <span>
          <strong>Frete:</strong>
        </span>
        <span>
          <strong>Seguro:</strong>
        </span>
        <span>
          <strong>Despesa:</strong>
        </span>
        <span>
          <strong>Total:</strong>
        </span>
      </div>
      <div
        className=".valor-sub-total"
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '20%',
          alignItems: 'flex-end',
        }}
      >
        <span>
          <strong>
            {formatCurrency(
              documento.valorTotal +
                documento.itens?.reduce(
                  (total, item) => (total += item?.itemValorDesconto),
                  0
                ) -
                documento.itens?.reduce(
                  (total, item) => (total += item?.itemValorAcrescimo),
                  0
                ),
              2
            )}
          </strong>
        </span>
        <span>
          <strong>
            {formatCurrency(
              documento.itens?.reduce(
                (total, item) => (total += item?.itemValorDesconto),
                0
              )
            )}
          </strong>
        </span>
        <span>
          <strong>
            {formatCurrency(
              documento.itens?.reduce(
                (total, item) => (total += item?.itemValorAcrescimo),
                0
              )
            )}
          </strong>
        </span>
        <span>
          <strong>
            {formatCurrency(
              documento.itens?.reduce((total, item) => (total += item?.itemValorFrete), 0)
            )}
          </strong>
        </span>
        <span>
          <strong>
            {formatCurrency(
              documento.itens?.reduce(
                (total, item) => (total += item?.itemValorSeguro),
                0
              )
            )}
          </strong>
        </span>
        <span>
          <strong>
            {formatCurrency(
              documento.itens?.reduce(
                (total, item) => (total += item?.itemValorDespesa),
                0
              )
            )}
          </strong>
        </span>
        <span>
          <strong>{formatCurrency(documento.valorTotal)}</strong>
        </span>
      </div>
    </div>
  </div>
);

export default TotalizersSection;
