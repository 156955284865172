import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { all } from '@redux-saga/core/effects';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Search as IconSearch } from '@material-ui/icons';
import useSWR from 'swr';
import axios from 'axios';

import { API_NODE_URL, API_LOCAL_URL, APP_ERROR, STORAGE_URL } from '~/constants';
import useReducerAndSaga from '~/hooks/useReducerAndSaga';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as actions from '~/store/modules/produto/produto-actions';
import saga from '~/store/modules/produto/produto-sagas';
import reducer from '~/store/modules/produto/produto-reducer';
import api from '~/services/api';

import ConsultaProdutoItem from '../ConsultaProdutoItem';
import {
  SET_FOCUS_CONSULTA_PRODUTO,
  SET_FOCUS_QUANTIDADE
} from '~/store/modules/pdv/pdv-constants';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import toast from '~/services/toast';

let cancel;
const CancelToken = axios.CancelToken;

const InputPesquisaProduto = ({ callback }) => {
  const dispatch = useDispatch();
  const searchRef = useRef(null);

  const isOnline = useSelector(getApplicationIsOnline());
  const applicationMode = useSelector(state => state.app.applicationMode);
  const { setFocusInputPesquisaProduto, itemVenda } = useSelector(state => state.pdv);
  // const [{ loading, items, queryType }, dispatchProduto] = useReducerAndSaga(
  //   reducer,
  //   function*() {
  //     yield all([saga]);
  //   },
  //   {
  //     loading: false,
  //     items: [],
  //     queryType: 'pesquisa'
  //   }
  // );
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const delay = delayInms => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  const fetcher = async () => {
    try {
      await delay(200);
      setLoading(true);

      if (cancel != undefined) cancel();
      const cancelToken = new CancelToken(function executor(c) {
        cancel = c;
      });

      let amountItem = 1;
      let searchValue = searchInput;

      // ---------------- código de barras ----------------
      // if(searchValue.includes('*')) {
      //   const value = searchValue.split('*')[0];
      //   amountItem = value;
      //   searchValue = searchValue.split('*')[1];
      // }

      if (searchValue?.length <= 3) {
        return [];
      }

      const { data } = await api.get(
        isOnline
          ? `${API_NODE_URL}/faturamento/documento-saida/produto-grade-venda?produto=${searchValue?.toUpperCase()}`
          : `${API_LOCAL_URL}/estoque/produto/?queryString=${searchValue?.toUpperCase()}`,
        {
          cancelToken
        }
      );

      // ---------------- código de barras ----------------
      // if(data.length === 1 && data[0].ean === searchValue) {
      //   dispatch(
      //     pdvActions.selecionaAddProduto(data[0], () => {
      //       dispatch({ type: SET_FOCUS_QUANTIDADE, focus: true });
      //     }, amountItem)
      //   );
      //   setResults([]);
      //   setSearchInput('');
      // }

      return data;
    } finally {
      setLoading(false);
    }
  };

  const { data: items } = useSWR(`/api/produto/search=${searchInput?.toUpperCase()}`, fetcher);

  /*
  const changeSearchInput = queryText => {
    setSearchInput(queryText);

    try {
      dispatchProduto(
        actions.searchRequest(queryText, produto => {
          changeProduto(produto);
        })
      );
      const items = [
        ...data?.map(item => ({
          ...item,
          produtoEstProdutoId: item.id,
          estProdutoGrupo: { ...item.estProdutoGrupo, prefixoProduto: 'EX' },
          itemQuantidade: 1,
          itemValorUnitario: item?.estProdutoImposto[0]?.precoVenda,
          itemEstoque: item?.saldoEstoque?.estoqueReal + item?.saldoEstoque?.estoqueReservado * -1,
          precoVenda: item?.estProdutoImposto[0]?.precoVenda,
          imageUrl: item?.foto ? `${STORAGE_URL}/produtos/${item?.foto}` : null
        }))
      ];
      setItems(items);
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    }
  };
  */

  const changeProduto = produto => {
    const produtoMultiplo = produto?.embalagemMultiplo === true
    
    if (produto) {
      if(produtoMultiplo) {
        toast.produtoMulti(`<b> ${produto.codigo} - ${produto.descricao} </b> é multiplo de embalagem (${produto.embalagemQuantidade}),
         e a quantidade vendida foi alterada de ${produto.itemQuantidade} para ${produto.embalagemQuantidade}`);
      }
      if (callback) {
        callback(produto);
      } else {
        dispatch(
          pdvActions.selecionarProduto(produto, () => {
            dispatch({ type: SET_FOCUS_QUANTIDADE, focus: true });
          })
        );
      }
    }
  };

  useEffect(() => {
    if (items && !loading)
      setResults([
        ...items?.map(item => ({
          ...item,
          produtoEstProdutoId: item.id,
          estProdutoGrupo: { ...item.estProdutoGrupo, prefixoProduto: 'EX' },
          itemQuantidade: 1,
          itemValorUnitario: item?.estProdutoImposto[0]?.precoVenda,
          itemEstoque: item?.saldoEstoque?.estoqueReal + item?.saldoEstoque?.estoqueReservado * -1,
          precoVenda: item?.estProdutoImposto[0]?.precoVenda,
          imageUrl: item?.foto ? `${STORAGE_URL}produtos/${item?.foto}` : null
        }))
      ]);
  }, [loading, items]);

  useEffect(() => {
    if (setFocusInputPesquisaProduto) {
      dispatch({ type: SET_FOCUS_CONSULTA_PRODUTO, focus: false });

      setSearchInput('');
      setResults([]);
      searchRef?.current?.focus();
    }
  }, [setFocusInputPesquisaProduto]);

  // useEffect(() => {
  //   dispatchProduto(actions.setApplicationMode(applicationMode));
  // }, [applicationMode]);

  return (
    <>
      <Autocomplete
        id="autocomplete-produto"
        onKeyDown={key => {
          if (key.code === 'Escape') {
            dispatch({ type: SET_FOCUS_CONSULTA_PRODUTO, focus: false });
          }
        }}
        // freeSolo
        loadingText="Carregando..."
        noOptionsText="Nenhum registro encontrado"
        fullWidth
        // disabled={loading}
        options={results}
        getOptionLabel={() => searchInput}
        inputValue={searchInput}
        // onInputChange={(_, value) => changeSearchInput(value)}
        onInputChange={(_, queryText) => setSearchInput(queryText)}
        onChange={(_, produto) => {
          changeProduto(produto);
        }}
        renderOption={item => <ConsultaProdutoItem data={item} />}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            // disabled={loading}
            size="small"
            placeholder="Pesquise por código, descrição, cód. barra, cód. fabricante, cód. auxiliar"
            inputRef={searchRef}
            InputProps={{
              ...params.InputProps,
              startdornment: (
                <>
                  <IconSearch />
                  {params.InputProps.startdornment}
                </>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </>
  );
};

export default InputPesquisaProduto;
