import styled from 'styled-components';
import MuiListItem from '@material-ui/core/ListItem';
import MuiList from '@material-ui/core/List';
import MuiButton from '@material-ui/core/Button';
import { alpha } from '@material-ui/core';

export const Container = styled.div`
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: #fff;
  background-color: ${props => props.theme.palette.common.black};
  box-shadow: ${props => props.theme.shadows[6]};
`;
