import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import * as monitorActions from '~/store/modules/monitor/monitor-actions';
import { PedidoOnlineService } from '~/store/modules/pedido/pedido-service';
import { formatDate, formatDatetime } from '~/utils/format';
import { TIPO_DOCUMENTO, APP_ERROR } from '~/constants';
import toast from '~/services/toast';

import ModalConfirmacao from '~/pages/PainelVenda/modal/ModalConfirmacao';

const Container = styled(Paper)`
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;

const PedidoIdentificacao = ({ data: pedido, onRefresh }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showModalCancelamento, setShowModalCancelamento] = useState({ open: false });

  const handlePrintPedido = () => {
    dispatch(monitorActions.printDocumento(TIPO_DOCUMENTO.PEDIDO, pedido));
  };

  const handleCancelPedido = async () => {
    setShowModalCancelamento({
      open: true,
      rotina: 'F060PED',
      title: 'Deseja cancelar o pedido selecionado?',
      body:
        'Certifique-se de que realmente deseja fazer isso, pois não será possivel reverter esta ação.',
      options: {
        icon: 'warning',
        confirmButtonText: 'Confirma cancelamento do pedido',
        cancelButtonText: 'Voltar'
      },
      onClose: () => {
        setShowModalCancelamento({ open: false });
      },
      onCloseCallback: async tokenLiberacaoRotina => {
        try {
          setShowModalCancelamento({ open: false });
          const pedidoService = new PedidoOnlineService();
          await pedidoService.cancelarPedidos([pedido.id], tokenLiberacaoRotina);
          if (onRefresh) onRefresh();

          toast.success('Operação realizada com sucesso!');
        } catch (error) {
          dispatch({ type: APP_ERROR, error });
        }
      }
    });
  };

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Container>
        <Mui.Box
          display="flex"
          flex
          flexDirection="row"
          justifyContent="space-between"
          px={2}
          pt={1}
        >
          <Mui.Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>
            IDENTIFICAÇÃO
          </Mui.Typography>

          <Mui.Box>
            <Mui.Button variant="contained" color="primary" onClick={handlePrintPedido}>
              <MuiIcons.Print />
              <Mui.Typography variant='span' style={{ paddingLeft: 4 }}>Imprimir</Mui.Typography>
            </Mui.Button>
            <Mui.Button variant="contained" style={{ marginLeft: 8 }} onClick={handleCancelPedido}>
              <MuiIcons.Cancel />
              <Mui.Typography variant='span' style={{ paddingLeft: 4 }}>Cancelar</Mui.Typography>
            </Mui.Button>
            {/* <Mui.Tooltip title="Mais ações">
              <Mui.IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                aria-label={'Mais ações'}
                onClick={handleOpenMenu}
              >
                <MuiIcons.MoreVert />
              </Mui.IconButton>
            </Mui.Tooltip>
            <Mui.Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              {!pedido?.cancelado && (
                <Mui.MenuItem onClick={handleCancelPedido}>
                  <MuiIcons.Cancel fontSize="small" /> Cancelar pedido
                </Mui.MenuItem>
              )}
            </Mui.Menu> */}
          </Mui.Box>
        </Mui.Box>

        <Mui.Box p={2}>
          <Mui.Grid container item spacing={2}>
            <Mui.Grid item xs={12} md={3}>
              <Mui.Typography variant="caption">Série / Número</Mui.Typography>
              <Mui.Typography variant="body2">
                {pedido?.serie} / {pedido?.numero}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={12} md={3}>
              <Mui.Typography variant="caption">Data emissão</Mui.Typography>
              <Mui.Typography variant="body2">{formatDate(pedido?.dataEmissao)}</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={12} md={3}>
              <Mui.Typography variant="caption">Cancelado</Mui.Typography>
              <Mui.Typography variant="body2">{pedido?.cancelado ? 'SIM' : 'NÃO'}</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={12} md={3}>
              <Mui.Typography variant="caption">Data criação</Mui.Typography>
              <Mui.Typography variant="body2">{formatDatetime(pedido?.logCriacao)}</Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container item spacing={2}>
            <Mui.Grid item xs={12} md={6}>
              <Mui.Typography variant="caption">Cliente</Mui.Typography>
              <Mui.Typography variant="body2">{pedido?.clienteNome ?? '-'}</Mui.Typography>
              {pedido?.clienteCpfCnpj && (
                <Mui.Typography variant="body2">
                  <b>CPF/CNPJ:</b> {pedido?.clienteCpfCnpj ?? '-'}
                </Mui.Typography>
              )}
            </Mui.Grid>
            <Mui.Grid item xs={12} md={6}>
              <Mui.Typography variant="caption">Telefone</Mui.Typography>
              <Mui.Typography variant="body2">
                {pedido?.clienteTelefone?.length ? pedido?.clienteTelefone : '-'}
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid item md={6}>
              <Mui.Box pb={2}>
                <Mui.Typography variant="caption">Endereço</Mui.Typography>
                {pedido?.enderecoLogradouro ? (
                  <>
                    <Mui.Typography variant="body2">
                      {pedido?.enderecoLogradouro},{pedido?.enderecoLogradouroNumero}
                      {' - '}
                      {pedido?.enderecoBairro}
                    </Mui.Typography>

                    <Mui.Typography variant="body2">
                    {pedido?.enderecoIntCidade?.cidade}/{pedido?.enderecoIntCidade?.uf} -{' '}
                      {pedido?.enderecoCep}
                    </Mui.Typography>
                  </>
                ) : (
                  <Mui.Typography variant="body2">-</Mui.Typography>
                )}
              </Mui.Box>
            </Mui.Grid>
            <Mui.Divider orientation="vertical" />
            <Mui.Grid item md={6} style={{ marginLeft: -1 }}>
              <Mui.Box pb={2}>
                <Mui.Typography variant="caption">Endereço de entrega</Mui.Typography>
                {pedido?.entregaLogradouro ? (
                  <>
                    <Mui.Typography variant="body2">
                      {pedido?.entregaLogradouro},{pedido?.entregaLogradouroNumero}
                      {' - '}
                      {pedido?.entregaBairro}
                    </Mui.Typography>

                    <Mui.Typography variant="body2">
                      {pedido?.entregaIntCidade?.cidade}/{pedido?.entregaIntCidade?.uf} -{' '}
                      {pedido?.entregaCep}
                    </Mui.Typography>
                  </>
                ) : (
                  <Mui.Typography variant="body2">-</Mui.Typography>
                )}
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container item spacing={2}>
            <Mui.Grid item xs={12} md={3}>
              <Mui.Typography variant="caption">Operação</Mui.Typography>
              <Mui.Typography variant="body2">
                {pedido?.fisOperacao?.descricao ?? '-'}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={12} md={3}>
              <Mui.Typography variant="caption">Vendedor</Mui.Typography>
              <Mui.Typography variant="body2">{pedido?.fatVendedor?.nome ?? '-'}</Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={12} md={3}>
              <Mui.Typography variant="caption">Condição de pagamento</Mui.Typography>
              <Mui.Typography variant="body2">
                {pedido?.fatCondicaopagamento?.descricao ?? '-'}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={12} md={3}>
              <Mui.Typography variant="caption">Tabela de venda</Mui.Typography>
              <Mui.Typography variant="body2">
                {pedido?.fatTabelapedido?.descricao ?? '-'}
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Box>
      </Container>

      {showModalCancelamento.open && <ModalConfirmacao {...showModalCancelamento} />}
    </>
  );
};

export default PedidoIdentificacao;
