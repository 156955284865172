import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { makeStyles, alpha, useTheme } from '@material-ui/core';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { Alert as MuiAlert } from '@material-ui/lab';

// import useAuth from '~/hooks/auth-hook';
// import useIsMountedRef from '~/hooks/useIsMountedRef';

import { APP_VERSION, APP_LOGIN, APP_SYNC_DATE, APPLICATION } from '~/constants';
import * as authActions from '~/store/modules/auth/auth-actions';
import * as appActions from '~/store/modules/app/app-actions';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';

import ModalIdentificacaoPDV from '~/pages/TelaVenda/modal/ModalIdentificacaoPDV';
import Page from '~/components/Page';
import Logo from '~/assets/logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    width: 520
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column'
  },

  logo: {
    color: theme.palette.primary.main,
    fontSize: '62px',
    '& > span': {
      fontWeight: 300,
      color: theme.palette.text.primary
    }
  }
}));

const Login = ({ className, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isOffline } = useSelector(state => state.app);
  const { showModalIdentificacaoPDV } = useSelector(state => state.app);
  const { loading, filiais } = useSelector(state => state.auth);
  const [dataSincronizacao, setDataSincronizacao] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState({
    login: '',
    senha: '',
    filialSelecionada: null,
    submit: null
  });
  const [validationSchema] = useState(
    Yup.object().shape({
      login: Yup.string()
        .email('Informar um email válido')
        .nullable()
        .max(255)
        .required('Email é obrigatório'),
      senha: Yup.string()
        .max(255)
        .required('Senha é obrigatório')
    })
  );

  const handleSubmit = async values => {
    dispatch(authActions.loginRequest({ ...values }));
  };

  useEffect(() => {
    const _dataSyncLocal = sessionStorage.getItem(APP_SYNC_DATE, null);
    setDataSincronizacao(_dataSyncLocal ? JSON.parse(_dataSyncLocal) : null);

    const _login = sessionStorage.getItem(APP_LOGIN, '');
    setLogin(state => ({
      ...state,
      login: _login
    }));
  }, []);

  return (
    <>
      <Page title="Login">
        <Mui.Card className={classes.root}>
          <Mui.CardContent>
            <Mui.Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 32
              }}
            >
              <img src={Logo} alt="Logo" width={'100%'} />
            </Mui.Box>
            <Mui.Divider
              style={{
                marginTop: 16,
                marginBottom: 16,
                marginLeft: -32,
                marginRight: -32
              }}
            />
            <Mui.Box flexGrow={1} mt={3} className={classes.cardContent}>
              <Formik
                initialValues={login}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  values
                }) => (
                  <form noValidate onSubmit={handleSubmit} {...rest}>
                    {filiais?.length > 0 ? (
                      <>
                        <Mui.FormControl variant="outlined" fullWidth>
                          <Mui.InputLabel id="filial-selecionada-select-outlined-label">
                            Filiais de acesso
                          </Mui.InputLabel>
                          <Mui.Select
                            labelId="filial-selecionada-select-outlined-label"
                            id="filial-selecionada-select-outlined"
                            value={values?.filialSelecionada}
                            label="Filiais de acesso"
                            fullWidth
                            disabled={loading}
                            renderValue={filial => filial?.fantasia}
                          >
                            {filiais?.map(filial => (
                              <Mui.MenuItem
                                key={filial._id}
                                value={filial}
                                onClick={event => {
                                  event.persist();
                                  setFieldValue('filialSelecionada', filial);
                                }}
                              >
                                {filial?.fantasia}
                                {'  '} ( <small>{filial?.cnpj}</small> )
                              </Mui.MenuItem>
                            ))}
                          </Mui.Select>
                        </Mui.FormControl>
                      </>
                    ) : (
                      <>
                        <Mui.TextField
                          error={Boolean(touched.login && errors.login)}
                          fullWidth
                          autoFocus
                          helperText={touched.login && errors.login}
                          label="E-mail"
                          margin="normal"
                          name="login"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={loading}
                          type="email"
                          value={values.login}
                          variant="outlined"
                          tabIndex={1}
                          // inputProps={{ style: { textTransform: "lowercase" } }}
                        />
                        <Mui.TextField
                          error={Boolean(touched.senha && errors.senha)}
                          fullWidth
                          helperText={touched.senha && errors.senha}
                          label="Senha"
                          margin="normal"
                          name="senha"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={loading}
                          type={showPassword ? 'text' : 'password'}
                          value={values.senha}
                          variant="outlined"
                          tabIndex={2}
                          InputProps={{
                            startAdornment: (
                              <>
                                {!loading && (
                                  <>
                                    <Mui.InputAdornment
                                      position="end"
                                      style={{ position: 'absolute', right: 0 }}
                                      tabIndex={-1}
                                    >
                                      <Mui.IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={event => event.preventDefault()}
                                      >
                                        {showPassword ? (
                                          <MuiIcons.Visibility />
                                        ) : (
                                          <MuiIcons.VisibilityOff />
                                        )}
                                      </Mui.IconButton>
                                    </Mui.InputAdornment>
                                  </>
                                )}
                              </>
                            )
                          }}
                        />
                      </>
                    )}

                    {errors.submit && (
                      <Mui.Box mt={3}>
                        <Mui.FormHelperText error>{errors.submit}</Mui.FormHelperText>
                      </Mui.Box>
                    )}
                    {APPLICATION !== 'web' && (
                      <>
                        <Mui.FormControlLabel
                          control={
                            <Mui.Switch
                              checked={isOffline ?? false}
                              onChange={() => dispatch(appActions.forceOfflineMode(!isOffline))}
                            />
                          }
                          label={'Modo Offline'}
                        />
                        <MuiAlert>
                          <Mui.Typography
                            style={{ fontSize: 12, marginLeft: 8, textTransform: 'none' }}
                          >
                            {dataSincronizacao
                              ? `Data hora última sincronização: ${dataSincronizacao}hrs`
                              : 'Nenhuma sincronização'}
                          </Mui.Typography>
                        </MuiAlert>
                      </>
                    )}

                    <Mui.Box mt={2}>
                      <Mui.Button
                        color="primary"
                        disabled={loading}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        tabIndex={3}
                      >
                        {loading ? (
                          <>
                            <Mui.CircularProgress size={24} style={{ marginRight: 12 }} />
                            <Mui.Typography variant="span">Carregando...</Mui.Typography>
                          </>
                        ) : (
                          <>
                            <Mui.Typography variant="span">
                              {filiais?.length > 0 ? 'Selecionar filial' : 'Acessar'}
                            </Mui.Typography>
                          </>
                        )}
                      </Mui.Button>
                      {filiais?.length > 0 && (
                        <Mui.Button
                          fullWidth
                          size="large"
                          variant="contained"
                          tabIndex={4}
                          style={{marginTop: 16}}
                          onClick={() => {
                            dispatch(authActions.setFilial([]));
                          }}
                        >
                          <Mui.Typography variant="span">Voltar</Mui.Typography>
                        </Mui.Button>
                      )}
                    </Mui.Box>
                  </form>
                )}
              </Formik>
            </Mui.Box>
          </Mui.CardContent>
          <Mui.CardContent
            style={{
              marginTop: 16,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: alpha(theme.palette.common.black, 0.5)
            }}
          >
            <Mui.Typography style={{ color: '#FFF' }}>
              Adsoft Gestão Empresarial - {APP_VERSION}
            </Mui.Typography>

            <Mui.Typography style={{ color: '#FFF' }}>
              © {new Date().getFullYear()}. TODOS OS DIREITOS RESERVADOS
            </Mui.Typography>
          </Mui.CardContent>
        </Mui.Card>
      </Page>

      {showModalIdentificacaoPDV?.show && <ModalIdentificacaoPDV allowCloseModal={false} />}
    </>
  );
};

Login.propTypes = {
  className: PropTypes.string
};

export default Login;
