export const SET_STATUS = '@pdv/SET_STATUS';
export const SET_LOADING = '@pdv/SET_LOADING';
export const SET_LOADING_PRODUTO = '@pdv/SET_LOADING_PRODUTO';
export const SET_LOADING_PAGAMENTO = '@pdv/SET_LOADING_PAGAMENTO';
export const SET_LOADING_SALVAMENTO = '@pdv/SET_LOADING_SALVAMENTO';
export const SET_LOADING_IMPRESSAO = '@pdv/SET_LOADING_IMPRESSAO';
export const SET_LOADING_TRANSMISSAO = '@pdv/SET_LOADING_TRANSMISSAO';
export const SET_SHOWMODAL_ATALHOS_PDV = '@pdv/SET_SHOWMODAL_ATALHOS_PDV';
export const SET_SHOWMODAL_IMPORTAR_DOCUMENTO = '@pdv/SET_SHOWMODAL_IMPORTAR_DOCUMENTO';
export const SET_SHOWMODAL_CONSULTA_PRODUTO = '@pdv/SET_SHOWMODAL_CONSULTA_PRODUTO';
export const SET_SHOWMODAL_CONSULTA_PRODUTO_REGRA = '@pdv/SET_SHOWMODAL_CONSULTA_PRODUTO_REGRA';
export const SET_SHOWMODAL_CONSULTA_CLIENTE = '@pdv/SET_SHOWMODAL_CONSULTA_CLIENTE';
export const SET_SHOWMODAL_CONSULTA_VENDEDOR = '@pdv/SET_SHOWMODAL_CONSULTA_VENDEDOR';
export const SET_SHOWMODAL_CONSULTA_PROFISSIONAL = '@pdv/SET_SHOWMODAL_CONSULTA_PROFISSIONAL';
export const SET_SHOWMODAL_CONSULTA_OPERACAO = '@pdv/SET_SHOWMODAL_CONSULTA_OPERACAO';
export const SET_SHOWMODAL_IDENTIFICAR_CLIENTE = '@pdv/SET_SHOWMODAL_IDENTIFICAR_CLIENTE';
export const SET_SHOWMODAL_ENDERECO_CLIENTE = '@pdv/SET_SHOWMODAL_ENDERECO_CLIENTE';
export const SET_SHOWMODAL_EXPEDICAO_MERCADORIA = '@pdv/SET_SHOWMODAL_EXPEDICAO_MERCADORIA';
export const SET_SHOWMODAL_INFORMACAO_COMPLEMENTAR = '@pdv/SET_SHOWMODAL_INFORMACAO_COMPLEMENTAR';
export const SET_SHOWMODAL_CONSULTA_PAGAMENTO = '@pdv/SET_SHOWMODAL_CONSULTA_PAGAMENTO ';
export const SET_SHOWMODAL_CONFIRMA_PAGAMENTO = '@pdv/SET_SHOWMODAL_CONFIRMA_PAGAMENTO';
export const SET_SHOWMODAL_CONDICAO_PAGAMENTO_PRODUTO =
'@pdv/SET_SHOWMODAL_CONDICAO_PAGAMENTO_PRODUTO';
export const SET_SHOWMODAL_SALDO_ESTOQUE_PRODUTO = '@pdv/SET_SHOWMODAL_SALDO_ESTOQUE_PRODUTO';
export const SET_SHOWMODAL_LOADING_MENSAGEM = '@pdv/SET_SHOWMODAL_LOADING_MENSAGEM';
export const SET_SHOWMODAL_LOADING_SALVAMENTO = '@pdv/SET_SHOWMODAL_LOADING_SALVAMENTO';
export const SET_SHOWMODAL_LIBERACAO_PEDIDO = '@pdv/SET_SHOWMODAL_LIBERACAO_PEDIDO';
export const SET_SHOWMODAL_MENSAGEM_TEF = '@pdv/SET_SHOWMODAL_MENSAGEM_TEF';
export const SET_SHOWMODAL_MENU_TEF = '@pdv/SET_SHOWMODAL_MENU_TEF';
export const SET_SHOWMODAL_PAGAMENTO_POR_SALDO = '@pdv/SET_SHOWMODAL_PAGAMENTO_POR_SALDO';
export const SET_SHOWMODAL_CADASTRO_CHEQUE = '@pdv/SET_SHOWMODAL_CADASTRO_CHEQUE';
export const SET_SHOWMODAL_PREVIEW_DANFE = '@pdv/SET_SHOWMODAL_PREVIEW_DANFE';
export const SET_SHOWMODAL_TROCA_USUARIO = '@pdv/SET_SHOWMODAL_TROCA_USUARIO';
export const SET_SHOWMODAL_CONSULTA_RAPIDA_PRODUTO = '@pdv/SET_SHOWMODAL_CONSULTA_RAPIDA_PRODUTO';
export const SET_SHOWMODAL_DESCONTO = '@pdv/SET_SHOWMODAL_DESCONTO';
export const SET_SHOWMODAL_ACRESCIMO = '@pdv/SET_SHOWMODAL_ACRESCIMO';
export const SET_SHOWMODAL_FRETE = '@pdv/SET_SHOWMODAL_FRETE';


export const SET_FOCUS_CONSULTA_PRODUTO = '@pdv/SET_FOCUS_CONSULTA_PRODUTO';
export const SET_FOCUS_QUANTIDADE = '@pdv/SET_FOCUS_QUANTIDADE';

export const UPDATE_LOADING_MENSAGEM = '@pdv/UPDATE_LOADING_MENSAGEM';

export const INICIAR_VENDA_REQUEST = '@pdv/INICIAR_VENDA_REQUEST';
export const INICIAR_VENDA_SUCCESS = '@pdv/INICIAR_VENDA_SUCCESS';

export const ALTERAR_DOCUMENTO = '@pdv/ALTERAR_DOCUMENTO';

export const RECUPERAR_VENDA = '@pdv/RECUPERAR_VENDA';

export const ALTERAR_TIPO_DOCUMENTO_REQUEST = '@pdv/ALTERAR_TIPO_DOCUMENTO_REQUEST';
export const ALTERAR_TIPO_DOCUMENTO_SUCCESS = '@pdv/ALTERAR_TIPO_DOCUMENTO_SUCCESS';

export const FECHAR_VENDA_REQUEST = '@pdv/FECHAR_VENDA_REQUEST';
export const FECHAR_VENDA_SUCCESS = '@pdv/FECHAR_VENDA_SUCCESS';
export const FECHAR_VENDA_ERROR = '@pdv/FECHAR_VENDA_ERROR';
export const LIBERACAO_PEDIDO = '@pdv/LIBERACAO_PEDIDO';

export const INICIAR_PAGAMENTO = '@pdv/INICIAR_PAGAMENTO';
export const GERAR_DOCUMENTO_OFFLINE = '@pdv/GERAR_DOCUMENTO_OFFLINE';

export const SELECIONAR_CLIENTE_REQUEST = '@pdv/SELECIONAR_CLIENTE_REQUEST';
export const SELECIONAR_CLIENTE_SUCCESS = '@pdv/SELECIONAR_CLIENTE_SUCCESS';
export const REMOVE_CLIENTE = '@pdv/REMOVE_CLIENTE';

export const IDENTIFICAR_CLIENTE_REQUEST = '@pdv/IDENTIFICAR_CLIENTE_REQUEST';
export const IDENTIFICAR_CLIENTE_SUCCESS = '@pdv/IDENTIFICAR_CLIENTE_SUCCESS';

export const SET_INFORMACAO_COMPLEMENTAR = '@pdv/SET_INFORMACAO_COMPLEMENTAR';

export const SET_TRANSFERENCIA_ITEM = '@pdv/SET_TRANSFERENCIA_ITEM';

export const SALVAR_ENDERECO_CLIENTE_REQUEST = '@pdv/SALVAR_ENDERECO_CLIENTE_REQUEST';
export const SALVAR_ENDERECO_CLIENTE_SUCCESS = '@pdv/SALVAR_ENDERECO_CLIENTE_SUCCESS';

export const REMOVER_ENDERECO_CLIENTE_REQUEST = '@pdv/REMOVER_ENDERECO_CLIENTE_REQUEST';
export const REMOVER_ENDERECO_CLIENTE_SUCCESS = '@pdv/REMOVER_ENDERECO_CLIENTE_SUCCESS';

export const SELECIONAR_ENDERECO_CLIENTE = '@pdv/SELECIONAR_ENDERECO_CLIENTE';

export const SELECIONAR_MEIO_PAGAMENTO_ENTRADA = '@pdv/SELECIONAR_MEIO_PAGAMENTO_ENTRADA';

export const SELECIONAR_MEIO_PAGAMENTO_REQUEST = '@pdv/SELECIONAR_MEIO_PAGAMENTO_REQUEST';
export const SELECIONAR_MEIO_PAGAMENTO_SUCCESS = '@pdv/SELECIONAR_MEIO_PAGAMENTO_SUCCESS';

export const SELECIONAR_CONDICAO_PAGAMENTO_REQUEST = '@pdv/SELECIONAR_CONDICAO_PAGAMENTO_REQUEST';
export const SELECIONAR_CONDICAO_PAGAMENTO_SUCCESS = '@pdv/SELECIONAR_CONDICAO_PAGAMENTO_SUCCESS';

export const SELECIONAR_OUTRO_PAGAMENTO = '@pdv/SELECIONAR_OUTRO_PAGAMENTO';

export const SELECIONAR_VENDEDOR = '@pdv/SELECIONAR_VENDEDOR';
export const SELECIONAR_PROFISSIONAL = '@pdv/SELECIONAR_PROFISSIONAL';
export const REMOVER_PROFISSIONAL = '@pdv/REMOVER_PROFISSIONAL';

export const SELECIONAR_OPERACAO_REQUEST = '@pdv/SELECIONAR_OPERACAO_REQUEST';
export const SELECIONAR_OPERACAO_SUCCESS = '@pdv/SELECIONAR_OPERACAO_SUCCESS';

export const SELECIONAR_PRODUTO_REQUEST = '@pdv/SELECIONAR_PRODUTO_REQUEST';
export const SELECIONAR_PRODUTO_REGRA_REQUEST = '@pdv/SELECIONAR_PRODUTO_REGRA_REQUEST';
export const SELECIONAR_PRODUTO_SUCCESS = '@pdv/SELECIONAR_PRODUTO_SUCCESS';
export const SELECIONAR_ADD_PRODUTO_REQUEST = '@pdv/SELECIONAR_ADD_PRODUTO_REQUEST';

export const ALTERAR_UNIDADE_COMERCIAL = '@pdv/ALTERAR_UNIDADE_COMERCIAL';
export const ALTERAR_ARMAZEM = '@pdv/ALTERAR_ARMAZEM';

export const ADICIONAR_PRODUTO_REQUEST = '@pdv/ADICIONAR_PRODUTO_REQUEST';
export const ADICIONAR_PRODUTO_SUCCESS = '@pdv/ADICIONAR_PRODUTO_SUCCESS';

export const ATUALIZAR_PRODUTO = '@pdv/ATUALIZAR_PRODUTO';

export const SELECIONAR_ENDERECO_ENTREGA = '@pdv/SELECIONAR_ENDERECO_ENTREGA';

export const ATUALIZAR_PRECO_VENDA_PRODUTO = '@pdv/ATUALIZAR_PRECO_VENDA_PRODUTO';

export const EDITAR_ITEM_VENDA = '@pdv/EDITAR_ITEM_VENDA';

export const ALTERAR_EXPEDICAO_MERCADORIAS = '@pdv/ALTERAR_EXPEDICAO_MERCADORIAS';

export const CANCELAR_PRODUTO_REQUEST = '@pdv/CANCELAR_PRODUTO_REQUEST';
export const CANCELAR_PRODUTO_SUCCESS = '@pdv/CANCELAR_PRODUTO_SUCCESS';

export const REMOVER_PRODUTO_REQUEST = '@pdv/REMOVER_PRODUTO_REQUEST';
export const REMOVER_PRODUTO_SUCCESS = '@pdv/REMOVER_PRODUTO_SUCCESS';

export const EDITAR_QUANTIDADE_ITEM_VENDIDO = '@pdv/EDITAR_QUANTIDADE_ITEM_VENDIDO';

export const ATUALIZAR_QUANTIDADE_REQUEST = '@pdv/ATUALIZAR_QUANTIDADE_REQUEST';
export const ATUALIZAR_QUANTIDADE_SUCCESS = '@pdv/ATUALIZAR_QUANTIDADE_SUCCESS';

export const ATUALIZAR_ITENS = '@pdv/ATUALIZAR_ITENS';

export const ATUALIZAR_DESCONTO_ITEM = '@pdv/ATUALIZAR_DESCONTO_ITEM';
export const ATUALIZAR_DESCONTO_ITEM_PERCENTUAL = '@pdv/ATUALIZAR_DESCONTO_ITEM_PERCENTUAL';
export const ATUALIZAR_ACRESCIMO_ITEM = '@pdv/ATUALIZAR_ACRESCIMO_ITEM';

export const ATUALIZAR_ACRESCIMO_REQUEST = '@pdv/ATUALIZAR_ACRESCIMO_REQUEST';
export const ATUALIZAR_ACRESCIMO_SUCCESS = '@pdv/ATUALIZAR_ACRESCIMO_SUCCESS';

export const ATUALIZAR_ACRESCIMO_PERCENTUAL_REQUEST = '@pdv/ATUALIZAR_ACRESCIMO_PERCENTUAL_REQUEST';
export const ATUALIZAR_ACRESCIMO_PERCENTUAL_SUCCESS = '@pdv/ATUALIZAR_ACRESCIMO_PERCENTUAL_SUCCESS';

export const ATUALIZAR_DESCONTO_REQUEST = '@pdv/ATUALIZAR_DESCONTO_REQUEST';
export const ATUALIZAR_DESCONTO_SUCCESS = '@pdv/ATUALIZAR_DESCONTO_SUCCESS';

export const ATUALIZAR_DESCONTO_PERCENTUAL_REQUEST = '@pdv/ATUALIZAR_DESCONTO_PERCENTUAL_REQUEST';
export const ATUALIZAR_DESCONTO_PERCENTUAL_SUCCESS = '@pdv/ATUALIZAR_DESCONTO_PERCENTUAL_SUCCESS';

export const ATUALIZAR_FRETE_SUCCESS = '@pdv/ATUALIZAR_FRETE_SUCCESS';

export const ATUALIZAR_FRETE_PERCENTUAL_SUCCESS = '@pdv/ATUALIZAR_FRETE_PERCENTUAL_SUCCESS';

export const LIMPAR_PAGAMENTOS = '@pdv/LIMPAR_PAGAMENTOS';

export const SET_SHOWMODAL_LISTA_PAGAMENTOS = '@pdv/SET_SHOWMODAL_LISTA_PAGAMENTOS';

export const MONTAR_PAGAMENTO = '@pdv/MONTAR_PAGAMENTO';

export const ATUALIZAR_VALOR_ENTRADA = '@pdv/ATUALIZAR_VALOR_ENTRADA';
export const ATUALIZAR_VALOR_RECEBIDO = '@pdv/ATUALIZAR_VALOR_RECEBIDO';
export const ATUALIZAR_ITEM_PAGAMENTO = '@pdv/ATUALIZAR_ITEM_PAGAMENTO';
export const ATUALIZAR_PARCELA_FECHAMENTO = '@pdv/ATUALIZAR_PARCELA_FECHAMENTO';

export const ATUALIZAR_NUMERO_DOCUMENTO_CARTAO = '@pdv/ATUALIZAR_NUMERO_DOCUMENTO_CARTAO';
export const ATUALIZAR_BANDEIRA_CARTAO = '@pdv/ATUALIZAR_BANDEIRA_CARTAO';

export const SET_MULTIPLOS_PAGAMENTOS = '@pdv/SET_MULTIPLOS_PAGAMENTOS';

export const ATUALIZAR_PARCELAS = '@pdv/ATUALIZAR_PARCELAS';

export const ADICIONAR_PARCELAS_REQUEST = '@pdv/ADICIONAR_PARCELAS_REQUEST';
export const ADICIONAR_PARCELAS_SUCCESS = '@pdv/ADICIONAR_PARCELAS_SUCCESS';

export const ADICIONAR_PAGAMENTO_REQUEST = '@pdv/ADICIONAR_PAGAMENTO_REQUEST';
export const ADICIONAR_PAGAMENTO_SUCCESS = '@pdv/ADICIONAR_PAGAMENTO_SUCCESS';

export const REMOVER_PAGAMENTOS_REQUEST = '@pdv/REMOVER_PAGAMENTOS_REQUEST';
export const REMOVER_PAGAMENTOS_SUCCESS = '@pdv/REMOVER_PAGAMENTOS_SUCCESS';

export const REMOVER_PARCELA = '@pdv/REMOVER_PARCELA';

export const ADICIONAR_CHEQUE_PARCELA = '@pdv/ADICIONAR_CHEQUE_PARCELA';
export const REMOVER_CHEQUE_PARCELA = '@pdv/REMOVER_CHEQUE_PARCELA';

export const IMPORTAR_ORCAMENTO = '@pdv/IMPORTAR_ORCAMENTO';
export const IMPORTAR_PEDIDDO = '@pdv/IMPORTAR_PEDIDDO';

export const LIMPAR_VENDA = '@pdv/LIMPAR_VENDA';
