import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import axios from 'axios';
import { all } from '@redux-saga/core/effects';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Search as IconSearch,
  MoreVert as IconMore,
  ArrowBack as IconArrowBack,
} from '@material-ui/icons';

import SwapVertIcon from '@material-ui/icons/SwapVert';

import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { formatCurrency, formatNumber } from '~/utils/format';
import useReducerAndSaga from '~/hooks/useReducerAndSaga';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import * as actions from '~/store/modules/produto/produto-actions';
import saga from '~/store/modules/produto/produto-sagas';
import reducer from '~/store/modules/produto/produto-reducer';
import api from '~/services/api';

import Searching from '~/components/Searching';
import ListEmpty from '~/components/ListEmpty';

import ModalCondicaoPagamentoProduto from '../ModalCondicaoPagamentoProduto';
import ModalSaldoEstoqueProduto from '../ModalSaldoEstoqueProduto';
import { SET_FOCUS_QUANTIDADE } from '~/store/modules/pdv/pdv-constants';
import { API_NODE_URL, APP_ERROR, STORAGE_URL, API_LOCAL_URL } from '~/constants';

const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      width: 1,
      height: 32,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  })
);

const ListHeader = ({ setOrderBy, sortBy, setSortBy }) => {

  const Ordenacao = (name) => {
    setOrderBy(name);
    if(sortBy === '') setSortBy('asc');
    if(sortBy === 'asc') setSortBy('desc');
    if(sortBy === 'desc') setSortBy('asc');
  }

  return (
    <ListItem>
      <Grid container item>
        <Grid item md={1} alignItems="left">
          <Typography align="left" style={{ fontWeight: 600, cursor: 'pointer', display: 'flex', alignItems: 'center', alignContent: 'center' }} onClick={() => Ordenacao('codigo')}>
          Código <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={5} alignItems="left">
          <Typography align="left" style={{ fontWeight: 600, cursor: 'pointer', display: 'flex', alignItems: 'center', alignContent: 'center' }} onClick={() => Ordenacao('descricao')}>
          Descrição <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={1} alignItems="left">
          <Typography align="left" style={{ fontWeight: 600, cursor: 'pointer', display: 'flex', alignItems: 'center', alignContent: 'center' }} onClick={() => Ordenacao('ean')}>
          Cód. Barra <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={2} alignItems="left">
          <Typography align="left" style={{ fontWeight: 600, cursor: 'pointer', display: 'flex', alignItems: 'center', alignContent: 'center' }} onClick={() => Ordenacao('precoVenda')}>
          Preço Venda <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={2} alignItems="left">
          <Typography align="left" style={{ fontWeight: 600, cursor: 'pointer', display: 'flex', alignItems: 'center', alignContent: 'center' }} onClick={() => Ordenacao('itemEstoque')}>
          Estoque Disponível <SwapVertIcon />
          </Typography>
        </Grid>
        <Grid item md={1} alignItems="left">
          <Typography align="left" style={{ fontWeight: 600, cursor: 'pointer' }}>
          
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  )
}

const ListItemProduto = ({ data, callback }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [produto] = useState(data);
  const [showModalCondicaoPagamento, setShowModalCondicaoPagamento] = useState(false);
  const [showModalSaldoEstoque, setShowModalSaldoEstoque] = useState(false);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <>
        <ListItem
          button
          disabled={produto?.desativado}
          onClick={event => {
            if (produto?.desativado) {
              return;
            }
            event.stopPropagation();

            if (callback) callback();
          }}
        >
          <Grid container item>
          <Grid item md={1} alignItems="left">
              <Typography align="left" style={{ fontWeight: 600 }}>
              {produto?.codigo}
              </Typography>
            </Grid>
            <Grid container item md={5} wrap="nowrap">
              <Grid item zeroMinWidth>
                <Typography
                  style={{
                    textTransform: 'uppercase',
                    fontWeight: 600
                  }}
                >
                  {produto?.descricao}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item md={1} alignItems="left">
              <Typography align="left" style={{ fontWeight: 600 }}>
              {produto?.ean}
              </Typography>
            </Grid>
            <Grid container item md={2} alignItems="left">
              <Typography align="left" style={{ fontWeight: 600 }}>
                {formatCurrency(produto?.precoVenda)}
              </Typography>
            </Grid>
            <Grid container item md={2} alignItems="left">
              <Typography align="left" style={{ fontWeight: 600 }}>
                {produto?.itemEstoque ?? 0}
              </Typography>
            </Grid>
            <Grid container item md={1}>
              <ListItemSecondaryAction>
                <Tooltip title="Menu">
                  <IconButton tabIndex={-1} aria-label={'Menu'} onClick={handleClick}>
                    <IconMore />
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={event => {
                      event.stopPropagation();
                      setShowModalSaldoEstoque(true);
                    }}
                  >
                    Saldo de estoque
                  </MenuItem>
                  <MenuItem
                    onClick={event => {
                      event.stopPropagation();
                      setShowModalCondicaoPagamento(true);
                    }}
                  >
                    Condições de pagamento
                  </MenuItem>
                </Menu>
              </ListItemSecondaryAction>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
      </>

      {showModalSaldoEstoque && (
        <ModalSaldoEstoqueProduto
          open={showModalSaldoEstoque}
          data={{
            produto: { ...produto },
            saldoEstoque: produto?.saldoEstoque
          }}
          onClose={() => {
            handleClose();
            setShowModalSaldoEstoque(false);
          }}
        />
      )}

      {showModalCondicaoPagamento && (
        <ModalCondicaoPagamentoProduto
          open={showModalCondicaoPagamento}
          data={{
            produto: { ...produto },
            precoVenda: produto?.precoVenda
          }}
          onClose={() => {
            handleClose();
            setShowModalCondicaoPagamento(false);
          }}
        />
      )}
    </>
  );
}

let cancel;
const CancelToken = axios.CancelToken;

const ModalConsultaProduto = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showModalConsultaProduto } = useSelector(state => state.pdv);
  const applicationMode = useSelector(state => state.app.applicationMode);
  // const [{ loading, items }, dispatchProduto] = useReducerAndSaga(
  //   reducer,
  //   function*() {
  //     yield all([saga]);
  //   },
  //   {
  //     loading: false,
  //     items: []
  //   }
  // );
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchBy, setSearchBy] = useState('termo');

  const delay = delayInms => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  const fetcher = async (isOnline) => {
    try {
      setLoading(true);

      if (cancel != undefined) cancel();
      const cancelToken = new CancelToken(function executor(c) {
        cancel = c;
      });

      if (searchInput?.length <= 3) {
        return [];
      }

      setResults([]);

        const { data } = await api.get(
          isOnline
            ? `${API_NODE_URL}/faturamento/documento-saida/produto-grade-venda?produto=${searchInput?.toUpperCase()}&pesquisaPor=${searchBy}`
            : `${API_LOCAL_URL}/estoque/produto/?queryString=${searchInput?.toUpperCase()}`,
          {
            cancelToken
          }
        );

      let newArray = [...data?.map(item => ({
        ...item,
        produtoEstProdutoId: item.id,
        estProdutoGrupo: { ...item.estProdutoGrupo, prefixoProduto: 'EX' },
        itemQuantidade: 1,
        itemValorUnitario: item?.estProdutoImposto[0]?.precoVenda,
        itemEstoque: item?.saldoEstoque ? (item?.saldoEstoque?.estoqueReal + item?.saldoEstoque?.estoqueReservado * -1) : 0,
        precoVenda: item?.estProdutoImposto[0]?.precoVenda,
        imageUrl: item?.foto ? `${STORAGE_URL}produtos/${item?.foto}` : null,
        codigoDesc: item.codigo.split('.')[0],
        codigoNumero: Number(item.codigo.split('.')[1])
      }))];

      const ordenaPorDoisValores = (arr, campo1, campo2, reverso = false) => {
        return !reverso ? 
        arr.sort(function (a, b) {
          let aCampo1 = a[campo1];
          let bCampo1 = b[campo1];
          let aCampo2 = a[campo2];
          let bCampo2 = b[campo2];
       
          if (aCampo1 == bCampo1) {
              return (aCampo2 < bCampo2) ? -1 : (aCampo2 > bCampo2) ? 1 : 0;
          } else {
              return (aCampo1 < bCampo1) ? -1 : 1;
          }
        })
        :
        arr.sort(function (a, b) {
          let aCampo1 = a[campo1];
          let bCampo1 = b[campo1];
          let aCampo2 = a[campo2];
          let bCampo2 = b[campo2];
       
          if (aCampo1 == bCampo1) {
              return (aCampo2 > bCampo2) ? -1 : (aCampo2 < bCampo2) ? 1 : 0;
          } else {
              return (aCampo1 > bCampo1) ? -1 : 1;
          }
        })
      }
        if(orderBy && sortBy === 'asc'){
          if(orderBy === 'codigo') {
            const dataOrdered = ordenaPorDoisValores(newArray, 'codigoDesc', 'codigoNumero');
            setResults(dataOrdered);
          } else {
            const dataOrdered = newArray.sort((a,b) => (a[orderBy] > b[orderBy]) ? 1 : ((b[orderBy] > a[orderBy]) ? -1 : 0))
            setResults(dataOrdered);
          }
        } else if(orderBy && sortBy === 'desc') {
          if(orderBy === 'codigo') {
            const dataOrdered = ordenaPorDoisValores(newArray, 'codigoDesc', 'codigoNumero', true);
            setResults(dataOrdered);
          } else {
            const dataOrdered = newArray.sort((a,b) => (a[orderBy] < b[orderBy]) ? 1 : ((b[orderBy] < a[orderBy]) ? -1 : 0))
            setResults(dataOrdered);
          }
        } else {
          setResults(newArray);
        }
      }
      
      catch (err){
        throw err;
    } finally {
      setLoading(false);
    }
  };


  const handleClose = () => {
    dispatch(pdvActions.setShowModalConsultaProduto({ show: false }));
  };

  const handleClickMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // const changeSearchInput = e => {
  //   const queryText = e.target.value;

  //   dispatchProduto(actions.searchRequest(queryText));
  // };

  // useEffect(() => {
  //   dispatchProduto(actions.setApplicationMode(applicationMode));
  // }, [applicationMode]);

  useEffect(() => {
    fetcher(true);  
  }, [searchInput, orderBy, sortBy, searchBy]);

  return (
    <>
      <Dialog
        fullScreen
        open={showModalConsultaProduto}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
      >
        <>
          <AppBar color="default" position="fixed">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <IconArrowBack />
              </IconButton>

              <Box style={{ minWidth: 180 }}>
                <Typography>Consulta de Produto</Typography>
              </Box>

              <Divider className={classes.divider} />

              <TextField
                variant="outlined"
                autoFocus
                focused
                fullWidth
                placeholder="Pesquise por código, descrição, cód. barra, cód. fabricante, cód. auxiliar"
                // onChange={changeSearchInput}
                value={searchInput}
                onChange={e => {
                  e.preventDefault();

                  const queryText = e.target.value;
                  // if (queryText?.length < 3) {
                  //   setLoading(false);
                  //   setResults([]);
                  //   return;
                  // }
                  setSearchInput(queryText);
                }}
                InputProps={{
                  style: {
                    height: 44
                  },
                  startAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: 0 }}>
                      <Mui.IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        aria-label={'Mais ações'}
                        onClick={e => handleClickMenu(e)}
                      >
                        { searchBy === 'termo' && <IconSearch />}
                        { searchBy === 'exato' && <MuiIcons.ZoomIn />}
                        { searchBy === 'inicio' && <MuiIcons.ArrowForward />}
                      </Mui.IconButton>
                      <Mui.Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                      >
                        <Mui.MenuItem onClick={() => {setSearchBy('termo'); setAnchorEl(null)}}>
                          <IconSearch fontSize="small" /> <span style={{ marginLeft: 12 }}>Pesquisa por termo</span>
                        </Mui.MenuItem>
                        <Mui.Divider />
                        <Mui.MenuItem onClick={() => { setSearchBy('exato'); setAnchorEl(null)}}>
                          <MuiIcons.ZoomIn fontSize="small" /> <span style={{ marginLeft: 12 }}>Pesquisa por valor exato</span>
                        </Mui.MenuItem>
                        <Mui.Divider />
                        <Mui.MenuItem onClick={() => { setSearchBy('inicio'); setAnchorEl(null)}}>
                          <MuiIcons.ArrowForward fontSize="small" /> <span style={{ marginLeft: 12 }}>Inicia com</span>
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </InputAdornment>
                  )
                }}
              />

              {/* <Divider className={classes.divider} />
              <Select variant="outlined" style={{ width: 280, height: 44 }}>
                <MenuItem value={{ field: 'produtoDescricao', order: 'asc' }}>
                  A/Z Descrição
                </MenuItem>
                <MenuItem value={{ field: 'produtoDescricao', order: 'desc' }}>
                  Z/A Descrição
                </MenuItem>
              </Select>
              <Divider className={classes.divider} />
              <Button color="inherit" style={{ padding: '8px 16px' }}>
                <IconSearch /> {'  Filtro'}
              </Button> */}
            </Toolbar>
          </AppBar>

          {loading ? (
            <>
              <Box style={{ marginTop: 64 }}>
                <LinearProgress variant="indeterminate" color="primary" />
              </Box>
              <Searching />
            </>
          ) : (
            <>
              {results?.length > 0 ? (
                <List style={{ marginTop: 64 }}>
                  <ListHeader dados={results} setOrderBy={setOrderBy} sortBy={sortBy} setSortBy={setSortBy}/>
                  <Divider />
                  {results.map((produto, index) => (
                    <ListItemProduto
                      key={`index-${index}`}
                      data={produto}
                      callback={() => {
                        dispatch(
                          pdvActions.selecionarProduto(produto, () => {
                            dispatch({
                              type: SET_FOCUS_QUANTIDADE,
                              focus: true
                            });
                          })
                        );
                      }}
                    />
                  ))}
                </List>
              ) : (
                <ListEmpty />
              )}
            </>
          )}
        </>
      </Dialog>
    </>
  );
};

export default ModalConsultaProduto;
