import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Skeleton from '@material-ui/lab/Skeleton';
import { Search as IconSearch } from '@material-ui/icons';

import { SHOW_SHORTCUTS } from '~/constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

const CardVendedor = React.memo(() => {
  const dispatch = useDispatch();
  const { venda, showModalLoadingMensagem } = useSelector(state => state.pdv);

  const handleConsultarVendedor = () => {
    dispatch(pdvActions.setShowModalConsultaVendedor({ show: true }));
  };

  return (
    <Paper>
      <Box p={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Box mr={1}>
          <Typography variant="caption">Vendedor {SHOW_SHORTCUTS && '(CTRL+S)'}</Typography>
          {showModalLoadingMensagem?.show ? (
            <Skeleton variant="text" width={180} />
          ) : (
            <Typography variant="body2">
              <b>{venda?.fatVendedor ? venda?.fatVendedor?.nome : 'INFORMAR VENDEDOR'}</b>
            </Typography>
          )}
        </Box>
        <Box display="flex" mr={1} justifyContent="center" alignItems="center">
          <Fab
            size="small"
            aria-label="Consulta vendedor"
            onClick={() => handleConsultarVendedor()}
          >
            <IconSearch />
          </Fab>
        </Box>
      </Box>
    </Paper>
  );
});

export default CardVendedor;
