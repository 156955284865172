import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';
import Lottie from 'react-lottie';
import { QRCodeCanvas } from 'qrcode.react';

import api from '~/services/api';
import { formatCurrency } from '~/utils/format';
import { API_TERMINAL_TEF_URL, DATATYPE_TEF } from '~/constants';
import * as appActions from '~/store/modules/app/app-actions';

import animationLoading from '~/assets/animations/loading.json';
import animationPrint from '~/assets/animations/print2.json';
import animationPosCreditCard from '~/assets/animations/pos-credit-card.json';

import { CustomNumberInput } from '~/components/CustomNumberInput';
import * as S from './styles';

const ModalMenuTEF = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.app.showModalMenuTef);
  const { mensagemTef } = useSelector(state => state.app);

  const [respostaSitef, setRespostaSitef] = useState(null);

  const onClose = () => {
    dispatch(
      appActions.setShowModalMenuTef({
        show: false,
        administracao: false
      })
    );
  };

  const handleClose = async () => {
    try {
      await api.post(`${API_TERMINAL_TEF_URL}/api/tef/callback`, {
        ...data.tef,
        interromper: true,
        voltar: false,
        respostaSitef: ''
      });
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  const handleSubmit = async () => {
    await api.post(`${API_TERMINAL_TEF_URL}/api/tef/callback`, {
      ...data.tef,
      interromper: false,
      voltar: false,
      respostaSitef: respostaSitef?.toString()
    });

    setRespostaSitef('');
  };

  const handleVoltar = async () => {
    await api.post(`${API_TERMINAL_TEF_URL}/api/tef/callback`, {
      ...data.tef,
      interromper: false,
      voltar: true,
      respostaSitef: ''
    });

    setRespostaSitef('');
  };

  return (
    <Mui.Dialog
      open={data?.show}
      onKeyDown={key => {
        if (key.code === 'Escape') {
          onClose();
        }
      }}
      PaperComponent={Paper}
      maxWidth={'lg'}
    >
      {data?.inicializado && !data?.imprimindo && (
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  {data?.title}
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />
            </Mui.Toolbar>
          </Mui.AppBar>
          {data?.tef?.titulo && (
            <S.Header>
              <Mui.Typography variant="h4">{data?.tef?.titulo}</Mui.Typography>
              {!data?.administracao && (
                <Mui.Box display="flex" flex flexDirection={'row'}>
                  <Mui.Typography variant="h6">
                    {data?.parcela?.finMeiopagamento?.descricao} {' - '}
                    {data?.parcela?.parcelaQuantidade}
                    {'x'}
                    {formatCurrency(
                      data?.parcela?.parcelaValor / data?.parcela?.parcelaQuantidade ?? 0
                    )}
                    {data?.parcela?.parcelaValorJuros > 0 ? (
                      <small>{' com juros'}</small>
                    ) : (
                      <small style={{ color: '#09A848' }}>{' sem juros'}</small>
                    )}
                  </Mui.Typography>
                  <Mui.Box flexGrow={1} />
                  <Mui.Typography variant="h4">
                    {formatCurrency(data?.parcela?.parcelaValor)}
                  </Mui.Typography>
                </Mui.Box>
              )}
            </S.Header>
          )}
        </Mui.DialogTitle>
      )}

      <Mui.DialogContent dividers style={{ width: 640 }}>
        {data?.inicializado ? (
          <>
            {data?.imprimindo ? (
              <>
                <Mui.Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={4}
                >
                  <Lottie
                    options={{
                      loop: data?.imprimindo,
                      autoplay: true,
                      animationData: animationPrint,
                      rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                      }
                    }}
                    height={256}
                    width={256}
                    isStopped={false}
                    isPaused={false}
                  />
                  <Mui.Typography variant="h4">Imprimindo comprovante ... </Mui.Typography>
                </Mui.Box>
              </>
            ) : (
              <>
                <Mui.Box p={2}>
                  {mensagemTef && data?.tef?.dataType !== DATATYPE_TEF.QRCODE ? (
                    <>
                      <Mui.Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        p={4}
                      >
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationPosCreditCard,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice'
                            }
                          }}
                          height={320}
                          width={256}
                          isStopped={false}
                          isPaused={false}
                        />

                        <Mui.Typography variant="h4">{mensagemTef}</Mui.Typography>
                      </Mui.Box>
                    </>
                  ) : (
                    <>
                      {data?.tef?.dataType === DATATYPE_TEF.AWAIT && (
                        <>
                          <Mui.Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            p={4}
                          >
                            <Lottie
                              options={{
                                loop: true,
                                autoplay: true,
                                animationData: animationLoading,
                                rendererSettings: {
                                  preserveAspectRatio: 'xMidYMid slice'
                                }
                              }}
                              height={320}
                              width={256}
                              isStopped={false}
                              isPaused={false}
                            />

                            <Mui.Typography variant="h4">{data?.tef?.mensagem}</Mui.Typography>
                          </Mui.Box>
                        </>
                      )}
                      {data?.tef?.dataType === DATATYPE_TEF.QRCODE && (
                        <>
                          <Mui.Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            p={4}
                          >
                            <div style={{ backgroundColor: '#FFF', padding: 16, margin:16 }}>
                              <QRCodeCanvas value={data?.qrCode} size={384} includeMargin />
                            </div>

                            {mensagemTef && (
                              <Mui.Typography variant="h4">{mensagemTef}</Mui.Typography>
                            )}
                          </Mui.Box>
                        </>
                      )}
                      {data?.tef?.dataType !== DATATYPE_TEF.AWAIT &&
                        data?.tef?.dataType !== DATATYPE_TEF.QRCODE && (
                          <>
                            {data?.montaCampo && (
                              <>
                                {(data?.tef?.dataType === DATATYPE_TEF.ALPHANUMERIC ||
                                  data?.tef?.dataType === DATATYPE_TEF.NUMERIC) && (
                                  <>
                                    {/* <Mui.Typography>{data?.tef?.titulo}</Mui.Typography> */}
                                    <Mui.TextField
                                      autoFocus
                                      variant="outlined"
                                      required
                                      type={
                                        data?.tef?.dataType === DATATYPE_TEF.NUMERIC
                                          ? 'numeric'
                                          : 'text'
                                      }
                                      fullWidth
                                      value={respostaSitef}
                                      onChange={event => setRespostaSitef(event.target.value)}
                                      onKeyPress={event => {
                                        console.log({ event });
                                        if (event?.charCode == 13) {
                                          handleSubmit();
                                        }
                                      }}
                                      inputProps={{
                                        minLength: data?.tef?.tamanhoMinimo ?? 0,
                                        maxLength: data?.tef?.tamanhoMaximo ?? 0
                                      }}
                                      InputProps={{
                                        style: {
                                          fontSize: 24,
                                          height: 64
                                        }
                                      }}
                                    />
                                  </>
                                )}
                                {data?.tef?.dataType === DATATYPE_TEF.CURRENCY && (
                                  <>
                                    {/* <Mui.Typography>{data?.tef?.titulo}</Mui.Typography> */}
                                    <Mui.TextField
                                      autoFocus
                                      variant="outlined"
                                      required
                                      type="numeric"
                                      fullWidth
                                      value={respostaSitef ?? 0}
                                      onChange={event => setRespostaSitef(event?.maskedvalue)}
                                      onKeyPress={event => {
                                        console.log({ event });
                                        if (event?.charCode == 13) {
                                          handleSubmit();
                                        }
                                      }}
                                      InputProps={{
                                        style: {
                                          fontSize: 24,
                                          height: 64
                                        },
                                        inputComponent: CustomNumberInput
                                      }}
                                    />
                                  </>
                                )}
                              </>
                            )}
                            {data?.montaMenu && (
                              <>
                                {data?.tef?.itensMenu?.length === 0 ? (
                                  <p>Montando menu</p>
                                ) : (
                                  <S.List>
                                    {data?.tef?.itensMenu
                                      ?.filter(x => x !== '')
                                      ?.map((item, index) => (
                                        <S.ListItem key={index}>
                                          <S.ButtonMenu
                                            autoFocus={index === 0}
                                            onClick={async () => {
                                              await api.post(
                                                `${API_TERMINAL_TEF_URL}/api/tef/callback`,
                                                {
                                                  ...data.tef,
                                                  interromper: false,
                                                  respostaSitef: `${index + 1}`
                                                }
                                              );
                                            }}
                                          >{`${item}`}</S.ButtonMenu>
                                        </S.ListItem>
                                      ))}
                                  </S.List>
                                )}
                              </>
                            )}
                          </>
                        )}
                    </>
                  )}
                </Mui.Box>
              </>
            )}
          </>
        ) : (
          <>
            <Mui.Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={4}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationLoading,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                height={320}
                width={256}
                isStopped={false}
                isPaused={false}
              />
              <Mui.Typography variant="h4">Aguarde inicializando SiTef ... </Mui.Typography>
            </Mui.Box>
          </>
        )}
      </Mui.DialogContent>
      {/* {mensagemTef && !data?.imprimindo && (
        <Mui.AppBar color="default" position="static">
          <Mui.Toolbar>
            <Mui.Typography variant="h4">{mensagemTef}</Mui.Typography>
          </Mui.Toolbar>
        </Mui.AppBar>
      )} */}

      {data?.inicializado && !data?.imprimindo ? (
        <Mui.DialogActions>
          <Mui.Box p={1} display="flex" justifyContent="space-between" alignItems="center">
            {data?.montaCampo && (
              <Mui.Box display="flex">
                <Mui.Button
                  size="large"
                  className="button-success"
                  onClick={async () => await handleSubmit()}
                >
                  <MuiIcons.CheckCircle /> <span style={{ paddingLeft: 8 }}>Confirmar </span>
                </Mui.Button>
              </Mui.Box>
            )}

            <Mui.Box display="flex">
              <>
                {data?.tef?.voltar && (
                  <Mui.Button
                    size="large"
                    variant="contained"
                    onClick={async () => await handleVoltar()}
                    style={{ marginLeft: 16 }}
                  >
                    <MuiIcons.ArrowBack />
                    <span style={{ paddingLeft: 8 }}> Voltar</span>
                  </Mui.Button>
                )}
              </>

              <Mui.Button
                size="large"
                onClick={async () => await handleClose()}
                style={{ marginLeft: 16 }}
              >
                <MuiIcons.Close />
                <span style={{ paddingLeft: 8 }}> Cancelar (ESC)</span>
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>
        </Mui.DialogActions>
      ) : (
        <Mui.DialogActions>
          <Mui.Button
            size="large"
            onClick={async () => await handleClose()}
            style={{ marginLeft: 16 }}
          >
            <MuiIcons.Close />
            <span style={{ paddingLeft: 8 }}>Fechar/Cancelar</span>
          </Mui.Button>
        </Mui.DialogActions>
      )}
    </Mui.Dialog>
  );
};

export default ModalMenuTEF;
