import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import storeSynchronize from 'redux-localstore';

import reactotron from '~/config/ReactotronConfig';
import rootReducer from './modules/root-reducer';
import rootSaga from './modules/root-saga';
import logger from 'redux-logger';

const isDevelopment = process.env.NODE_ENV === 'development';

const sagaMiddleware = isDevelopment
  ? createSagaMiddleware({ sagaMonitor: reactotron?.createSagaMonitor() })
  : createSagaMiddleware();

const middleWares = [logger, sagaMiddleware];

const enchancer = isDevelopment
  ? compose(reactotron?.createEnhancer(), applyMiddleware(...middleWares))
  : applyMiddleware(sagaMiddleware);

const store = createStore(
  rootReducer,
  compose(enchancer, applyMiddleware(thunk))
);

sagaMiddleware.run(rootSaga);

storeSynchronize(store, {
  whitelist: ['pdv'],
  storage: 'sessionStorage'
});

export default store;
