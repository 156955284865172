import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons'

import { formatCurrency } from '~/utils/format';
import { SHOW_SHORTCUTS, STATUS_PDV, TIPO_DOCUMENTO, MODELO_DOCUMENTO } from '~/constants';
import { selectorFechamento } from '~/store/modules/pdv/pdv-selectors';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import { selectorRotina } from '~/store/modules/auth/auth-selectors';

import * as S from './styles';
import toast from '~/services/toast';

const Totalizadores = () => {
  const dispatch = useDispatch();
  const { venda, status, itemPagamento, tipoDocumento, multiplosPagamentos } = useSelector(state => state.pdv);
  const { valorSaldoPagar, valorPago, permiteFechamento } = useSelector(
    selectorFechamento([])
  );
  const exibirModalCliente = useSelector(selectorRotina('PP004'));
  const clienteVazio = venda.intCliente === null
  const empresa = useSelector(state => state.auth.empresa);

  let nomeTipoDocumento; 
  if(tipoDocumento === TIPO_DOCUMENTO.ORCAMENTO) nomeTipoDocumento = 'Orçamento';
  else if(tipoDocumento === TIPO_DOCUMENTO.PEDIDO) nomeTipoDocumento = 'Pedido';
  else if(tipoDocumento === TIPO_DOCUMENTO.DOCUMENTOSAIDA) nomeTipoDocumento='Venda';

  const mensagemObrigaCliente = `Conforme artigo 37-B do RICMS BA, nas vendas de mercadorias </br> a não contribuintes do ICMS com valor superior a R$500,00 </br> é obrigatório informar CPF ou CNPJ!`

  return (
    <>
      <S.Container>
        <Mui.Box py={1} px={2}>
          <Mui.Grid container item spacing={2}>
            <Mui.Grid item md={6}>
              <Mui.Grid container item justify="space-between">
                <Mui.Typography align="left">Mercadoria</Mui.Typography>
                <Mui.Typography align="right">{formatCurrency(venda?.valorMercadoria)}</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container item justify="space-between">
                <Mui.Typography align="left">Desconto</Mui.Typography>
                <Mui.Typography align="right">{formatCurrency(venda?.valorDesconto)}</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container item justify="space-between">
                <Mui.Typography align="left">Acréscimo</Mui.Typography>
                <Mui.Typography align="right">{formatCurrency(venda?.valorAcrescimo)}</Mui.Typography>
              </Mui.Grid>
              {/* <Mui.Grid container item justify="space-between">
                <Mui.Typography align="left">Frete</Mui.Typography>
                <Mui.Typography align="right">{formatCurrency(venda?.valorFrete)}</Mui.Typography>
              </Mui.Grid> */}
            </Mui.Grid>
            <Mui.Divider orientation="vertical" flexItem />
            <Mui.Grid item md={6} style={{ marginLeft: -1 }}>
              <Mui.Grid container item justify="space-between">
                <Mui.Typography align="left">Saldo a pagar</Mui.Typography>
                <Mui.Typography align="right">{formatCurrency(valorSaldoPagar)}</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container item justify="space-between">
                <Mui.Typography align="left">Valor pago</Mui.Typography>
                <Mui.Typography align="right">{formatCurrency(valorPago)}</Mui.Typography>
              </Mui.Grid>
              {/* <Mui.Divider style={{margin: '0 8'}} /> */}

              <Mui.Grid container item justify="space-between">
                <Mui.Typography align="right">Total</Mui.Typography>
                <Mui.Typography
                  variant="h3"
                  align="right"
                  style={{ textTransform: 'uppercase', fontWeight: 600 }}
                >
                  {formatCurrency(venda?.valorTotal)}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Box>
        <Mui.Divider />
        <Mui.Box display="flex" py={1} px={2} alignContent="center" justifyContent="space-between">
          <Mui.Box display="flex" flexDirection="row">
            {status === STATUS_PDV.VENDA && (
              <>
                <S.ButtonReturn
                  onClick={() => dispatch(pdvActions.setStatus(STATUS_PDV.IDENTIFICACAO))}
                >
                  IDENTIFICAÇÃO
                </S.ButtonReturn>
              </>
            )}
            {status === STATUS_PDV.IDENTIFICACAO && venda?.itens?.length > 0 && (
              <>
                <S.ButtonNext onClick={() => dispatch(pdvActions.setStatus(STATUS_PDV.VENDA))}>
                  ITENS
                </S.ButtonNext>
              </>
            )}

            {venda?.itens?.length > 0 && tipoDocumento !== TIPO_DOCUMENTO.ORCAMENTO && (
              <>
                <S.ButtonPrimary
                  startIcon={<MuiIcons.LocalShipping />}
                  onClick={() =>
                    dispatch(pdvActions.setShowModalExpedicaoMercadoria({ show: true }))
                  }
                >
                  EXPEDIÇÃO
                </S.ButtonPrimary>
              </>
            )}
          </Mui.Box>

          <S.ButtonSubmit
            disabled={venda?.itens?.length === 0}
            onClick={() => {
              if (clienteVazio && exibirModalCliente && tipoDocumento === TIPO_DOCUMENTO.PEDIDO) {
                dispatch(pdvActions.setShowModalIdentificarCliente({ show: true }));
                toast.info("Para finalizar uma venda, é necessário informar um cliente")
                return
              }
              if (TIPO_DOCUMENTO.DOCUMENTOSAIDA && venda?.documentoModelo === MODELO_DOCUMENTO.NFCE && (!venda?.intCliente || venda?.pfCpf)) {
                if (venda?.valorTotal >= 500 && empresa?.uf === 'BA') {
                  toast.warning(mensagemObrigaCliente);
                  dispatch(pdvActions.setShowModalIdentificarCliente({ show: true }));
                  return
                }
              }
              if (multiplosPagamentos) {
                if (valorSaldoPagar === 0) {
                  dispatch(pdvActions.fecharVenda());
                } else {
                  dispatch(pdvActions.setShowModalMultiploPagamento({ show: true }));
                }
              } else {
                if (!itemPagamento?.meioPagamento || !itemPagamento.condicaoPagamento) {
                  dispatch(pdvActions.setShowModalConsultaPagamento({ show: true, confirmaPagamento: true }))
                } else {
                  dispatch(pdvActions.setShowModalConfirmaPagamento({ show: true }));
                }
              }
            }}
          >
            <Mui.Box display="flex" flexDirection="column" justifyContent="center">
              <Mui.Typography variant="body2">
                <b>FINALIZAR {nomeTipoDocumento} {SHOW_SHORTCUTS && `(F12)`}</b>
              </Mui.Typography>
              {itemPagamento?.meioPagamento && itemPagamento?.condicaoPagamento && (
                <Mui.Typography variant="caption">
                  {itemPagamento?.meioPagamento?.descricao} (
                  {itemPagamento?.condicaoPagamento?.descricao})
                </Mui.Typography>
              )}
            </Mui.Box>
          </S.ButtonSubmit>
        </Mui.Box>
      </S.Container>
    </>
  );
};

export default Totalizadores;
