import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { Alert, AlertTitle } from '@material-ui/lab';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
import MaskedInput from 'react-text-mask';
import validator from 'cpf-cnpj-validator';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import * as Yup from 'yup';
import axios from 'axios';

import api from '~/services/api';
import toast from '~/services/toast';
import { API_LOCAL_URL, API_NODE_URL, API_BASE_URL, APP_ERROR, TIPO_DOCUMENTO, APP_SETTINGS } from '~/constants';
import { getApplicationIsOnline } from '~/store/modules/app/app-selectors';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import clienteService from '~/store/modules/cliente/cliente-service';

import InputPesquisaCliente from '../../components/InputPesquisaCliente';
import { ModalCadastroEnderecoCliente } from '../ModalEnderecoCliente';
import { selectorParametroAsBoolean, selectorParametroAsInteger, selectorRotina } from '~/store/modules/auth/auth-selectors';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

let cancel;
const CancelToken = axios.CancelToken;

const ModalIdentificarCliente = () => {
  const temaAtual = JSON.parse(sessionStorage.getItem(APP_SETTINGS));
  const dispatch = useDispatch();

  const { parametros } = useSelector(state => state.auth);
  const { venda, showModalIdentificarCliente, tipoDocumento } = useSelector(state => state.pdv);
  const isOnline = useSelector(getApplicationIsOnline());

  const criticaFaltaCpf = useSelector(selectorParametroAsBoolean('PA001'));
  const criticaCpfDuplicado = useSelector(selectorParametroAsBoolean('PA003'));
  const intClienteGrupoId = useSelector(selectorParametroAsInteger('PA004'));
  const parametroCadastraClienteTelaVenda = useSelector(selectorParametroAsBoolean('PA005'));
  const permissaoPreCadastroCliente = useSelector(selectorRotina('A060CLI'));
  const showSwitchPreCadastroCliente = permissaoPreCadastroCliente
    ? permissaoPreCadastroCliente
    : parametroCadastraClienteTelaVenda;

    const obrigaMeioPagamento = useSelector(selectorParametroAsBoolean('PP001'));

    const [loadingCliente, setLoadingCliente] = useState(false);
  const [showModalCadastrarEndereco, setShowModalCadastrarEndereco] = useState({ show: false });
  const [cadastrarCliente, setCadastrarCliente] = useState(false);

  const [showAvisoCliente, setshowAvisoCliente] = useState(true);
  const [cliente, setCliente] = useState();
  const [endereco, setEndereco] = useState(null);
  const validationSchema = Yup.object().shape({
    clienteNome: Yup.string().required('Nome do cliente é obrigatório'),
    clienteCpfCnpj: Yup.string().when('$other', (_, schema) =>
      criticaFaltaCpf && cadastrarCliente
        ? schema.required('CPF/CNPJ é obrigatório')
        : schema.nullable()
    )
  });
  const handleClose = () => {
    dispatch(pdvActions.setShowModalIdentificarCliente({ show: false, desativarModalsSubsequentes: false }));
    if(obrigaMeioPagamento && tipoDocumento !== TIPO_DOCUMENTO.DOCUMENTOSAIDA && !showModalIdentificarCliente.desativarModalsSubsequentes) {
      dispatch(pdvActions.setShowModalConsultaPagamento({ show: true }));
    }
  };

  const handleChangeCpfCnpj = async (cpfCnpj, nome = '', telefone = '', celular = '') => {
    try {
      if (cancel != undefined) cancel();

      if (cpfCnpj?.length < 14) {
        return;
      }
      setLoadingCliente(true);
      const {
        data: { items }
      } = isOnline
        ? await clienteService.query(cpfCnpj, {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            })
          })
        : await api.get(`${API_LOCAL_URL}/integracao/cliente?queryString=${cpfCnpj}`);
      if (items?.length > 0) {
        informarCliente(items[0]);
      } else
        setCliente({
          clienteNome: nome,
          clienteCpfCnpj: cpfCnpj,
          clienteTelefone: telefone,
          clienteCelular: celular,
          intCliente: null,
          fatTabelavenda: null
        });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoadingCliente(false);
    }
  };

  const handleShowModalEndereco = () => {
    setShowModalCadastrarEndereco({ show: true, type: 'entrega' });
  };

  const validacpf = (cpf) => {


    if(cpf.length !== 12 && cpf.length !== 14 && cpf.length !== 16 && cpf.length !== 18) return false;

    if(cpf.length === 14 || cpf.length === 12) {
      cpf = cpf.replace(/\D/g, '');
      if(cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
      let result = true;
      [9,10].forEach(function(j){
          let soma = 0, r;
          cpf.split(/(?=)/).splice(0,j).forEach(function(e, i){
              soma += parseInt(e) * ((j+2)-(i+1));
          });
          r = soma % 11;
          r = (r <2)?0:11-r;
          if(r != cpf.substring(j, j+1)) result = false;
      });
      return result;
    }

    if(cpf.length === 18 || cpf.length === 16) {
      let cnpj = cpf;
      cnpj = cnpj.replace(/[^\d]+/g,'');
 
      if(cnpj == '') return false;
       
      if (cnpj.length != 14)
          return false;
   
      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" || 
          cnpj == "11111111111111" || 
          cnpj == "22222222222222" || 
          cnpj == "33333333333333" || 
          cnpj == "44444444444444" || 
          cnpj == "55555555555555" || 
          cnpj == "66666666666666" || 
          cnpj == "77777777777777" || 
          cnpj == "88888888888888" || 
          cnpj == "99999999999999")
          return false;
           
      // Valida DVs
      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0,tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0))
          return false;
           
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1))
            return false;
             
      return true;
    }
}

  const handleSubmit = async values => {
    if(values.clienteNome) {
      values.clienteNome = values.clienteNome.toUpperCase();
      values.clienteNome = values.clienteNome.replace(/\s+$/, '');
    }
    if(values?.clienteCpfCnpj && !validacpf(values.clienteCpfCnpj)) return toast.warning('CPF ou CNPJ inválido!');

    if (cadastrarCliente && !values?.intCliente?.id && isOnline) {
      if (!endereco?.logradouro || !endereco?.logradouroNumero) {
        toast.warning('Informe o endereço!');
        return;
      }

      if (criticaCpfDuplicado) {
        const { data } = await api.post(`${API_NODE_URL}/integracao/cliente/pesquisa-cpf-cnpj`, {
          cpfCnpj: values?.pfCpf ?? values?.pjCnpj
        });

        if (data?.dados?.length > 0) {
          toast.warning('Esse CPF/CNPJ já está cadastrado no sistema!');
          return;
        }
      }

      try {
        const natureza = values?.clienteCpfCnpj?.length == 14 ? 'F' : 'J';
        const payload = {
          enderecoCep: endereco?.cep,
          enderecoLogradouro: endereco?.logradouro,
          enderecoLogradouroNumero: endereco?.logradouroNumero,
          enderecoBairro: endereco?.bairro,
          enderecoIntCidade: endereco?.intCidade,
          enderecoIntCidadeId: endereco?.intCidade?.id,
          enderecoComplemento: endereco?.referencia ?? null,
          enderecoUf: endereco.uf,
          enderecos: [{ ...endereco }],
          natureza: natureza,
          razaoSocial: values?.clienteNome,
          telefone: values?.clienteTelefone,
          celular: values?.clienteCelular,
          pfCpf: natureza === 'F' ? values?.clienteCpfCnpj : null,
          pjCnpj: natureza === 'J' ? values?.clienteCpfCnpj : null,
          tipo: 'C',
          tipoContribuinte: 9,
          intClienteGrupoId
        };
        const { data: clienteSalvo } = await api.post(
          `${API_NODE_URL}/integracao/cliente`,
          payload
        );

        const $select = ['id', 'uf', 'cidade', 'codigoIbge', 'distrito', 'subdistrito'].join(',');
        const {
          data: [cidade]
        } = await api.get(
          `${API_BASE_URL}/v1/integracao/cidade/?$select=${$select?.toString()}&$filter=id eq ${
            clienteSalvo?.enderecos[0]?.intCidadeId
          }`
        );

        dispatch(
          pdvActions.identificarCliente({
            clienteId: clienteSalvo?.id,
            clienteNome: clienteSalvo?.razaoSocial ?? '',
            clienteCpfCnpj: clienteSalvo?.pfCpf ?? clienteSalvo?.pjCnpj,
            clienteTelefone: clienteSalvo?.telefone ?? '',
            clienteCelular: clienteSalvo?.celular ?? '',
            intCliente: {
              ...clienteSalvo,
              intClienteEnderecoId: clienteSalvo?.enderecos[0]?.id,
              enderecoIntCidade: cidade,
              enderecos: [{ ...clienteSalvo?.enderecos[0], intCidade: cidade }]
            },
            fatTabelavenda: null
          })
        );

        dispatch(
          pdvActions.selecionarEnderecoEntrega({ ...clienteSalvo?.enderecos[0], intCidade: cidade })
        );

        toast.success('Cliente cadastrado com sucesso!');
      } catch (error) {
        dispatch({ type: APP_ERROR, error });
      }

      
    } else {
      dispatch(
        pdvActions.identificarCliente({
          ...values
        })
      );
    }

    handleClose();

    if (showModalIdentificarCliente?.callback) showModalIdentificarCliente?.callback();
  };

  const handleClearCliente = () => {
    setCliente({
      clienteNome: '',
      clienteCpfCnpj: '',
      clienteTelefone: '',
      clienteCelular: '',
      intCliente: null,
      fatTabelavenda: null
    });

  };

  const informarCliente = values => {
    setshowAvisoCliente(true);
    setCliente(
      values
        ? {
            clienteId: values?.id,
            clienteNome: values?.razaoSocial ?? '',
            clienteCpfCnpj: values?.pfCpf ?? values?.pjCnpj,
            clienteTelefone: values?.telefone ?? '',
            clienteCelular: values?.celular ?? '',
            intCliente: { ...values }
          }
        : {
            clienteId: venda?.clienteId ?? 0,
            clienteNome: venda?.clienteNome ?? '',
            clienteCpfCnpj: venda?.clienteCpfCnpj ?? '',
            clienteTelefone: venda?.clienteTelefone ?? '',
            clienteCelular: venda?.clienteCelular ?? '',
            intCliente: { ...venda?.intCliente }
          }
    );
  };

  useEffect(() => {
    informarCliente(showModalIdentificarCliente?.clienteSelecionado);
  }, [showModalIdentificarCliente]);

  return (
    <>
      <Mui.Dialog
        open={showModalIdentificarCliente?.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Mui.Paper}
        maxWidth={'lg'}
        style={{zIndex: '999'}}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box style={{ minWidth: 180 }}>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  {cadastrarCliente && isOnline ? 'Pré-cadastro de cliente' : 'Identificar cliente'}
                </Mui.Typography>
              </Mui.Box>

              <Mui.Box flexGrow={1} />
              {showSwitchPreCadastroCliente && (
                <Mui.Box>
                  <Mui.Switch
                    checked={cadastrarCliente}
                    onChange={() => setCadastrarCliente(state => !state)}
                  />
                  Cadastrar cliente
                </Mui.Box>
              )}

              <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
          <Mui.Box p={2}>
            <InputPesquisaCliente
              setFocus={true}
              callback={cliente => {
                informarCliente(cliente);
              }}
            />
          </Mui.Box>
        </Mui.DialogTitle>

        <Formik
          initialValues={cliente}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
            <>
              <form noValidate>
                <Mui.DialogContent dividers style={{ padding: 32, width: 800 }}>
                  <Mui.Grid container spacing={4}>
                    <Mui.Grid item md={4} style={{padding: 8}}>
                      <Mui.TextField
                        name="clienteCpfCnpj"
                        label={'CPF/CNPJ'}
                        value={values?.clienteCpfCnpj}
                        error={Boolean(touched.clienteCpfCnpj && errors.clienteCpfCnpj)}
                        autoFocus
                        fullWidth
                        helperText={touched.clienteCpfCnpj && errors.clienteCpfCnpj}
                        onBlur={handleBlur}
                        onChange={e => {
                          const cpfCnpj = e.target.value;
                          setFieldValue('clienteCpfCnpj', cpfCnpj);
                          handleChangeCpfCnpj(cpfCnpj, values?.clienteNome, values?.clienteTelefone, values?.clienteCelular);
                        }}
                        variant="outlined"
                        InputProps={{
                          inputComponent: CpfCnpj,
                          endAdornment: (
                            <>
                              {loadingCliente ? (
                                <Mui.CircularProgress color="inherit" size={20} />
                              ) : (
                                <>
                                  {values?.clienteCpfCnpj?.length >= 14 && (
                                    <Mui.IconButton
                                      tabIndex={-1}
                                      color="inherit"
                                      onClick={handleClearCliente}
                                      aria-label="close"
                                    >
                                      <MuiIcons.Close />
                                    </Mui.IconButton>
                                  )}
                                </>
                              )}
                            </>
                          )
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                            fontWeight: 'bold'
                          },
                        }}
                      />
                    </Mui.Grid>

                    <Mui.Grid item md={4} style={{padding: 8}}>
                      <Mui.TextField
                        name="clienteTelefone"
                        label={'Telefone'}
                        value={values?.clienteTelefone}
                        error={Boolean(touched.clienteTelefone && errors.clienteTelefone)}
                        fullWidth
                        helperText={touched.clienteTelefone && errors.clienteTelefone}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        InputProps={{
                          inputComponent: TextMaskCustom
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                            fontWeight: 'bold'
                          },
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={4} style={{padding: 8}}>
                      <Mui.TextField
                        name="clienteCelular"
                        label={'Celular'}
                        value={values?.clienteCelular}
                        error={Boolean(touched.clienteCelular && errors.clienteCelular)}
                        fullWidth
                        helperText={touched.clienteCelular && errors.clienteCelular}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        InputProps={{
                          inputComponent: TextMaskCustom
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                            fontWeight: 'bold'
                          },
                        }}
                      />
                    </Mui.Grid>
                    <Mui.Grid item md={12}>
                      <Mui.TextField
                        name="clienteNome"
                        label={'Nome'}
                        value={values?.clienteNome?.toUpperCase()}
                        error={Boolean(touched.clienteNome && errors.clienteNome)}
                        fullWidth
                        helperText={touched.clienteNome && errors.clienteNome}
                        disabled={loadingCliente}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: temaAtual?.theme === "DEFAULT" ? '#000' : "#FFF",
                            fontWeight: 'bold'
                          },
                        }}
                        inputProps={{
                          maxLength: 50
                        }}
                      />

                      {values?.intCliente?.fantasia && (
                        <Mui.Box mt={2}>
                          <Mui.Typography variant="body2">
                            <b>Nome fantasia/Apelido:</b> {values?.intCliente?.fantasia}
                          </Mui.Typography>
                        </Mui.Box>
                      )}

                      {values?.intCliente?.enderecoLogradouro && (
                        <Mui.Box mt={2}>
                          <Mui.Typography variant="body2">
                            {values?.intCliente?.enderecoLogradouro},
                            {values?.intCliente?.enderecoLogradouroNumero}
                            {' - '}
                            {values?.intCliente?.enderecoBairro}
                          </Mui.Typography>

                          <Mui.Typography variant="body2">
                            {values?.intCliente?.enderecoIntCidade?.cidade}/
                            {values?.intCliente?.enderecoIntCidade?.uf} -{' '}
                            {values?.intCliente?.enderecoCep}
                          </Mui.Typography>
                        </Mui.Box>
                      )}
                    </Mui.Grid>

                    {values?.intCliente?.mensagemVenda && showAvisoCliente && (
                      <Mui.Grid container item md={12}>
                        <Mui.Box flex={1} mt={1}>
                          <Alert severity="info" onClose={() => setshowAvisoCliente(false)}>
                            <AlertTitle>AVISO</AlertTitle>
                            <Mui.Typography variant="body2">
                              <b>{values?.intCliente?.mensagemVenda}</b>
                            </Mui.Typography>
                          </Alert>
                        </Mui.Box>
                      </Mui.Grid>
                    )}
                  </Mui.Grid>
                </Mui.DialogContent>
                <Mui.DialogActions>
                  <Mui.Box flex={1} display="flex" p={1}>
                    <Mui.Box
                      style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: cadastrarCliente ? 'space-between' : `flex-end`,
                        alignItems: 'center'
                      }}
                    >
                      {cadastrarCliente && (
                        <Mui.Button
                          size="large"
                          variant="outlined"
                          className="button-success"
                          startIcon={<MuiIcons.AddCircle />}
                          onClick={handleShowModalEndereco}
                        >
                          Adicionar endereço
                        </Mui.Button>
                      )}
                      <Mui.Box>
                        <Mui.Button
                          type="submit"
                          variant="contained"
                          size="large"
                          color="primary"
                          autoFocus
                          onClick={handleSubmit}
                          startIcon={<MuiIcons.CheckCircle />}
                        >
                          <span
                            style={{
                              paddingLeft: 8
                            }}
                          >
                            {cadastrarCliente && isOnline && !cliente?.intCliente?.id
                              ? 'Cadastrar'
                              : 'Confirmar'}
                          </span>
                        </Mui.Button>

                        <Mui.Button
                          startIcon={<MuiIcons.Close />}
                          size="large"
                          onClick={handleClose}
                          style={{ marginLeft: 16 }}
                        >
                          CANCELAR (ESC)
                        </Mui.Button>
                      </Mui.Box>
                    </Mui.Box>
                  </Mui.Box>
                </Mui.DialogActions>
              </form>
            </>
          )}
        </Formik>
      </Mui.Dialog>
      {showModalCadastrarEndereco.show && (
        <ModalCadastroEnderecoCliente
          showModal={showModalCadastrarEndereco.show}
          onClose={() => {
            setShowModalCadastrarEndereco({ show: false });
          }}
          data={endereco}
          clienteId={cliente?.id ?? null}
          callback={async values => {
            setEndereco({ ...values });
            setShowModalCadastrarEndereco({ show: false });
          }}
        />
      )}
    </>
  );
};

export default ModalIdentificarCliente;
