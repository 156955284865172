import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled(Paper)`
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;


export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  color: #fff;
  background-color: ${props => props.theme.palette.common.black};
  box-shadow: ${props => props.theme.shadows[6]};
`;
