import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import PedidoIdentificacao from '../../components/PedidoIdentificacao';
import PedidoParcelas from '../../components/PedidoParcelas';
import PedidoItens from '../../components/PedidoItens';

import { APP_ERROR } from '~/constants';
import pedidoService from '~/store/modules/pedido/pedido-service';

const ModalVisualizarPedido = ({ id, isDocumentoOffline, open, onClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pedido, setPedido] = useState(null);

  const loadData = async () => {
    try {
      setLoading(true);

      const { data } = await pedidoService.getId(id);
      setPedido({ ...data });
    } catch (error) {
      dispatch({ type: APP_ERROR, error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    pedidoService.setStategy(isDocumentoOffline);

    loadData();
  }, [isDocumentoOffline]);

  return (
    <>
      <Mui.Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Mui.Paper}
        maxWidth={'md'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Pedido de Venda
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ minWidth: 640, padding: 16 }}>
          {loading ? (
            <>
              <Mui.Box display="flex" justifyContent="center" alignItems="center" p={4}>
                <Mui.CircularProgress variant="indeterminate" color="primary" />
              </Mui.Box>
            </>
          ) : (
            <>
              <PedidoIdentificacao
                data={pedido}
                onRefresh={() => {
                  loadData();
                }}
              />

              <PedidoItens data={pedido?.itens}></PedidoItens>

              <PedidoParcelas data={pedido?.parcelas} />
            </>
          )}
        </Mui.DialogContent>
      </Mui.Dialog>
    </>
  );
};

export default ModalVisualizarPedido;
