import { Theme } from '@material-ui/core';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  html,
  body,
  #root {
    height: 100%;
  }
  html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    background: ${props => props.theme.palette.background.default};
  }
  body, input, button {
    font: 15px 'Montserrat', sans-serif;
  }
  button {
    cursor: pointer;
  }

  .priorityZIndex {
    z-index: 999999 !important;
  }

  .swal2-container {
    z-index: 9999;
  }
  .swal2-styled, .swal2-confirm, .swal2-deny, .swal2-cancel {
    margin: 4px;
  }

  .button-danger, .button-danger:hover{
    background-color: #d32f2f;
    color: #FFF;   
  }

  .button-warning, .button-warning:hover{
    background-color: orange;
    color: #FFF;   
  }

  .button-success, .button-success:hover{
    background-color: #4caf50;
    color: #FFF;   
  }

  .button-primary, .button-primary:hover{
    background-color: #0068e9;
    color: #FFF;   
  }

  iframe {
    pointer-events: none !important;
  }

 `;

// const useStyles = makeStyles(() => createStyles({
//   '@global': {
//     '*': {
//       boxSizing: 'border-box',
//       margin: 0,
//       padding: 0,
//     },
//     html: {
//       '-webkit-font-smoothing': 'antialiased',
//       '-moz-osx-font-smoothing': 'grayscale',
//       height: '100%',
//       width: '100%'
//     },
//     body: {
//       height: '100%',
//       width: '100%'
//     },
//     '#root': {
//       height: '100%',
//       width: '100%'
//     }

//   }
// }));

// const GlobalStyles = () => {
//   useStyles();

//   return null;
// };

// export default GlobalStyles;
