import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import { Brightness4 as IconDark, Brightness7 as IconLigth } from '@material-ui/icons';

import { THEMES, TIPO_DOCUMENTO } from '~/constants';
import * as appActions from '~/store/modules/app/app-actions';
import useSettings from '~/hooks/useSettings';

const ChangeTheme = () => {
  // const { theme } = useSelector(state => state.app);
  const { settings, saveSettings } = useSettings();

  const dispatch = useDispatch();
  return (
    <Tooltip title="Alterar tema claro/escuro">
      <IconButton
        style={{ marginLeft: 8 }}
        onClick={() =>
          saveSettings({
            theme: settings.theme === THEMES.DEFAULT ? THEMES.DARK : THEMES.DEFAULT
          })
        }
      >
        {settings.theme === THEMES.DEFAULT ? <IconDark /> : <IconLigth />}
      </IconButton>
    </Tooltip>
  );
};

export default ChangeTheme;
