import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

export const ButtonPagamento = styled(MuiButton)`
  padding: 0;
  height: 54px;
  margin-bottom: 16px;
`;

export const Selected = styled.div`
  background-color: ${props => (props?.selected ? '#09A848' : 'transparent')};
  border-radius: 4px;
  width: 6px;
  height: 54px;
`;
