import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import toast from '~/services/toast';
import * as appActions from '~/store/modules/app/app-actions';
import { SYNC_TYPE, APP_SYNC_CONFIGURATION, APP_SYNC_DATE } from '~/constants';

const DEFAULT_ITENS = [
  {
    title: 'Empresa',
    entity: 'empresa',
    sync: SYNC_TYPE.LOGIN,
    children: [
      {
        title: 'Parâmetro',
        entity: 'parametro'
      },
      {
        title: 'Sequência',
        entity: 'sequencia'
      }
    ]
  },
  {
    title: 'Usuário',
    entity: 'usuario',
    sync: SYNC_TYPE.LOGIN,
    children: [
      {
        title: 'Permissões de acesso',
        entity: 'permissao'
      },
      {
        title: 'vendedor',
        entity: 'vendedor'
      }
    ]
  },

  {
    title: 'Pagamaneto',
    entity: 'pagamento',
    sync: SYNC_TYPE.BACKGROUND,
    children: [
      {
        title: 'Meio Pagamento',
        entity: 'meiopagamento'
      },
      {
        title: 'Condição de pagamento',
        entity: 'condicaopagamento'
      },
      {
        title: 'Cartão bandeira',
        entity: 'cartaobandeira'
      },
      {
        title: 'Instituição financeira',
        entity: 'banco'
      }
    ]
  },
  {
    title: 'Fiscal/Tributação',
    entity: 'fiscal',
    sync: SYNC_TYPE.BACKGROUND,
    children: [
      {
        title: 'Operação',
        entity: 'operacao'
      },
      {
        title: 'Operação PDV',
        entity: 'operacaopdv'
      },
      {
        title: 'Regra CFOP',
        entity: 'cfopregra'
      },
      {
        title: 'Modelo de tributação',
        entity: 'modelotributacao'
      }
    ]
  },
  {
    title: 'Regras de venda',
    entity: 'regravenda',
    sync: SYNC_TYPE.BACKGROUND,
    children: [
      {
        title: 'Tabelas de venda',
        entity: 'tabelavenda'
      },
      {
        title: 'Regra de desconto',
        entity: 'regradesconto'
      },
      {
        title: 'Regra comercial',
        entity: 'regracomercial'
      }
    ]
  },
  {
    title: 'Modelidade de expedição',
    entity: 'modalidadeexpedicao',
    sync: SYNC_TYPE.BACKGROUND
  },

  {
    title: 'Produto',
    entity: 'produto',
    sync: SYNC_TYPE.MANUAL,
    subtitle: 'Impostos, Armazém, Armazém endereço',
    children: [
      {
        title: 'Grupo de produto',
        entity: 'produto_grupo'
      },
      {
        title: 'Saldo de estoque',
        entity: 'saldo_estoque'
      }
    ]
  },

  {
    title: 'Cliente',
    subtitle: 'Grupo de cliente',
    entity: 'cliente',
    sync: SYNC_TYPE.MANUAL
  }
];

const ModalDownload = () => {
  const dispatch = useDispatch();
  const { showModalDownload } = useSelector(state => state.app);
  const [itens, setItens] = useState(DEFAULT_ITENS);

  const handleChangeSyncItem = (item, sync) => {
    setItens(state => {
      const itensAtualizados = [...state];
      itensAtualizados[itensAtualizados?.indexOf(item)] = {
        ...item,
        sync
      };

      return [...itensAtualizados];
    });
  };

  const handleChangeCheckboxItem = (item, checked) => {
    setItens(state => {
      const itensAtualizados = [...state];
      itensAtualizados[itensAtualizados?.indexOf(item)] = {
        ...item,
        checked
      };

      return [...itensAtualizados];
    });
  };

  const handleSubmit = () => {
    sessionStorage.setItem(APP_SYNC_CONFIGURATION, JSON.stringify(itens));

    toast.success('Dados de sincronização salvo com sucesso!');
    handleClose();
  };

  const handleSync = () => {
    const entities = itens
      ?.filter(x => x?.checked)
      ?.map(x => (x.children?.length ? [x.entity, ...x.children?.map(x => x.entity)] : x.entity))
      .flat(Infinity);

    dispatch(appActions.downloadData([...entities]));
    handleClose();
  };

  const handleClose = () => {
    dispatch(appActions.setShowModalDownload({ show: false }));
  };

  useEffect(() => {
    const data = sessionStorage.getItem(APP_SYNC_CONFIGURATION, '');
    setItens(data ? JSON.parse(data)?.map(x => ({ ...x, checked: false })) : DEFAULT_ITENS);
  }, []);

  return (
    <>
      <Mui.Dialog
        open={showModalDownload?.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Sincronização
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />
              <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>
        <Mui.DialogContent style={{ width: 720 }} dividers>
          <Mui.List>
            {itens?.map((item, index) => (
              <Mui.ListItem key={`index-${index}`}>
                <Mui.Box display={'flex'} flexDirection={'row'} justifyItems={'center'}>
                  <Mui.Checkbox
                    checked={item?.checked}
                    onChange={e => {
                      handleChangeCheckboxItem(item, e.target.checked);
                    }}
                  />
                  <Mui.Box style={{ width: 340 }}>
                    <Mui.Typography>{item?.title}</Mui.Typography>
                    <small>
                      {item?.subtitle} {item?.subtitle && item?.children?.length > 0 ? ', ' : ''}
                      {item?.children?.map(x => x.title)?.join(', ')}
                    </small>
                  </Mui.Box>
                  <Mui.Select
                    id="tipo-sincronizacao"
                    variant="outlined"
                    fullWidth
                    style={{ height: 44, width: 260, marginLeft: 18 }}
                    value={item?.sync}
                    onChange={e => handleChangeSyncItem(item, e.target.value)}
                  >
                    {[
                      { title: 'Sincronizar após o login', value: SYNC_TYPE.LOGIN },
                      { title: 'Sincronizar em segundo plano', value: SYNC_TYPE.BACKGROUND },
                      { title: 'Sincronizar manualmente', value: SYNC_TYPE.MANUAL }
                    ]?.map((item, index) => (
                      <Mui.MenuItem key={`index-${index}`} value={item?.value}>
                        {item?.title}
                      </Mui.MenuItem>
                    ))}
                  </Mui.Select>
                </Mui.Box>
              </Mui.ListItem>
            ))}
          </Mui.List>
          <Alert severity="info" variant="filled" style={{ margin: '16px' }}>
            <Mui.Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Mui.Typography variant="body1">
                Data última sincronização:
                {JSON.parse(sessionStorage.getItem(APP_SYNC_DATE, ''))}hs
              </Mui.Typography>
            </Mui.Box>
          </Alert>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Box style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
            <Mui.Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => handleSync()}
              style={{ marginLeft: 16 }}
            >
              <MuiIcons.CloudDownloadSharp />
              <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                {itens?.some(x => x?.checked) ? 'Sincronizar selecionados' : 'Sincronizar tudo'}
              </Mui.Typography>
            </Mui.Button>
            <Mui.Box>
              <Mui.Button
                type="submit"
                size="large"
                variant="contained"
                className="button-success"
                onClick={() => handleSubmit()}
              >
                <MuiIcons.Save />
                <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                  Salvar
                </Mui.Typography>
              </Mui.Button>
              <Mui.Button size="large" style={{ marginLeft: 8 }} onClick={() => handleClose()}>
                <MuiIcons.Close />
                <Mui.Typography variant="span" style={{ paddingLeft: 8 }}>
                  Cancelar (ESC)
                </Mui.Typography>
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ModalDownload;
