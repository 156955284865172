import React, { useState } from 'react';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import Paper from '@material-ui/core/Paper';

import { Header } from './styles';

const ModalEnderecos = ({ open, data: { produto }, armazens, onClose }) => {
    const [dense, setDense] = useState(false);
    const [secondary, setSecondary] = useState(true);

  return (
    <>
      <Mui.Dialog
        open={open}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            onClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'lg'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box style={{ minWidth: 180 }}>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  Endereços
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={onClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>

          <Mui.Grid item md={12}>
            <Header>
              <Mui.Typography variant="caption">Produto</Mui.Typography>
              <Mui.Typography variant="h3">
                {produto?.codigo} {' - '}
                {produto?.descricao}
              </Mui.Typography>
            </Header>
          </Mui.Grid>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers>
            <Mui.Grid item xs={12} md={6}>
            <>
                <Mui.List dense={dense}>
                    { armazens.length > 0 &&
                        armazens.map(armazem => (
                          armazem.estArmazemEndereco &&
                          <Mui.ListItem>
                            <Mui.ListItemText
                                primary={armazem?.descricao}
                                secondary={armazem?.estArmazemEndereco?.map(endereco => endereco.endereco)}
                            />
                          </Mui.ListItem>
                        ))
                    }
                </Mui.List>
            </>
            </Mui.Grid>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Box p={1}>
            <Mui.Button size="large" onClick={onClose} style={{ marginLeft: 16 }}>
              Cancelar
            </Mui.Button>
          </Mui.Box>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ModalEnderecos;
