import React from 'react';

const ComplementaryInfo = ({ info }) => (
  <div style={{ border: '1px solid black', padding: '20px', borderTop: 'none', fontSize: '13px' }}>
    <div className="color-black">
      <strong>Info. Complementar</strong>
    </div>
    <p>{info ? info : 'Sem Informação Complementar.'}</p>
  </div>
);

export default ComplementaryInfo;