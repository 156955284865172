import React from 'react';
import { alpha, useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as Mui from "@material-ui/core";
import * as MuiIcons from "@material-ui/icons";
import Lottie from 'react-lottie';

import { SHOW_SHORTCUTS } from '~/constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

import emptyStateAnimation from '~/assets/animations/cart.json';

const CartEmpty = React.memo(() => {
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <Mui.Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 64
      }}
    >
      <Lottie
        style={{
          backgroundColor: alpha(theme.palette.common.black, 0.08),
          borderRadius: '100%',
          marginBottom: 16
        }}
        options={{
          loop: true,
          autoplay: true,
          animationData: emptyStateAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={256}
        width={256}
        isStopped={false}
        isPaused={false}
      />
      <Mui.Typography variant="h4" align="center">
        Carrinho vazio
      </Mui.Typography>
      <Mui.Typography variant="body1" align="center">
        Adicione um produto para iniciar a venda
      </Mui.Typography>

      <Mui.Button
        variant="contained"
        style={{ marginTop: 32 }}
        onClick={() => {
          dispatch(pdvActions.setShowModalConsultaProduto({ show: true }));
        }}
      >
        <MuiIcons.Search />
        <span style={{paddingLeft: 8}}>Pesquisar produto {SHOW_SHORTCUTS && '(F4)'}</span>
      </Mui.Button>
    </Mui.Box>
  );
});

export default CartEmpty;
