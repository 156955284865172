import {
  SEARCH_SUCCESS,
  SET_APPLICATION_MODE,
  SET_LOADING,
  SET_QUERY_TYPE
} from './produto-constants';

const produtoReducer = (
  state = {
    loading: false,
    items: [],
    queryType: 'pesquisa'
  },
  action
) => {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }
    case SET_APPLICATION_MODE:
      return {
        ...state,
        applicationMode: action.applicationMode
      };

    case SET_QUERY_TYPE: {
      return {
        ...state,
        queryType: action.queryType
      };
    }

    case SEARCH_SUCCESS: {
      return {
        ...state,
        items: [...action.items],
        loading: false
      };
    }

    default:
      return state;
  }
};

export default produtoReducer;
