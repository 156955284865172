import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import clsx from 'clsx';

import { APPLICATION } from '~/constants';
import * as appActions from '~/store/modules/app/app-actions';

const useStyles = makeStyles(theme => ({
  buttonConnection: {
    color: '#fff',
    height: 32,
    width: 128,
    borderRadius: 0
  },
  buttonSuccess: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[800]
    }
  },
  buttonError: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700]
    }
  }
}));

const ButtonChangeMode = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { isOffline } = useSelector(state => state.app);

  if (APPLICATION === 'web') return null;
  // return (
  //   <Mui.Tooltip title="Alternar online/offline">
  //     <Mui.IconButton
  //       className={isOffline ? classes.buttonError : classes.buttonSuccess}
  //       onClick={() => {
  //         dispatch(appActions.forceOfflineMode(!isOffline));
  //       }}
  //     >
  //       <>{isOffline ? <MuiIcons.WifiOff color="#FFF" /> : <MuiIcons.WifiSharp color="#FFF" />}</>
  //     </Mui.IconButton>
  //   </Mui.Tooltip>
  // );

  return (
    <Mui.Tooltip title="Alternar online/offline">
      <Mui.Button
        className={
          isOffline
            ? clsx(classes.buttonConnection, classes.buttonError)
            : clsx(classes.buttonConnection, classes.buttonSuccess)
        }
        onClick={() => {
          dispatch(appActions.forceOfflineMode(!isOffline));
        }}
      >
        <>
          {isOffline ? (
            <>
              <MuiIcons.WifiOff color="#FFF" />
              <Mui.Typography variant="span" style={{ fontSize: 12, marginLeft: 8, textTransform: 'none' }}>
                Offline
              </Mui.Typography>
            </>
          ) : (
            <>
              <MuiIcons.WifiSharp color="#FFF" />
              <Mui.Typography variant="span" style={{ fontSize: 12, marginLeft: 8, textTransform: 'none' }}>
                Online
              </Mui.Typography>
            </>
          )}
        </>
      </Mui.Button>
    </Mui.Tooltip>
  );
};

export default ButtonChangeMode;
