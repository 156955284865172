import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Skeleton from '@material-ui/lab/Skeleton';
import { Search as IconSearch, HighlightOff as IconHighlightOff } from '@material-ui/icons';

import { SHOW_SHORTCUTS } from '~/constants';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';

const CardProfissional = React.memo(() => {
  const dispatch = useDispatch();
  const { venda, showModalLoadingMensagem } = useSelector(state => state.pdv);

  const handleConsultarProfissional = () => {
    dispatch(pdvActions.setShowModalConsultaProfissional({ show: true }));
  };

  const handleRemoverProfissional = () => {
    dispatch(pdvActions.setRemoverProfissional({ show: true }));
  };

  return (
    <Paper>
      <Box p={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Box mr={1}>
          <Typography variant="caption">Profissional {SHOW_SHORTCUTS && '(CTRL+P)'}</Typography>
          {showModalLoadingMensagem?.show ? (
            <Skeleton variant="text" width={180} />
          ) : (
            <Typography variant="body2">
              <b>{venda?.fatProfissional ? venda?.fatProfissional?.nome : 'INFORMAR PROFISSIONAL'}</b>
            </Typography>
          )}
        </Box>
        <Box display="flex" mr={1} justifyContent="center" alignItems="center">
          {venda?.fatProfissional?.nome && 
          <Fab
            size="small"
            aria-label="Remover profissional"
            title="Remover profissional"
            onClick={() => handleRemoverProfissional()}
            style={{marginRight: '15px'}}
          >
            <IconHighlightOff />
          </Fab>}
          <Fab
            size="small"
            aria-label="Consulta profissional"
            onClick={() => handleConsultarProfissional()}
          >
            <IconSearch />
          </Fab>
        </Box>
      </Box>
    </Paper>
  );
});

export default CardProfissional;
